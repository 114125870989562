import * as React from 'react'
import useMedia from 'hooks/useMedia'
import Button from 'components/atoms/button'
import OnboardingCardItem from 'components/atoms/onboarding-card-item'

import styles from './style.module.css'

type OnboardingCardProps = {
    onLogin: () => void
    onRegister: () => void
    onClose: () => void
}

const OnboardingCard: React.FC<OnboardingCardProps> = ({ onLogin, onRegister, onClose }) => {
    const isMobile = useMedia('(max-width: 1024px)')

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <OnboardingCardItem title='Canlı Grup Dersleri ve Kişiselleştirilmiş Antrenmanlar ile Harika Hissedin!'
                                    description='Kişisel bilgilerini ve hedeflerini söyle, senin için en uygun programı birlikte oluşturalım.'
                                    image={'/images/onboarding.png'} />
            </div>

            <div className={styles.actions}>
                {isMobile ? (
                    <>
                        <Button onClick={onRegister} type='danger' variation='block'>
                            Hesap Oluştur
                        </Button>
                        <Button onClick={onLogin} type='danger-2' variation='block'>
                            Giriş Yap
                        </Button>
                    </>
                ) : (
                    <Button onClick={onClose} type='danger-2' variation='wide'>
                        Pencereyi Kapat
                    </Button>
                )}
            </div>
        </div>
    )
}

export default OnboardingCard
