import Config from 'config'
import * as React from 'react'
import classnames from 'classnames'
import useMedia from 'hooks/useMedia'
import { toast } from 'react-toastify'
import Text from 'components/atoms/text'
import { RoomInfo } from '../live-modal'
import { MutatingDots } from 'react-loader-spinner'
import ContactModal from '../contact-modal'
import Card from 'components/molecules/card'
import { useHistory } from 'react-router-dom'
import ListBox from 'components/atoms/listbox'
import ConfirmModalCard from '../confirm-modal-card'
import ToggleButton from 'components/atoms/toggle-button'
import Modal, { ModalSize, ModalType } from 'components/atoms/modal'
import AgoraRTC, { AgoraVideoPlayer, AudienceLatencyLevelType, createClient, ICameraVideoTrack, IMicrophoneAudioTrack } from 'custom-agora-rtc-react'
import { CameraLine, CameraOffLine, FullscreenExitLine, FullscreenLine, MicLine, MicOffLine, UserSettingsLine } from 'components/icons'

import styles from './style.module.css'

// Agora Clients
// @ts-ignore
const client = createClient({ mode: 'live', codec: 'vp8', role: 'host', clientRoleOptions: {level: AudienceLatencyLevelType.AUDIENCE_LEVEL_LOW_LATENCY}, audienceLatency: 1 })

// Toggle Agora Logs (4: None)
AgoraRTC.setLogLevel(4)

const AgoraHost = ({ room, onlyVideo, onClick }: { room: RoomInfo, onlyVideo: boolean, onClick: () => void }) => {
    const isMobile = useMedia('(max-width: 1024px)')
    const history = useHistory()

    // Buttons States
    const [isCameraActive, setCameraActive] = React.useState(true)
    const [isMicrophoneActive, setMicrophoneActive] = React.useState(true)

    // Settings Modal States
    const [isModalVisible, setModalVisible] = React.useState(false)
    const [showErrorModal, setShowErrorModal] = React.useState(false)
    const [contactModal, setContactModal] = React.useState(false)

    const [availableCameras, setAvailableCameras] = React.useState<MediaDeviceInfo[]>([])
    const [selectedCamera, setSelectedCamera] = React.useState(0)

    const [availableMicrophones, setAvailableMicrophones] = React.useState<MediaDeviceInfo[]>([])
    const [selectedMicrophone, setSelectedMicrophone] = React.useState(0)

    const [availableSpeakers, setAvailableSpeakers] = React.useState<MediaDeviceInfo[]>([])
    const [selectedSpeaker, setSelectedSpeaker] = React.useState(0)

    const [fullVideo, setFullVideo] = React.useState(true)

    // Video Call States
    const [tracksInit, setTracksInit] = React.useState(false)

    // User Room States
    const [isUserJoin, setUserJoin] = React.useState<boolean>(false)

    // Create Stream Tracks
    const [tracks, setTracks] = React.useState<[IMicrophoneAudioTrack, ICameraVideoTrack] | null>(null)

    React.useEffect(() => {
        AgoraRTC.createMicrophoneAndCameraTracks({ encoderConfig: 'high_quality_stereo' }, { encoderConfig: '720p_2' })
            .then(x => setTracks(x))
            .catch(x => {
                if (x.code === 'NOT_READABLE') {
                    toast.error('Kamera Kullanımda')
                }
                setShowErrorModal(true)
            })
    }, [])

    // Get Device Camera And Microphone Options
    React.useEffect(() => {
        if (isModalVisible) {
            (async () => {
                const getCameras = await AgoraRTC.getCameras(false)
                if (getCameras.length > 0) {
                    setAvailableCameras(getCameras)
                }

                const getMicrophones = await AgoraRTC.getMicrophones(false)
                if (getMicrophones.length > 0) {
                    setAvailableMicrophones(getMicrophones)
                }

                const getSpeakers = await AgoraRTC.getPlaybackDevices(false)
                if (getSpeakers.length > 0) {
                    setAvailableSpeakers(getSpeakers)
                }
            })()
        }
    }, [isModalVisible])

    // Current Camera Switch
    React.useEffect(() => {
            if (!tracks || !tracks[1]) return
            (async () => {
                await tracks[1].setDevice(availableCameras[selectedCamera].deviceId)
            })()
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedCamera])

    // Current Microphone Switch
    React.useEffect(() => {
            if (!tracks || !tracks[0]) return
            (async () => {
                await tracks[0].setDevice(availableMicrophones[selectedMicrophone].deviceId)
            })()
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedMicrophone])

    // Current Speaker Switch
    React.useEffect(() => {
            if (!tracks || !tracks[0]) return
            (async () => {
                await tracks[0].setPlaybackDevice(availableSpeakers[selectedSpeaker].deviceId)
            })()
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedSpeaker])

    // Enable Video Or Audio Track
    const openTrack = (async (track: IMicrophoneAudioTrack | ICameraVideoTrack) => {
        try {
            await track.setEnabled(true)
        } catch {
        }
    })

    // Disable Video Or Audio Track
    const closeTrack = (async (track: IMicrophoneAudioTrack | ICameraVideoTrack) => {
        try {
            await track.setEnabled(false)
        } catch {
        }
    })

    // Toggle Camera Track
    React.useEffect(() => {
        if (!Array.isArray(tracks)) return
        const trackVideo = tracks.find(x => x.trackMediaType === 'video')
        if (!trackVideo) return

        if (isCameraActive) {
            openTrack(trackVideo)
        } else {
            closeTrack(trackVideo)
        }
    }, [isCameraActive, tracks])

    // Toggle Microphone Track
    React.useEffect(() => {
        if (!Array.isArray(tracks)) return
        const trackAudio = tracks.find(x => x.trackMediaType === 'audio')
        if (!trackAudio) return

        if (isMicrophoneActive) {
            openTrack(trackAudio)
        } else {
            closeTrack(trackAudio)
        }
    }, [isMicrophoneActive, tracks])

    // Video And Microphone Tracks Publish
    const tracksPublish = async (initialTrucks: [IMicrophoneAudioTrack, ICameraVideoTrack]) => {
        // console.log({ note: 'Video And Microphone Tracks Publish', tracksInit, initialTrucks })
        if (tracksInit) return null

        try {
            await client().publish(initialTrucks)
            setTracksInit(true)
            return true
        } catch (e) {
            setTracksInit(false)
            setCameraActive(false)
            toast.error('Kameraya Bağlanamadı')
        }
        return false
    }

    // Video Call Effects
    React.useEffect(() => {
            (async () => {
                // console.log({ note: 'Video Call Effects', client })
                if (!isUserJoin && room.token) {
                    try {
                        await client().join(Config.agora.appId, room.channelName, room.token, room.uid)
                        setUserJoin(true)
                        // console.log({ note: 'joinden sonrası', tracks, isUserJoin })
                    } catch {
                        setUserJoin(false)
                    }
                }
            })()
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [tracks])

    React.useEffect(() => {
            if (tracks && isUserJoin) {
                tracksPublish(tracks)
            }
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [isUserJoin, tracks])

    React.useEffect(() => {
        return (() => {
            if (Array.isArray(tracks)) {
                closeTrack(tracks[0])
                closeTrack(tracks[1])
            }

            client().unpublish(tracks ?? undefined)
        })
    }, [tracks])

    React.useEffect(() => {
        if (isUserJoin && !AgoraRTC.checkSystemRequirements()) {
            toast.error('Sistem Gereksinimleri Karşılanmamaktadır.')
        }
    }, [isUserJoin])

    React.useEffect(() => {
        return (() => {
            client().leave()
        })
    }, [])

    return (
        <div className={styles.live}>
            <div className={styles['wrapper']}>
                <div className={classnames(styles['videos'])} onClick={() => onClick()}>
                    {(tracks && tracks[1] && isUserJoin) ? (
                        <AgoraVideoPlayer
                            className={classnames(styles['vid'], styles.video, { [styles.fullVideo]: fullVideo })}
                            videoTrack={tracks[1]}
                        />
                    ) : (
                        <div className={classnames(styles['vid'], styles.video, styles['no-cam'])}>
                            <MutatingDots color={'#334362'} secondaryColor={'#e3352a'} height={100} width={100} />
                            <Text weight={'medium'} size={'2xl'}>Bağlantı Bekleniyor...</Text>
                        </div>
                    )}

                </div>
                <div className={classnames(styles.footer, { [styles.hide]: onlyVideo && isMobile, [styles.notHide]: !onlyVideo && isMobile })}>
                    <div className={styles.footerGroup}>
                        <ToggleButton active={!isCameraActive} onChange={setCameraActive} inactiveIcon={<CameraLine />} activeIcon={<CameraOffLine />} />
                        <ToggleButton active={!isMicrophoneActive} onChange={setMicrophoneActive} inactiveIcon={<MicLine />} activeIcon={<MicOffLine />} />
                    </div>
                    <div className={styles.footerGroup}>
                        <ToggleButton active={!fullVideo} onChange={() => setFullVideo(x => !x)} inactiveIcon={<FullscreenExitLine />} activeIcon={<FullscreenLine />} />
                        <ToggleButton active={!isModalVisible} onChange={() => setModalVisible(x => !x)} inactiveIcon={<UserSettingsLine />} activeIcon={<UserSettingsLine />} />
                    </div>
                </div>
            </div>

            <Modal isVisible={isModalVisible} onModalClose={() => setModalVisible(false)} type={ModalType.Centered} size={ModalSize.Small}>
                <Card heading={{ title: 'Ayarlar', divider: true }} type={'light'}>
                    <div style={{ display: 'grid', gap: '0.5rem' }}>

                        <Text size={'lg'} type={'default'} weight={'default'} decoration={'default'}>Kamera</Text>
                        <ListBox
                            value={selectedCamera}
                            text={availableCameras[selectedCamera]?.label ?? 'Kamera'}
                            onChange={setSelectedCamera}
                            options={availableCameras}
                            disabled={availableCameras.length < 2}
                            valueIndexBased
                        />

                        <Text size={'lg'} type={'default'} weight={'default'} decoration={'default'}>Mikrofon</Text>
                        <ListBox
                            value={selectedMicrophone}
                            text={availableMicrophones[selectedMicrophone]?.label ?? 'Mikrofon'}
                            onChange={setSelectedMicrophone}
                            options={availableMicrophones}
                            disabled={availableMicrophones.length < 2}
                            valueIndexBased
                        />

                        <Text size={'lg'} type={'default'} weight={'default'} decoration={'default'}>Ses</Text>
                        <ListBox
                            value={selectedSpeaker}
                            text={availableSpeakers[selectedSpeaker]?.label ?? 'Ses'}
                            onChange={setSelectedSpeaker}
                            options={availableSpeakers}
                            disabled={availableSpeakers.length < 2}
                            valueIndexBased
                        />

                    </div>
                </Card>
            </Modal>

            <ConfirmModalCard
                title={'Bağlantı Problemi'}
                description={'Kameranızın kullanımda olmadığından emin olun ve sayfayı yeniden yükleyin, Herhangi bir sorun olmadığını düşünüyorsanız bizimle iletişime geçiniz'}
                confirmButtonText={'Yeniden Yükle'}
                cancelButtonText={'İletişime Geç'}
                isVisible={showErrorModal}
                onModalClose={() => history.go(0)}
                onConfirmButtonClick={() => history.go(0)}
                onCancelButtonClick={() => {
                    setShowErrorModal(false)
                    setContactModal(true)
                }}
            />

            <ContactModal isVisible={contactModal} onModalClose={(confirm) => {
                if (confirm) {
                    history.push({ pathname: '/canli-yayin-takvimi' })
                } else {
                    setContactModal(false)
                    setShowErrorModal(true)
                }
            }} />

        </div>
    )
}

export default React.memo(AgoraHost)
