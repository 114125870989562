import * as React from 'react'
import { Product } from '../types'

type PaymentContextProps = {
    modalShown: boolean
    hideModal: () => void
    cancelPayment: () => void
    showModal: () => void
    setModalShown: React.Dispatch<React.SetStateAction<boolean>>

    product: Product
    setProduct: React.Dispatch<React.SetStateAction<Product | undefined>>
    loading: boolean
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export const PaymentContext = React.createContext<Partial<PaymentContextProps>>({})

export const PaymentProvider: React.FC = ({ children }) => {
    const [modalShown, setModalShown] = React.useState<boolean>(false)
    const [product, setProduct] = React.useState<Product | undefined>(undefined)
    const [loading, setLoading] = React.useState<boolean>(false)

    const showModal = () => {
        setModalShown(true)
    }

    const hideModal = () => {
        setModalShown(false)
    }

    const cancelPayment = () => {
        setModalShown(false)
        setProduct(undefined)
    }

    return (
        <PaymentContext.Provider
            value={{ modalShown, showModal, hideModal, setModalShown, product, setProduct, cancelPayment, loading, setLoading }}>{children}</PaymentContext.Provider>
    )
}
