import * as React from "react";

function SvgPage500(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={265.591}
      height={364.695}
      viewBox="0 0 265.591 364.695"
      {...props}
    >
      <g data-name="500 Internal Server Error-amico">
        <g
          data-name="freepik--server--inject-55"
          transform="translate(0 87.039)"
        >
          <path
            data-name="Path 6334"
            d="M75.043.509l63.52 36.668a4.706 4.706 0 012.125 3.677v192.9a4.706 4.706 0 01-2.125 3.687l-68.7 39.7a4.686 4.686 0 01-4.25 0L2.096 240.434a4.686 4.686 0 01-2.1-3.677V43.851a4.706 4.706 0 012.125-3.687L70.828.508a4.686 4.686 0 014.211 0z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6335"
            d="M0 43.842v192.915a4.725 4.725 0 002.125 3.677l63.487 36.706a4.231 4.231 0 002.125.514V79.801a4.357 4.357 0 01-2.125-.514L2.096 42.62l-.582-.485-.757-.621A4.434 4.434 0 000 43.842z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6336"
            d="M0 43.842v192.915a4.725 4.725 0 002.125 3.677l63.487 36.706a4.231 4.231 0 002.125.514V79.801a4.357 4.357 0 01-2.125-.514L2.096 42.62l-.582-.485-.757-.621A4.434 4.434 0 000 43.842z"
            opacity={0.3}
          />
          <path
            data-name="Path 6337"
            d="M2.096 40.165L70.832.509a4.667 4.667 0 014.24 0l63.516 36.668a1.3 1.3 0 010 2.455L69.862 79.297a4.745 4.745 0 01-4.25 0L2.096 42.62a1.3 1.3 0 010-2.455z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6338"
            d="M2.096 40.165L70.832.509a4.667 4.667 0 014.24 0l63.516 36.668a1.3 1.3 0 010 2.455L69.862 79.297a4.745 4.745 0 01-4.25 0L2.096 42.62a1.3 1.3 0 010-2.455z"
            opacity={0.15}
          />
          <path
            data-name="Path 6339"
            d="M133.057 171.01l-57 32.912v60.5l57-32.912z"
            fill="#455a64"
          />
          <path
            data-name="Path 6340"
            d="M105.044 214.227a.6.6 0 00-.592.058l-23.189 13.368a1.737 1.737 0 00-.592.621l-1.553-.9a1.717 1.717 0 01.582-.621l22.7-13.05a1.8 1.8 0 011.659 0z"
            fill="#263238"
          />
          <path
            data-name="Path 6341"
            d="M80.428 229.073v25.975c0 .534.378.747.834.485l23.19-13.322a1.844 1.844 0 00.834-1.436v-26c0-.534-.378-.747-.834-.485l-23.19 13.371a1.834 1.834 0 00-.834 1.412z"
            fill="#37474f"
          />
          <path
            data-name="Path 6342"
            d="M80.653 255.581l-.97-.553a1.834 1.834 0 01-.83-1.434V228.17a1.649 1.649 0 01.243-.825l1.552.9a1.756 1.756 0 00-.243.825v25.975a.631.631 0 00.243.534z"
            fill="#263238"
          />
          <path
            data-name="Path 6343"
            d="M82.281 253.195a.447.447 0 01-.194-.049.5.5 0 01-.213-.446v-23.1a1.3 1.3 0 01.592-.97l20.672-11.89a.485.485 0 01.495 0 .5.5 0 01.213.446v23.084a1.281 1.281 0 01-.582 1.019l-20.673 11.886a.621.621 0 01-.311.019zm21.157-36.328a.4.4 0 00-.175.048l-20.687 11.9a1.048 1.048 0 00-.466.805v23.08c0 .116 0 .2.087.233a.252.252 0 00.252 0l20.677-11.886a1.048 1.048 0 00.466-.805v-23.123c0-.116 0-.2-.087-.233a.117.117 0 00-.068-.019z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6344"
            d="M83.911 250.157a.2.2 0 01-.116 0 .252.252 0 01-.126-.243v-19.619a.7.7 0 01.281-.485l17.669-10.178a.272.272 0 01.281 0 .281.281 0 01.126.252v19.619a.621.621 0 01-.281.495l-17.65 10.14a.31.31 0 01-.184.019zm0-.233zm17.854-30.077l-17.679 10.186a.485.485 0 00-.155.272v19.668l17.64-10.14a.378.378 0 00.165-.281v-19.62a.058.058 0 00.039-.107z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6345"
            d="M85.105 224.638a.243.243 0 01-.233-.243v-5.525a.233.233 0 01.466 0v5.531a.243.243 0 01-.233.237z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6346"
            d="M88.977 222.417a.233.233 0 01-.233-.233v-5.54a.233.233 0 01.466 0v5.54a.233.233 0 01-.233.233z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6347"
            d="M92.847 220.194a.233.233 0 01-.243-.233v-5.531a.243.243 0 01.475 0v5.531a.233.233 0 01-.232.233z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6348"
            d="M96.71 217.958a.233.233 0 01-.233-.233v-5.54a.243.243 0 01.475 0v5.54a.243.243 0 01-.242.233z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6349"
            d="M100.581 215.76a.233.233 0 01-.233-.233v-5.531a.233.233 0 01.233-.233.223.223 0 01.233.233v5.531a.223.223 0 01-.233.233z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6350"
            d="M105.044 193.793a.582.582 0 00-.592.058l-23.19 13.342a1.785 1.785 0 00-.592.611l-1.552-.894a1.64 1.64 0 01.582-.621l22.7-13.06a1.853 1.853 0 011.659 0z"
            fill="#263238"
          />
          <path
            data-name="Path 6351"
            d="M80.428 208.629v13.1c0 .534.378.747.834.485l23.19-13.342a1.843 1.843 0 00.834-1.436v-13.11c0-.524-.378-.737-.834-.475l-23.19 13.342a1.824 1.824 0 00-.834 1.436z"
            fill="#37474f"
          />
          <path
            data-name="Path 6352"
            d="M80.653 222.263l-.97-.553a1.834 1.834 0 01-.825-1.436v-12.548a1.649 1.649 0 01.243-.815l1.552.893a1.785 1.785 0 00-.243.825v13.109a.621.621 0 00.243.525z"
            fill="#263238"
          />
          <path
            data-name="Path 6353"
            d="M82.281 219.893a.446.446 0 01-.194-.049.5.5 0 01-.213-.446V209.22a1.3 1.3 0 01.592-.97l20.672-11.896a.446.446 0 01.708.417v10.2a1.281 1.281 0 01-.582 1.019l-20.673 11.884a.621.621 0 01-.311.019zm21.157-23.452a.31.31 0 00-.175.058L82.572 208.38a1.048 1.048 0 00-.466.805v10.2c0 .116 0 .2.087.233a.252.252 0 00.252 0l20.677-11.886a1.048 1.048 0 00.466-.805V196.68a.281.281 0 00-.087-.243z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6354"
            d="M83.911 216.847a.2.2 0 01-.116 0 .252.252 0 01-.126-.242v-6.7a.737.737 0 01.281-.495l17.669-10.181a.291.291 0 01.281 0 .262.262 0 01.126.243v6.792a.621.621 0 01-.281.495l-17.65 10.14a.32.32 0 01-.184-.049zm0-.243zm17.853-17.223L84.086 209.56a.543.543 0 00-.155.281v6.744l17.64-10.14a.378.378 0 00.165-.281v-6.792s.039.019.039.01z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6355"
            d="M109.731 191.124a3.047 3.047 0 00-1.378 2.377c0 .873.621 1.223 1.378.786a3.047 3.047 0 001.368-2.368c0-.873-.621-1.233-1.368-.795z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6356"
            d="M109.731 214.411a3.047 3.047 0 00-1.378 2.377c0 .873.621 1.223 1.378.786a3.047 3.047 0 001.368-2.368c0-.868-.621-1.231-1.368-.795z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6357"
            d="M109.731 220.68a3.027 3.027 0 00-1.378 2.368c0 .873.621 1.223 1.378.786a3.027 3.027 0 001.368-2.368c0-.873-.621-1.223-1.368-.786z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6358"
            d="M109.731 226.938a3.047 3.047 0 00-1.378 2.377c0 .864.621 1.223 1.378.786a3.047 3.047 0 001.368-2.368c0-.872-.621-1.232-1.368-.795z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6359"
            d="M109.731 233.205a3.027 3.027 0 00-1.378 2.367c0 .873.621 1.223 1.378.786a3.047 3.047 0 001.368-2.368c0-.867-.621-1.221-1.368-.785z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6360"
            d="M109.731 196.781a3.047 3.047 0 00-1.378 2.377c0 .873.621 1.223 1.378.786a3.047 3.047 0 001.368-2.368c0-.873-.621-1.232-1.368-.795z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6361"
            d="M109.731 202.431a3.047 3.047 0 00-1.378 2.377c0 .873.621 1.223 1.378.786a3.047 3.047 0 001.368-2.368c0-.866-.621-1.225-1.368-.795z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6362"
            d="M124.702 194.081l-.136-1.271a.136.136 0 00-.175-.126 2.854 2.854 0 01-.631.068.291.291 0 00-.252.126l-.281.417 1.494.854a.136.136 0 01-.019-.068zm1.08-8.9a2.908 2.908 0 010 .3 4.851 4.851 0 01-2.212 3.881l-.272.146c.136 1.184 1.067 1.62 2.193.97a4.852 4.852 0 002.212-3.833c.017-1.364-.827-1.944-1.921-1.464zm4.793-1.126l-1.5-.873-.223.456a.32.32 0 000 .281 2.91 2.91 0 01.262.582.136.136 0 00.194.087l1.164-.514a.116.116 0 01.1 0zm-2.911-3.318l-.844-.495h-.126l-.689 1.038-.873-.5-.631-.369h-.068l-.9.524-.408.233h-.049v.058a.4.4 0 000 .078l-.126 1.465a.166.166 0 010 .048.107.107 0 010 .049.591.591 0 01-.107.175 7.886 7.886 0 00-.737.767.136.136 0 01-.107.068.253.253 0 01-.146 0l-.844-.291h-.194l-.4.689-.417.728-.146.252a.2.2 0 000 .126.223.223 0 00.068.126l.679.582.049.068a.223.223 0 010 .107.184.184 0 010 .078 8.163 8.163 0 00-.31 1.019.5.5 0 01-.078.165l-.087.1-1.193.834a1.208 1.208 0 00-.087.1.33.33 0 000 .165v1.514a.116.116 0 000 .1l1.523.873-.553 1.116a.175.175 0 00.068.223l1.844 1.067h.078a.448.448 0 01-.068-.068.194.194 0 010-.165l.776-1.562a.136.136 0 000-.068.281.281 0 000-.213 3.19 3.19 0 01-.252-.582.136.136 0 00-.194-.087l-.592.262-.582.262a.087.087 0 01-.1 0 .1.1 0 01-.049-.1v-1.514a.32.32 0 01.049-.155.1.1 0 01.087-.1l1.193-.844a.311.311 0 00.1-.1.359.359 0 00.068-.155 8.16 8.16 0 01.311-1.019.243.243 0 000-.087.262.262 0 00-.078-.175l-.679-.582a.155.155 0 01-.058-.116.194.194 0 010-.126l.281-.475.33-.592.349-.6a.243.243 0 01.155-.1h.087l.834.291a.214.214 0 00.155 0 .252.252 0 00.107-.068 7.973 7.973 0 01.728-.776.408.408 0 00.107-.165.223.223 0 000-.107l.136-1.455a.232.232 0 010-.078.33.33 0 01.136-.165l.243-.146.524-.3.543-.31a.1.1 0 01.087 0 .256.256 0 01.058.087l.068.67.058.6a.136.136 0 00.175.126 2.912 2.912 0 01.631-.068.233.233 0 00.184-.058.156.156 0 00.068-.068l.97-1.455a.165.165 0 01.223-.049z"
            fill="#263238"
          />
          <path
            data-name="Path 6363"
            d="M124.722 182.499a.233.233 0 000 .078l-.136 1.455a.223.223 0 010 .107l-1.941-1.116v-.048a.167.167 0 000-.048l.126-1.465a.4.4 0 010-.078z"
            opacity={0.1}
          />
          <path
            data-name="Path 6364"
            d="M123.597 185.147a.214.214 0 01-.155 0l-.834-.291h-.087l-1.941-1.1h.058l.844.291a.253.253 0 00.146 0z"
            opacity={0.15}
          />
          <path
            data-name="Path 6365"
            d="M122.199 187.612l-1.941-1.106v-.1l-.049-.068-.679-.582a.223.223 0 01-.068-.126l1.941 1.116a.155.155 0 00.058.116l.679.582a.262.262 0 01.059.168z"
            opacity={0.15}
          />
          <path
            data-name="Path 6366"
            d="M122.189 192.036a.136.136 0 010 .068l-.776 1.562a.194.194 0 000 .165l-1.834-1.067a.175.175 0 01-.068-.223l.553-1.116.408.233a.087.087 0 00.1 0l.582-.262z"
            opacity={0.15}
          />
          <path
            data-name="Path 6367"
            d="M124.702 194.084a.136.136 0 000 .068l-1.494-.854.281-.417a.291.291 0 01.252-.126 2.853 2.853 0 00.631-.068.136.136 0 01.175.126z"
            opacity={0.15}
          />
          <path
            data-name="Path 6368"
            d="M128.7 181.353a.165.165 0 00-.223.049l-.97 1.455a.155.155 0 01-.068.068l-.543-.311-.5-.291-.068-.669s0-.078-.058-.087l-.388-.233.689-1.038a.214.214 0 01.107 0l.844.495z"
            opacity={0.1}
          />
          <path
            data-name="Path 6369"
            d="M121.811 188.844a.31.31 0 01-.1.1l-1.193.844a1.21 1.21 0 00-.087.1l-1.941-1.116a.342.342 0 01.087-.1l1.193-.834.087-.1z"
            opacity={0.15}
          />
          <path
            data-name="Path 6370"
            d="M130.486 184.06l-1.164.524a.136.136 0 01-.194-.087 2.911 2.911 0 00-.262-.582.32.32 0 010-.281l.776-1.562a.184.184 0 00-.068-.233l-.844-.485a.175.175 0 00-.233.048l-.97 1.455a.291.291 0 01-.252.126 2.912 2.912 0 00-.631.068.136.136 0 01-.175-.126l-.126-1.271a.1.1 0 00-.155-.078l-1.31.757a.32.32 0 00-.155.243l-.136 1.455a.456.456 0 01-.136.272 7.975 7.975 0 00-.728.776.262.262 0 01-.262.068l-.834-.291a.213.213 0 00-.243.087l-.97 1.669a.2.2 0 000 .243l.679.582a.262.262 0 01.068.262 8.162 8.162 0 00-.311 1.019.4.4 0 01-.165.252l-1.193.844a.33.33 0 00-.136.252v1.514a.1.1 0 00.146.1l1.174-.524a.136.136 0 01.194.087 3.194 3.194 0 00.252.582.291.291 0 010 .281l-.776 1.562a.184.184 0 00.068.233l.844.485a.175.175 0 00.233 0l.97-1.455a.291.291 0 01.252-.126 2.852 2.852 0 00.631-.068.136.136 0 01.175.126l.136 1.271c0 .087.068.126.155.078l1.31-.757a.369.369 0 00.155-.243l.126-1.455a.456.456 0 01.136-.272 6.951 6.951 0 00.725-.776.262.262 0 01.262-.068l.844.291a.2.2 0 00.233-.087l.97-1.669a.194.194 0 000-.243l-.679-.582a.262.262 0 01-.068-.262 8.163 8.163 0 00.311-1.019.446.446 0 01.165-.252l1.193-.844a.33.33 0 00.136-.252v-1.513a.103.103 0 10-.146-.146zm-4.978 6.365c-1.223.708-2.212.136-2.212-1.281a4.852 4.852 0 012.212-3.835c1.223-.708 2.212-.136 2.212 1.281a4.852 4.852 0 01-2.212 3.833z"
            fill="#37474f"
          />
          <path
            data-name="Path 6371"
            d="M121.472 197.208h-.058l-.311-.175-.883-.514h-.194l-.825.078h-.078l-.446 1.193a.223.223 0 01-.068.1l-.107.078-.272.136-.854-.495a.155.155 0 00-.087 0l-.64.679-.262.272a.126.126 0 000 .058v.058a.107.107 0 000 .048l.252.854v.107a.407.407 0 010 .1 5.984 5.984 0 00-.417.718.384.384 0 01-.194.146l-.776.194h-.058a.1.1 0 00-.049 0 .068.068 0 000 .058l-.291.97-.1.349v.1l.514.3h.068l.281.155v.349a.272.272 0 01-.068.2l-.786.97a.33.33 0 000 .107.117.117 0 000 .1l.369.815 1.562.912h.078v-.078l-.184-.4-.155-.349a.194.194 0 010-.107.136.136 0 01.049-.087l.786-.97a.3.3 0 000-.1.291.291 0 000-.116 3.967 3.967 0 010-.679.116.116 0 00-.116-.116h-.767a.087.087 0 010-.087l.175-.592.194-.679v-.058a.252.252 0 01.146-.087l.776-.194a.155.155 0 00.1-.049.185.185 0 00.1-.1 7.664 7.664 0 01.417-.718.292.292 0 000-.1.214.214 0 000-.107l-.252-.854a.165.165 0 010-.1.165.165 0 01.058-.1l.611-.65.291-.311s.1-.058.126 0l.417.64h.175a4.132 4.132 0 01.514-.3.261.261 0 00.1-.058.252.252 0 00.078-.107l.446-1.2v-.058l.3-.078.66-.068h.068zm-3.493 8.733a.126.126 0 00-.078-.146 1.466 1.466 0 01-.359-.213.175.175 0 00-.194 0l-.834.767a.2.2 0 01-.1 0l.825.475.514.3a.087.087 0 010-.078zm3.687-8.655a.1.1 0 010 .087l-.262 1.174a.136.136 0 00.087.146 1.943 1.943 0 01.359.213h.184l.834-.776c.049 0 .1-.048.126 0zm-1.1 8.733l-.417-.64c0-.048-.107-.048-.175 0l-.252.155.864.5zm.67-5.346a.825.825 0 00-.65-.514h-.1a1.349 1.349 0 00-.582.126 1.643 1.643 0 010 .223 3.629 3.629 0 01-1.659 2.862l-.183.109a1.2 1.2 0 00.107.427.815.815 0 00.776.524h.32a2.348 2.348 0 001.067-.7 3.134 3.134 0 00.951-3.056zm1.3 0v.33a.116.116 0 00.126.116h.757z"
            fill="#263238"
          />
          <path
            data-name="Path 6372"
            d="M120.569 206.018l-.864-.5.252-.155c.068-.049.146-.049.175 0z"
            opacity={0.15}
          />
          <path
            data-name="Path 6373"
            d="M117.717 207.163a.087.087 0 000 .078l-.514-.3-.825-.475a.2.2 0 00.1 0l.834-.767a.175.175 0 01.194 0 1.465 1.465 0 00.359.213.126.126 0 01.078.146z"
            opacity={0.1}
          />
          <path
            data-name="Path 6374"
            d="M116.882 204.369a.273.273 0 01-.058.087l-.8.97a.175.175 0 000 .1l-1.659-.97a.5.5 0 010-.116l.786-.97a.087.087 0 01.049-.078z"
            opacity={0.1}
          />
          <path
            data-name="Path 6375"
            d="M116.892 203.563v.679a.252.252 0 010 .116l-1.669-.97a.2.2 0 000-.126v-.349l.776.456h.767a.116.116 0 01.126.194z"
            opacity={0.05}
          />
          <path
            data-name="Path 6376"
            d="M117.406 201.603a.184.184 0 01-.107 0l-.776.194a.223.223 0 00-.146.1l-1.659-.97h.107l.776-.184a.263.263 0 00.1-.058z"
            opacity={0.1}
          />
          <path
            data-name="Path 6377"
            d="M117.977 200.575a.214.214 0 010 .107l-1.659-.97a.233.233 0 000-.116l-.252-.854v-.049l1.669.97a.369.369 0 000 .1z"
            opacity={0.1}
          />
          <path
            data-name="Path 6378"
            d="M120.627 197.46v.058l-.437 1.193a.3.3 0 01-.087.116l-1.669-.97.068-.087.456-1.2z"
            opacity={0.1}
          />
          <path
            data-name="Path 6379"
            d="M120.627 197.461a.262.262 0 01.136-.068h.165"
            fill="none"
          />
          <path
            data-name="Path 6380"
            d="M123.402 201.177h-.757a.116.116 0 01-.126-.116v-.679a.33.33 0 01.078-.2l.8-.97a.213.213 0 000-.194l-.369-.825h-.126l-.834.776c-.068.048-.146.068-.184 0a1.942 1.942 0 00-.359-.213.136.136 0 01-.087-.146l.262-1.174a.1.1 0 000-.087.106.106 0 00-.078 0l-.66.068h-.165a.262.262 0 00-.136.068v.058l-.446 1.2a.252.252 0 01-.078.107.262.262 0 01-.1.058 4.139 4.139 0 00-.514.3h-.175l-.417-.64h-.126l-.291.31-.611.65a.252.252 0 00-.068.1.165.165 0 000 .1l.252.854a.213.213 0 010 .107.291.291 0 010 .1 7.667 7.667 0 00-.417.718.184.184 0 01-.1.1.155.155 0 01-.1.048l-.776.194a.252.252 0 00-.146.087v.058l-.194.679-.175.592a.087.087 0 000 .087h.767a.116.116 0 01.116.116 3.964 3.964 0 000 .679.292.292 0 010 .116.3.3 0 010 .1l-.786.97a.223.223 0 00-.049.194l.155.349v.048l.049.107.068.136v.184a.2.2 0 00.1 0l.834-.767a.175.175 0 01.194 0 1.465 1.465 0 00.359.213.126.126 0 01.078.146l-.262 1.184a.087.087 0 000 .078.107.107 0 00.078 0l.825-.078a.262.262 0 00.184-.126l.446-1.193a.311.311 0 01.175-.175l.262-.146.252-.155c.068-.048.146-.048.175 0l.417.64a.116.116 0 00.136 0l.9-.97a.2.2 0 00.068-.194l-.252-.854a.33.33 0 01.049-.2 7.649 7.649 0 00.417-.718.272.272 0 01.194-.146l.776-.184a.3.3 0 00.175-.146l.388-1.32a.078.078 0 00-.065-.063zm-4.124 3.2h-.32a.815.815 0 01-.776-.524 1.2 1.2 0 01-.107-.427 2.048 2.048 0 010-.243 3.716 3.716 0 011.019-2.338 2.669 2.669 0 01.631-.5l.2-.107a1.349 1.349 0 01.582-.126h.1a.825.825 0 01.65.514 3.134 3.134 0 01-.893 3.008 2.348 2.348 0 01-1.087.737z"
            fill="#37474f"
          />
          <path
            data-name="Path 6381"
            d="M120.646 219.603a6.22 6.22 0 00-3.512 2.911 5.764 5.764 0 00.184 5.473 6.307 6.307 0 00-.97 3.386 7.51 7.51 0 004.949 6.685l.281.1.844-.485a8.044 8.044 0 01-4.541-4.172 5.676 5.676 0 01.146-4.454 7.132 7.132 0 001.611 1.523c1.688 1.106 4.978 1.941 6.52.165a2.756 2.756 0 00-.126-3.6 5.4 5.4 0 00-3.881-1.814 5.453 5.453 0 00-4.036 1.7 5.3 5.3 0 01-.4-2.5 4.774 4.774 0 013.415-4.007c1.4 2.173 4.483 4.153 6.86 2.3s-1.417-3.532-3.008-3.677a10.391 10.391 0 00-3.289.184 3.571 3.571 0 01-.213-1.941 4.26 4.26 0 011.62-2.523 5.434 5.434 0 016.4-.388.352.352 0 00.446-.543c-3.808-3.502-11.298.777-9.3 5.677zm-.6 7.2a4.046 4.046 0 013.58-.165c.97.437 2.135 1.358 1.941 2.581-.272 2.125-3.134 1.533-4.473.97a5.618 5.618 0 01-2.513-2.173 4.628 4.628 0 011.465-1.213zm2.911-6.7a7.064 7.064 0 012.988.213c.456.146 1.756.582 1.688 1.242 0 .408-.631.718-.97.854a2.465 2.465 0 01-1.562.078 5.327 5.327 0 01-2.7-1.941l-.223-.32a6.639 6.639 0 01.747-.126z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6382"
            d="M120.646 219.603a6.22 6.22 0 00-3.512 2.911 5.764 5.764 0 00.184 5.473 6.307 6.307 0 00-.97 3.386 7.51 7.51 0 004.949 6.685l.281.1.844-.485a8.044 8.044 0 01-4.541-4.172 5.676 5.676 0 01.146-4.454 7.132 7.132 0 001.611 1.523c1.688 1.106 4.978 1.941 6.52.165a2.756 2.756 0 00-.126-3.6 5.4 5.4 0 00-3.881-1.814 5.453 5.453 0 00-4.036 1.7 5.3 5.3 0 01-.4-2.5 4.774 4.774 0 013.415-4.007c1.4 2.173 4.483 4.153 6.86 2.3s-1.417-3.532-3.008-3.677a10.391 10.391 0 00-3.289.184 3.571 3.571 0 01-.213-1.941 4.26 4.26 0 011.62-2.523 5.434 5.434 0 016.4-.388.352.352 0 00.446-.543c-3.808-3.502-11.298.777-9.3 5.677zm-.6 7.2a4.046 4.046 0 013.58-.165c.97.437 2.135 1.358 1.941 2.581-.272 2.125-3.134 1.533-4.473.97a5.618 5.618 0 01-2.513-2.173 4.628 4.628 0 011.465-1.213zm2.911-6.7a7.064 7.064 0 012.988.213c.456.146 1.756.582 1.688 1.242 0 .408-.631.718-.97.854a2.465 2.465 0 01-1.562.078 5.327 5.327 0 01-2.7-1.941l-.223-.32a6.639 6.639 0 01.747-.126z"
            fill="#fff"
            opacity={0.7}
          />
          <path
            data-name="Path 6383"
            d="M117.668 229.984a5.266 5.266 0 01.34-.97 3.746 3.746 0 01-.689-1.058 6.794 6.794 0 00-.524 1.029 8.339 8.339 0 00.873.999z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6384"
            d="M119.929 221.067a7.024 7.024 0 011.184-.572 5.531 5.531 0 01-.466-.893 7.1 7.1 0 00-1.32.611 6.737 6.737 0 00.6.854z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6385"
            d="M121.558 238.149l.844-.485a10.391 10.391 0 01-1.048-.5l-.9.514a6.988 6.988 0 00.825.378z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6386"
            d="M144.053 222.484a6.258 6.258 0 00-4.386-1.252 5.822 5.822 0 00-4.405 3.26 6.25 6.25 0 00-2.212.485 7.6 7.6 0 00-1.135.621 7.355 7.355 0 00-2.843 6.986 4.537 4.537 0 00.146.883l.058.213.747-.427v-.233a7.578 7.578 0 011.048-4.958 5.531 5.531 0 012.009-1.8 5.822 5.822 0 011.737-.6 6.685 6.685 0 00-.32 2.212c0 2.018 1.223 5.2 3.561 5.463a2.756 2.756 0 002.911-2.154 5.308 5.308 0 00-.718-4.23 5.463 5.463 0 00-3.677-2.368 5.239 5.239 0 011.824-1.747 4.774 4.774 0 015.24.543c-.97 2.387-.873 6.035 2 6.938s2.1-3.163 1.329-4.551a10.81 10.81 0 00-2.018-2.61 3.532 3.532 0 011.494-1.29 4.231 4.231 0 012.989-.1 5.453 5.453 0 013.959 5.055.351.351 0 10.7.058c.642-5.047-7.138-8.822-10.038-4.397zm-6.268 3.59a4.036 4.036 0 012.164 2.853c.2 1.067.1 2.532-.97 3.1-1.941.97-3.047-1.717-3.348-3.144a5.7 5.7 0 01.369-3.3 4.7 4.7 0 011.785.495zm7.151-1.436a7.19 7.19 0 011.517 2.576c.136.456.524 1.785-.078 2.1-.349.194-.97-.107-1.242-.3a2.4 2.4 0 01-.97-1.242 5.278 5.278 0 01.078-3.328 2.917 2.917 0 01.126-.378 7.766 7.766 0 01.566.572z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6387"
            d="M144.053 222.484a6.258 6.258 0 00-4.386-1.252 5.822 5.822 0 00-4.405 3.26 6.25 6.25 0 00-2.212.485 7.6 7.6 0 00-1.135.621 7.355 7.355 0 00-2.843 6.986 4.537 4.537 0 00.146.883l.058.213.747-.427v-.233a7.578 7.578 0 011.048-4.958 5.531 5.531 0 012.009-1.8 5.822 5.822 0 011.737-.6 6.685 6.685 0 00-.32 2.212c0 2.018 1.223 5.2 3.561 5.463a2.756 2.756 0 002.911-2.154 5.308 5.308 0 00-.718-4.23 5.463 5.463 0 00-3.677-2.368 5.239 5.239 0 011.824-1.747 4.774 4.774 0 015.24.543c-.97 2.387-.873 6.035 2 6.938s2.1-3.163 1.329-4.551a10.81 10.81 0 00-2.018-2.61 3.532 3.532 0 011.494-1.29 4.231 4.231 0 012.989-.1 5.453 5.453 0 013.959 5.055.351.351 0 10.7.058c.642-5.047-7.138-8.822-10.038-4.397zm-6.268 3.59a4.036 4.036 0 012.164 2.853c.2 1.067.1 2.532-.97 3.1-1.941.97-3.047-1.717-3.348-3.144a5.7 5.7 0 01.369-3.3 4.7 4.7 0 011.785.495zm7.151-1.436a7.19 7.19 0 011.517 2.576c.136.456.524 1.785-.078 2.1-.349.194-.97-.107-1.242-.3a2.4 2.4 0 01-.97-1.242 5.278 5.278 0 01.078-3.328 2.917 2.917 0 01.126-.378 7.766 7.766 0 01.566.572z"
            fill="#fff"
            opacity={0.7}
          />
          <path
            data-name="Path 6388"
            d="M133.813 225.919a5.506 5.506 0 01.97-.262 4.1 4.1 0 01.475-1.164 6.645 6.645 0 00-1.145.165 8.028 8.028 0 00-.3 1.261z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6389"
            d="M142.429 222.727a5.822 5.822 0 011.145.65 6.719 6.719 0 01.475-.9 7.435 7.435 0 00-1.271-.708 5.683 5.683 0 00-.349.961z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6390"
            d="M129.273 233.681l-.058-.213a13.107 13.107 0 01-.146-.883l.922.437v.233z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6391"
            d="M130.932 125.775l-52.765 30.467a4.7 4.7 0 00-2.115 3.677v18.106c0 1.358.97 1.941 2.125 1.232l52.755-30.458a4.725 4.725 0 002.125-3.677v-18.115c0-1.349-.951-1.902-2.125-1.232z"
            fill="#455a64"
          />
          <path
            data-name="Path 6392"
            d="M129.05 133.76a4.56 4.56 0 01-2.125 3.532l-44.789 25.859c-1.174.669-2.125.194-2.125-1.077a4.56 4.56 0 012.125-3.532l44.789-25.858c1.155-.67 2.125-.195 2.125 1.076z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6393"
            d="M129.049 142.968a4.56 4.56 0 01-2.125 3.532l-44.77 25.849c-1.174.679-2.125.2-2.125-1.067a4.56 4.56 0 012.125-3.532l44.79-25.86c1.135-.678 2.105-.193 2.105 1.078z"
            fill="#263238"
          />
          <path
            data-name="Path 6394"
            d="M126.944 141.89l-19.008 10.975v4.6l19.008-10.964a4.56 4.56 0 002.125-3.532c-.02-1.272-.988-1.757-2.125-1.079z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6395"
            d="M57.771 83.149c-2.2-1.271-3.988-.116-3.988 2.6v97.4a9.121 9.121 0 003.988 7.219c2.2 1.281 3.988.116 3.988-2.591V90.368a9.15 9.15 0 00-3.988-7.219z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6396"
            d="M57.771 83.149c-2.2-1.271-3.988-.116-3.988 2.6v97.4a9.121 9.121 0 003.988 7.219c2.2 1.281 3.988.116 3.988-2.591V90.368a9.15 9.15 0 00-3.988-7.219z"
            opacity={0.5}
          />
          <path
            data-name="Path 6397"
            d="M57.772 188.038a4.561 4.561 0 01-1.989-3.6v-97.7c0-1.358.893-1.941 1.989-1.31a4.551 4.551 0 011.941 3.61v97.7c.048 1.358-.845 1.941-1.941 1.3z"
            fill="#37474f"
          />
          <path
            data-name="Path 6398"
            d="M57.772 188.039a4.561 4.561 0 01-1.989-3.6v-47.738l3.978 2.3v47.739c0 1.357-.893 1.939-1.989 1.299z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6399"
            d="M45.817 76.29c-2.2-1.281-3.988-.116-3.988 2.591v97.35a9.15 9.15 0 003.988 7.219c2.193 1.271 3.978.107 3.978-2.6V83.46a9.131 9.131 0 00-3.978-7.17z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6400"
            d="M45.817 76.29c-2.2-1.281-3.988-.116-3.988 2.591v97.35a9.15 9.15 0 003.988 7.219c2.193 1.271 3.978.107 3.978-2.6V83.46a9.131 9.131 0 00-3.978-7.17z"
            opacity={0.5}
          />
          <path
            data-name="Path 6401"
            d="M45.817 181.14a4.561 4.561 0 01-2-3.61v-97.7c0-1.358.893-1.941 2-1.3a4.56 4.56 0 011.941 3.6v97.7c.048 1.358-.844 1.941-1.941 1.31z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6402"
            d="M33.854 69.342c-2.2-1.271-3.988-.116-3.988 2.6v97.4a9.121 9.121 0 003.988 7.219c2.2 1.281 3.988.116 3.988-2.591V76.561a9.15 9.15 0 00-3.988-7.219z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6403"
            d="M33.854 69.342c-2.2-1.271-3.988-.116-3.988 2.6v97.4a9.121 9.121 0 003.988 7.219c2.2 1.281 3.988.116 3.988-2.591V76.561a9.15 9.15 0 00-3.988-7.219z"
            opacity={0.5}
          />
          <path
            data-name="Path 6404"
            d="M33.853 174.231a4.56 4.56 0 01-1.941-3.6v-97.7c0-1.358.893-1.941 1.941-1.31a4.56 4.56 0 012.047 3.61v97.7c-.058 1.358-.97 1.941-2.047 1.3z"
            fill="#37474f"
          />
          <path
            data-name="Path 6405"
            d="M33.853 174.231a4.56 4.56 0 01-1.941-3.6V99.683l3.992 2.31v70.938c-.062 1.358-.974 1.941-2.051 1.3z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6406"
            d="M21.902 62.443c-2.2-1.281-3.988-.116-3.988 2.591v97.408a9.15 9.15 0 003.988 7.219c2.193 1.271 3.978.107 3.978-2.6V69.653a9.111 9.111 0 00-3.978-7.21z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6407"
            d="M21.902 62.443c-2.2-1.281-3.988-.116-3.988 2.591v97.408a9.15 9.15 0 003.988 7.219c2.193 1.271 3.978.107 3.978-2.6V69.653a9.111 9.111 0 00-3.978-7.21z"
            opacity={0.5}
          />
          <path
            data-name="Path 6408"
            d="M21.9 167.333a4.56 4.56 0 01-2-3.61v-97.7c0-1.358.893-1.941 2-1.3a4.56 4.56 0 011.989 3.6v97.7c0 1.359-.892 1.941-1.989 1.31z"
            fill="#37474f"
          />
          <path
            data-name="Path 6409"
            d="M21.9 167.332a4.56 4.56 0 01-2-3.61v-17.814l3.988 2.3v17.815c.001 1.358-.891 1.94-1.988 1.309z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6410"
            d="M9.936 55.535c-2.2-1.281-3.988-.116-3.988 2.6v97.4a9.121 9.121 0 003.988 7.219c2.2 1.281 3.988.116 3.988-2.591V62.754a9.15 9.15 0 00-3.988-7.219z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6411"
            d="M9.936 55.535c-2.2-1.281-3.988-.116-3.988 2.6v97.4a9.121 9.121 0 003.988 7.219c2.2 1.281 3.988.116 3.988-2.591V62.754a9.15 9.15 0 00-3.988-7.219z"
            opacity={0.5}
          />
          <path
            data-name="Path 6412"
            d="M9.936 160.424a4.56 4.56 0 01-1.989-3.6V59.115c0-1.349.893-1.941 1.989-1.3a4.56 4.56 0 012 3.61v97.7c-.001 1.347-.894 1.942-2 1.299z"
            fill="#37474f"
          />
          <path
            data-name="Path 6413"
            d="M9.936 160.424a4.56 4.56 0 01-1.989-3.6v-52.347l3.988 2.309v52.338c0 1.349-.893 1.941-1.999 1.3z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6414"
            d="M130.932 52.342L78.167 82.803a4.677 4.677 0 00-2.115 3.677v60.644c0 1.349.97 1.941 2.125 1.223l52.755-30.458a4.725 4.725 0 002.125-3.677V53.565c0-1.362-.951-1.902-2.125-1.223z"
            fill="#37474f"
          />
          <path
            data-name="Path 6415"
            d="M76.653 84.469a4.163 4.163 0 00-.572 2.009v60.644c0 1.349.97 1.941 2.125 1.223l52.756-30.459a4.444 4.444 0 001.543-1.669z"
            fill="#263238"
          />
          <path
            data-name="Path 6416"
            d="M78.167 82.8a4.677 4.677 0 00-2.116 3.678v60.64l52.755-30.458a4.725 4.725 0 002.115-3.687v-60.63z"
            fill="#455a64"
          />
          <g fill="#ff435b">
            <path
              data-name="Path 6417"
              d="M95.545 98.742a1.067 1.067 0 01-.757-.3l-7.015-6.792a1.091 1.091 0 111.514-1.572l7.015 6.744a1.1 1.1 0 01-.757 1.882z"
            />
            <path
              data-name="Path 6418"
              d="M88.525 102.788a1.018 1.018 0 01-.466-.107 1.1 1.1 0 01-.524-1.455l7.02-14.846a1.1 1.1 0 011.979.941l-7.015 14.846a1.1 1.1 0 01-.99.621z"
            />
            <path
              data-name="Path 6419"
              d="M118.444 85.517a1.087 1.087 0 01-.757-.3l-7.015-6.744a1.1 1.1 0 011.523-1.582l7.006 6.744a1.106 1.106 0 010 1.552 1.067 1.067 0 01-.757.33z"
            />
            <path
              data-name="Path 6420"
              d="M111.426 89.573a1.164 1.164 0 01-.466-.107 1.106 1.106 0 01-.524-1.455l7.015-14.846a1.1 1.1 0 011.941.97l-6.976 14.768a1.1 1.1 0 01-.99.67z"
            />
            <path
              data-name="Path 6421"
              d="M88.22 119.614a1.1 1.1 0 01-.563-2.047l30.538-17.631a1.1 1.1 0 111.087 1.9l-30.538 17.622a.971.971 0 01-.524.155z"
            />
            <path
              data-name="Path 6422"
              d="M109.497 120.109a3.017 3.017 0 01-1.533-.408 4.046 4.046 0 01-1.756-3.677v-8.529a1.106 1.106 0 011.1-1.1 1.106 1.106 0 011.1 1.1v8.577a2.009 2.009 0 00.66 1.776 1.6 1.6 0 001.514-.233 7.617 7.617 0 003.27-5.958v-8.519a1.1 1.1 0 112.183 0v8.519a9.79 9.79 0 01-4.357 7.85 4.328 4.328 0 01-2.181.602z"
            />
            <path
              data-name="Path 6423"
              d="M111.118 114.616a1.1 1.1 0 01-1.1-1.1v-8.22a1.1 1.1 0 012.193 0v8.228a1.087 1.087 0 01-1.1 1.1z"
            />
          </g>
          <g data-name="freepik--server--inject-55">
            <path
              data-name="Path 6424"
              d="M14.71 240.658a2.047 2.047 0 01.97 1.62c0 .6-.417.844-.97.543a2.1 2.1 0 01-.97-1.62c.028-.602.455-.844.97-.543z"
              fill="#37474f"
            />
            <path
              data-name="Path 6425"
              d="M22.549 245.189a2.047 2.047 0 01.97 1.62c0 .592-.417.834-.97.543a2.067 2.067 0 01-.97-1.63c.029-.601.447-.834.97-.533z"
              fill="#37474f"
            />
            <path
              data-name="Path 6426"
              d="M30.244 249.633a2.038 2.038 0 01.97 1.611c0 .6-.417.844-.97.543a2.067 2.067 0 01-.97-1.62c.039-.593.456-.835.97-.534z"
              fill="#37474f"
            />
            <path
              data-name="Path 6427"
              d="M38.017 254.116a2.067 2.067 0 01.97 1.611c0 .592-.417.834-.97.543a2.067 2.067 0 01-.97-1.611c.038-.601.455-.805.97-.543z"
              fill="#37474f"
            />
            <path
              data-name="Path 6428"
              d="M5.57 194.588l8.015 4.57a5.016 5.016 0 012.251 3.881v4.57c0 1.436-.97 2.028-2.251 1.3l-8.015-4.541a5.007 5.007 0 01-2.261-3.881v-4.57c0-1.465 1.019-2.047 2.261-1.329z"
              fill="#37474f"
            />
            <path
              data-name="Path 6429"
              d="M7.762 199.74l1.038-.6a1.368 1.368 0 011.378.136 4.347 4.347 0 011.941 3.406 1.358 1.358 0 01-.572 1.261l-.97.553-.175-.291a1.756 1.756 0 01-.563-.213 4.328 4.328 0 01-1.941-3.4 2 2 0 01.058-.5z"
              fill="#ff435b"
            />
            <path
              data-name="Path 6430"
              d="M7.762 199.74l1.038-.6a1.368 1.368 0 011.378.136 4.347 4.347 0 011.941 3.406 1.358 1.358 0 01-.572 1.261l-.97.553-.175-.291a1.756 1.756 0 01-.563-.213 4.328 4.328 0 01-1.941-3.4 2 2 0 01.058-.5z"
              opacity={0.2}
            />
            <path
              data-name="Path 6431"
              d="M9.14 199.847a4.357 4.357 0 011.941 3.406c0 1.242-.883 1.756-1.941 1.126a4.337 4.337 0 01-1.941-3.4c-.019-1.248.864-1.753 1.941-1.132z"
              fill="#ff435b"
            />
            <path
              data-name="Path 6432"
              d="M5.57 172.591l8.015 4.609a4.987 4.987 0 012.251 3.881v4.56c0 1.446-.97 2.028-2.251 1.31l-7.966-4.58a5 5 0 01-2.261-3.881v-4.57c-.049-1.465.97-2.047 2.212-1.329z"
              fill="#455a64"
            />
            <path
              data-name="Path 6433"
              d="M7.762 177.744l1.038-.6a1.378 1.378 0 011.378.146 4.318 4.318 0 011.941 3.4 1.388 1.388 0 01-.572 1.271l-.97.553-.175-.291a2.057 2.057 0 01-.563-.223 4.318 4.318 0 01-1.941-3.4 2.058 2.058 0 01.058-.5z"
              fill="#ff435b"
            />
            <path
              data-name="Path 6434"
              d="M7.762 177.744l1.038-.6a1.378 1.378 0 011.378.146 4.318 4.318 0 011.941 3.4 1.388 1.388 0 01-.572 1.271l-.97.553-.175-.291a2.057 2.057 0 01-.563-.223 4.318 4.318 0 01-1.941-3.4 2.058 2.058 0 01.058-.5z"
              opacity={0.2}
            />
            <path
              data-name="Path 6435"
              d="M9.14 177.861a4.327 4.327 0 011.941 3.4c0 1.252-.883 1.756-1.941 1.135a4.376 4.376 0 01-1.941-3.406c-.019-1.255.864-1.789 1.941-1.129z"
              fill="#ff435b"
            />
            <path
              data-name="Path 6436"
              d="M130.573 155.922l-36.871 21.3a5.492 5.492 0 00-2.474 4.3v5.016c0 1.572 1.106 2.212 2.474 1.426l36.871-21.3a5.5 5.5 0 002.484-4.3v-5.007c0-1.58-1.096-2.221-2.484-1.435z"
              fill="#455a64"
            />
            <path
              data-name="Path 6437"
              d="M130.437 160.171v2.474a1.1 1.1 0 01-.495.864l-.553.32a.312.312 0 01-.5-.281v-2.436a1.1 1.1 0 01.5-.864l.553-.32c.272-.193.495-.077.495.243z"
              fill="#ff435b"
            />
            <path
              data-name="Path 6438"
              d="M127.931 161.618v2.426a1.1 1.1 0 01-.495.864l-.563.32a.307.307 0 01-.495-.281v-2.435a1.116 1.116 0 01.495-.864l.563-.32c.275-.157.495-.03.495.29z"
              fill="#ff435b"
            />
            <path
              data-name="Path 6439"
              d="M125.431 163.054v2.435a1.116 1.116 0 01-.495.864l-.563.32a.308.308 0 01-.495-.291v-2.426a1.1 1.1 0 01.495-.864l.563-.32a.311.311 0 01.495.282z"
              fill="#ff435b"
            />
            <path
              data-name="Path 6440"
              d="M122.927 164.499v2.435a1.077 1.077 0 01-.495.854l-.563.33a.308.308 0 01-.495-.291v-2.435a1.1 1.1 0 01.495-.854l.563-.32c.272-.161.495-.03.495.281z"
              fill="#ff435b"
            />
            <path
              data-name="Path 6441"
              d="M120.414 165.945v2.435a1.067 1.067 0 01-.495.854l-.553.32a.3.3 0 01-.495-.281v-2.435a1.1 1.1 0 01.495-.854l.553-.32c.282-.166.495-.03.495.281z"
              fill="#37474f"
            />
            <path
              data-name="Path 6442"
              d="M117.91 167.391v2.435a1.1 1.1 0 01-.495.854l-.553.32a.3.3 0 01-.495-.281v-2.435a1.067 1.067 0 01.495-.854l.553-.33c.272-.156.495-.03.495.291z"
              fill="#37474f"
            />
            <path
              data-name="Path 6443"
              d="M115.408 168.837v2.423a1.1 1.1 0 01-.495.864l-.563.32a.307.307 0 01-.495-.281v-2.435a1.116 1.116 0 01.495-.864l.563-.32c.272-.154.495-.027.495.293z"
              fill="#ff435b"
            />
            <path
              data-name="Path 6444"
              d="M112.904 170.282v2.426a1.1 1.1 0 01-.495.864l-.563.32a.308.308 0 01-.495-.291v-2.426a1.116 1.116 0 01.495-.864l.563-.32c.272-.155.495-.029.495.291z"
              fill="#ff435b"
            />
            <path
              data-name="Path 6445"
              d="M110.401 171.718v2.435a1.116 1.116 0 01-.495.864l-.563.32a.308.308 0 01-.495-.291v-2.426a1.1 1.1 0 01.495-.864l.563-.32c.272-.154.495-.057.495.282z"
              fill="#ff435b"
            />
            <path
              data-name="Path 6446"
              d="M107.897 173.162v2.435a1.067 1.067 0 01-.5.854l-.553.33a.308.308 0 01-.495-.291v-2.435a1.1 1.1 0 01.495-.854l.553-.32c.306-.164.5-.028.5.281z"
              fill="#37474f"
            />
            <path
              data-name="Path 6447"
              d="M105.384 174.61v2.435a1.1 1.1 0 01-.495.854l-.553.32a.3.3 0 01-.495-.281v-2.435a1.1 1.1 0 01.495-.854l.553-.32c.272-.166.495-.039.495.281z"
              fill="#ff435b"
            />
            <path
              data-name="Path 6448"
              d="M102.852 176.056v2.435a1.1 1.1 0 01-.495.854l-.553.32a.3.3 0 01-.495-.281v-2.435a1.067 1.067 0 01.495-.854l.553-.33c.301-.156.495-.03.495.291z"
              fill="#ff435b"
            />
            <path
              data-name="Path 6449"
              d="M100.378 177.501v2.426a1.1 1.1 0 01-.495.864l-.563.32a.307.307 0 01-.495-.281v-2.435a1.116 1.116 0 01.495-.864l.563-.32a.307.307 0 01.495.29z"
              fill="#37474f"
            />
            <path
              data-name="Path 6450"
              d="M97.874 178.947v2.426a1.1 1.1 0 01-.495.864l-.563.32a.308.308 0 01-.495-.291v-2.426a1.116 1.116 0 01.495-.864l.563-.32c.272-.156.495-.029.495.291z"
              fill="#ff435b"
            />
            <path
              data-name="Path 6451"
              d="M95.371 180.383v2.435a1.116 1.116 0 01-.495.864l-.563.32a.308.308 0 01-.495-.291v-2.426a1.1 1.1 0 01.495-.864l.563-.32a.308.308 0 01.495.282z"
              fill="#37474f"
            />
            <path
              data-name="Path 6452"
              d="M87.472 185.263l-1.029-.6a1.368 1.368 0 00-1.378.136 4.357 4.357 0 00-1.941 3.406 1.378 1.378 0 00.572 1.261l.97.543.175-.291a1.941 1.941 0 00.563-.213 4.357 4.357 0 001.941-3.4 2.579 2.579 0 00-.058-.5z"
              fill="#455a64"
            />
            <path
              data-name="Path 6453"
              d="M86.046 185.371a4.376 4.376 0 00-1.941 3.406c0 1.252.883 1.756 1.941 1.126a4.318 4.318 0 001.941-3.4c.019-1.249-.854-1.753-1.941-1.132z"
              fill="#ff435b"
            />
            <path
              data-name="Path 6454"
              d="M86.046 185.371a4.376 4.376 0 00-1.941 3.406c0 1.252.883 1.756 1.941 1.126a4.318 4.318 0 001.941-3.4c.019-1.249-.854-1.753-1.941-1.132z"
              fill="#fff"
              opacity={0.8}
            />
            <path
              data-name="Path 6455"
              d="M78.963 190.174l-1.038-.592a1.4 1.4 0 00-1.378.146 4.318 4.318 0 00-1.941 3.4 1.368 1.368 0 00.572 1.271l.97.553.175-.291a1.882 1.882 0 00.563-.223 4.318 4.318 0 001.941-3.4 1.941 1.941 0 00-.058-.495z"
              fill="#455a64"
            />
            <path
              data-name="Path 6456"
              d="M77.527 190.289a4.337 4.337 0 00-1.941 3.4c0 1.252.883 1.756 1.941 1.135a4.337 4.337 0 001.941-3.4c.029-1.251-.874-1.765-1.941-1.135z"
              fill="#ff435b"
            />
            <path
              data-name="Path 6457"
              d="M77.527 190.289a4.337 4.337 0 00-1.941 3.4c0 1.252.883 1.756 1.941 1.135a4.337 4.337 0 001.941-3.4c.029-1.251-.874-1.765-1.941-1.135z"
              fill="#fff"
              opacity={0.8}
            />
            <path
              data-name="Path 6458"
              d="M22.192 180.402L60.8 202.719a2.765 2.765 0 011.251 2.159v10.673c0 .8-.563 1.116-1.252.718l-38.607-22.312a2.746 2.746 0 01-1.252-2.2v-10.679c-.001-.753.552-1.073 1.252-.676z"
              fill="#455a64"
            />
            <path
              data-name="Path 6459"
              d="M23.288 185.098a2.659 2.659 0 001.252 2.076l33.912 19.571c.689.4 1.252.107 1.252-.64a2.688 2.688 0 00-1.252-2.076l-33.913-19.557c-.698-.4-1.251-.121-1.251.626z"
              fill="#ff435b"
            />
            <path
              data-name="Path 6460"
              d="M23.288 190.523a2.659 2.659 0 001.252 2.076l33.912 19.532c.689.4 1.252.116 1.252-.631a2.688 2.688 0 00-1.252-2.076l-33.913-19.532c-.698-.398-1.251-.078-1.251.631z"
              fill="#263238"
            />
            <path
              data-name="Path 6461"
              d="M24.539 189.892l18.736 10.8v2.707l-18.736-10.8a2.659 2.659 0 01-1.252-2.076c.001-.709.554-1.029 1.252-.631z"
              fill="#ff435b"
            />
            <path
              data-name="Path 6462"
              d="M5.57 172.591l8.015 4.609a4.987 4.987 0 012.251 3.881v4.56c0 1.446-.97 2.028-2.251 1.31l-7.966-4.58a5 5 0 01-2.261-3.881v-4.57c-.049-1.465.97-2.047 2.212-1.329z"
              fill="#37474f"
            />
            <path
              data-name="Path 6463"
              d="M7.762 177.744l1.038-.6a1.378 1.378 0 011.378.146 4.318 4.318 0 011.941 3.4 1.388 1.388 0 01-.572 1.271l-.97.553-.175-.291a2.057 2.057 0 01-.563-.223 4.318 4.318 0 01-1.941-3.4 2.058 2.058 0 01.058-.5z"
              fill="#ff435b"
            />
            <path
              data-name="Path 6464"
              d="M7.762 177.744l1.038-.6a1.378 1.378 0 011.378.146 4.318 4.318 0 011.941 3.4 1.388 1.388 0 01-.572 1.271l-.97.553-.175-.291a2.057 2.057 0 01-.563-.223 4.318 4.318 0 01-1.941-3.4 2.058 2.058 0 01.058-.5z"
              opacity={0.2}
            />
            <path
              data-name="Path 6465"
              d="M9.14 177.861a4.327 4.327 0 011.941 3.4c0 1.252-.883 1.756-1.941 1.135a4.376 4.376 0 01-1.941-3.406c-.019-1.255.864-1.789 1.941-1.129z"
              fill="#ff435b"
            />
            <path
              data-name="Path 6466"
              d="M22.192 180.402L60.8 202.719a2.765 2.765 0 011.251 2.159v10.673c0 .8-.563 1.116-1.252.718l-38.607-22.312a2.746 2.746 0 01-1.252-2.2v-10.679c-.001-.753.552-1.073 1.252-.676z"
              fill="#37474f"
            />
            <path
              data-name="Path 6467"
              d="M23.288 185.098a2.659 2.659 0 001.252 2.076l33.912 19.571c.689.4 1.252.107 1.252-.64a2.688 2.688 0 00-1.252-2.076l-33.913-19.557c-.698-.4-1.251-.121-1.251.626z"
              fill="#ff435b"
            />
            <path
              data-name="Path 6468"
              d="M23.288 190.523a2.659 2.659 0 001.252 2.076l33.912 19.532c.689.4 1.252.116 1.252-.631a2.688 2.688 0 00-1.252-2.076l-33.913-19.532c-.698-.398-1.251-.078-1.251.631z"
              fill="#263238"
            />
            <path
              data-name="Path 6469"
              d="M24.539 189.892l18.736 10.8v2.707l-18.736-10.8a2.659 2.659 0 01-1.252-2.076c.001-.709.554-1.029 1.252-.631z"
              fill="#ff435b"
            />
            <path
              data-name="Path 6470"
              d="M22.192 202.428l38.607 22.317a2.8 2.8 0 011.252 2.173v10.673c0 .8-.563 1.116-1.252.718l-38.607-22.297a2.756 2.756 0 01-1.252-2.173v-10.673a.783.783 0 011.252-.738z"
              fill="#37474f"
            />
            <path
              data-name="Path 6471"
              d="M23.288 207.154a2.659 2.659 0 001.252 2.067l33.912 19.561c.689.408 1.252.116 1.252-.631a2.688 2.688 0 00-1.252-2.076l-33.913-19.552c-.698-.398-1.251-.117-1.251.631z"
              fill="#ff435b"
            />
            <path
              data-name="Path 6472"
              d="M23.288 212.577a2.659 2.659 0 001.252 2.076l33.912 19.561c.689.4 1.252.116 1.252-.631a2.688 2.688 0 00-1.252-2.076l-33.913-19.561c-.698-.398-1.251-.116-1.251.631z"
              fill="#263238"
            />
            <path
              data-name="Path 6473"
              d="M24.539 211.946l23.433 13.507v2.707l-23.433-13.507a2.659 2.659 0 01-1.252-2.076c.001-.747.554-1.029 1.252-.631z"
              fill="#ff435b"
            />
          </g>
          <path
            data-name="Path 6474"
            d="M5.569 216.711l8.015 4.57a5 5 0 012.251 3.881v4.57c0 1.436-.97 2.018-2.251 1.3l-7.966-4.57a5.026 5.026 0 01-2.261-3.881v-4.57c-.049-1.436.97-1.999 2.212-1.3z"
            fill="#455a64"
          />
          <path
            data-name="Path 6475"
            d="M7.762 221.834l1.038-.6a1.368 1.368 0 011.378.136 4.327 4.327 0 011.941 3.4 1.368 1.368 0 01-.572 1.271l-.97.553-.175-.291a2.019 2.019 0 01-.563-.213 4.347 4.347 0 01-1.941-3.406 1.941 1.941 0 01.058-.495z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6476"
            d="M7.762 221.834l1.038-.6a1.368 1.368 0 011.378.136 4.327 4.327 0 011.941 3.4 1.368 1.368 0 01-.572 1.271l-.97.553-.175-.291a2.019 2.019 0 01-.563-.213 4.347 4.347 0 01-1.941-3.406 1.941 1.941 0 01.058-.495z"
            opacity={0.2}
          />
          <path
            data-name="Path 6477"
            d="M9.14 221.97a4.328 4.328 0 011.941 3.4c0 1.252-.883 1.756-1.941 1.135a4.337 4.337 0 01-1.941-3.4c-.019-1.271.864-1.756 1.941-1.135z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6478"
            d="M5.569 216.711l8.015 4.57a5 5 0 012.251 3.881v4.57c0 1.436-.97 2.018-2.251 1.3l-7.966-4.57a5.026 5.026 0 01-2.261-3.881v-4.57c-.049-1.436.97-1.999 2.212-1.3z"
            fill="#37474f"
          />
          <path
            data-name="Path 6479"
            d="M7.762 221.834l1.038-.6a1.368 1.368 0 011.378.136 4.327 4.327 0 011.941 3.4 1.368 1.368 0 01-.572 1.271l-.97.553-.175-.291a2.019 2.019 0 01-.563-.213 4.347 4.347 0 01-1.941-3.406 1.941 1.941 0 01.058-.495z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6480"
            d="M7.762 221.834l1.038-.6a1.368 1.368 0 011.378.136 4.327 4.327 0 011.941 3.4 1.368 1.368 0 01-.572 1.271l-.97.553-.175-.291a2.019 2.019 0 01-.563-.213 4.347 4.347 0 01-1.941-3.406 1.941 1.941 0 01.058-.495z"
            opacity={0.2}
          />
          <path
            data-name="Path 6481"
            d="M9.14 221.97a4.328 4.328 0 011.941 3.4c0 1.252-.883 1.756-1.941 1.135a4.337 4.337 0 01-1.941-3.4c-.019-1.271.864-1.756 1.941-1.135z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6482"
            d="M22.19 224.522l38.608 22.317a2.765 2.765 0 011.3 2.164v10.673c0 .805-.563 1.126-1.252.728l-38.607-22.317a2.746 2.746 0 01-1.252-2.164V225.25c-.048-.806.505-1.134 1.203-.728z"
            fill="#37474f"
          />
          <path
            data-name="Path 6483"
            d="M23.287 229.214a2.659 2.659 0 001.252 2.076l33.912 19.561c.689.4 1.252.116 1.252-.631a2.688 2.688 0 00-1.252-2.076l-33.913-19.518c-.698-.447-1.251-.156-1.251.588z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6484"
            d="M23.287 234.632a2.678 2.678 0 001.252 2.086l33.912 19.561c.689.4 1.252.116 1.252-.631a2.707 2.707 0 00-1.252-2.086l-33.913-19.56c-.698-.398-1.251-.117-1.251.63z"
            fill="#263238"
          />
          <path
            data-name="Path 6485"
            d="M24.538 234.002l18.736 10.8v2.717l-18.736-10.8a2.678 2.678 0 01-1.252-2.086c.001-.748.554-1.029 1.252-.631z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6486"
            d="M122.539 41.757l-51.494 29.73a2.979 2.979 0 01-2.659 0l-51.494-29.73a.815.815 0 010-1.543l51.493-28.701a2.979 2.979 0 012.659 0l51.494 28.7a.815.815 0 010 1.543z"
            opacity={0.1}
          />
          <path
            data-name="Path 6487"
            d="M70.987 69.857l49.272-28.449a2.814 2.814 0 001.281-2.2v-1.659a2.824 2.824 0 00-1.281-2.212L70.987 6.884a2.794 2.794 0 00-2.542 0L19.173 35.333a2.824 2.824 0 00-1.281 2.212v1.659a2.814 2.814 0 001.281 2.2l49.272 28.449a2.794 2.794 0 002.542 0z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6488"
            d="M70.987 69.857l49.272-28.449a2.814 2.814 0 001.281-2.2v-1.659a2.824 2.824 0 00-1.281-2.212L70.987 6.884a2.794 2.794 0 00-2.542 0L19.173 35.333a2.824 2.824 0 00-1.281 2.212v1.659a2.814 2.814 0 001.281 2.2l49.272 28.449a2.794 2.794 0 002.542 0z"
            fill="#fff"
            opacity={0.5}
          />
          <path
            data-name="Path 6489"
            d="M70.987 69.857l49.272-28.449a2.814 2.814 0 001.281-2.2v-1.659a2.824 2.824 0 00-1.281-2.212L70.987 6.884a2.794 2.794 0 00-2.542 0L19.173 35.333a2.824 2.824 0 00-1.281 2.212v1.659a2.814 2.814 0 001.281 2.2l49.272 28.449a2.794 2.794 0 002.542 0z"
            opacity={0.05}
          />
          <path
            data-name="Path 6490"
            d="M120.259 36.808l-49.271 28.44a2.8 2.8 0 01-2.542 0l-49.273-28.44a.776.776 0 010-1.475L68.445 7.874a2.794 2.794 0 012.542 0l49.272 27.459a.776.776 0 010 1.475z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6491"
            d="M120.259 36.808l-49.271 28.44a2.8 2.8 0 01-2.542 0l-49.273-28.44a.776.776 0 010-1.475L68.445 7.874a2.794 2.794 0 012.542 0l49.272 27.459a.776.776 0 010 1.475z"
            fill="#fff"
            opacity={0.7}
          />
          <path
            data-name="Path 6492"
            d="M69.716 65.558v4.6a2.591 2.591 0 001.271-.3l49.274-28.451a2.824 2.824 0 001.281-2.212v-1.659a2.833 2.833 0 00-1.041-2.038c.456.4.378.97-.243 1.3L70.987 65.247a2.494 2.494 0 01-1.271.31z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6493"
            d="M69.716 65.558v4.6a2.591 2.591 0 001.271-.3l49.274-28.451a2.824 2.824 0 001.281-2.212v-1.659a2.833 2.833 0 00-1.041-2.038c.456.4.378.97-.243 1.3L70.987 65.247a2.494 2.494 0 01-1.271.31z"
            fill="#fff"
            opacity={0.6}
          />
          <path
            data-name="Path 6494"
            d="M70.987 56.053l33.33-19.241a2.8 2.8 0 001.271-2.212v-1.659a2.794 2.794 0 00-1.271-2.2L82.97 18.392a8.451 8.451 0 01-2.154-1.939L70.608 2.703a1.688 1.688 0 00-2.145-.45L35.115 21.526a2.8 2.8 0 00-1.271 2.2v10.838a2.833 2.833 0 001.271 2.212l33.33 19.241a2.794 2.794 0 002.542.036z"
            fill="#263238"
          />
          <path
            data-name="Path 6495"
            d="M68.445 51.441L47.099 39.108a6.977 6.977 0 01-1.116-.825 7.636 7.636 0 01-1.038-1.1L34.718 23.438a1.271 1.271 0 01.4-1.941L68.445 2.253a1.688 1.688 0 012.144.446l10.203 13.754a8.034 8.034 0 001.941 1.941l21.545 12.259a.767.767 0 010 1.465l-33.33 19.245a2.8 2.8 0 01-2.5.078z"
            fill="#455a64"
          />
          <path
            data-name="Path 6496"
            d="M35.115 21.526l33.33-19.241a1.688 1.688 0 012.144.446L80.798 16.48a7.763 7.763 0 001.046 1.084s-35.9 20.716-35.9 20.706a7.608 7.608 0 01-1.038-1.087L34.717 23.437a1.271 1.271 0 01.4-1.911z"
            fill="#37474f"
          />
          <path
            data-name="Path 6497"
            d="M96.234 31.064a3.309 3.309 0 00-2.989 0c-.834.485-.834 1.252 0 1.727a3.309 3.309 0 002.989 0 .912.912 0 000-1.727z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6498"
            d="M90.237 34.567a3.309 3.309 0 00-2.989 0 .912.912 0 000 1.727 3.309 3.309 0 002.989 0 .912.912 0 000-1.727z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6499"
            d="M84.27 37.972a3.309 3.309 0 00-2.989 0 .912.912 0 000 1.727 3.309 3.309 0 002.989 0 .912.912 0 000-1.727z"
            fill="#263238"
          />
          <path
            data-name="Path 6500"
            d="M78.293 41.427a3.309 3.309 0 00-2.989 0 .912.912 0 000 1.727 3.309 3.309 0 002.989 0 .912.912 0 000-1.727z"
            fill="#263238"
          />
          <path
            data-name="Path 6501"
            d="M91.752 28.483a3.27 3.27 0 00-3 0 .9.9 0 000 1.717 3.27 3.27 0 003 0 .9.9 0 000-1.717z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6502"
            d="M85.764 31.928a3.309 3.309 0 00-2.989 0 .912.912 0 000 1.727 3.309 3.309 0 002.989 0 .912.912 0 000-1.727z"
            fill="#263238"
          />
          <ellipse
            data-name="Ellipse 171"
            cx={2.115}
            cy={1.223}
            rx={2.115}
            ry={1.223}
            transform="translate(76.178 35.023)"
            fill="#ff435b"
          />
          <path
            data-name="Path 6503"
            d="M73.81 38.836a3.309 3.309 0 00-2.989 0 .912.912 0 000 1.727 3.309 3.309 0 002.989 0 .912.912 0 000-1.727z"
            fill="#263238"
          />
          <path
            data-name="Path 6504"
            d="M87.259 25.892a3.309 3.309 0 00-2.989 0 .912.912 0 000 1.727 3.309 3.309 0 002.989 0 .912.912 0 000-1.727z"
            fill="#263238"
          />
          <path
            data-name="Path 6505"
            d="M81.286 29.347a3.25 3.25 0 00-2.989 0 .9.9 0 000 1.717 3.251 3.251 0 002.989 0 .9.9 0 000-1.717z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6506"
            d="M75.305 32.794a3.309 3.309 0 00-2.989 0 .912.912 0 000 1.727 3.309 3.309 0 002.989 0 .912.912 0 000-1.727z"
            fill="#263238"
          />
          <path
            data-name="Path 6507"
            d="M72.277 44.803a3.309 3.309 0 00-2.989 0 .912.912 0 000 1.727 3.309 3.309 0 002.988 0 .912.912 0 000-1.727z"
            fill="#ff435b"
          />
          <ellipse
            data-name="Ellipse 172"
            cx={2.115}
            cy={1.223}
            rx={2.115}
            ry={1.223}
            transform="translate(64.185 41.854)"
            fill="#263238"
          />
          <path
            data-name="Path 6508"
            d="M69.328 36.246a3.309 3.309 0 00-2.989 0 .912.912 0 000 1.727 3.309 3.309 0 002.989 0 .912.912 0 000-1.727z"
            fill="#263238"
          />
          <path
            data-name="Path 6509"
            d="M82.776 23.302a3.309 3.309 0 00-2.989 0 .912.912 0 000 1.727 3.309 3.309 0 002.988 0 .912.912 0 000-1.727z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6510"
            d="M76.799 26.804a3.309 3.309 0 00-2.989 0 .912.912 0 000 1.727 3.309 3.309 0 002.989 0 .912.912 0 000-1.727z"
            fill="#263238"
          />
          <path
            data-name="Path 6511"
            d="M70.832 30.21a3.309 3.309 0 00-2.989 0 .912.912 0 000 1.727 3.309 3.309 0 002.989 0 .912.912 0 000-1.727z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6512"
            d="M64.845 33.655a3.309 3.309 0 00-2.989 0c-.834.485-.834 1.252 0 1.727a3.309 3.309 0 002.989 0 .912.912 0 000-1.727z"
            fill="#263238"
          />
          <path
            data-name="Path 6513"
            d="M63.311 39.632a3.309 3.309 0 00-2.989 0 .912.912 0 000 1.727 3.309 3.309 0 002.989 0 .912.912 0 000-1.727z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6514"
            d="M58.829 37.041a3.309 3.309 0 00-2.989 0 .912.912 0 000 1.727 3.309 3.309 0 002.989 0 .912.912 0 000-1.727z"
            fill="#263238"
          />
          <path
            data-name="Path 6515"
            d="M69.648 51.751v4.59a2.639 2.639 0 001.339-.291l33.33-19.251a2.775 2.775 0 001.271-2.2v-1.662a2.8 2.8 0 00-1.038-2.038c.466.4.388.97-.233 1.3L70.987 51.44a2.552 2.552 0 01-1.339.311z"
            fill="#37474f"
          />
          <path
            data-name="Path 6516"
            d="M76.391 15.898a1.135 1.135 0 00-1.436-.3L45.924 32.306a.864.864 0 00-.262 1.281 1.145 1.145 0 001.436.3L76.091 17.14a.854.854 0 00.3-1.242z"
            fill="#263238"
          />
          <path
            data-name="Path 6517"
            d="M76.391 15.898a1.135 1.135 0 00-1.436-.3L53.366 28.007l1.2 1.62 21.55-12.449a.854.854 0 00.272-1.281z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6518"
            d="M73.993 12.686a1.126 1.126 0 00-1.436-.3l-29.03 16.708a.844.844 0 00-.262 1.271 1.126 1.126 0 001.436.3l29.041-16.7a.854.854 0 00.252-1.281z"
            fill="#263238"
          />
          <path
            data-name="Path 6519"
            d="M73.995 12.686a1.126 1.126 0 00-1.436-.3l-7.214 4.162 1.2 1.611 7.2-4.153a.854.854 0 00.243-1.32z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6520"
            d="M71.598 9.465a1.126 1.126 0 00-1.436-.3L41.131 25.834a.864.864 0 00-.262 1.281 1.126 1.126 0 001.436.291l28.992-16.738a.854.854 0 00.3-1.2z"
            fill="#263238"
          />
          <path
            data-name="Path 6521"
            d="M71.599 9.465a1.126 1.126 0 00-1.436-.3l-15.108 8.729 1.223 1.533 15.1-8.733a.854.854 0 00.221-1.229z"
            fill="#ff435b"
          />
          <path
            data-name="Path 6522"
            d="M69.202 6.244a1.126 1.126 0 00-1.436-.291l-29.031 16.7a.854.854 0 00-.262 1.281 1.126 1.126 0 001.436.3L68.892 7.525a.854.854 0 00.31-1.281z"
            fill="#263238"
          />
          <path
            data-name="Path 6523"
            d="M69.202 6.244a1.126 1.126 0 00-1.436-.291L41.413 21.109l1.2 1.611L68.892 7.525a.854.854 0 00.31-1.281z"
            fill="#ff435b"
          />
        </g>
        <path
          data-name="Path 6629"
          d="M164.523 94.554c.262.378.563.767.883 1.164a3.542 3.542 0 001.164.97 3.386 3.386 0 001.659.32 5.1 5.1 0 002.28-.786 10.48 10.48 0 002.27-1.8 13.071 13.071 0 001.941-2.562 13.759 13.759 0 001.339-3.144 13.03 13.03 0 00.485-3.609c0-1.941-.5-3.318-1.514-4s-2.387-.5-4.124.5a6.5 6.5 0 00-1.514 1.223 18.5 18.5 0 00-1.31 1.494l-1.106 1.378a3.366 3.366 0 01-.864.844l-8.878 5.133a.883.883 0 01-1.145 0 1.8 1.8 0 01-.4-1.436l1.708-32.3a5.55 5.55 0 01.485-1.941 2.8 2.8 0 011.058-1.349l24.374-14.079a.834.834 0 011.087.078 1.882 1.882 0 01.456 1.407v9.3a4.57 4.57 0 01-.456 1.941 2.824 2.824 0 01-1.087 1.32l-16.569 9.542-.4 8.034a20.57 20.57 0 012.368-2.814 13.925 13.925 0 012.911-2.261 14.729 14.729 0 016.123-2.135 7.394 7.394 0 014.852 1.2 9.266 9.266 0 013.25 4.619 24.2 24.2 0 011.164 8.18 38.472 38.472 0 01-1.281 10.033 39.122 39.122 0 01-3.53 8.844 34.735 34.735 0 01-5.269 7.209 29.72 29.72 0 01-6.491 5.152 16.66 16.66 0 01-6.928 2.455 8.034 8.034 0 01-5.152-1.145 8.733 8.733 0 01-3.251-4.085 16.9 16.9 0 01-1.193-6.278 3.687 3.687 0 01.369-1.572 2.445 2.445 0 01.883-1.087l7.238-4.172c.6-.349 1.067-.446 1.4-.281a1.785 1.785 0 01.715.496z"
          fill="#ff435b"
        />
        <path
          data-name="Path 6630"
          d="M208.837 24.944q4.279-2.465 7.345-2.309a7.365 7.365 0 015.1 2.232 12.905 12.905 0 013.018 5.822 37.765 37.765 0 011.164 8.4q.116 3.716.116 7.83t-.115 7.765a58.359 58.359 0 01-1.164 9.751 42.267 42.267 0 01-3.018 9.325 36.348 36.348 0 01-5.1 8.121 27.071 27.071 0 01-7.345 6.171c-2.843 1.64-5.3 2.416-7.345 2.309a7.287 7.287 0 01-5.094-2.232 12.857 12.857 0 01-3.027-5.822 38.813 38.813 0 01-1.164-8.4q-.087-3.5-.087-7.607t.116-7.966a60.2 60.2 0 011.164-9.7 42.374 42.374 0 013.027-9.334 35.957 35.957 0 015.094-8.121 27.217 27.217 0 017.316-6.229zm6.093 35.428q.233-7.578 0-14.739a26.894 26.894 0 00-.427-3.784 6.656 6.656 0 00-.97-2.63 2.523 2.523 0 00-1.844-1.058 4.91 4.91 0 00-2.853.864 9.7 9.7 0 00-2.843 2.426 12.44 12.44 0 00-1.824 3.2 19.569 19.569 0 00-1.029 3.794 31.409 31.409 0 00-.349 4.269q-.291 7.442 0 14.729a24.925 24.925 0 00.369 3.823 6.258 6.258 0 001.029 2.6 2.581 2.581 0 001.853 1.087 4.851 4.851 0 002.843-.854 9.974 9.974 0 002.853-2.435 13.032 13.032 0 001.853-3.221 16.764 16.764 0 001.019-3.794 32.378 32.378 0 00.32-4.279z"
          fill="#ff435b"
        />
        <path
          data-name="Path 6631"
          d="M248.027 2.327c2.843-1.65 5.3-2.416 7.345-2.319a7.432 7.432 0 015.1 2.232 13.147 13.147 0 013.018 5.822 38.34 38.34 0 011.164 8.4q.116 3.716.116 7.83c0 2.746 0 5.346-.116 7.762a59.386 59.386 0 01-1.164 9.7 43.313 43.313 0 01-3.018 9.325 36.251 36.251 0 01-5.1 8.121 27.042 27.042 0 01-7.345 6.171c-2.853 1.649-5.3 2.416-7.345 2.319a7.413 7.413 0 01-5.1-2.232 13.05 13.05 0 01-3.018-5.822 38.334 38.334 0 01-1.164-8.4q-.116-3.532-.116-7.656c0-2.746 0-5.4.116-7.956a59.387 59.387 0 011.16-9.744 43.033 43.033 0 013.018-9.334 36.068 36.068 0 015.1-8.112 26.732 26.732 0 017.345-6.1zm6.093 35.416q.233-7.578 0-14.739a28.413 28.413 0 00-.427-3.784 6.549 6.549 0 00-.97-2.62 2.474 2.474 0 00-1.824-1.1 4.851 4.851 0 00-2.843.854 9.819 9.819 0 00-2.882 2.4 12.692 12.692 0 00-1.824 3.2 19.287 19.287 0 00-1.019 3.784 31.4 31.4 0 00-.4 4.269q-.291 7.442 0 14.729a23.76 23.76 0 00.369 3.823 6.171 6.171 0 001.029 2.61 2.6 2.6 0 001.844 1.077 4.852 4.852 0 002.853-.854 9.781 9.781 0 002.843-2.435 12.836 12.836 0 001.853-3.212 17.463 17.463 0 001.029-3.707 34.464 34.464 0 00.369-4.3z"
          fill="#ff435b"
        />
        <path
          data-name="Path 6632"
          d="M186.549 149.081a.281.281 0 01.378 0 .66.66 0 01.165.495v3.305a1.591 1.591 0 01-.165.679 1.029 1.029 0 01-.378.475l-9.577 5.521a.281.281 0 01-.378 0 .66.66 0 01-.165-.495v-20.087a1.591 1.591 0 01.165-.679 1.028 1.028 0 01.378-.475l9.412-5.434a.31.31 0 01.388 0 .669.669 0 01.155.495v3.3a1.572 1.572 0 01-.155.679 1.106 1.106 0 01-.388.475l-6.22 3.59v3.356l5.773-3.338a.32.32 0 01.388 0 .679.679 0 01.155.5v3.289a1.61 1.61 0 01-.155.689.97.97 0 01-.388.466l-5.773 3.338v3.483z"
          fill="#fff"
        />
        <path
          data-name="Path 6633"
          d="M195.428 127.165a7.334 7.334 0 012.387-.97 2.581 2.581 0 011.785.281 2.785 2.785 0 011.135 1.562 9.111 9.111 0 01.388 2.911 13.457 13.457 0 01-.621 4.23 11.208 11.208 0 01-1.776 3.377l2.5 5.948a.883.883 0 01.058.311 1.291 1.291 0 01-.136.553.815.815 0 01-.311.388l-2.746 1.582c-.272.155-.456.175-.572.058a1.571 1.571 0 01-.272-.408l-2.222-5.308-1.863 1.077v6.647a1.5 1.5 0 01-.165.689 1.048 1.048 0 01-.378.466l-2.649 1.533a.3.3 0 01-.378 0 .689.689 0 01-.165-.5v-20.1a1.63 1.63 0 01.165-.689 1.048 1.048 0 01.378-.466zm-2.28 10.741l2.28-1.32a4.328 4.328 0 001.407-1.31 3.716 3.716 0 00.553-2.125c0-.864-.184-1.358-.553-1.475a1.756 1.756 0 00-1.407.31l-2.28 1.32z"
          fill="#fff"
        />
        <path
          data-name="Path 6634"
          d="M209.467 119.064a7.21 7.21 0 012.377-.97 2.571 2.571 0 011.785.281 2.785 2.785 0 011.135 1.562 9.082 9.082 0 01.4 2.911 13.449 13.449 0 01-.631 4.231 10.993 10.993 0 01-1.776 3.377l2.5 5.948a.883.883 0 01.058.311 1.272 1.272 0 01-.126.553.776.776 0 01-.32.388l-2.746 1.582c-.262.155-.456.175-.572.058a1.571 1.571 0 01-.272-.408l-2.241-5.356-1.863 1.077v6.647a1.611 1.611 0 01-.155.689.97.97 0 01-.388.466l-2.639 1.523a.3.3 0 01-.388 0 .689.689 0 01-.165-.5v-20.053a1.63 1.63 0 01.165-.689.97.97 0 01.388-.466zm-2.29 10.741l2.29-1.32a4.288 4.288 0 001.4-1.31 3.717 3.717 0 00.553-2.125c0-.864-.184-1.358-.553-1.475a1.737 1.737 0 00-1.4.311l-2.29 1.32z"
          fill="#fff"
        />
        <path
          data-name="Path 6635"
          d="M217.017 122.819a17.289 17.289 0 01.495-3.561 15.827 15.827 0 011.232-3.386 13.9 13.9 0 011.941-2.911 9.965 9.965 0 012.63-2.193 5.269 5.269 0 012.629-.844 2.911 2.911 0 011.941.67 4.337 4.337 0 011.242 1.941 10.751 10.751 0 01.485 2.989c0 .883.048 1.8.048 2.775s0 1.892-.048 2.765a18.058 18.058 0 01-.485 3.561 16.126 16.126 0 01-1.242 3.4 13.138 13.138 0 01-1.941 2.911 10.236 10.236 0 01-2.63 2.173 5.443 5.443 0 01-2.629.864 2.824 2.824 0 01-1.941-.679 4.463 4.463 0 01-1.232-1.941 10.46 10.46 0 01-.495-3v-2.71c0-.971-.029-1.922 0-2.824zm8.868.261v-5.22a5.4 5.4 0 00-.223-1.31 1.941 1.941 0 00-.475-.854 1.126 1.126 0 00-.776-.32 2.338 2.338 0 00-1.1.349 4.278 4.278 0 00-1.087.912 5.268 5.268 0 00-.776 1.213 6.587 6.587 0 00-.485 1.417 8.937 8.937 0 00-.213 1.552c0 .369-.048.776-.048 1.223v2.785a9.592 9.592 0 00.048 1.213 3.405 3.405 0 00.7 2.183q.611.67 1.863-.058a4.463 4.463 0 001.863-2.1 8.879 8.879 0 00.709-2.985z"
          fill="#fff"
        />
        <path
          data-name="Path 6636"
          d="M237.994 102.588a7.248 7.248 0 012.387-.97 2.581 2.581 0 011.785.281 2.765 2.765 0 011.126 1.572 8.733 8.733 0 01.4 2.911 13.457 13.457 0 01-.621 4.23 11.014 11.014 0 01-1.785 3.377l2.5 5.958a.843.843 0 01.068.3 1.3 1.3 0 01-.136.563.834.834 0 01-.31.378l-2.775 1.63c-.272.155-.466.165-.582.049a1.485 1.485 0 01-.262-.4l-2.251-5.366-1.853 1.077v6.647a1.6 1.6 0 01-.165.689.97.97 0 01-.378.466l-2.649 1.533a.281.281 0 01-.378 0 .64.64 0 01-.165-.495v-20.083a1.436 1.436 0 01.167-.68 1.028 1.028 0 01.378-.475zm-2.277 10.741l2.28-1.32a4.124 4.124 0 001.407-1.31 3.706 3.706 0 00.553-2.115q0-1.31-.553-1.485a1.8 1.8 0 00-1.407.311l-2.28 1.32z"
          fill="#fff"
        />
        <path
          data-name="Path 6637"
          d="M154.888 141.982a.165.165 0 01-.223 0 .388.388 0 01-.087-.281v-11.643a.971.971 0 01.087-.388.679.679 0 01.223-.281l1.533-.873a.155.155 0 01.213 0 .369.369 0 01.1.291v11.644a.854.854 0 01-.1.4.582.582 0 01-.213.272z"
          fill="#fff"
        />
        <path
          data-name="Path 6638"
          d="M163.98 136.742a.281.281 0 01-.32 0 .572.572 0 01-.155-.165l-2.668-4.6v6.123a.97.97 0 01-.1.4.534.534 0 01-.223.272l-1.523.883a.175.175 0 01-.223 0 .378.378 0 01-.1-.291v-11.646a.825.825 0 01.1-.388.533.533 0 01.223-.272l1.213-.708a.3.3 0 01.32 0 .475.475 0 01.155.165l2.668 4.6v-6.075a.883.883 0 01.1-.4.583.583 0 01.223-.272l1.523-.883a.175.175 0 01.223 0 .369.369 0 01.1.291v11.6a.854.854 0 01-.1.4.582.582 0 01-.223.272z"
          fill="#fff"
        />
        <path
          data-name="Path 6639"
          d="M173.411 118.694a.175.175 0 01.223 0 .4.4 0 01.087.291v1.941a.97.97 0 01-.087.4.65.65 0 01-.223.272l-2.057 1.184v9.208a.97.97 0 01-.087.4.65.65 0 01-.223.272l-1.533.883a.175.175 0 01-.223 0 .4.4 0 01-.087-.291v-9.213l-2.057 1.184a.165.165 0 01-.214 0 .378.378 0 01-.1-.291v-1.941a.854.854 0 01.1-.4.573.573 0 01.214-.272z"
          fill="#fff"
        />
        <path
          data-name="Path 6640"
          d="M180.911 124.09a.165.165 0 01.223 0 .4.4 0 01.087.291v1.94a.969.969 0 01-.087.4.65.65 0 01-.223.272l-5.531 3.192a.175.175 0 01-.223 0 .4.4 0 01-.087-.291v-11.646a.971.971 0 01.087-.4.65.65 0 01.223-.272l5.434-3.135a.175.175 0 01.223 0 .369.369 0 01.1.291v1.941a.854.854 0 01-.1.4.582.582 0 01-.223.272l-3.59 2.076v1.941l3.338-1.941a.165.165 0 01.223 0 .388.388 0 01.087.281v1.939a.97.97 0 01-.087.4.592.592 0 01-.223.272l-3.338 1.941v2.009z"
          fill="#fff"
        />
        <path
          data-name="Path 6641"
          d="M186.297 111.262a3.881 3.881 0 011.378-.543 1.446 1.446 0 011.029.155 1.639 1.639 0 01.65.9 5.152 5.152 0 01.233 1.7 7.763 7.763 0 01-.369 2.445 6.278 6.278 0 01-1.019 1.941l1.446 3.445a.553.553 0 010 .175.756.756 0 01-.068.32.553.553 0 01-.184.223l-1.591.912c-.155.1-.262.107-.33 0a.971.971 0 01-.155-.243l-1.223-3.027-1.077.611v3.881a.835.835 0 01-.1.4.582.582 0 01-.213.272l-1.533.883a.175.175 0 01-.223 0 .417.417 0 01-.087-.291v-11.642a.97.97 0 01.087-.388.533.533 0 01.223-.272zm-1.32 6.2l1.32-.767a2.4 2.4 0 00.805-.757 2.115 2.115 0 00.32-1.223c0-.5-.107-.786-.32-.854a1.038 1.038 0 00-.805.175l-1.32.767z"
          fill="#fff"
        />
        <path
          data-name="Path 6642"
          d="M196.563 117.918q-.2.116-.32 0a.4.4 0 01-.155-.165l-2.678-4.6v6.075a.97.97 0 01-.087.4.611.611 0 01-.223.262l-1.533.883a.165.165 0 01-.213 0 .369.369 0 01-.1-.291v-11.646a.834.834 0 01.1-.4.582.582 0 01.214-.272l1.213-.7a.3.3 0 01.33 0 .756.756 0 01.155.165l2.668 4.6v-6.071a.824.824 0 01.1-.388.582.582 0 01.213-.272l1.533-.883a.175.175 0 01.223 0 .4.4 0 01.087.291v11.6a.97.97 0 01-.087.4.592.592 0 01-.223.272z"
          fill="#fff"
        />
        <path
          data-name="Path 6643"
          d="M204.335 100.871c.146-.1.272-.1.349 0a.728.728 0 01.175.3l2.542 9.839a.972.972 0 010 .243.757.757 0 01-.068.32.553.553 0 01-.184.223l-1.475.854c-.155.087-.262.1-.33 0a.378.378 0 01-.116-.233l-.33-1.3-3.1 1.8-.32 1.679a1.514 1.514 0 01-.126.369.8.8 0 01-.33.34l-1.475.854a.136.136 0 01-.175 0 .281.281 0 01-.078-.233 1.1 1.1 0 01.049-.291l2.539-12.794a2.389 2.389 0 01.165-.485.825.825 0 01.359-.408zm-1.892 8.936l1.892-1.1-.97-3.755z"
          fill="#fff"
        />
        <path
          data-name="Path 6644"
          d="M214.436 104.752a.175.175 0 01.223 0 .388.388 0 01.087.281v1.941a.971.971 0 01-.087.388.592.592 0 01-.223.272l-5.4 3.124a.175.175 0 01-.223 0 .4.4 0 01-.087-.291V98.823a.97.97 0 01.087-.4.65.65 0 01.223-.272l1.533-.883a.165.165 0 01.213 0 .359.359 0 01.1.281v9.218z"
          fill="#fff"
        />
        <path
          data-name="Path 6645"
          d="M221.518 90.761a3.115 3.115 0 011.368-.456 1.941 1.941 0 011.077.233 1.756 1.756 0 01.718.737 2.27 2.27 0 01.262 1.048.757.757 0 01-.068.32c-.058.107-.107.184-.175.213l-1.523.883a.407.407 0 01-.262.078.4.4 0 01-.2-.1.64.64 0 01-.116-.116.621.621 0 00-.213-.136.971.971 0 00-.349 0 1.5 1.5 0 00-.514.194 4.143 4.143 0 00-.427.291 3.138 3.138 0 00-.359.359 1.351 1.351 0 00-.252.427 1.232 1.232 0 00-.107.5.5.5 0 00.116.378.524.524 0 00.369.126 3.311 3.311 0 00.7-.107l1.067-.281a3.552 3.552 0 011.126-.146 1.3 1.3 0 01.8.311 1.542 1.542 0 01.475.8 4.85 4.85 0 01.155 1.368 6.232 6.232 0 01-.262 1.8 7.113 7.113 0 01-.757 1.659 7.463 7.463 0 01-1.135 1.446 7.1 7.1 0 01-1.446 1.1 3.483 3.483 0 01-1.446.514 1.941 1.941 0 01-1.126-.2 1.766 1.766 0 01-.689-.825 2.571 2.571 0 01-.281-1.184.65.65 0 01.078-.32.437.437 0 01.165-.213l1.533-.883a.408.408 0 01.252-.087.5.5 0 01.184.078l.146.155a.738.738 0 00.252.184.7.7 0 00.4.068 1.387 1.387 0 00.582-.213 4.443 4.443 0 001.038-.8 1.446 1.446 0 00.408-.97c0-.184-.048-.3-.155-.359a.669.669 0 00-.446-.068 3.637 3.637 0 00-.776.146l-1.116.291a1.688 1.688 0 01-1.756-.31 3.212 3.212 0 01-.553-2.154 6.359 6.359 0 01.223-1.6 8.01 8.01 0 01.66-1.62 7.693 7.693 0 011.048-1.455 5.714 5.714 0 011.307-1.104z"
          fill="#fff"
        />
        <path
          data-name="Path 6646"
          d="M232.483 94.311a.165.165 0 01.223 0 .388.388 0 01.087.281v1.945a.971.971 0 01-.087.4.65.65 0 01-.223.272l-5.531 3.192a.175.175 0 01-.223 0 .388.388 0 01-.087-.281V88.47a.971.971 0 01.087-.4.65.65 0 01.223-.272l5.434-3.134a.165.165 0 01.223 0 .359.359 0 01.1.281v1.941a.835.835 0 01-.1.4.534.534 0 01-.223.272l-3.59 2.028v1.941l3.338-1.941a.175.175 0 01.223 0 .4.4 0 01.087.291v1.941a.97.97 0 01-.087.388.591.591 0 01-.223.272l-3.338 1.941v2.008z"
          fill="#fff"
        />
        <path
          data-name="Path 6647"
          d="M237.907 81.484a4.114 4.114 0 011.378-.543 1.494 1.494 0 011.028.155 1.688 1.688 0 01.66.912 5.345 5.345 0 01.223 1.688 7.763 7.763 0 01-.369 2.445 6.231 6.231 0 01-1.019 1.941l1.446 3.435a.552.552 0 010 .175.835.835 0 01-.068.33.456.456 0 01-.184.213l-1.582.97c-.155.087-.272.1-.34 0a.972.972 0 01-.155-.233l-1.29-3.1-1.077.621v3.884a.825.825 0 01-.1.388.582.582 0 01-.213.272l-1.533.883a.175.175 0 01-.223 0 .4.4 0 01-.087-.291V83.987a.97.97 0 01.087-.4.592.592 0 01.223-.272zm-1.32 6.161l1.32-.757a2.5 2.5 0 00.805-.757 2.1 2.1 0 00.33-1.232c0-.5-.107-.786-.33-.854a.97.97 0 00-.805.184l-1.32.757z"
          fill="#fff"
        />
        <path
          data-name="Path 6648"
          d="M245.029 89.935c-.3.175-.5.058-.611-.359l-2.387-9.849a.592.592 0 000-.146v-.068a.65.65 0 01.078-.32.476.476 0 01.175-.223l1.475-.854c.155-.087.262-.1.33 0a.456.456 0 01.126.233l1.62 6.967 1.62-8.839a1.331 1.331 0 01.116-.369.835.835 0 01.34-.349l1.465-.844a.145.145 0 01.184 0 .32.32 0 01.078.233v.078a1.018 1.018 0 010 .175l-2.378 12.613a1.5 1.5 0 01-.621 1.077z"
          fill="#fff"
        />
        <path
          data-name="Path 6649"
          d="M256.808 80.261a.175.175 0 01.223 0 .359.359 0 01.1.281v1.941a.825.825 0 01-.1.388.534.534 0 01-.223.272l-5.5 3.183a.175.175 0 01-.223 0 .378.378 0 01-.1-.291V74.391a.824.824 0 01.1-.388.533.533 0 01.223-.272l5.434-3.144a.175.175 0 01.223 0 .4.4 0 01.087.291v1.941a.97.97 0 01-.087.388.592.592 0 01-.223.272l-3.59 2.076v1.941l3.328-1.941a.165.165 0 01.223 0 .369.369 0 01.1.291v1.941a.853.853 0 01-.1.4.582.582 0 01-.223.272l-3.328 1.941v2.005z"
          fill="#fff"
        />
        <path
          data-name="Path 6650"
          d="M262.233 67.434a4.425 4.425 0 011.378-.543 1.523 1.523 0 011.039.155 1.678 1.678 0 01.65.912 5.152 5.152 0 01.223 1.688 7.7 7.7 0 01-.359 2.445 6.238 6.238 0 01-1.029 1.941l1.446 3.435a.4.4 0 010 .184.776.776 0 01-.078.32.476.476 0 01-.184.223l-1.582.912c-.155.087-.262.1-.33 0a.757.757 0 01-.155-.233l-1.271-3.056-1.067.621v3.881a.972.972 0 01-.1.4.65.65 0 01-.223.272l-1.523.834a.175.175 0 01-.223 0 .369.369 0 01-.1-.291V69.889a.855.855 0 01.1-.4.582.582 0 01.223-.272zm-1.31 6.2l1.31-.757a2.435 2.435 0 00.815-.757 2.135 2.135 0 00.32-1.223c0-.5-.107-.8-.32-.864a1.077 1.077 0 00-.815.184l-1.31.757z"
          fill="#fff"
        />
        <path
          data-name="Path 6651"
          d="M195.107 160.806a1.426 1.426 0 00-2.115.262c-.65.805-.708 3.289-.97 4.968a20.27 20.27 0 01-1.64 5.181 26.974 26.974 0 01-5.54 7.627 35.056 35.056 0 01-10.605 7.073 30.826 30.826 0 01-4.114 1.436c-1.388.378-3.251.417-4.444 1.2a.408.408 0 000 .6 9.5 9.5 0 004.754.136 25.139 25.139 0 005.618-1.552 34.309 34.309 0 0010.13-6.161 30.021 30.021 0 007.219-9 21.065 21.065 0 002-5.88 20.308 20.308 0 00.427-3.241 3.124 3.124 0 00-.72-2.649z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default SvgPage500;
