import * as React from 'react'
import classnames from 'classnames'
import useMedia from 'hooks/useMedia'
import Text from 'components/atoms/text'
import { Statistics } from '@tetahq/bironbir_api_client'
import {
    ArrowDownSLine,
    ArrowRightSLine,
    ArrowUpSLine,
    BroadcastLine,
    FireLine,
    FlashlightFill,
    TimerFlashLine,
    TurkishLiraCircle,
    UserHeartLine,
    UserReceivedLine,
} from '../../icons'

import styles from './style.module.css'

type PerformanceCardProps = {
    performance: Statistics
    selected: boolean
    onClick: () => void
    type: 'TOTAL_EARNINGS' | 'LIVE_STREAM_COUNT' | 'LIVE_STREAM_DURATION' | 'BURNT_CALORIES' | 'UNIQUE_USERS_JOINED_TO_BROADCASTS' | 'REFERENCE_COUNT'
}

const PerformanceCard = ({ performance, selected, onClick, type }: PerformanceCardProps) => {
    const isMobile = useMedia('(max-width: 1024px)')
    const [icon, setIcon] = React.useState(<FlashlightFill />)
    const [title, setTitle] = React.useState('')
    const [prefix, setPrefix] = React.useState('')
    const [description, setDescription] = React.useState('')

    const onClickHandler = () => {
        onClick && onClick()
    }

    React.useEffect(() => {
        switch (type) {
            case 'TOTAL_EARNINGS':
                setIcon(<TurkishLiraCircle />)
                setTitle(`${performance.total.toString()} TL`)
                setDescription('Toplam Kazanç')
                setPrefix('TL')
                break
            case 'LIVE_STREAM_COUNT':
                setIcon(<BroadcastLine />)
                setTitle(`${performance.total.toString()} Canlı Yayın`)
                setDescription('Canlı Yayın Sayısı')
                setPrefix('Canlı Yayın')
                break
            case 'LIVE_STREAM_DURATION':
                setIcon(<TimerFlashLine />)
                setTitle(`${performance.total.toString()} Dakika`)
                setDescription('Canlı Yayın Süresi')
                setPrefix('Dakika')
                break
            case 'BURNT_CALORIES':
                setIcon(<FireLine />)
                setTitle(`${performance.total.toString()} kcal`)
                setDescription('Yayınlarda Yakılan Kalori')
                setPrefix('Kalori')
                break
            case 'UNIQUE_USERS_JOINED_TO_BROADCASTS':
                setIcon(<UserHeartLine />)
                setTitle(`${performance.total.toString()} Kişi`)
                setDescription('Yayınlara Katılan Kişi')
                setPrefix('Kişi')
                break
            case 'REFERENCE_COUNT':
                setIcon(<UserReceivedLine />)
                setTitle(`${performance.total.toString()} Kişi`)
                setDescription('Referans Sayısı')
                setPrefix('Kişi')
                break
        }
    }, [type, performance])

    return (
        <div>
            <div className={classnames(styles['performance-card'], { [styles.selected]: (!isMobile && selected) })} onClick={onClickHandler}>
                <div className={classnames(styles['icon'], { [styles.selected]: selected })}>{icon}</div>
                <div>
                    <Text weight={'medium'}>{title}</Text>
                    <Text>{description}</Text>
                </div>
                {!isMobile && <ArrowRightSLine />}
                {isMobile && (selected ? <ArrowUpSLine /> : <ArrowDownSLine />)}
            </div>
            {selected && isMobile && (
                <div className={styles.dropdown}>
                    <div>
                        <Text weight={'medium'}>Bugün</Text>
                        <Text weight={'medium'}>{performance.daily} {prefix}</Text>
                    </div>
                    <div>
                        <Text weight={'medium'}>Bu Hafta</Text>
                        <Text weight={'medium'}>{performance.weekly} {prefix}</Text>
                    </div>
                    <div>
                        <Text weight={'medium'}>Bu Ay</Text>
                        <Text weight={'medium'}>{performance.monthly} {prefix}</Text>
                    </div>
                </div>
            )}
        </div>
    )
}

export default PerformanceCard
