import * as React from 'react'
import useAuth from 'hooks/useAuth'
import Button from 'components/atoms/button'
import { AuthContextProps } from 'providers/auth'
import SimpleImageSelector from 'components/atoms/simple-image-selector'

import styles from './style.module.css'

type ProfilePictureCardProps = {
    fullName?: string
    loading?: boolean
    onConfirm: (image: string) => void
}

const ProfilePictureCard: React.FC<ProfilePictureCardProps> = ({ fullName, loading, onConfirm }) => {
    const auth = useAuth() as AuthContextProps
    const [image, setImage] = React.useState<string | undefined>(undefined)

    const handleSubmit = async () => {
        onConfirm(image ?? `https://ui-avatars.com/api/?size=512&name=${fullName ?? auth.user?.name + ' ' + auth.user?.surname}`)
    }

    return (
        <>
            <div className={styles.uploader}>
                <SimpleImageSelector fullName={fullName} onImageCropped={setImage ?? ' '} />
            </div>

            <div className={styles.actions}>
                <Button loading={loading} onClick={handleSubmit} type='danger-2' variation='block'>
                    Kaydı Tamamla
                </Button>
            </div>
        </>
    )
}

export default ProfilePictureCard
