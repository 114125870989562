import { FC } from 'react'
import Text from 'components/atoms/text'
import { ArrowRightSLine } from 'components/icons'
import PlanIndicator from '../../atoms/plan-indicator'
import { PackageCourseTrainingListItem } from '@tetahq/bironbir_api_client'

import styles from './style.module.css'

type TrainingItemProps = {
    clickable?: boolean
    training: PackageCourseTrainingListItem
    onClick?: () => void
}

const TrainingItem: FC<TrainingItemProps> = ({ clickable, training, onClick }) => {
    return (
        <div className={styles.wrapper}>
            <PlanIndicator percentage={training.isWatched ? 100 : 0} onPress={onClick} />
            <div className={styles.body} onClick={onClick}>
                <Text weight='medium'>{training.header}</Text>
                <Text size='sm'>{training.subheader}</Text>
            </div>
            {clickable && <ArrowRightSLine className={styles.icon} />}
        </div>
    )
}

export default TrainingItem
