import * as React from 'react'
import moment from 'moment'
import styles from './style.module.css'
import useMedia from '../../../hooks/useMedia'
import classNames from 'classnames'

export interface DayButtonProps {
    day: string
    isSelected: boolean
    onClicked: () => void
}

export const DayButton: React.FC<DayButtonProps> = ({day, isSelected, onClicked}) => {
    const isMobile = useMedia('(max-width: 768px)')

    const momentDay = (() => {
        return moment(day)
    })()

    const currentMoment = (() => {
        return moment()
    })()

    const isBefore = (() => {
        return momentDay.startOf('day').isBefore(currentMoment.startOf('day'))
    })()

    const isToday = (() => {
        return momentDay.isSame(currentMoment, 'day')
    })()

    const renderMobile = (() => {
        if(!isMobile) {
            return null
        }

        return (
            <div
                className={classNames(styles.dayBlock, {[styles.daySelected]: isSelected})}
                onClick={onClicked}
            >
                <div className={styles.dayName}>
                    {momentDay.format('ddd').toLocaleUpperCase()}
                </div>

                <div className={classNames(styles.dayNumber, {[styles.past]: isBefore, [styles.future]: !isBefore && !isToday, [styles.today]: isToday})}>
                    {momentDay.format('D')}
                </div>
            </div>
        )
    })()


    const renderDesktop = (() => {
        if(isMobile) {
            return null
        }

        return (
            <div
                className={classNames(styles.dayBlock, styles.dayBlockDesktop, {[styles.daySelected]: isSelected})}
                onClick={onClicked}
            >
                <div className={classNames(styles.dayNumber, {[styles.past]: isBefore, [styles.future]: !isBefore && !isToday, [styles.today]: isToday})}>
                    {momentDay.format('D')}
                </div>

                <div className={styles.dayName}>
                    {momentDay.format('ddd').toLocaleUpperCase()}
                </div>
            </div>
        )
    })()

    return isMobile ? renderMobile : renderDesktop
}
