import React, { useEffect, useRef } from 'react'
import moment from 'moment-timezone'
import { DayButton } from '../../atoms/day-button'
import useMedia from '../../../hooks/useMedia'
import classNames from 'classnames'
import styles from './style.module.css'

export interface BroadcastDaySelectionProps {
    selectedDay: string
    onClickedOnDay: (selectedDay: string) => void
}

export const BroadcastDaySelection: React.FC<BroadcastDaySelectionProps> = ({ selectedDay, onClickedOnDay }) => {
    const isMobile = useMedia('(max-width: 768px)')
    const daysRef = useRef<HTMLDivElement>(null)

    const selectedMoment = (() => {
        return moment(selectedDay)
    })()

    const renderAllDays = React.useMemo(() => {
        const iterator = moment().subtract(7, 'days')
        const last = moment().add(7, 'days')
        const allDays = []

        while (iterator.isSameOrBefore(last, 'day')) {
            allDays.push(
                <DayButton
                    key={iterator.format('DD-MM')}
                    day={iterator.format()}
                    isSelected={selectedMoment.isSame(iterator, 'day')}
                    onClicked={onClickedOnDay.bind(null, iterator.format())}
                />,
            )
            iterator.add(1, 'day')
        }

        return allDays
    }, [selectedMoment, onClickedOnDay])

    useEffect(() => {
        setTimeout(() => {
            if (daysRef.current !== null) {
                daysRef.current.scrollTo({
                    left: (daysRef.current.scrollWidth - daysRef.current.clientWidth) / 2,
                    top: (daysRef.current.clientHeight - daysRef.current.scrollHeight) / 2,
                })
            }
        }, 50)
    }, [daysRef])

    return (
        <div ref={daysRef} className={classNames({ [styles.horizontalDaySelection]: isMobile, [styles.verticalDaySelection]: !isMobile })}>
            {renderAllDays}
        </div>
    )
}
