import moment from 'moment'
import * as React from 'react'
import useApi from 'hooks/useApi'
import useAuth from 'hooks/useAuth'
import useMedia from 'hooks/useMedia'
import { toast } from 'react-toastify'
import { AuthContextProps } from 'providers/auth'
import { ApiContextInterface } from 'providers/api'
import { InputProps } from 'components/atoms/input'
import LoginCard from 'components/molecules/login-card'
import Modal, { ModalSize } from 'components/atoms/modal'
import RegisterCard from 'components/molecules/register-card'
import NewPasswordCard from '../../molecules/new-password-card'
import OnboardingCard from 'components/molecules/onboarding-card'
import AuthModalNavbar from 'components/molecules/auth-modal-navbar'
import VerifyEmailCard from 'components/molecules/verify-email-card'
import WhoReferencedCard from 'components/molecules/who-referenced-card'
import ProfilePictureCard from 'components/molecules/profile-picture-card'
import BodyInformationCard from 'components/molecules/body-information-card'
import { FitnessTargetOption, HearFromOption } from '@tetahq/bironbir_api_client'
import ForgottenPasswordCard from 'components/molecules/forgotten-password-card'

import styles from './style.module.css'
import useAppInfo from '../../../hooks/useAppInfo'
import { Device } from '@capacitor/device'

type PageHeading = {
    title?: string
    webTitle?: string
    description?: string
    canBack?: boolean
    canClose?: boolean
}

const AuthModal: React.FC = () => {
    const api = useApi() as ApiContextInterface
    const auth = useAuth() as AuthContextProps
    const appInfo = useAppInfo()
    const isMobile = useMedia('(max-width: 1024px)')

    const [loading, setLoading] = React.useState(false)
    const [activePage, setActivePage] = React.useState<keyof typeof pages>('entry')
    const [currentEmail, setCurrentEmail] = React.useState('')
    const [recoverEmail, setRecoverEmail] = React.useState('')
    const [currentFullName, setCurrentFullName] = React.useState<string | undefined>(undefined)
    const [loginStatus, setLoginStatus] = React.useState<InputProps['status']>('default')
    const [referenceChannels, setChannels] = React.useState<HearFromOption[]>([])
    const [referenceTrainerId, setPersonalTrainerId] = React.useState<number | null>(null)
    const [personalHeight, setHeight] = React.useState<string>('')
    const [personalWeight, setWeight] = React.useState<string>('')
    const [personalAge, setAge] = React.useState<Date>(new Date())
    const [personalGoals, setGoals] = React.useState<FitnessTargetOption[]>([])
    const [nextResendAt, setNextResendAt] = React.useState<string>('')
    const [isApp, setIsApp] = React.useState(false)

    const pages: { [key: string]: PageHeading } = {
        entry: {
            canBack: false,
            canClose: isMobile && !isApp,
        },
        registerBase: {
            title: 'Aramıza katılın!',
            webTitle: 'Yeni Hesap Oluştur',
            description: '',
            canBack: isMobile,
            canClose: false,
        },
        registerVerifyEmail: {
            title: 'E-Posta doğrulama',
            webTitle: 'E-Posta Doğrulama',
            description: 'Lütfen e-posta adresinize gelen doğrulama kodunu aşağıdaki alana girin.',
            canBack: false,
            canClose: false,
        },
        registerWhoReferenced: {
            title: 'Bizi nereden duydunuz?',
            webTitle: 'Bizi nereden duydunuz?',
            description: 'Size daha iyi hizmet verebilmek için lütfen bize referansınızı bildiriniz.',
            canBack: false,
            canClose: false,
        },
        registerBodyInformation: {
            title: 'Kişisel Hedeflerinizi Birlikte Belirleyim!',
            webTitle: 'Kişisel Hedeflerinizi Birlikte Belirleyim!',
            description: 'Kişisel bilgilerini ve hedeflerini söyle, senin için en uygun programı birlikte oluşturalım.',
            canBack: true,
            canClose: false,
        },
        registerProfilePicture: {
            webTitle: 'Profil Fotoğrafı Yükle',
            canBack: true,
            canClose: false,
        },
        login: {
            title: 'Tekrar hoş geldin,',
            webTitle: 'Giriş Yap',
            description: '',
            canBack: isMobile,
            canClose: false,
        },
        forgottenPassword: {
            title: 'Şifrenizi kurtarın',
            webTitle: 'Şifrenizi Kurtarın',
            description: 'E-mail adresinize bir kod yollayacağız.',
            canBack: isMobile,
            canClose: false,
        },
        newPassword: {
            title: 'Yeni Şifre',
            webTitle: 'Yeni Şifre',
            description: 'Lütfen yeni şifrenizi belirleyiniz.',
            canBack: false,
            canClose: false,
        },
    }

    React.useEffect(() => {
            (async () => {
                const { platform } = await Device.getInfo()
                if (platform !== 'web' && !auth.isLoggedIn) {
                    setIsApp(true)
                    auth.showModal('entry')
                } else {
                    setIsApp(false)
                }
            })()
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [auth.isLoggedIn])

    React.useEffect(() => {
            if (auth.isLoggedIn) {
                const isAccountActivated = localStorage.getItem('isAccountActivated')
                const profileCompleted = localStorage.getItem('isProfileCompleted')
                if (isAccountActivated !== null && isAccountActivated?.toString() === 'false') {
                    auth.showModal?.('registerVerifyEmail')
                } else if (profileCompleted !== null && profileCompleted?.toString() === 'false') {
                    auth.showModal?.('registerWhoReferenced')
                }
            }
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [auth.isLoggedIn])

    React.useEffect(() => {
        if (auth.initialPage) setActivePage(auth.initialPage)
    }, [auth.initialPage])

    const goBack = () => {
        switch (activePage) {
            case 'registerVerifyEmail':
                break
            case 'registerWhoReferenced':
                break
            case 'registerBodyInformation':
                setActivePage('registerWhoReferenced')
                break
            case 'registerProfilePicture':
                setActivePage('registerBodyInformation')
                break
            case 'forgottenPassword':
                setActivePage('login')
                break
            default:
                setActivePage('entry')
                break
        }
    }

    const handleRegister = async (name: string, surname: string, email: string, phoneNumber: string | undefined, password: string) => {
        setLoading(true)
        try {
            const { data } = await api.Account.register({ email, password, surname, phoneNumber, name })

            setCurrentEmail(email)
            setCurrentFullName(name + ' ' + surname)
            setChannels([])
            setPersonalTrainerId(null)

            toast.success('Hesabınız Başarıyla Oluşturuldu')

            if (data.nextResendPossibleAt) {
                setNextResendAt(data.nextResendPossibleAt)
            }

            setActivePage('registerVerifyEmail')
        } catch {
        }
        setLoading(false)
    }

    const handleEmailVerification = async (code: string) => {
        setLoading(true)
        try {
            await api.Account.activateAccount({ email: currentEmail, confirmationCode: code })
            localStorage.setItem('isAccountActivated', 'true')
            localStorage.setItem('isProfileCompleted', 'false')
            toast.success('Email Adresinizi Doğruladığınız İçin Teşekkürler')
            setActivePage('registerWhoReferenced')
        } catch {
        }
        setLoading(false)
    }

    const handleVerificationCodeResend = async (email?: string) => {
        setLoading(true)
        try {
            const { data } = await api.Account.resendAccountActivationCode({ email: email ?? currentEmail })
            if (data.nextResendPossibleAt) {
                setNextResendAt(data.nextResendPossibleAt)
            }
        } catch (e) {
            setLoading(false)
            throw e
        }
        setLoading(false)
    }

    const handleReferences = (channels: HearFromOption[], personalTrainerId?: number) => {
        setChannels(channels)
        setPersonalTrainerId(personalTrainerId ?? null)

        setActivePage('registerBodyInformation')
    }

    const handleBodyInformation = (height: string, weight: string, age: Date, goals: FitnessTargetOption[]) => {
        setHeight(height)
        setWeight(weight)
        setAge(age)
        setGoals(goals)

        setActivePage('registerProfilePicture')
    }

    const handleProfilePicture = async (image: string) => {
        setLoading(true)
        try {
            const pathToBlob = await fetch(image).then(r => r.blob())
            const { data } = await api.Account.completeCustomerProfile(pathToBlob, JSON.stringify({
                heardFroms: referenceChannels.map(x => x.id?.toString()),
                referralPersonalTrainer: referenceTrainerId?.toString(),
                height: parseInt(personalHeight),
                weight: parseInt(personalWeight),
                birthDate: moment(personalAge).format('YYYY-MM-DD'),
                fitnessTargets: personalGoals.map(x => x.id?.toString()),
            }))

            auth.setUser?.((x: any) => {
                if (x && x.avatar) {
                    x.avatar.avatar1X = data.avatars.avatar1X
                    x.avatar.avatar2X = data.avatars.avatar2X
                    x.avatar.avatar3X = data.avatars.avatar3X
                    x.avatar.avatar4X = data.avatars.avatar4X
                }
                return x
            })

            toast.success('Kayıt İşleminiz Başarıyla Tamamlandı')

            localStorage.setItem('isProfileCompleted', 'true')
            auth.hideModal?.()
        } catch {

        }
        setLoading(false)
    }

    const handleLogin = async (email: string, password: string) => {
        setLoading(true)
        setLoginStatus('default')
        try {
            const { data } = await api.Account.login({ email, password })

            if (data && data.jwt) {
                if (appInfo.playerId) {
                    await api.OneSignal.setAppId(appInfo.playerId)
                }
            }

            auth.setModalShown?.(false)
        } catch (e: any) {
            if (e.response.data?.status === 401) {
                try {
                    await handleVerificationCodeResend(email)
                    setCurrentEmail(email)
                    setActivePage('registerVerifyEmail')
                } catch {
                }
            }
            setLoginStatus('danger')
        }
        setLoading(false)
    }

    const handlePasswordReset = async (email: string) => {
        setLoading(true)
        try {
            await api.Account.lostPassword({ email })
            setRecoverEmail(email)
            toast.success('Şifre sıfırlama kodunuz emailinize gönderildi')
            setActivePage('newPassword')
        } catch {
        }
        setLoading(false)
    }

    const handleNewPassword = async (newPassword: string, recoverCode?: string) => {
        setLoading(true)
        try {
            await api.Account.recoverPassword({ email: recoverEmail, newPassword, recoverCode })
            await handleLogin(recoverEmail, newPassword)
            setRecoverEmail('')
            hideModal()
        } catch {
        }
        setLoading(false)
    }

    const hideModal = () => {
        auth.hideModal?.()
    }

    return (
        <Modal size={activePage === 'entry' ? ModalSize.Large : ModalSize.Small}
               isVisible={auth.modalShown ?? false}
               onModalClose={hideModal}
               backdropUnClickable={!pages[activePage].canClose}
               escUnClickable={!pages[activePage].canClose}
               transparent={activePage === 'entry'}>
            <div className={styles.wrapper}>
                <AuthModalNavbar canGoBack={pages[activePage].canBack} onGoBack={goBack} canClose={pages[activePage].canClose} onClose={hideModal} {...pages[activePage]} />

                {activePage === 'entry' && <OnboardingCard onClose={hideModal} onLogin={() => setActivePage('login')} onRegister={() => setActivePage('registerBase')} />}
                {activePage === 'registerBase' && <RegisterCard loading={loading} onConfirm={handleRegister} />}
                {activePage === 'registerVerifyEmail' &&
                    <VerifyEmailCard loading={loading} onConfirm={handleEmailVerification} onCodeResend={handleVerificationCodeResend} nextResendAt={nextResendAt}
                                     email={currentEmail} />}
                {activePage === 'registerWhoReferenced' && <WhoReferencedCard loading={loading} onConfirm={handleReferences} />}
                {activePage === 'registerBodyInformation' && <BodyInformationCard loading={loading} onConfirm={handleBodyInformation} />}
                {activePage === 'registerProfilePicture' && <ProfilePictureCard fullName={currentFullName} loading={loading} onConfirm={handleProfilePicture} />}
                {activePage === 'login' &&
                    <LoginCard
                        loading={loading}
                        onConfirm={handleLogin}
                        onForgottenPasswordClicked={() => setActivePage('forgottenPassword')}
                        onRegisterClicked={() => setActivePage('registerBase')}
                        status={loginStatus}
                    />}
                {activePage === 'forgottenPassword' && <ForgottenPasswordCard loading={loading} onConfirm={handlePasswordReset} />}
                {activePage === 'newPassword' && <NewPasswordCard loading={loading} onConfirm={handleNewPassword} />}
            </div>
        </Modal>
    )
}

export default AuthModal
