import * as React from 'react'
import { ChatUser } from 'types'
import Text from '../../atoms/text'
import classnames from 'classnames'
import moment from 'moment-timezone'
import Avatar from '../../atoms/avatar'
import { getMessageTime } from 'utils/time-helpers'
import { GroupMessageReceivedWithUserMetadata } from '@fosh/chat-client/FoshChatClient.Types'

import styles from './style.module.css'

type ChatCardProps = {
    message: GroupMessageReceivedWithUserMetadata<ChatUser | null>
}

const ChatCard = ({ message }: ChatCardProps) => {
    return (
        <div className={classnames(styles.wrapper, { [styles.owner]: true })}>
            <div className={classnames(styles.card, { [styles.owner]: true })}>
                <div><Avatar size={1.75} url={message.user?.avatar.avatar1X} fallbackSrc={`https://ui-avatars.com/api/?size=128&name=${message.user?.fullName}`} /></div>
                <div>
                    <div><Text size={'xs'} type={'dimmed'}>{message.user?.fullName}</Text></div>
                    <div className={styles.messageText}><Text size={'2xs'} type={'white'}>{message.message}</Text></div>
                </div>
                <div><Text size={'xs'} type={'soft-blue'}>{getMessageTime(moment(message.messageTime).toDate())}</Text></div>
            </div>
        </div>
    )
}

export default React.memo(ChatCard)
