import * as React from 'react'
import classnames from 'classnames'
import useMedia from 'hooks/useMedia'

import styles from './style.module.css'
import moment from 'moment-timezone'

export interface BroadcastStatusBadgeProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, any> {
    isEnded: boolean
    isLive: boolean
    startsAt: string
}

export const BroadcastStatusBadge: React.FC<BroadcastStatusBadgeProps> = ({ startsAt, isLive, isEnded, className, ...otherProps }) => {
    const isMobile = useMedia('(max-width: 478px)')

    const classes = classnames(
        className,
        styles.broadcastStatusBadge,
        {
            [styles.live]: isLive,
            [styles.past]: isEnded,
            [styles.future]: !isEnded && !isLive,
            [styles.sizeBig]: !isMobile,
        },
    )

    return (
        <div className={classes} {...otherProps}>
            {isLive ? 'CANLI' : moment(startsAt).format('HH:mm')}
        </div>
    )
}
