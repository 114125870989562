import classNames from 'classnames'
import Vimeo from '@u-wave/react-vimeo'
import Text from 'components/atoms/text'
import { TailSpin } from 'react-loader-spinner'
import Modal from 'components/atoms/modal'
import React, { FC, useState } from 'react'
import Button from 'components/atoms/button'
import ErrorBox from '../../atoms/error-box'
import PlanIndicator from 'components/atoms/plan-indicator'
import { ArrowDownSLine, ArrowRightSLine, CloseLine } from 'components/icons'
import { PackageCourseTrainingListItem } from '@tetahq/bironbir_api_client'

import styles from './style.module.css'

type PlanCardHeadingProps = {
    training: PackageCourseTrainingListItem | undefined
    initialStatus: boolean
    index: number
    onActive: (index: number) => void
    onEnd?: () => void
    videoShow?: boolean
    onModalClose?: () => void
}

const PlanCardHeading: FC<PlanCardHeadingProps> = ({ onModalClose, videoShow, training, initialStatus, children, onActive, index, onEnd }) => {
    const [active, setActive] = useState<boolean>(initialStatus ?? false)
    const [loaded, setLoaded] = useState<boolean>(false)
    const [videoShown, setVideoShown] = useState(false)

    React.useEffect(() => {
        setActive(initialStatus)
    }, [initialStatus])

    React.useEffect(() => {
        videoShow && setVideoShown(videoShow)
    }, [videoShow])

    const playVideo = () => {
        setVideoShown(true)
    }

    const hideVideo = () => {
        setVideoShown(false)
        onModalClose && onModalClose()
        onEnd && onEnd()
    }

    const onClickHandler = () => {
        onActive(index)
        if (!initialStatus) {
            setActive(x => !x)
        }
    }

    React.useEffect(() => {
        setLoaded(false)
    }, [training, videoShown])

    if (!training) {
        return <></>
    }

    return (
        <>
            <div
                className={styles.reset}
                onClick={onClickHandler}
                tabIndex={-1}
                role='button'
            >
                <div className={classNames(styles.wrapper, { [styles.active]: active })}>
                    <PlanIndicator onPress={playVideo} percentage={training.isWatched ? 100 : 0} />
                    <div className={styles.body}>
                        <Text weight='medium'>{training.header}</Text>
                        <Text size='sm'>{training.subheader}</Text>
                    </div>
                    {active ? <ArrowRightSLine className={styles.icon} /> : <ArrowDownSLine className={styles.icon} />}
                </div>
            </div>
            {active && <>
                {children}
                <Modal isVisible={videoShown} onModalClose={hideVideo}>
                    <>
                        {!loaded && <div className={styles.loaderWrapper}><TailSpin color={'#334362'} height={96} width={96} /></div>}

                        {training?.vimeoVideoInformation?.vimeoEmbedUrl ? (
                            <Vimeo className={styles.videoWrapper} onLoaded={() => setLoaded(true)} video={training.vimeoVideoInformation.vimeoEmbedUrl} autoplay width={'100%'}
                                   height={'100%'} onEnd={hideVideo} />
                        ) : (
                            <div className={styles.center}>
                                <ErrorBox text={'Video yüklenirken hata oluştu. Lütfen bizimle iletişime geçiniz.'} size={'sm'} />
                            </div>
                        )}

                        <div className={styles.closeButton}>
                            <Button onClick={hideVideo} type='danger-2' variation='circle'>
                                <CloseLine />
                            </Button>
                        </div>
                    </>
                </Modal>

            </>}

        </>
    )
}

export default PlanCardHeading
