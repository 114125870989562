import { FC } from 'react'
import classNames from 'classnames'

import styles from './style.module.css'

export { styles }

type PackageCourseTemplateProps = {
    isMobile: boolean
}

const PackageCourseTemplate: FC<PackageCourseTemplateProps> = ({ isMobile, children }) => {
    return (
        <div
            className={classNames({
                [styles.wrapper]: isMobile,
                [styles.wideWrapper]: !isMobile,
            })}
        >
            {children}
        </div>
    )
}

export default PackageCourseTemplate
