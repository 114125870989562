import * as React from 'react'
import { FitnessTargetOption, HearFromOption, LegalDocumentsListItem, FaqsListItem } from '@tetahq/bironbir_api_client'

type AppInfoContextProps = {
  currentAndroidVersion: string | undefined
  setCurrentAndroidVersion: React.Dispatch<React.SetStateAction<string | undefined>>

  currentIOSVersion: string | undefined
  setCurrentIOSVersion: React.Dispatch<React.SetStateAction<string | undefined>>

  minimumAndroidVersion: string | undefined
  setMinimumAndroidVersion: React.Dispatch<React.SetStateAction<string | undefined>>

  minimumIOSVersion: string | undefined
  setMinimumIOSVersion: React.Dispatch<React.SetStateAction<string | undefined>>

  inMaintenance: boolean | undefined
  setInMaintenance: React.Dispatch<React.SetStateAction<boolean | undefined>>

  fitnessTargetOptions: FitnessTargetOption[] | undefined
  setFitnessTargetOptions: React.Dispatch<React.SetStateAction<FitnessTargetOption[] | undefined>>

  hearFromOptions: HearFromOption[] | undefined
  setHearFromOptions: React.Dispatch<React.SetStateAction<HearFromOption[] | undefined>>

  legalDocuments: LegalDocumentsListItem[] | undefined
  setLegalDocuments: React.Dispatch<React.SetStateAction<LegalDocumentsListItem[] | undefined>>

  playerId: string | undefined
  setPlayerId: React.Dispatch<React.SetStateAction<string | undefined>>

  faqs: FaqsListItem[] | undefined
  setFaqs: React.Dispatch<React.SetStateAction<FaqsListItem[] | undefined>>
}

export const AppInfoContext = React.createContext<Partial<AppInfoContextProps>>({})

export const AppInfoProvider: React.FC = ({ children }) => {
  const [currentAndroidVersion, setCurrentAndroidVersion] = React.useState<string | undefined>(undefined)
  const [currentIOSVersion, setCurrentIOSVersion] = React.useState<string | undefined>(undefined)
  const [minimumAndroidVersion, setMinimumAndroidVersion] = React.useState<string | undefined>(undefined)
  const [minimumIOSVersion, setMinimumIOSVersion] = React.useState<string | undefined>(undefined)
  const [inMaintenance, setInMaintenance] = React.useState<boolean | undefined>(undefined)
  const [fitnessTargetOptions, setFitnessTargetOptions] = React.useState<FitnessTargetOption[] | undefined>(undefined)
  const [hearFromOptions, setHearFromOptions] = React.useState<HearFromOption[] | undefined>(undefined)
  const [legalDocuments, setLegalDocuments] = React.useState<LegalDocumentsListItem[] | undefined>(undefined)
  const [playerId, setPlayerId] = React.useState<string | undefined>(undefined)
  const [faqs, setFaqs] = React.useState<FaqsListItem[] | undefined>(undefined)

  return (
    <AppInfoContext.Provider
      value={{
        currentAndroidVersion,
        setCurrentAndroidVersion,
        currentIOSVersion,
        setCurrentIOSVersion,
        minimumAndroidVersion,
        setMinimumAndroidVersion,
        minimumIOSVersion,
        setMinimumIOSVersion,
        inMaintenance,
        setInMaintenance,
        fitnessTargetOptions,
        setFitnessTargetOptions,
        hearFromOptions,
        setHearFromOptions,
        legalDocuments,
        setLegalDocuments,
        playerId,
        setPlayerId,
        faqs,
        setFaqs,
      }}>{children}</AppInfoContext.Provider>
  )
}
