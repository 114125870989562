import * as React from 'react'
import Moment from 'react-moment'
import classnames from 'classnames'
import Text from '../../atoms/text'
import moment from 'moment-timezone'
import Button from '../../atoms/button'
import { PlayCircleFill } from '../../icons'
import ImageWithFallback from '../../atoms/image-with-fallback'
import { BroadcastProgramListItem } from '@tetahq/bironbir_api_client'
import { BroadcastStatusBadge } from '../../atoms/broadcast-status-badge'

import styles from './style.module.css'

export enum eBroadcastCardType {
    HOMEPAGE = 'homepage',
    BROADCAST_FIXTURE = 'broadcast_fixture'
}

type BroadcastCardProps = {
    onClick?: () => void
    mode?: eBroadcastCardType
    broadcast: BroadcastProgramListItem
}

const BroadcastCard: React.FC<BroadcastCardProps> = ({ onClick, mode = eBroadcastCardType.HOMEPAGE, broadcast }) => {
    const [notStartedYet] = React.useState(moment(broadcast.startsAt).add(1, 'hours').isAfter(moment.now()))

    const onClickHandler = () => {
        onClick && onClick()
    }

    const renderPlayButton = React.useMemo(() => {
        if (broadcast.isEnded && !broadcast.isLive) {
            return (
                <Button className={classnames(styles.playButton, { [styles.homePage]: mode === eBroadcastCardType.HOMEPAGE })} variation={'circle'} type={'blured'}>
                    <PlayCircleFill />
                </Button>
            )
        }
        return null
    }, [broadcast, mode])

    const renderHomepageCard = (() => {
        return (
            <div onClick={onClickHandler} className={styles['card']}>
                <div className={classnames(styles.backdrop, { [styles.homePage]: mode === eBroadcastCardType.HOMEPAGE })} />
                <ImageWithFallback
                    srcSet={broadcast.coverPhoto ? `${broadcast.coverPhoto.coverImage1X}, ${broadcast.coverPhoto.coverImage2X} 1.5x, ${broadcast.coverPhoto.coverImage3X} 2x, ${broadcast.coverPhoto.coverImage4X} 3x,` : undefined}
                    src={broadcast.coverPhoto?.coverImage4X}
                    fallbackSrc={'/images/fallbacks/250-250.png'}
                    alt=''
                />

                {broadcast.isLive && <div className={styles['live-badge']}>{<Text weight='medium' size='2xs' type='white'>CANLI</Text>}</div>}
                {!broadcast.isLive && !broadcast.isEnded && notStartedYet && (<Text weight={'medium'} size='2xs' type='white'>
                        <Moment
                            format={'DD.MM.YYYY - HH:mm'}
                            className={classnames(
                                styles['live-badge'], {
                                    [styles['coming-soon']]: notStartedYet,
                                },
                            )}>{broadcast.startsAt}</Moment>
                    </Text>
                )}
                {renderPlayButton}
                <div className={styles.name}><Text size={'xs'} type={'default'}>{broadcast.personalTrainer?.visibleName}</Text></div>
                <div className={styles.title}><Text size={'sm'} type={'default'}
                                                    weight={'medium'}>{broadcast.title}</Text></div>
            </div>
        )
    })()

    const renderBroadcastFixtureCard = (() => {
        return (
            <div onClick={onClickHandler} className={styles.cardInBroadcastFixture}>
                <div className={styles.backdrop} />
                <ImageWithFallback
                    srcSet={broadcast.coverPhoto ? `${broadcast.coverPhoto.coverImage1X}, ${broadcast.coverPhoto.coverImage2X} 1.5x, ${broadcast.coverPhoto.coverImage3X} 2x, ${broadcast.coverPhoto.coverImage4X} 3x,` : undefined}
                    src={broadcast.coverPhoto?.coverImage4X}
                    fallbackSrc={'/images/fallbacks/250-250.png'}
                    alt='Canlı-Yayın'
                />

                <BroadcastStatusBadge
                    className={styles.statusIndicator}
                    startsAt={broadcast.startsAt ?? ''}
                    isLive={broadcast.isLive ?? false}
                    isEnded={((!broadcast.isLive && broadcast.isEnded) || moment(broadcast.endsAt).isSameOrBefore()) ?? false}
                />

                {renderPlayButton}

                <div className={styles.cardInBroadcastFixtureName}>{broadcast.personalTrainer?.visibleName}</div>
                <div className={styles.cardInBroadcastFixtureTitle}>{broadcast.title}</div>
            </div>
        )
    })()

    return mode === eBroadcastCardType.HOMEPAGE ? renderHomepageCard : renderBroadcastFixtureCard
}

export default BroadcastCard
