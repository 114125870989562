import * as React from 'react'

import styles from './style.module.css'
import Text from '../../atoms/text'
import Button from '../../atoms/button'
import classnames from 'classnames'
import { SubscriptionPlanListItem } from '@tetahq/bironbir_api_client'

type PackagePurchaseCardProps = {
    onClick?: () => void
    plan: SubscriptionPlanListItem
    type?: 'light' | 'danger'
    loading?: boolean
    buttonText?: string
    buttonDisabled?: boolean
}

const PackagePurchaseCard = ({ onClick, plan, type, loading, buttonText, buttonDisabled }: PackagePurchaseCardProps) => {
    const onClickHandler = () => {
        onClick && onClick()
    }

    return (
        <div className={classnames(styles.card, { [styles.current]: plan.owned, [styles[`type-${type}`]]: type })}>
            <div className={styles['cardInfo']}>
                <div>
                    <div className={classnames(styles.packageTypeText, { [styles[`${type}`]]: type })}>
                        <Text size={'2xs'} type={'danger'} weight={'medium'}>{plan.name}</Text>
                    </div>

                    <Text weight={'bold'} size={'lg'} className={classnames(styles.monthAmount, { [styles[`${type}`]]: type })}>
                        {plan.durationAsMonths} Aylık Paket
                    </Text>
                </div>
                <Text weight={'bold'} size={'4xl'} type={'danger'} className={classnames(styles.payAmount, { [styles[`${type}`]]: type })}>{plan.price}TL</Text>
            </div>
            <Button loading={loading} variation={'block'} type={plan.owned ? 'danger' : 'danger-2'} disabled={buttonDisabled}
                    onClick={onClickHandler}>{buttonText}</Button>
        </div>
    )
}

export default PackagePurchaseCard
