import * as React from 'react'

export const useLocalStorage = (key: string) => {
    // initialize the value from localStorage
    const localValue = localStorage.getItem(key)
    const [currentValue, setCurrentValue] = React.useState<string | null>(() =>
        localStorage.getItem(key)
    );

    React.useEffect(() => {
        setCurrentValue(localValue)
    },[localValue])

    // on every render, re-subscribe to the storage event
    React.useEffect(() => {
        const handler = (e: StorageEvent) => {
            if (e.storageArea === localStorage && e.key === key) {
                setCurrentValue(e.newValue);
            }
        };
        window.addEventListener('storage', handler);
        return () => {
            window.removeEventListener('storage', handler);
        };
    });

    // update localStorage when the currentValue changes via setCurrentValue
    React.useEffect(() => {
        if(currentValue){
            localStorage.setItem(key, currentValue);
        }
    }, [key, currentValue]);

    // use as const to tell TypeScript this is a tuple
    return currentValue;
};
