import classnames from 'classnames'

import styles from './style.module.css'

export interface CheckboxChanged {
    (checked: boolean): void
}

type CheckboxProps = {
    label?: string
    checked: boolean
    onChange: CheckboxChanged
    circle?: boolean
    reverse?: boolean
}

const Checkbox = ({ label, checked, onChange, circle, reverse }: CheckboxProps) => {
    const onChangeHandler = () => {
        onChange(!checked)
    }

    return (
        <label className={classnames(classnames(styles.wrapper, { [styles['reverse']]: reverse }))}>
            <input type='checkbox' checked={checked} onChange={onChangeHandler} />
            <span className={classnames(styles.checkbox, { [styles['circle']]: circle })}>
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
                    <path d='M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z' />
                </svg>
            </span>
            {label && <div className={classnames(styles.label)}>{label}</div>}
        </label>
    )
}

export default Checkbox
