import * as React from 'react'
import classnames from 'classnames'
import Button from '../../atoms/button'
import { Link } from 'react-router-dom'
import { Popover } from '@headlessui/react'
import ErrorBox from '../../atoms/error-box'
import { Notification3Line } from '../../icons'
import useNotification from 'hooks/useNotification'
import NotificationCard from '../notification-card'
import { NotificationListItem } from '@tetahq/bironbir_api_client'

import styles from './style.module.css'

type NotificationButtonProps = {
    notifications: NotificationListItem[]
}

const NotificationButton = ({ notifications }: NotificationButtonProps) => {
    const noty = useNotification()

    return (
        <Popover className={styles.popover}>
            {({ open }) => (
                <div className={styles.area}>
                    <Popover.Button as={'div'} className={classnames(styles.button, { [styles.open]: open })}>
                        {noty.hasNewNotification && <div className={classnames(styles.new, { [styles.open]: open })} />}
                        <Notification3Line />
                    </Popover.Button>
                    <Popover.Panel className={styles.calendar}>
                        <div className={styles.dropdown}>
                            <div className={styles.wrapper}>
                                {notifications.map((notification, index) => {
                                    if (index >= 4) return true
                                    return <NotificationCard key={notification.id.toString() + 'dropdownNotification'} notification={notification} />
                                })}
                                {notifications.length < 1 && <ErrorBox text={'Henüz Bildiriminiz Bulunmamaktadır'} size={'sm'} />}
                            </div>

                            {notifications.length > 1 && (
                                <Popover.Button as={'div'}>
                                    <Link to={'/bildirimler'} onClick={() => (open = false)}>
                                        <Button style={{ borderRadius: 0 }} size={'small'} variation={'block'} type={'gray'}>Tümünü Görüntüle</Button>
                                    </Link>
                                </Popover.Button>
                            )}
                        </div>
                    </Popover.Panel>
                </div>
            )}
        </Popover>
    )
}

export default NotificationButton
