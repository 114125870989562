export function isoToHuman(iso: string) {
    const date = new Date(iso)

    return new Intl.DateTimeFormat('tr', { dateStyle: 'long' }).format(date)
}

export function isoToHumanFull(iso: string) {
    const date = new Date(iso)

    return new Intl.DateTimeFormat('tr', { dateStyle: 'full' }).format(date)
}

export function isoToTime(iso: string) {
    const date = new Date(iso)

    return new Intl.DateTimeFormat('tr', { timeStyle: 'short' }).format(date)
}

export function getMessageTime(date: Date) {
    return pad(date.getHours()) + ':' + pad(date.getMinutes())
}

export function secondsToTime(seconds: number) {
    if (seconds < 1) {
        return '0:00'
    }
    const mins = Math.floor(seconds / 60)
    const secs = seconds - mins * 60
    return `${pad(mins)}:${pad(secs)}`
}

function pad(n: number) {
    return `${n < 10 ? '0' : ''}${n}`
}

export function hmsToSecond(time: string) {
    const splitHour = time.split(':')
    const secondWithoutMs = splitHour[2].split(',')

    const hour = parseInt(splitHour[0])
    const minute = parseInt(splitHour[1])
    const second = parseInt(secondWithoutMs[0])

    const totalSec = (hour * 3600) + (minute * 60) + (second)
    return totalSec + '.' + secondWithoutMs[1]
}

export function remainingSeconds(date: string): number {
    const countDownDate = new Date(date).getTime()
    const now = new Date().getTime()
    return Math.floor(((countDownDate - now) % (10000000 * 60)) / 1000)
}

export function minuteDiffToDates(datePast: string, dateFuture: string): number {
    const future = new Date(dateFuture).getTime()
    const past = new Date(datePast).getTime()
    return Math.floor(((future - past) % (10000000 * 60 * 60)) / (1000 * 60))
}

