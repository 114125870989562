import * as React from 'react'
import Text from '../../atoms/text'
import ImageWithFallback from '../../atoms/image-with-fallback'
import { PersonalTrainerListItem } from '@tetahq/bironbir_api_client'

import styles from './style.module.css'

type PersonalTrainerCardProps = {
    onClick?: () => void
    personalTrainer: PersonalTrainerListItem
}

const PersonalTrainerCard = ({ onClick, personalTrainer }: PersonalTrainerCardProps) => {

    const onClickHandler = () => {
        onClick && onClick()
    }

    return (
        <div onClick={onClickHandler} className={styles['card']}>
            <ImageWithFallback src={personalTrainer.avatar ? personalTrainer.avatar.avatar4X?.replace('small_user_avatar', 'user_avatar') : ' '}
                               fallbackSrc={'/images/fallbacks/250-250.png'} alt='' />
            <div className={styles.name}><Text size='sm' type={'default'} weight={'medium'}>{personalTrainer.fullName}</Text></div>
        </div>
    )
}

export default PersonalTrainerCard
