import * as React from 'react'
import Config from '../config'
import { UserRole } from 'types'
import useApi from 'hooks/useApi'
import useAuth from 'hooks/useAuth'
import useChat from 'hooks/useChat'
import moment from 'moment-timezone'
import { toast } from 'react-toastify'
import { getUserRole } from 'utils/auth'
import NotFoundScreen from './not-found'
import { Helmet } from 'react-helmet-async'
import useThrottleFn from 'hooks/useThrottleFn'
import { AuthContextProps } from 'providers/auth'
import { ChatContextProps } from 'providers/chat'
import { ApiContextInterface } from 'providers/api'
import ErrorCard from 'components/organisms/error-card'
import { HubConnectionState } from '@microsoft/signalr'
import { useHistory, useParams } from 'react-router-dom'
import LiveModal, { RoomInfo } from 'components/organisms/live-modal'
import RateVideoCallModal from 'components/organisms/rate-video-call-modal'
import BroadcastCalorieAmountModal from 'components/organisms/broadcast-calorie-amount'

const LiveScreen: React.FC = () => {
    const api = useApi() as ApiContextInterface
    const auth = useAuth() as AuthContextProps
    const history = useHistory()
    const userRole = getUserRole()
    const chat = useChat() as ChatContextProps

    const [roomInfo, setRoomInfo] = React.useState<RoomInfo | undefined>(undefined)

    const [showLive, setShowLive] = React.useState(true)
    const [showTrainerModal, setShowTrainerModal] = React.useState(false)
    const [showUserModal, setShowUserModal] = React.useState(false)
    const [duration, setDuration] = React.useState(0)
    const [isJoinedToRoom, setIsJoinedToRoom] = React.useState(false)

    const [token, setToken] = React.useState<string | undefined>(undefined)
    const [loading, setLoading] = React.useState<boolean>(true)

    const { id } = useParams<{ id?: string }>()

    React.useEffect(() => {
        if (!loading && !showLive && !showUserModal && !showTrainerModal) {
            history.replace({ pathname: '/' })
        }
    }, [loading, showLive, showUserModal, showTrainerModal, history])

    React.useEffect(() => {
        // console.log('Chat metodu içine girildi.')
        if (!token || !auth.isLoggedIn) return
        if (chat.state.connectionState === HubConnectionState.Connected) {
            // console.log('connected durumunda')
            if (typeof id !== 'undefined' && showLive && !isJoinedToRoom) {
                // console.log('id undefined değil.', id)
                setIsJoinedToRoom(true)
                chat.methods.joinToRoom(id)
                // console.log('Join to room yapıldı.')
            } else if (!showLive && isJoinedToRoom) {
                setIsJoinedToRoom(false)
                chat.methods.leaveFromCurrentRoom()
            }
        }

        return () => {
            if (chat.state.connectionState === HubConnectionState.Connected && !showLive && isJoinedToRoom) {
                setIsJoinedToRoom(false)
                chat.methods.leaveFromCurrentRoom()
            }
        }
    }, [id, chat.methods, chat.state.connectionState, isJoinedToRoom, showLive, token, auth.isLoggedIn])

    React.useEffect(() => {
        (async () => {
            if (token || !auth.isLoggedIn) return
            try {
                const { data } = await api.Live.enterToLiveRoom({ roomId: id })
                setToken(data.liveRoomJoinToken)
            } catch {
                history.replace({ pathname: '/canli-yayin-takvimi' })
            }
        })()
    }, [token, api.Live, id, auth.isLoggedIn, history])

    React.useEffect(() => {
            (async () => {
                setLoading(true)
                if (!auth.user || !token || !auth.isLoggedIn) return

                try {
                    const { data } = await api.Live.getRoomInfo(id)
                    if (data.liveRoomPersonalTrainerInformation && data.liveStreamTitle && data.liveStreamStartedAt && data.broadcastId && id) {
                        setRoomInfo({
                            type: data.liveStreamTitle,
                            viewer: 0,
                            isChatActivate: !data.isChatLocked,
                            host: data.liveRoomPersonalTrainerInformation,
                            owner: data.liveRoomPersonalTrainerInformation.userId === auth.user.id,
                            channelName: id,
                            token: token,
                            uid: auth.user.id.toString(),
                            startedAt: data.liveStreamStartedAt,
                            broadcastId: data.broadcastId,
                        })
                    }
                } catch {
                }
                setLoading(false)
            })()
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [auth, token, api.Live, id])

    const changeChatToggle = (isOpen: boolean) => {
        if (!id) return
        try {
            api.Live.toggleChat({ agoraRoomId: id, newState: isOpen })
        } catch {
        }
    }

    const sendMessage = (message: string) => {
        if(chat.state.connectionState === HubConnectionState.Connected){
            try {
                chat.methods.sendMessageToCurrentRoom(message)
            } catch {
            }
        }
    }

    const { callback: sendLike } = useThrottleFn(() => {
        if (id) {
            try {
                api.Live.like({ agoraRoomId: id })
            } catch {
            }
        }
    }, 250)

    const closeTrainerModal = () => {
        setShowTrainerModal(false)
        history.replace({ pathname: '/yayin-takvimi' })
    }

    const closeUserModal = () => {
        setShowUserModal(false)
        history.replace({ pathname: '/canli-yayin-takvimi' })
    }

    const leaveRoom = () => {
        try {
            api.Live.leaveFromLiveRoom({ roomId: id })
            setShowLive(false)
        } catch {
        }
    }

    React.useEffect(() => {
            if (chat.state.isEndedByAdmin) {
                toast.success('Canlı Yayın Sonlanmıştır. Katıldığınız İçin Teşşekkürler')
                setShowLive(false)
            } else if (roomInfo && chat.state.isEnded) {
                if (!roomInfo.owner && userRole === UserRole.ROLE_CUSTOMER) {
                    setShowUserModal(true)
                } else {
                    completeLive()
                }
                setShowLive(false)
            }
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [chat.state.isEnded, chat.state.isEndedByAdmin, roomInfo, userRole])

    const completeLive = async () => {
        if (!roomInfo) return

        leaveRoom()

        if (roomInfo?.owner) {
            setDuration(Math.floor(moment().diff(moment(roomInfo.startedAt), 'minutes', true)))
            setShowTrainerModal(true)
        }
    }

    const rateSubmit = async (rate: number) => {
        if (roomInfo?.broadcastId) {
            try {
                await api.BroadcastProgram.rateBroadcast({ broadcastId: roomInfo.broadcastId, rate: rate })
                setShowUserModal(false)
                history.replace({ pathname: '/canli-yayin-takvimi' })
            } catch {
                toast.error('Oylama yapılırken bir hata oluştu, Tekrar Deneyiniz')
            }
        }
    }

    const submitBroadcastCalorie = async (calorie: number) => {
        if (roomInfo?.broadcastId) {
            try {
                await api.BroadcastProgram.setBurntCalories({ broadcastId: roomInfo.broadcastId, caloriesBurnt: calorie })
                setShowTrainerModal(false)
                history.replace({ pathname: '/yayin-takvimi' })
            } catch {
                toast.error('Kalori belirlenirken bir hata oluştu, Tekrar Deneyiniz')
            }
        }
    }

    if (!auth.isLoggedIn) {
        return (
            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <ErrorCard type={'401'} />
            </div>
        )
    }

    if (!roomInfo && !loading) return <NotFoundScreen />

    return (
        <>
            <Helmet>
                <title>Canlı Yayın | {Config.app.name}</title>
            </Helmet>

            {showLive && roomInfo && (
                <LiveModal
                    closeChat={changeChatToggle}
                    sendMessage={sendMessage}
                    sendLike={sendLike}
                    live={roomInfo}
                    onComplete={completeLive}
                />
            )}

            {roomInfo && (
                <BroadcastCalorieAmountModal
                    isVisible={showTrainerModal}
                    onClose={closeTrainerModal}
                    broadcastDuration={duration}
                    broadcastType={roomInfo.type}
                    onSubmit={submitBroadcastCalorie}
                />
            )}

            <RateVideoCallModal
                isVisible={showUserModal}
                onSubmit={rateSubmit}
                onClose={closeUserModal}
            />
        </>
    )
}

export default LiveScreen
