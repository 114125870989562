import * as React from 'react'
import useApi from 'hooks/useApi'
import Card from '../../molecules/card'
import Text from 'components/atoms/text'
import usePayment from 'hooks/usePayment'
import { ApiContextInterface } from 'providers/api'
import AuthModalNavbar from '../../molecules/auth-modal-navbar'
import Modal, { ModalSize, ModalType } from 'components/atoms/modal'

import styles from './style.module.css'
import { toast } from 'react-toastify'

const PaymentModal: React.FC = () => {
    const api = useApi() as ApiContextInterface
    const payment = usePayment()
    const [url, setUrl] = React.useState<string | undefined>(undefined)

    const hideModal = () => {
        setUrl(undefined)
        payment.cancelPayment?.()
        localStorage.removeItem('iyziHash')
    }

    React.useEffect(() => {
            if (typeof payment.product === 'undefined' || !payment.modalShown || payment.loading) return undefined
            payment.setLoading?.(true)
            if (payment.product.type === 'packageCourse') {
                api.PackageCourse.startPackageCoursePurchaseProcess({ packageCourseId: payment.product.id })
                    .then((response) => {
                        if (response.data.checkoutFormInitCode !== null) {
                            setUrl(response.data.checkoutFormInitCode)
                            payment.setLoading?.(false)
                        } else {
                            throw new Error()
                        }

                    })
                    .catch(() => {
                        payment.cancelPayment?.()
                        payment.setLoading?.(false)
                        setUrl(undefined)
                    })
            } else if (payment.product.type === 'subscription') {
                api.SubscriptionPlan.subscribe({ subscriptionPlanId: payment.product.id })
                    .then((response) => {
                        if (response.data.checkoutFormInitCode !== null) {
                            setUrl(response.data.checkoutFormInitCode)
                            payment.setLoading?.(false)
                        } else {
                            if (typeof response.data.errorMessage === 'string' && response.data.errorMessage !== '') {
                                toast.error(response.data.errorMessage)
                            }

                            throw new Error()
                        }

                    })
                    .catch(() => {
                        payment.cancelPayment?.()
                        payment.setLoading?.(false)
                        setUrl(undefined)
                    })
            }
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [payment.product, payment.modalShown, api.PackageCourse, api.SubscriptionPlan])

    React.useEffect(() => {
        return (() => {
            setUrl(undefined)
            localStorage.removeItem('iyziHash')
        })
    }, [])

    React.useEffect(() => {
        if (!payment.modalShown) {
            setUrl(undefined)
            localStorage.removeItem('iyziHash')
        }
    }, [payment.modalShown])

    if (typeof payment.product === 'undefined') {
        return <></>
    }

    return (
        <Modal size={ModalSize.Large} isVisible={!!(payment.modalShown)} type={ModalType.Fullscreen} onModalClose={hideModal} backdropUnClickable>
            <div className={styles.container}>
                <AuthModalNavbar
                    canClose={true}
                    canGoBack={false}
                    title={'Ödeme Yap'}
                    webTitle={'Ödeme Yap'}
                    onClose={() => payment.hideModal?.()}
                />

                <Card
                    type={'light'}
                    heading={{
                        title: payment.product.title,
                        divider: true,
                    }}
                >
                    <div className={styles.center}><Text weight={'medium'} size={'lg'}>{payment.product.content}</Text></div>
                    <div className={styles.center}><Text weight={'medium'} type={'soft-blue'}>{payment.product.description}</Text></div>
                </Card>

                <Card style={{ marginTop: '1rem' }} type={'light'} paddingles>
                    {typeof url !== 'undefined' && payment.modalShown && (
                        <>
                            <div id='iyzipay-checkout-form' className='responsive' />
                            <Script src={url} type={payment.product.type} />
                        </>
                    )}
                    {(typeof url === 'undefined') && (
                        <ClearScript type={payment.product.type} />
                    )}
                </Card>
            </div>
        </Modal>
    )
}

export default PaymentModal

const useScript = (url: string, type: 'packageCourse' | 'subscription') => {
    React.useEffect(() => {
        let cleanUrl = ''
        if (type === 'packageCourse') {
            cleanUrl = url.replace(/<script type="text\/javascript">(.*)<\/script>/, '$1').replace(/(.*)<\/script><script type="text\/javascript">(.*)/, '$1 $2')
        } else if (type === 'subscription') {
            cleanUrl = url.replace(/<script type="text\/javascript">(.*)<\/script>/, '$1').replace(/(.*)<\/script><script type="text\/javascript">(.*)/, '$1 $2').replace(/(.*)<\/script><script type="text\/javascript">(.*)/, '$1 $2')
        }

        const script = document.createElement('script')

        const text = document.createTextNode(cleanUrl)
        script.appendChild(text)

        document.body.appendChild(script)

        return () => {
            document.body.removeChild(script)
        }
    }, [url, type])
}

export function Script({ src, type }: { src: string, type: 'packageCourse' | 'subscription' }) {
    useScript(src, type)

    return null
}

const ClearUseScript = (type: 'packageCourse' | 'subscription') => {
    React.useEffect(() => {

        const cleanUrl = 'if(typeof iyziInit !== \'undefined\'){iyziInit = undefined}if(typeof iyziUcsInit !== \'undefined\'){iyziUcsInit = undefined}if(typeof iyziSubscriptionInit !== \'undefined\'){iyziSubscriptionInit = undefined}'

        const script = document.createElement('script')

        const text = document.createTextNode(cleanUrl)
        script.appendChild(text)

        document.body.appendChild(script)
        localStorage.removeItem('iyziHash')

        return () => {
            document.body.removeChild(script)
        }
    }, [type])
}

export function ClearScript({ type }: { type: 'packageCourse' | 'subscription' }) {
    ClearUseScript(type)

    return null
}
