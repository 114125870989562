import classNames from 'classnames'
import { FC } from 'react'
import styles from './style.module.css'

export enum BadgeType {
    Red,
    RedTransparent,
    Green,
    GreenTransparent,
}

export enum BadgeSize {
    Small,
}

type BadgeProps = {
    type?: BadgeType
    size?: BadgeSize
    rounded?: boolean
}

const Badge: FC<BadgeProps> = ({ type, size, rounded, children }) => {
    return (
        <div
            className={classNames(styles.wrapper, {
                [styles.default]: !type,
                [styles.red]: type === BadgeType.Red,
                [styles.redTransparent]: type === BadgeType.RedTransparent,
                [styles.green]: type === BadgeType.Green,
                [styles.greenTransparent]: type === BadgeType.GreenTransparent,
                [styles.small]: size === BadgeSize.Small,
                [styles.rounded]: !!rounded,
            })}
        >
            {children}
        </div>
    )
}

export default Badge
