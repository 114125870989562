import * as React from 'react'
import classnames from 'classnames'

import styles from './style.module.css'

export type HomeTemplateProps = {
    children: React.ReactNode
}

const HomeTemplate: React.FC<HomeTemplateProps> = ({ children }) => {
    return <div className={classnames(styles.home)}>{children}</div>
}

export default HomeTemplate
