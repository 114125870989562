import * as React from 'react'
import classnames from 'classnames'

import styles from './style.module.css'
import { CSSProperties } from 'react'

type ErrorBoxProps = {
    text: string
    align?: 'left' | 'center'
    size?: 'sm' | 'lg'
    style?: CSSProperties
}
export default function ErrorBox({ text, align, size,style }: ErrorBoxProps) {
    return <div style={style} className={classnames(styles.box, { [styles[`align-${align}`]]: align }, { [styles[`size-${size}`]]: size })}>{text}</div>
}
