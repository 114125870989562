import * as React from 'react'
import Config from '../config'
import useApi from 'hooks/useApi'
import moment from 'moment-timezone'
import { toast } from 'react-toastify'
import useAuth from '../hooks/useAuth'
import { Helmet } from 'react-helmet-async'
import { useHistory } from 'react-router-dom'
import { ApiContextInterface } from 'providers/api'
import { AuthContextProps } from '../providers/auth'
import ErrorCard from 'components/organisms/error-card'
import { BroadcastProgramListItem } from '@tetahq/bironbir_api_client'
import { BroadcastFixtureList } from 'components/organisms/broadcast-fixture-list'
import { BroadcastDaySelection } from 'components/molecules/broadcast-day-selection'
import BroadcastFixtureTemplate, { styles } from 'components/templates/broadcast-fixture'
import BroadcastReplayModal from '../components/organisms/broadcast-replay-modal'

export const BroadcastFixtureScreen: React.FC = () => {
  const api = useApi() as ApiContextInterface
  const history = useHistory()
  const auth = useAuth() as AuthContextProps

  const [loading, setLoading] = React.useState(true)
  const [broadcasts, setBroadcasts] = React.useState<BroadcastProgramListItem[]>([])
  const [selectedDay, setSelectedDay] = React.useState<string>(moment().format('YYYY-MM-DD'))
  const [selectedBroadcast, setSelectedBroadcast] = React.useState<BroadcastProgramListItem | undefined>(undefined)

  const onNewDaySelected = (newDaySelection: string) => {
    setSelectedDay(moment(newDaySelection).format('YYYY-MM-DD'))
  }

  React.useEffect(() => {
    (async () => {
      setLoading(true)
      if (auth.isLoggedIn) {
        try {
          const { data } = await api.BroadcastProgram.listBroadcastPrograms(selectedDay)
          if (data.broadcastPrograms) {
            data.broadcastPrograms
              .sort((x, y) => (moment(x.startsAt).isAfter(moment(y.startsAt)) ? 0 : 1) - (moment(y.startsAt).isAfter(moment(x.startsAt)) ? 0 : 1))
              .sort((x, y) => (x.isLive ? 0 : 1) - (y.isLive ? 0 : 1))
            setBroadcasts(data.broadcastPrograms)
          }
        } catch {
        }
      } else {
        setBroadcasts([])
      }
      setLoading(false)
    })()
  }, [selectedDay, api.BroadcastProgram, auth.isLoggedIn])

  const broadcastClick = (item: BroadcastProgramListItem) => {
    if (!item.isLive && item.isEnded) {
      if (item.replayVideoHlsUrl) {
        setSelectedBroadcast(item)
      } else if (!item.isUserJoined && auth.isLoggedIn && !auth.user?.isPremium) {
        toast.warning('Katılmadığınız Canlı Yayının Tekrarını İzleyebilmek İçin Premium Olmalısınız', { toastId: 'userNotJoinedBroadcast' + item.id })
      } else {
        toast.warning('Canlı Yayın Tekrarı Henüz Yayınlanmadı!', { toastId: 'broadcastReplayNotPublished' + item.id })
      }
    } else {
      if (item.isLive && !item.isEnded) {
        history.push({ pathname: `/canli-yayin/${item.agoraRoomId}` })
      } else {
        toast.warning('Canlı Yayın Henüz Başlamadı', { toastId: 'broadcastNotYetStarted' + item.id })
      }
    }
  }

  if (!auth.isLoggedIn) {
    return (
      <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <ErrorCard type={'401'} />
      </div>
    )
  }

  return (
    <BroadcastFixtureTemplate>
      <Helmet>
        <title>Canlı Yayınlar | {Config.app.name}</title>
      </Helmet>
      <BroadcastDaySelection selectedDay={selectedDay} onClickedOnDay={onNewDaySelected} />
      <div className={styles.divider} />
      <BroadcastFixtureList onClick={broadcastClick} broadcasts={broadcasts} isLoading={loading} />
      <BroadcastReplayModal broadcast={selectedBroadcast} onClose={() => setSelectedBroadcast(undefined)} />
    </BroadcastFixtureTemplate>
  )
}
