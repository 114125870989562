import Text from '../text'
import * as React from 'react'
import ImageWithFallback from '../image-with-fallback'

import styles from './style.module.css'

type OnboardingCardItemProps = {
    title: string
    description: string
    image: string
}

const OnboardingCardItem: React.FC<OnboardingCardItemProps> = ({ title, description, image }) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.image}>
                <div className={styles.imagePlaceholder}/>
                <ImageWithFallback src={image} fallbackSrc='/images/fallbacks/1000-1000.png' alt='onboarding' />
            </div>
            <div className={styles.content}>
                <Text size='lg' weight='bold'>
                    {title}
                </Text>
                <Text size='sm'>{description}</Text>
            </div>
        </div>
    )
}

export default OnboardingCardItem
