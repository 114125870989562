import * as React from 'react'

import styles from './style.module.css'

export { styles }

export type BroadcastFixtureTemplateProps = {
    children: React.ReactNode
}

const BroadcastFixtureTemplate: React.FC<BroadcastFixtureTemplateProps> = ({ children }) => {
    return (
        <div className={styles.broadcastFixtureScreenWrapper}>
            <div className={styles.broadcastFixtureScreen}>
                {children}
            </div>
        </div>
    )
}

export default BroadcastFixtureTemplate
