import * as React from 'react'
import Config from '../config'
import { UserRole } from 'types'
import useApi from 'hooks/useApi'
import useAuth from 'hooks/useAuth'
import useMedia from 'hooks/useMedia'
import { toast } from 'react-toastify'
import { getUserRole } from 'utils/auth'
import Text from 'components/atoms/text'
import usePayment from 'hooks/usePayment'
import { Device } from '@capacitor/device'
import { Helmet } from 'react-helmet-async'
import Button from 'components/atoms/button'
import SwiperCore, { FreeMode } from 'swiper'
import { AuthContextProps } from 'providers/auth'
import { Purchases } from '@ionic-native/purchases'
import { ApiContextInterface } from 'providers/api'
import ErrorCard, { ErrorCardProps } from 'components/organisms/error-card'
import PullToRefresh from 'react-simple-pull-to-refresh'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'
import CurrentPlanCard from 'components/molecules/current-plan-card'
import ImageWithFallback from 'components/atoms/image-with-fallback'
import { SubscriptionPlanListItem } from '@tetahq/bironbir_api_client'
import ConfirmModalCard from 'components/organisms/confirm-modal-card'
import PhoneNumberModal from '../components/organisms/phone-number-modal'
import PackagePurchaseCard from 'components/molecules/package-purchase-card'
import MembershipPackagesTemplate from 'components/templates/membership-packages'
import MobilePackagePurchaseCard from 'components/molecules/mobile-package-purchase-card'

import styles from 'components/templates/membership-packages/style.module.css'
import ErrorBox from '../components/atoms/error-box'
import moment from 'moment'

SwiperCore.use([FreeMode])

const MembershipPackagesScreen: React.FC = () => {
  const api = useApi() as ApiContextInterface
  const auth = useAuth() as AuthContextProps
  const payment = usePayment()
  const userRole = getUserRole()
  const isMobile = useMedia('(max-width: 1024px)')

  const [plans, setPlans] = React.useState<SubscriptionPlanListItem[]>([])
  const [ownedPlan, setOwnedPlan] = React.useState<SubscriptionPlanListItem | undefined>(undefined)
  const [current, setCurrent] = React.useState<number | null>(null)
  const [cancelModal, setCancelModal] = React.useState(false)
  const [phoneNumberUpdateModal, setPhoneNumberUpdateModal] = React.useState(false)

  const [swipeHandle, setSwipeHandle] = React.useState(false)

  const [platform, setPlatform] = React.useState<'ios' | 'android' | 'web'>('web')

  React.useEffect(() => {
    (async () => {
      const detectPlatform = await Device.getInfo()
      setPlatform(detectPlatform.platform)
    })()
  }, [])

  React.useEffect(() => {
      if (auth.isLoggedIn && plans.length > 0 && window.cordova && platform === 'ios') {
        document.addEventListener('deviceready', inAppPurchaseSetup, false)
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [plans, platform, auth])

  React.useEffect(() => {
      if (auth.isLoggedIn && userRole === UserRole.ROLE_CUSTOMER) {
        getPlans()
      } else {
        setPlans([])
        setOwnedPlan(undefined)
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [auth.isLoggedIn, userRole])

  const inAppPurchaseSetup = async () => {
    try {
      Purchases.setDebugLogsEnabled(true)
      Purchases.setup(Config.iap.publicKey, auth.user?.id.toString())
      Purchases.setEmail(auth.user?.email ?? null)
      Purchases.setDisplayName(`${auth.user?.name} ${auth.user?.surname}`)
    } catch (e) {
      console.log({ e })
    }
  }

  const getPlans = async () => {
    try {
      const { data } = await api.SubscriptionPlan.listSubscriptionPlans()
      setPlans(data.subscriptionPlans)
      setOwnedPlan(data.subscriptionPlans.find(x => x.owned))
    } catch {
      setPlans([])
      setOwnedPlan(undefined)
    }
  }

  const handleCancelSubscription = async () => {
    if (platform === 'ios') {
      window.open('itms-apps://apps.apple.com/account/subscriptions')
    } else {
      try {
        await api.SubscriptionPlan.cancelSubscription()
        toast.success('Aboneliğiniz İptal Edildi')
        setCancelModal(false)
      } catch {
      }
    }
  }

  const handlePayment = (index: number) => {
    if (ownedPlan) {
      setCancelModal(true)
      return
    }

    payment.setProduct?.({
      id: plans[index].id,
      title: plans[index].name,
      content: 'Popüler Paket',
      description: `Aylık ${plans[index].price} TL`,
      price: plans[index].price,
      type: 'subscription',
    })
    payment.showModal?.()
  }

  const onPlanClickHandler = async (index: number) => {
    if (platform === 'ios') {
      payment.setLoading?.(true)
      try {
        await Purchases.purchaseProduct(plans[index].iosProductId!)
        setOwnedPlan(plans[index])
        toast.success('Abonelik Satın Alma İşlemi Başarılı')
      } catch (e) {
        toast.error('Satın Alma İşlemi Tamamlanamadı')
      }

      payment.setLoading?.(false)
    } else if (!ownedPlan) {
      if (typeof auth.user?.phoneNumber !== 'string' || auth.user?.phoneNumber === '') {
        setPhoneNumberUpdateModal(true)
      } else {
        setCurrent(index)
        handlePayment(index)
      }
    }
  }

  //TODO restore subscription gerekli ise kullanılabilir.

  // const restoreSubscription = async () => {
  //     if (platform !== 'ios') return
  //
  //     try {
  //         await Purchases.restoreTransactions()
  //         toast.success('Aboneliğiniz Kurtarıldı')
  //     } catch {
  //         toast.error('Kurtarılacak Abonelik Bulunamadı')
  //     }
  // }

  const errorCardWrapper = (type: ErrorCardProps['type']) => {
    return <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      <ErrorCard type={type} />
    </div>
  }

  if (!auth.isLoggedIn || userRole !== UserRole.ROLE_CUSTOMER) {
    return errorCardWrapper('401')
  }

  if (auth.user?.premiumBoughtFrom === 'Ios' && platform !== 'ios') {
    return errorCardWrapper('onlyShowIos')
  }

  if (auth.user?.premiumBoughtFrom === 'Other' && platform === 'ios') {
    return errorCardWrapper('onlyShowWeb')
  }

  return (
    <MembershipPackagesTemplate>
      <Helmet>
        <title>Abonelik | {Config.app.name}</title>
      </Helmet>
      {isMobile ? (
        <PullToRefresh onRefresh={getPlans} isPullable={isMobile && !swipeHandle} pullingContent='' resistance={1} pullDownThreshold={40}>
          <>
            <div className={styles['cardsAndImage']}>
              <div className={styles['imageContainer']}>
                <ImageWithFallback src={'/images/subscription-splash.jpg'} fallbackSrc={'/images/fallbacks/1400-1000.png'} />
                <Text type={'white'} size={'lg'} weight={'bold'}>Şimdi Premium Olun</Text>
                <Text type={'white'} size={'base'} className={styles['subHead']}>
                  Sınırsız Avantaj
                </Text>
                <Text type={'white'} size={'sm'} className={styles['subTextMobile']}>
                  Alanında uzman hocalarımız ile haftalık canlı yayınlarımıza katıl, kişiye özel antremanlarımız ile iyi hisset!
                </Text>
              </div>
              <Swiper
                className={styles['swiper']}
                grabCursor
                slidesPerView={'auto'}
                freeMode
                centeredSlides
                centeredSlidesBounds
                centerInsufficientSlides
                onSliderFirstMove={() => setSwipeHandle(true)}
                onTouchEnd={() => setSwipeHandle(false)}
              >
                {plans.map((plan, i) => (
                  <SwiperSlide key={plan.id + 'purchase'} style={{ width: '9.5rem' }}>
                    <MobilePackagePurchaseCard
                      key={plan.id + '_purchase'}
                      onClick={() => setCurrent(i)}
                      currentPackage={i === current}
                      plan={plan}
                      platform={platform}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>

            <div className={styles['mobileButton']}>
              {auth.user?.premiumBoughtFrom === 'Free' ? (
                <div>
                  <ErrorBox text='Ücretsiz Aboneliğiniz Devam Ediyor!' size='sm' style={{ marginBottom: '1rem' }} />
                  <ErrorBox text='Aboneliğiniz Sona Erdiğinde Abonelik Paketi Satın Alabilirsiniz.' size='sm' />
                </div>
              ) : (
                <Button disabled={current === null && !ownedPlan}
                        onClick={() => (current !== null && !ownedPlan) ? onPlanClickHandler(current) : handleCancelSubscription()}
                        variation={'block'}
                        type={'danger-2'}
                        loading={payment.loading}>
                  {ownedPlan ? 'Aboneliği İptal Et' : 'Paketi Satın Al'}
                </Button>
              )
              }
              {/*//TODO*/}
              {/*{platform === 'ios' && (*/}
              {/*    <Button onClick={() => restoreSubscription}*/}
              {/*            variation={'block'}*/}
              {/*            type={'danger-link'}*/}
              {/*            className={styles.restoreButton}>*/}
              {/*        Aboneliğimi Kurtar*/}
              {/*    </Button>*/}
              {/*)}*/}

              {platform === 'ios' && (
                <Button
                  onClick={() => window.open(Config.app.eulaLink)}
                  variation={'block'}
                  type={'danger-link'}
                  className={styles.privacyButton}>
                  Kullanım Koşulları ve Gizlilik Şartları
                </Button>
              )}
            </div>
          </>
        </PullToRefresh>
      ) : (
        <>
          <div className={styles['header']}>
            <div className={styles['texts']}>
              <Text weight='bold' size='2xl'>
                Şimdi Premium Olun
              </Text>
              <Text className={styles['subtext']} weight='default' size='sm'>
                Alanında uzman hocalarımız ile haftalık canlı yayınlarımıza katıl, kişiye özel antremanlarımız ile iyi hisset!
              </Text>
            </div>

            {ownedPlan && <div className={styles['currentPlanCard']}>
              <CurrentPlanCard payAmount={ownedPlan.price} monthAmount={ownedPlan.durationAsMonths} onCancelClick={() => setCancelModal(true)} />
            </div>}

            {auth.user?.premiumBoughtFrom === 'Free' && (
              <CurrentPlanCard payAmount={0} monthAmount={moment(auth.user.currentPremiumEndsAt).diff(moment(auth.user.currentPremiumStartedAt), 'months')} onCancelClick={() => setCancelModal(true)} />
            )}

          </div>

          <div className={styles['cards']}>
            {plans.map((plan, i) => (
              <PackagePurchaseCard
                key={plan.id + '_purchase'}
                onClick={() => onPlanClickHandler(i)}
                plan={plan}
                type={i === plans.length - 1 ? 'danger' : 'light'}
                loading={payment.loading}
                buttonText={plan.owned ? 'Mevcut Paket' : 'Paketi Satın Al'}
                buttonDisabled={auth.user?.premiumBoughtFrom === 'Free' || typeof ownedPlan !== 'undefined'}
              />
            ))}
          </div>

          {auth.user?.premiumBoughtFrom === 'Free' && (
            <div style={{marginTop: '1rem'}}>
              <Text type="danger" weight='default' size='sm'>
                Ücretsiz Aboneliğiniz Sona Erdiğinde Abonelik Paketi Satın Alabilirsiniz!
              </Text>
            </div>
          )}

          {/*//TODO
                    {platform === 'ios' && (
                        <Button onClick={() => restoreSubscription}
                                variation={'block'}
                                type={'danger-link'}
                                className={styles.restoreButton}>
                            Aboneliğimi Kurtar
                        </Button>
                    )}*/}
        </>
      )}

      <ConfirmModalCard
        title={'Mevcut Aboneliği İptal Et'}
        description={'Aboneliğinizi iptal etmek istediğiniz emin misiniz? Bu işlem onaylandığında geri döndürülemez. Faturalama dönem sonuna kadar aboneliğinizi kullanabilirsiniz. Bir sonraki dönemde aboneliğiniz yenilenmeyecektir.'}
        confirmButtonText={'Aboneliği İptal Et'}
        isVisible={cancelModal}
        onModalClose={() => setCancelModal(false)}
        onConfirmButtonClick={handleCancelSubscription}
        onCancelButtonClick={() => setCancelModal(false)}
      />
      <PhoneNumberModal isVisible={phoneNumberUpdateModal} onModalClose={() => setPhoneNumberUpdateModal(false)} />
    </MembershipPackagesTemplate>
  )
}

export default MembershipPackagesScreen
