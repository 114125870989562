import * as React from 'react'
import Config from '../config'
import classnames from 'classnames'
import Text from 'components/atoms/text'
import { use100vh } from 'react-div-100vh'
import { Helmet } from 'react-helmet-async'
import Button from 'components/atoms/button'
import { Link, useHistory } from 'react-router-dom'
import { CheckboxCircleFill, CloseCircleFill } from 'components/icons'
import PaymentStatusTemplate, { styles } from 'components/templates/payment-status'

const PaymentStatusScreen: React.FC<{ status: 'success' | 'error' }> = ({ status }) => {
    const height = use100vh()
    const history = useHistory()
    const [icon, setIcon] = React.useState<React.ReactNode>(<></>)
    const [title, setTitle] = React.useState<string>('')
    const [description, setDescription] = React.useState<string>('')

    React.useEffect(() => {
        switch (status) {
            case 'success':
                setIcon(<CheckboxCircleFill />)
                setTitle('Ödeme Başarılı')
                setDescription('Satın Almanız İçin Teşekkür Ederiz, Birkaç Saniye Sonra Anasayfaya Yönlendirileceksiniz...')
                setTimeout(() => {
                    history.push({ pathname: '/' })
                }, 3000)
                break
            case 'error':
                setIcon(<CloseCircleFill />)
                setTitle('Ödeme Başarısız')
                setDescription('Üzgünüz, Satın Alma İşlemi Başarısız. Lütfen Tekrar Deneyiniz...')
                break
        }
    }, [status, history])

    return (
        <PaymentStatusTemplate>
            <Helmet>
                <title>{title} | {Config.app.name}</title>
            </Helmet>

            <div className={styles.container} style={{ height: `calc(${height ?? '100vh'} - 9rem)` }}>
                <div className={classnames(styles.icon, { [styles.success]: status === 'success', [styles.error]: status === 'error' })}>{icon}</div>
                <div className={styles.texts}>
                    <Text size='2xl' weight={'medium'}>{title}</Text>
                    <Text size='xl' type={'soft-blue'}>{description}</Text>
                </div>

                <Link to={'/'}>
                    <Button type='gradient' variation='wide'>Anasayfa</Button>
                </Link>
            </div>
        </PaymentStatusTemplate>
    )
}

export default PaymentStatusScreen
