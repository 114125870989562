import * as React from 'react'
import { EyeLine, EyeOffLine } from 'components/icons'
import Input, { IInput, InputProps } from 'components/atoms/input'

import styles from './style.module.css'

const PasswordInput = (props: InputProps & IInput) => {
    const [isShown, setIsShown] = React.useState<boolean>(false)

    return (
        <Input
            {...props}
            type={isShown ? 'text' : 'password'}
            trailingComponent={
                <div className={styles['button-wrapper']}>
                    <button className={styles.button} onClick={() => setIsShown(r => !r)}>
                        {isShown ? <EyeOffLine /> : <EyeLine />}
                    </button>
                </div>
            }
        />
    )
}

export default PasswordInput
