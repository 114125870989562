import * as React from 'react'
import Config from '../config'
import useApi from 'hooks/useApi'
import useChat from 'hooks/useChat'
import useAuth from 'hooks/useAuth'
import { isUndefined } from 'lodash'
import useMedia from 'hooks/useMedia'
import useQuery from 'hooks/useQuery'
import { toast } from 'react-toastify'
import Vimeo from '@u-wave/react-vimeo'
import NotFoundScreen from './not-found'
import Text from 'components/atoms/text'
import usePayment from 'hooks/usePayment'
import { Device } from '@capacitor/device'
import { Helmet } from 'react-helmet-async'
import Button from 'components/atoms/button'
import Card from 'components/molecules/card'
import Avatar from '../components/atoms/avatar'
import { AuthContextProps } from 'providers/auth'
import { ChatContextProps } from 'providers/chat'
import { ApiContextInterface } from 'providers/api'
import { Purchases } from '@ionic-native/purchases'
import ErrorCard from 'components/organisms/error-card'
import TrainingItem from 'components/molecules/trainig-item'
import { Link, useHistory, useParams } from 'react-router-dom'
import TrainingInfoCard from 'components/molecules/training-info-card'
import { GetPackageCourseDetailsQueryResult } from '@tetahq/bironbir_api_client'
import TrainingMobileInfoCard from 'components/molecules/training-mobile-info-card'
import PackageCoursePreviewTemplate, { styles } from 'components/templates/package-course-preview'

const PackageCoursePreviewScreen: React.FC = () => {
    const auth = useAuth() as AuthContextProps
    const api = useApi() as ApiContextInterface
    const chat = useChat() as ChatContextProps
    const params = useQuery()
    const history = useHistory()
    const payment = usePayment()
    const { id } = useParams<{ id: string }>()
    const isMobile = useMedia('(max-width: 1024px)')

    const [loading, setLoading] = React.useState(true)
    const [course, setCourse] = React.useState<GetPackageCourseDetailsQueryResult | undefined>(undefined)
    const [platform, setPlatform] = React.useState<'ios' | 'android' | 'web'>('web')
    const [waitingResponse, setWaitingResponse] = React.useState(false)

    React.useEffect(() => {
        (async () => {
            const detectPlatform = await Device.getInfo()
            setPlatform(detectPlatform.platform)
        })()
    }, [])

    React.useEffect(() => {
            if (auth.isLoggedIn && course && window.cordova && platform === 'ios') {
                document.addEventListener('deviceready', inAppPurchaseSetup, false)
            }
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [course, platform, auth])

    const inAppPurchaseSetup = async () => {
        try {
            Purchases.setDebugLogsEnabled(true)
            await Purchases.setup(Config.iap.publicKey, auth.user?.id.toString())
            Purchases.setEmail(auth.user?.email ?? null)
            Purchases.setDisplayName(`${auth.user?.name} ${auth.user?.surname}`)
        } catch (e) {
            console.log({ e })
        }
    }

    React.useEffect(() => {
        (async () => {
            if (auth.isLoggedIn) {
                setLoading(true)
                try {
                    const { data } = await api.PackageCourse.getPackageCourseDetails(parseInt(id))
                    setCourse(data)
                } catch {
                }
                setLoading(false)
            }
        })()
    }, [id, api.PackageCourse, auth.isLoggedIn])

    React.useEffect(() => {
        if (typeof chat.state.userPackageCourseOwnerShipChange !== 'undefined' && chat.state.userPackageCourseOwnerShipChange.packageCourseId === parseInt(id)) {
            toast.success('Satın Alma İşlemi Başarılı')
            setWaitingResponse(false)
            setCourse(x => {
                if (x) {
                    x.isPurchased = chat.state.userPackageCourseOwnerShipChange?.isBought
                }
                return x
            })
        }
    }, [chat.state.userPackageCourseOwnerShipChange, id])

    const handlePayment = async () => {
        if (isUndefined(course) || !course.name || isUndefined(course.price) || !course.description) return

        if (course.price === 0) {
            payment.setLoading?.(true)

            try {
                await api.PackageCourse.buyFreePackageCourse({ packageCourseId: parseInt(id) })
                toast.success('Paket Başarıyla Alındı')
            } catch {
            }

            try {
                const { data } = await api.PackageCourse.getPackageCourseDetails(parseInt(id))
                setCourse(data)
            } catch {
            }

            payment.setLoading?.(false)
        } else {
            if (platform === 'ios') {
                payment.setLoading?.(true)

                if (course.appStoreProductId && typeof course.iosPrice === 'number' && course.iosPrice !== 0) {
                    try {
                        await Purchases.purchaseProduct(course.appStoreProductId)
                        setWaitingResponse(true)
                    } catch (e) {
                        toast.error('Satın Alma İşlemi Tamamlanamadı')
                    }
                } else {
                    toast.error('Lütfen websitemiz üzerinden satın almayı deneyin.')
                }

                payment.setLoading?.(false)
            } else {
                payment.showModal?.()
                payment.setProduct?.({
                    id: parseInt(id),
                    title: course.name,
                    content: course.description,
                    description: `${course.price} TL`,
                    price: course.price,
                    type: 'packageCourse',
                })
            }
        }
    }

    const courseClickHandler = (training?: number) => {
        if (!course?.isPurchased) {
            handlePayment()
        }

        if (course?.isPurchased && course.id && !isUndefined(training)) {
            params.set('course', course.id.toString())
            params.set('training', training.toString())
            history.push({ pathname: `/antrenmanlar`, search: params.toString() })
        }
    }

    if (!auth.isLoggedIn) {
        return (
            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <ErrorCard type={'401'} />
            </div>
        )
    }

    if (loading) {
        return (
            <Helmet>
                <title>Yükleniyor... | {Config.app.name}</title>
            </Helmet>
        )
    } else if (!course) {
        return (<NotFoundScreen />)
    }

    return (
        <PackageCoursePreviewTemplate isMobile={isMobile}>
            <Helmet>
                <title>{course.name} | {Config.app.name}</title>
            </Helmet>

            {isMobile && (
                <>
                    <div className={styles.video}>
                        <Vimeo video={course.promotionalVimeoEmbedUrl ?? ''} width={'100%'} height={'auto'} showByline autoplay muted loop showTitle={false} />
                    </div>

                    <div className={styles.infoCardWrapper}>
                        <TrainingMobileInfoCard training={course} platform={platform} />
                    </div>

                    {!course.isPurchased && (
                        <div className={styles.limitedContent}>
                            <div className={styles.backdrop} onClick={() => courseClickHandler(0)}>
                                <img src='/images/transparent-backdrop.png' alt='' />
                            </div>
                            <div className={styles.limitedContentItems}>
                                {course.trainings && course.trainings.map((training) =>
                                    <TrainingItem training={training} clickable={course?.isPurchased} key={training.id + 'training'} />,
                                )}
                            </div>
                            <div className={styles.limitedContentButtonWrapper}>
                                <Button type='danger-2' variation='block' onClick={handlePayment} loading={payment.loading} disabled={waitingResponse}>
                                    {course.price === 0 ? 'Ücretsiz Al' : `Paketi Satın Al`}
                                </Button>
                                {platform === 'ios' && (
                                    <Button
                                        onClick={() => window.open(Config.app.eulaLink)}
                                        variation={'block'}
                                        type={'danger-link'}
                                        className={styles.privacyButton}>
                                        Kullanım Koşulları ve Gizlilik Şartları
                                    </Button>
                                )}
                            </div>
                        </div>
                    )}

                    {course.isPurchased && <div className={styles.content}>
                        {course.trainings && course.trainings.map((training) => (
                            <TrainingItem training={training} clickable={course?.isPurchased} key={training.id + 'training'} onClick={() => courseClickHandler(training.id)} />
                        ))}
                    </div>}
                </>
            )}

            {!isMobile && (
                <>
                    <div>
                        <TrainingInfoCard training={course} onClick={handlePayment} loading={payment.loading} platform={platform} />

                        <Card type='light' style={{ marginTop: '1rem' }}>
                            <Link to={'/antrenor/' + course.personalTrainer?.id}>
                                <div className={styles.trainerCard}>
                                    <Avatar user={course.personalTrainer} rounded={true} size={3.5} />
                                    <Text className={styles.title} size='base' weight='medium'>{course.personalTrainer?.fullName}</Text>
                                </div>
                            </Link>
                        </Card>
                    </div>
                    <div>
                        <Card
                            type='light'
                            heading={{
                                title: course.name ?? '',
                                alignment: 'left',
                                size: 'md',
                                divider: true,
                            }}
                        >
                            <Text size='sm'>
                                {course.description}
                            </Text>
                        </Card>
                        <div className={styles.content}>
                            {course.trainings && course.trainings.map((training) => (
                                <TrainingItem training={training} clickable={course?.isPurchased} key={training.id + 'training'} onClick={() => courseClickHandler(training.id)} />
                            ))}
                        </div>
                    </div>
                    <div>
                        <Card
                            type='light'
                            heading={{
                                title: 'Tanıtım Videosu',
                                alignment: 'left',
                                size: 'md',
                                divider: true,
                            }}
                        >
                            {course.promotionalVimeoEmbedUrl &&
                                <Vimeo video={course.promotionalVimeoEmbedUrl} width={433} height={'auto'} showByline autoplay muted loop showTitle={false} />}
                        </Card>
                    </div>
                </>
            )}
        </PackageCoursePreviewTemplate>
    )
}

export default PackageCoursePreviewScreen
