import * as React from 'react'
import { MenuItem } from 'types/index'
import { NavLink } from 'react-router-dom'

import styles from './style.module.css'

const NavigationMenuItem: React.FC<MenuItem> = ({ url, icon, label, exact, clickHandler }) => {
    if (clickHandler) {
        return (<div onClick={() => clickHandler()} className={styles.button}>
            {icon}
            {label}
        </div>)
    }

    return (
        <NavLink
            to={{
                pathname: url,
            }}
            className={styles.button}
            activeClassName={styles.active}
            exact={exact}
        >
            {icon}
            <span>{label}</span>
        </NavLink>
    )
}

export default NavigationMenuItem
