import * as React from 'react'
import useAuth from 'hooks/useAuth'
import Config from '../../../config'
import Text from 'components/atoms/text'
import ContactModal from '../contact-modal'
import Button from 'components/atoms/button'
import { useHistory } from 'react-router-dom'
import { AuthContextProps } from 'providers/auth'
import { Page401, Page404, Page500, PageBanned, PageMaintenance } from 'components/icons'

import styles from './style.module.css'

export type ErrorCardProps = {
    type: '401' | '404' | '500' | 'banned' | 'maintenance' | 'updateRequired' | 'multiLogin' | 'onlyShowIos' | 'onlyShowWeb',
}

const ErrorCard = ({ type }: ErrorCardProps) => {
    const [showContact, setShowContact] = React.useState(false)
    const history = useHistory()
    const auth = useAuth() as AuthContextProps

    let svg: JSX.Element
    let title: string
    let description: string
    let button: string
    let computedOnClick

    const backToHome = () => {
        history.push({ pathname: '/' })
    }

    const contactUs = () => {
        setShowContact(true)
    }

    const updateRedirect = () => {
        window.open(Config.linkLy.url)
    }

    const refreshPage = () => {
        window.location.reload()
    }

    const loginModal = () => {
        auth?.showModal?.('login')
    }

    React.useEffect(() => {
        if (type === 'multiLogin' && auth.isLoggedIn) {
            history.go(-1)
        }
    }, [type, auth, history])

    switch (type) {
        case '401':
            svg = <Page401 />
            title = '401 Yetkisiz Giriş'
            description = 'Sayfayı Görüntüleyebilmek İçin Giriş Yapmalısınız'
            button = 'Giriş Yap'
            computedOnClick = () => auth?.showModal?.('login')
            break
        case '404':
            svg = <Page404 />
            title = '404 Sayfa Bulunamadı'
            description = 'Aradığınız Sayfa Bulunamadı'
            button = 'Anasayfaya Dön'
            computedOnClick = backToHome
            break
        case '500':
            svg = <Page500 />
            title = 'Sunucu Hatası'
            description = 'Beklenmedik Bir Hata'
            button = 'Anasayfaya Dön'
            computedOnClick = backToHome
            break
        case 'banned':
            svg = <PageBanned />
            title = 'Engellendiniz'
            description = 'Politikalarımıza Uymadığınızdan Sistem Tarafından Girişiniz Engellendi'
            button = 'İletişime Geç'
            computedOnClick = contactUs
            break
        case 'updateRequired':
            svg = <PageBanned />
            title = 'Güncelleme Gerekiyor'
            description = 'Yeni Sürümümüz Mevcut! Hemen Uygulamanızı Güncelleyin'
            button = 'Güncelle'
            computedOnClick = updateRedirect
            break
        case 'maintenance':
            svg = <PageMaintenance />
            title = 'Sistem Bakımda'
            description = 'Sizler İçin Güncelleştirmeler Yapıyoruz'
            button = 'Sayfayı Yenile'
            computedOnClick = refreshPage
            break
        case 'multiLogin':
            svg = <img src='/images/multi-login-detect.png' alt='' />
            title = 'Şüpheli Giriş'
            description = `Hesabınıza farklı bir cihazdan giriş yapıldı.\nMevcut üyeliğiniz ile aynı anda yalnızca bir cihaz aktif olarak kullanılabilir.\nEğer bu işlem bilginiz dahilinde gerçekleşmediyse lütfen bizimle iletişime geçiniz.`
            button = 'Giriş Yap'
            computedOnClick = loginModal
            break
        case 'onlyShowIos':
            svg = <PageBanned />
            title = 'IOS Cihaz Gerekli'
            description = 'Aktif aboneliğinizi yönetebilmek için abone olduğunuz IOS cihazınızdan giriş yapmalısınız! Bir sorun olduğunu düşünüyorsanız iletişime geçin.'
            button = 'İletişime Geç'
            computedOnClick = contactUs
            break
        case 'onlyShowWeb':
            svg = <PageBanned />
            title = 'Sayfa Görüntülenemiyor!'
            description = 'Aktif aboneliğinizi yönetebilmek için web tarayıcınız üzerinden giriş yapmalısınız!'
            button = 'İletişime Geç'
            computedOnClick = contactUs
            break
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.background}>
                {svg}
            </div>
            <Text size={'3xl'} type={'default'} weight={'medium'} decoration={'default'}>{title}</Text>
            <Text size={'sm'} type={'default'} weight={'default'} decoration={'default'}>{description}</Text>
            <Button type={'gradient'} onClick={computedOnClick}>{button}</Button>
            <ContactModal isVisible={showContact} onModalClose={() => setShowContact(false)} />
        </div>
    )
}

export default ErrorCard
