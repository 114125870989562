import * as React from 'react'
import { FC } from 'react'
import Text from 'components/atoms/text'
import { CheckboxCircleFill } from 'components/icons'
import Badge, { BadgeType } from 'components/atoms/badge'

import styles from './style.module.css'
import ImageWithFallback from '../../atoms/image-with-fallback'
import { GetPackageCourseDetailsQueryResult } from '@tetahq/bironbir_api_client'

type TrainingMobileInfoCardProps = {
    training: GetPackageCourseDetailsQueryResult
    platform: 'ios' | 'android' | 'web'
}

const TrainingMobileInfoCard: FC<TrainingMobileInfoCardProps> = ({ training, platform }) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.heading}>
                <ImageWithFallback
                    srcSet={training.coverPhoto ? `${training.coverPhoto.coverImage1X}, ${training.coverPhoto.coverImage2X} 1.5x, ${training.coverPhoto.coverImage3X} 2x, ${training.coverPhoto.coverImage4X} 3x,` : undefined}
                    src={training.coverPhoto?.coverImage4X}
                    fallbackSrc={'/images/fallbacks/250-250.png'}
                    alt=''
                />
                <Text className={styles.title} size='lg' weight='medium'>
                    {training.name}
                </Text>
                {training.isPurchased && <Badge type={BadgeType.GreenTransparent}>
                    <CheckboxCircleFill />
                </Badge>}

                {!training.isPurchased && <Badge type={BadgeType.Red}>{training.price === 0 ? 'Ücretsiz' : `₺${platform === 'ios' ? training.iosPrice : training.price}`}</Badge>}
            </div>
            <div className={styles.body}>
                <Text size='sm'>
                    {training.description}
                </Text>
            </div>
        </div>
    )
}

export default TrainingMobileInfoCard
