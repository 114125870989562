import { toast } from 'react-toastify'
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import * as Services from '@tetahq/bironbir_api_client'

import config from '../config'

process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'

const onRequestSucceed = async (response: AxiosResponse): Promise<AxiosResponse> => {
  if (typeof response.data.jwt !== 'undefined' && response.data.jwt.length > 0) {
    localStorage.setItem('token', response.data.jwt)

    if (typeof response.data.isAccountActivated !== 'undefined') {
      localStorage.setItem('isAccountActivated', response.data.isAccountActivated)
    }

    if (typeof response.data.isProfileCompleted !== 'undefined') {
      localStorage.setItem('isProfileCompleted', response.data.isProfileCompleted)
    }
  }
  return response
}

const onRequestFailed = (error: AxiosError<any>) => {
  switch (error.response?.data.title) {
    case 'USER_ACCOUNT_NOT_FOUND':
      toast.error('Kullanıcı Hesabı Bulunamadı!')
      break
    case 'BROADCAST_PROGRAM_NOT_FOUND':
      toast.error('Canlı Yayın Bulunamadı!')
      break
    case 'BROADCAST_PROGRAM_START_DATE_GREATER_THAN_END_DATE':
      toast.error('Canlı yayının başlangıç tarihi, bitiş tarihinden önce olmalıdır!')
      break
    case 'BROADCAST_PROGRAM_START_DATE_EQUAL_END_DATE':
      toast.error('Canlı yayının başlangıç ve bitiş tarihi aynı olamaz!')
      break
    case 'PERSONAL_TRAINER_NOT_FOUND':
      toast.error('Antrenör Bulunamadı!')
      break
    case 'PERSONAL_TRAINER_ACCOUNT_NOT_FOUND':
      toast.error('Antrenöre ait hesap bulunamadı!')
      break
    case 'IMAGE_COULD_NOT_BE_SAVED':
      toast.error('Resim Kaydedilemedi!')
      break
    case 'PLEASE_ENTER_THE_PARAMETERS_YOU_WANT_TO_UPDATE':
      toast.error('Güncellemek için değer giriniz!')
      break
    case 'USER_NOT_FOUND':
      toast.error('Kullanıcı Bulunamadı!')
      break
    case 'LEGAL_DOCUMENT_NOT_FOUND':
      toast.error('Yasal Döküman Bulunamadı!')
      break
    case 'PERSONAL_TRAINER_METADATA_NOT_FOUND':
      toast.error('Antrenöre ait bilgiler bulunamadı!')
      break
    case 'USER_NOT_FOUND_WITH_RECOVER_CODE':
      toast.error('Kurtarılacak kullanıcı bulunamadı!')
      break
    case 'USER_HAS_ALREADY_ACTIVATED':
      toast.error('Kullanıcı hesabı zaten onaylanmış!')
      break
    case 'COMPLAINT_NOT_FOUND':
      toast.error('Şikayet Bulunamadı!')
      break
    case 'USER_EMAIL_HAS_ALREADY_USED':
      toast.error('Bu Email Zaten Kullanılıyor.')
      break
    case 'INVALID_ACTIVATION_CODE_EXCEPTION':
      toast.error('Aktivasyon Kodu Hatası!')
      break
    case 'CAN_NOT_PROCESS_THE_FILE':
      toast.error('Dosya Yüklenirken Hata Oluştu!')
      break
    case 'INVALID_PASSWORD':
      toast.error('Geçersiz Şifre')
      break
    case 'EMAIL_ALREADY_IN_USE':
      toast.error('Bu Email Zaten Kullanılıyor')
      break
    case 'PACKAGE_COURSE_NOT_FOUND':
      toast.error('Antrenman Paketi Bulunamadı')
      break
    case 'PACKAGE_COURSE_TRAINING_NOT_FOUND':
      toast.error('Antrenman Paketi İçeriği Bulunamadı')
      break
    case 'PACKAGE_COURSE_TRAINING_NOT_FOUND_LINKED_TO_PACKAGE_COURSE':
      toast.error('Antrenman Paketi İçeriği Bulunamadı')
      break
    case 'PACKAGE_COURSE_TRAINING_SET_NOT_FOUND':
      toast.error('Antrenman Paketinin Setleri Bulunamadı')
      break
    case 'AGORA_ROOM_INFO_NOT_FOUND':
      toast.error('Yayın Bulunamadı!')
      break
    case 'AGORA_ROOM_INFO_IS_NOT_LIVE':
      toast.error('Canlı Yayın Henüz Başlamadı')
      break
    case 'NON_PREMIUM_ACCOUNTS_JOIN_LIVE_ROOM_ONLY_ONCE_A_DAY':
      toast.error('Daha Fazla Günlük Canlı Yayın Hakkınız İçin Premium Olun')
      break
    case 'INVALID_LOGIN':
      toast.error('Email veya Şifreniz Hatalı')
      break
    case 'VIMEO_URL_IS_NOT_VALID':
      toast.error('Video URLsi Geçersiz')
      break
    case 'VIMEO_VIDEO_NOT_FOUND':
      toast.error('Video Bulunamadı')
      break
    case 'SUBSCRIPTION_PLAN_NOT_FOUND':
      toast.error('Abonelik Paketi Bulunamadı')
      break
    case 'CUSTOMER_METADATA_NOT_FOUND':
      toast.error('Kullanıcı Bilgileri Bulunamadı')
      break
    case 'OPERATION_NOT_ALLOWED':
      toast.error('Yetkisiz İşlem')
      break
    case 'BROADCAST_IS_NOT_ENDED_YET':
      toast.error('Canlı Yayın Henüz Sonlanmadı!')
      break
    case 'USER_NOT_JOINED_YET':
      toast.error('Kullanıcı Henüz Katılmadı!')
      break
    case 'USER_IS_NOT_BROADCASTER':
      toast.error('Odanın Sahibi Değilsiniz')
      break
    case 'BROADCAST_IS_ENDED':
      toast.error('Yayın Tamamlanmış')
      break
    case 'PAYMENT_FAILED':
      toast.error('Ödeme Yaparken Hata Oluştu')
      break
    case 'YOU_ARE_NOT_IN_ROOM':
      toast.error('Yayına Katılamadınız!')
      break
    case 'IYZICO_SIGNATURE_NOT_VALID':
      toast.error('İyzico İmza Hatası!')
      break
    case 'PAYMENT_NOT_FOUND':
      toast.error('Ödeme Bulunamadı!')
      break
    case 'USER_ALREADY_HAS_SUBSCRIPTION':
      toast.error('Zaten Abonesiniz!')
      break
    case 'SUBSCRIPTION_DOWNGRADE_NOT_ALLOWED':
      toast.error('Abonelik Paketinizi Düşüremezsiniz!')
      break
    case 'MINIMUM_PHOTO_SIZE':
      toast.error(`Fotoğraf Boyutu Minimum "${error.response?.data.minimumSize}" Piksel olmalıdır.`)
      break
    case 'PHOTO_MUST_BE_SQUARE':
      toast.error('Fotoğraf Kare Olmalıdır!')
      break
    case 'SUBSCRIPTION_NOT_FOUND':
      toast.error('Abonelik Paketiniz Bulunmuyor!')
      break
    case 'SUBSCRIPTION_ALREADY_CANCELLED':
      toast.error('Abonelik İptal Talebiniz Daha Önceden Alındı, Dönem Sonunda Aboneliğiniz Otomatik Olarak İptal Edilecektir.!')
      break
    case 'PACKAGE_COURSE_IS_NOT_FREE':
      toast.error('Paket Artık Ücretsiz Değil')
      break
    case 'USER_ALREADY_BOUGHT_THIS_PACKAGE_COURSE':
      toast.error('Bu Pakete Zaten Sahipsiniz')
      break
    case 'CAROUSEL_IMAGE_NOT_FOUND':
      toast.error('Slider Resmi Bulunamadı')
      break
  }

  if (error.response?.data.errorKey === 'emailexists') {
    toast.warning('Girdiğiniz Email Zaten Kayıtlı')
  }

  if (error.response?.data.detail === 'Full authentication is required to access this resource') {
    toast.error('Oturum süreniz sona erdi, Tekrar Giriş Yapmalısınız!')
    localStorage.clear()
  }

  switch (error.response?.status) {
    case 500:
      toast.error('Sunucu işleminizi tamamlayamadı. Tekrar deneyiniz.')
      break
    case 401:
      localStorage.clear()
      break
  }

  throw error
}

const onRequest = (req: AxiosRequestConfig) => {
  const token = localStorage.getItem('token')

  if (token && req.headers) {
    req.headers.Authorization = 'Bearer ' + token
  }

  return req
}

axios.interceptors.request.use(onRequest)
axios.interceptors.response.use(onRequestSucceed, onRequestFailed)

export const Account = Services.AccountApiFactory(undefined, config.api.baseUrl, axios)
export const Advertise = Services.AdvertiseApiFactory(undefined, config.api.baseUrl, axios)
export const AppInfo = Services.AppInfoApiFactory(undefined, config.api.baseUrl, axios)
export const BroadcastProgram = Services.BroadcastProgramApiFactory(undefined, config.api.baseUrl, axios)
export const Carousel = Services.CarouselApiFactory(undefined, config.api.baseUrl, axios)
export const CustomerService = Services.CustomerServiceApiFactory(undefined, config.api.baseUrl, axios)
export const LegalDocument = Services.LegalDocumentApiFactory(undefined, config.api.baseUrl, axios)
export const Live = Services.LiveApiFactory(undefined, config.api.baseUrl, axios)
export const Notification = Services.NotificationApiFactory(undefined, config.api.baseUrl, axios)
export const PackageCourse = Services.PackageCourseApiFactory(undefined, config.api.baseUrl, axios)
export const PersonalTrainer = Services.PersonalTrainerApiFactory(undefined, config.api.baseUrl, axios)
export const SubscriptionPlan = Services.SubscriptionPlanApiFactory(undefined, config.api.baseUrl, axios)
export const System = Services.SystemApiFactory(undefined, config.api.baseUrl, axios)
export const Chat = Services.ChatApiFactory(undefined, config.api.baseUrl, axios)
export const OneSignal = Services.OnesignalApiFactory(undefined, config.api.baseUrl, axios)

