import Card from '../card'
import * as React from 'react'
import Text from 'components/atoms/text'
import useAppInfo from 'hooks/useAppInfo'
import ReactMarkdown from 'react-markdown'
import Input from 'components/atoms/input'
import Button from 'components/atoms/button'
import PasswordInput from '../password-input'
import Checkbox from 'components/atoms/checkbox'
import ErrorBox from 'components/atoms/error-box'
import { ArrowLeftSLine } from 'components/icons'
import Modal, { ModalSize, ModalType } from 'components/atoms/modal'
import { replaceNumber, validateEmail, validatePhoneNumber } from 'utils/validation-helpers'

import styles from './style.module.css'

type RegisterCardProps = {
    loading?: boolean
    onConfirm: (name: string, surname: string, email: string, phoneNumber: string | undefined, password: string) => void
}

const RegisterCard: React.FC<RegisterCardProps> = ({ loading, onConfirm }) => {
    const appInfo = useAppInfo()
    const [name, setName] = React.useState('')
    const [surname, setSurname] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [phoneNumber, setPhoneNumber] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [kvkk, setKvkk] = React.useState(false)
    const [showModal, setShowModal] = React.useState(false)

    const handleRegister = () => {
        if (kvkk) {
            onConfirm(name, surname, email, phoneNumber === "" ? undefined : replaceNumber(phoneNumber), password)
            return
        }
    }

    const isCompleted = React.useMemo(() => {
        return name.length > 1 && surname.length > 1 && validateEmail(email) && (phoneNumber === '' || validatePhoneNumber(phoneNumber)) && password.length > 5 && kvkk
    }, [name, surname, email, phoneNumber, password, kvkk])

    return (
        <>
            <div className={styles.inputs}>
                <Input value={name} onChangeText={setName} label='Adınız *' maxLength={50} name={'name'} autoComplete={'given-name'} />
                {name && name !== '' && name.length < 2 && <ErrorBox text={'En az 2 karakter olmalıdır'} size={'sm'} align={'left'} />}

                <Input value={surname} onChangeText={setSurname} label='Soyadınız *' maxLength={50} name={'surname'} autoComplete={'family-name'} />
                {surname && surname !== '' && surname.length < 2 && <ErrorBox text={'En az 2 karakter olmalıdır'} size={'sm'} align={'left'} />}

                <Input value={email} onChangeText={setEmail} label='E-Postanız *' type={'email'} name={'email'} autoComplete={'email'} />
                {email && email !== '' && !validateEmail(email) && <ErrorBox text={'Geçerli bir email adresi giriniz'} size={'sm'} align={'left'} />}

                <Input value={phoneNumber}
                       onChangeText={setPhoneNumber}
                       label='Telefon Numarası'
                       type='tel'
                       name='tel'
                       autoComplete='tel-national'
                       format='(###) ###-####'
                />
                {phoneNumber && phoneNumber !== '' && !validatePhoneNumber(phoneNumber) && <ErrorBox text='Geçerli bir telefon numarası yazmalısınız (örn: (555) 555-5555)' size='sm' align='left' />}

                <PasswordInput value={password} onChangeText={setPassword} label='Şifreniz *' name='password' autoComplete='new-password' maxLength={64} />
                <Text>Şifreniz en az 6, en fazla 64 karakterden oluşmalıdır.</Text>
            </div>

            <div className={styles.controls}>
                <Checkbox checked={kvkk} onChange={setKvkk} />
                <div onClick={() => setShowModal(x => !x)}>
                    <Text weight={'medium'} decoration={'underline'} clickable>KVKK koşullarını</Text>
                </div>
                <div onClick={() => setKvkk(x => !x)}>
                    <Text weight={'medium'}>kabul ediyorum.</Text>
                </div>
            </div>

            <div className={styles.actions}>
                <Button loading={loading} onClick={handleRegister} type='danger-2' variation='block' disabled={!isCompleted}>
                    Hesabımı Oluştur
                </Button>
            </div>

            <Modal isVisible={showModal} onModalClose={() => setShowModal(false)} size={ModalSize.Small} type={ModalType.ActionSheet}>
                <div className={styles.modal}>
                    <Card
                        type='light'
                        heading={{
                            leading: <div className={styles.back} onClick={() => setShowModal(x => !x)}><ArrowLeftSLine /></div>,
                            title: 'Gizlilik Sözleşmesi',
                            alignment: 'center',
                            size: 'lg',
                            divider: true,
                        }}
                    >
                        <div className={styles.contentWrapper}>
                            <Text size='sm'>
                                <ReactMarkdown>{appInfo.legalDocuments?.find(x => x.title === 'Gizlilik Sözleşmesi')?.content ?? ''}</ReactMarkdown>
                            </Text>
                        </div>
                    </Card>
                </div>
            </Modal>
        </>
    )
}

export default RegisterCard
