import * as React from 'react'
import Config from '../config'
import { ChatUser } from '../types'
import { AuthContextProps } from './auth'
import { ApiContextInterface } from './api'
import { FoshChatClient } from '@fosh/chat-client'
import { HubConnectionState } from '@microsoft/signalr'
import { NotificationContextProps } from './notification'
import { GroupMessageReceivedWithUserMetadata } from '@fosh/chat-client/FoshChatClient.Types'
import { GroupInfoUpdatedData, SystemMessageReceivedData } from '@fosh/chat-client/hubs/ChatHub.Types'
import {
  BroadcastStartedEventDTO,
  BadgeReceivedEventDTO,
  LogoutDTO,
  ToggleChatEventDTO,
  WeeklyFixtureEventDTO,
  IosPackageCourseOwnershipChangedDTO,
  IosPremiumStateChangedDTO, CustomNotificationReceivedDTO,
} from 'types/chat'

export interface ChatProviderState {
  connectionState: HubConnectionState;
  currentRoom: string;
  onlineUsers: number;
  chatMessages: GroupMessageReceivedWithUserMetadata<ChatUser>[];
  likes: number;
  chatAvailability: boolean;
  isEnded: boolean
  isEndedByAdmin: boolean
  userPackageCourseOwnerShipChange: undefined | IosPackageCourseOwnershipChangedDTO
}

export interface ChatContextMethods {
  joinToRoom: (groupId: string) => void;
  leaveFromCurrentRoom: () => void;
  sendMessageToCurrentRoom: (message: string) => void;
}

export interface ChatContextProps {
  state: ChatProviderState;
  client?: FoshChatClient<ChatUser | null>;
  methods: ChatContextMethods;
}

export interface ChatProviderProps {
  api: ApiContextInterface;
  auth: AuthContextProps;
  notification: NotificationContextProps
}

export const ChatContext = React.createContext<ChatContextProps>({
  state: {
    connectionState: HubConnectionState.Disconnected,
    onlineUsers: 0,
    chatMessages: [],
    currentRoom: '',
    likes: 0,
    chatAvailability: true,
    isEnded: false,
    isEndedByAdmin: false,
    userPackageCourseOwnerShipChange: undefined,
  },
  methods: {
    joinToRoom: (groupId: string) => {
    },
    leaveFromCurrentRoom: () => {
    },
    sendMessageToCurrentRoom: (message: string) => {
    },
  },
})


export class ChatProvider extends React.Component<ChatProviderProps, ChatProviderState> {
  private readonly _chatClient: FoshChatClient<ChatUser | null>

  public get ChatClient(): FoshChatClient<ChatUser | null> {
    return this._chatClient
  }

  constructor(props: ChatProviderProps) {
    super(props)

    this._chatClient = new FoshChatClient(Config.chat.appId, this.fetchChatUser.bind(this))
    this._chatClient.Events.on('connectionStateChanged', this.onConnectionStateChanged.bind(this))
    this._chatClient.Events.on('groupInfoUpdated', this.onGroupInfoUpdated.bind(this))
    this._chatClient.Events.on('groupMessageReceived', this.onMessageReceivedFromRoom.bind(this))
    this._chatClient.Events.on('systemMessageReceived', this.onSystemMessageReceived.bind(this))

    this.state = {
      connectionState: HubConnectionState.Disconnected,
      onlineUsers: 0,
      chatMessages: [],
      currentRoom: '',
      likes: 0,
      chatAvailability: true,
      isEnded: false,
      isEndedByAdmin: false,
      userPackageCourseOwnerShipChange: undefined,
    }
  }

  private async fetchChatUser(userId: string): Promise<ChatUser | null> {
    // console.log('FETCH CHAT USER!', userId)
    try {
      const { data } = await this.props.api.Chat.getChatUserInformation(userId)

      // console.log(data)
      if (data == null) {
        return null
      }

      return {
        id: data.id ?? '',
        avatar: {
          avatar1X: data.avatar?.avatar1X ?? '',
          avatar2X: data.avatar?.avatar2X ?? '',
          avatar3X: data.avatar?.avatar3X ?? '',
          avatar4X: data.avatar?.avatar4X ?? '',
        },
        fullName: data.fullName ?? '',
      }
    } catch (e) {
      throw e
    }
  }

  private onConnectionStateChanged(newState: HubConnectionState): void {
    this.setState({
      connectionState: newState,
    })
  }

  private onGroupInfoUpdated(groupInfoUpdatedData: GroupInfoUpdatedData) {
    if (groupInfoUpdatedData.groupId !== this.state.currentRoom) {
      return
    }

    this.setState({
      onlineUsers: groupInfoUpdatedData.totalUserCount,
    })
  }

  private onMessageReceivedFromRoom(messageData: GroupMessageReceivedWithUserMetadata<ChatUser | null>) {
    // console.log('Grup mesajı alındı!', messageData)
    if (messageData.groupId !== this.state.currentRoom || messageData.user === null) {
      return
    }

    let computedMessages = [...this.state.chatMessages]

    if (this.state.chatMessages.length > 99) {
      computedMessages.shift()
    }

    this.setState({
      chatMessages: [...computedMessages, messageData as GroupMessageReceivedWithUserMetadata<ChatUser>],
    })
  }

  private async onSystemMessageReceived(systemMessageData: SystemMessageReceivedData) {
    // console.log({ systemMessageData })

    switch (systemMessageData.type) {
      case 'BroadcastStarted':
        const broadcastStart: BroadcastStartedEventDTO = JSON.parse(systemMessageData.message)
        broadcastStart.didRead = false
        broadcastStart.sentAt = undefined
        this.props.notification.addNotification(broadcastStart)
        break
      case 'BroadcastEnded':
        // const broadcastEnd: BroadcastEndedEventDTO = JSON.parse(systemMessageData.message)
        this.setState({ isEnded: true })
        break
      case 'BroadcastEndedByAdmin':
        // const broadcastEnd: BroadcastEndedEventDTO = JSON.parse(systemMessageData.message)
        this.setState({ isEndedByAdmin: true })
        break
      case 'LikeReceived':
        // const like: LikeEventDTO = JSON.parse(systemMessageData.message)
        this.setState({ likes: this.state.likes + 1 })
        break
      case 'ChatAvailabilityChanged':
        const chatAvailability: ToggleChatEventDTO = JSON.parse(systemMessageData.message)
        this.setState({ chatAvailability: chatAvailability.newState })
        break
      case 'BadgeReceived':
        const badgeReceived: BadgeReceivedEventDTO = JSON.parse(systemMessageData.message)
        badgeReceived.didRead = false
        badgeReceived.sentAt = undefined

        this.props.notification.addNotification(badgeReceived)
        break
      case 'WeeklyBroadcastReceived':
        const weeklyBroadcastReceived: WeeklyFixtureEventDTO = JSON.parse(systemMessageData.message)
        weeklyBroadcastReceived.didRead = false
        weeklyBroadcastReceived.sentAt = undefined

        this.props.notification.addNotification(weeklyBroadcastReceived)
        break
      case 'Logout':
        const logout: LogoutDTO = JSON.parse(systemMessageData.message)
        if (localStorage.getItem('token') !== logout.jwt) {
          this.props.auth.multiLoginDetect()
        }
        break
      case 'IosPremiumStateChanged':
        const premiumChanged: IosPremiumStateChangedDTO = JSON.parse(systemMessageData.message)
        this.props.auth.setUser(user => {
          if (user) {
            user.isPremium = premiumChanged.newPremiumState
          }
          return user
        })
        break
      case 'IosPackageCourseOwnershipChanged':
        const packageCourseOwnershipChanged: IosPackageCourseOwnershipChangedDTO = JSON.parse(systemMessageData.message)
        this.setState({ userPackageCourseOwnerShipChange: packageCourseOwnershipChanged })
        break

      case 'CustomNotificationReceivedDTO':
        const customNotificationReceivedDTO: CustomNotificationReceivedDTO = JSON.parse(systemMessageData.message)
        customNotificationReceivedDTO.didRead = false
        customNotificationReceivedDTO.sentAt = undefined

        this.props.notification.addNotification(customNotificationReceivedDTO)
        break
    }
  }

  async componentDidMount() {
    await this.connect()
  }

  async componentDidUpdate(prevProps: Readonly<ChatProviderProps>) {
    if (prevProps.auth.isLoggedIn !== this.props.auth.isLoggedIn) {
      if (this.props.auth.isLoggedIn) {
        await this.connect()
      } else {
        await this.disconnect()
      }
    }
  }

  async connect() {
    if (this.props.auth.isLoggedIn) {
      try {
        const { data } = await this.props.api.Chat.getChatJwt()

        if (data.chatJwt) {
          this._chatClient.setUserJwt(data.chatJwt)
          await this._chatClient.Connect()
        }
      } catch {
      }
    }
  }

  async disconnect() {
    if (!this.props.auth.isLoggedIn) {
      await this._chatClient.Disconnect()
    }
  }

  async joinToRoom(groupId: string) {
    await this._chatClient.joinToGroup(groupId)
    this.setState({
      currentRoom: groupId,
      chatMessages: [],
    })
  }

  async leaveFromCurrentRoom() {
    await this._chatClient.leaveFromGroup(this.state.currentRoom)
    this.setState({
      currentRoom: '',
      isEnded: false,
      chatAvailability: true,
      likes: 0,
      onlineUsers: 0,
      chatMessages: [],
    })
  }

  async sendMessageToCurrentRoom(message: string) {
    await this._chatClient.sendMessageToGroup(this.state.currentRoom, message)
  }

  render() {
    return (
      <ChatContext.Provider value={
        {
          state: this.state,
          client: this._chatClient,
          methods: {
            joinToRoom: this.joinToRoom.bind(this),
            leaveFromCurrentRoom: this.leaveFromCurrentRoom.bind(this),
            sendMessageToCurrentRoom: this.sendMessageToCurrentRoom.bind(this),
          },
        }
      }>
        {this.props.children}
      </ChatContext.Provider>
    )
  }
}
