import { FC } from 'react'

import styles from './style.module.css'

export { styles }

const MyPerformanceTemplate: FC = ({ children }) => {
    return (
        <div className={styles.container}>{children}</div>
    )
}

export default MyPerformanceTemplate
