import * as React from 'react'
import { kebabCase } from 'lodash'
import classnames from 'classnames'
import useAuth from 'hooks/useAuth'
import useQuery from 'hooks/useQuery'
import { getUserRole } from 'utils/auth'
import useAppInfo from 'hooks/useAppInfo'
import ContactModal from '../contact-modal'
import Card from 'components/molecules/card'
import Avatar from 'components/atoms/avatar'
import Divider from 'components/atoms/divider'
import { roundHalf } from 'utils/number-helpers'
import { Link, NavLink, useHistory } from 'react-router-dom'
import { AuthContextProps } from 'providers/auth'
import { MenuItem, UserRole } from '../../../types'
import { CustomerService2Line, DeleteBinLine, Download2Line, FileCopy2Line, LockPasswordLine, LogoutCircleLine, QuestionLine, StarFill, UserSettingsLine } from 'components/icons'

import styles from './style.module.css'

type ProfileNavigationProps = {
    onNewPasswordClick: () => void
    onExportMyDataClick: () => void
    onDeleteMyAccountClick: () => void
}

const ProfileNavigation = (
    {
        onNewPasswordClick,
        onExportMyDataClick,
        onDeleteMyAccountClick,
    }: ProfileNavigationProps) => {
    const auth = useAuth() as AuthContextProps
    const userRole = getUserRole()
    const params = useQuery()
    const appInfo = useAppInfo()
    const history = useHistory()

    const [showContact, setShowContact] = React.useState(false)

    const logout = () => {
        auth.logout()
        history.push({ pathname: '/' })
    }

    const [primaryLinks] = React.useState<MenuItem[]>([
        { label: 'Şifre Değiştirme', icon: <LockPasswordLine />, url: '', clickHandler: onNewPasswordClick, active: false },
        { label: 'Profilimi Güncelle', icon: <UserSettingsLine />, url: '/profilim/profilimi-guncelle', active: false },
        { label: 'Bilgilerimi İndir', icon: <Download2Line />, url: '', clickHandler: onExportMyDataClick, active: false },
        { label: 'Hesabımı Sil', icon: <DeleteBinLine />, url: '', clickHandler: onDeleteMyAccountClick, active: false },
    ])

    const [secondaryLinks, setSecondaryLinks] = React.useState<MenuItem[]>([
        { label: 'S.S.S', icon: <QuestionLine />, url: '/s-s-s', active: false },
    ])

    const [footerLinks] = React.useState<MenuItem[]>([
        { label: 'İletişime Geçin', icon: <CustomerService2Line />, url: '', clickHandler: () => setShowContact(true), active: false },
        { label: 'Oturumu Kapat', icon: <LogoutCircleLine />, url: '', clickHandler: logout, active: false },
    ])

    React.useEffect(() => {
            appInfo.legalDocuments?.forEach(x => {
                if (!x.title || secondaryLinks.find(y => y.label === x.title)) {
                    return
                }

                setSecondaryLinks(y => {
                    if (x.title) {
                        return [...y, {
                            label: x.title, icon: <FileCopy2Line />, url: '', clickHandler: () => {
                                params.set('dokuman', kebabCase(x.title))
                                history.replace({ search: params.toString() })
                            }, active: false,
                        }]
                    }
                    return y
                })
            })
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [appInfo.legalDocuments])


    if (typeof auth.user === 'undefined') {
        return <></>
    }

    return (
        <div>
            <Card type={'light'}>
                <Link to={'/profilim'}>
                    <div className={classnames(styles.user)}>
                        <Avatar user={auth.user} rounded={true} size={3.5} />
                        <div>
                            <div className={classnames(styles.username)}>{auth.user.name + ' ' + auth.user.surname}</div>
                        </div>
                        {userRole === UserRole.ROLE_PERSONAL_TRAINER ? (
                            <div className={styles.badge}><StarFill /> {roundHalf(auth.user.rate)}</div>
                        ) : (
                            auth.user.isPremium && <div className={styles.badge}><StarFill /> Premium</div>
                        )}
                    </div>
                </Link>

                <Divider />

                <div className={classnames(styles['links'], styles['primary-links'])}>
                    {primaryLinks.map((r, i) => {
                        if (userRole !== UserRole.ROLE_CUSTOMER && i > 1) {
                            return null
                        }
                        if (r.clickHandler) {
                            return (<div onClick={() => r.clickHandler?.()} className={classnames(styles['link'], styles['primary-link'])}
                                         key={i.toString() + 'primary'}>
                                {r.icon}
                                <span>{r.label}</span>
                            </div>)
                        }
                        return (
                            <NavLink key={i.toString() + 'primary'} to={r.url ?? ''} activeClassName={styles.active}>
                                <div className={classnames(styles['link'], styles['primary-link'])}>
                                    {r.icon}
                                    <span>{r.label}</span>
                                </div>
                            </NavLink>
                        )
                    })}
                </div>

                <Divider />

                <div className={classnames(styles['links'])}>
                    {secondaryLinks.map((r, i) => {
                        if (r.clickHandler) {
                            return (<div onClick={() => r.clickHandler?.()} className={classnames(styles['link'], styles['primary-link'])}
                                         key={i.toString() + 'secondary'}>
                                {r.icon}
                                <span>{r.label}</span>
                            </div>)
                        }
                        return (
                            <NavLink key={i.toString() + 'secondary'} to={r.url ?? ''} activeClassName={styles.active}>
                                <div className={classnames(styles['link'], styles['primary-link'])}>
                                    {r.icon}
                                    <span>{r.label}</span>
                                </div>
                            </NavLink>
                        )
                    })}
                </div>

                <Divider />

                <div className={classnames(styles['links'])}>
                    {footerLinks.map((r, i) => {
                        if (r.clickHandler) {
                            return (<div onClick={() => r.clickHandler?.()} className={classnames(styles['link'], styles['primary-link'])}
                                         key={i.toString() + 'footer'}>
                                {r.icon}
                                <span>{r.label}</span>
                            </div>)
                        }
                        return (
                            <NavLink key={i.toString() + 'footer'} to={r.url ?? ''}>
                                <div className={classnames(styles['link'], styles['primary-link'], { [styles['danger-link']]: i === 0 })}>
                                    {r.icon}
                                    <span>{r.label}</span>
                                </div>
                            </NavLink>
                        )
                    })}
                </div>
            </Card>
            <ContactModal isVisible={showContact} onModalClose={() => setShowContact(false)} />
        </div>
    )
}

export default ProfileNavigation
