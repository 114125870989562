import * as React from 'react'
import Text from '../../atoms/text'
import { StarFill } from '../../icons'
import Button from '../../atoms/button'

import styles from './style.module.css'

type CurrentPlanCardProps = {
  payAmount: number
  monthAmount: number
  onCancelClick: () => void
}

const CurrentPlanCard = ({ payAmount, monthAmount, onCancelClick }: CurrentPlanCardProps) => {

  return (
    <div className={styles.container}>
      {payAmount !== 0 && <Button type={'danger-link'} size={'default'} onClick={onCancelClick}>Aboneliği İptal Et</Button>}
      <div className={styles['card']}>
        <Button as={'anchor'} variation={'circle'} type={'danger-2'} unClickable>
          <StarFill />
        </Button>

        <div className={styles['cardInfo']}>
          <div>
            <Text size={'xs'} type={'soft-blue'}>Mevcut Aboneliğiniz</Text>
            <Text size={'xs'} type={'soft-blue'}>{monthAmount} Aylık Paket</Text>
          </div>

          {payAmount === 0 ? <Text size={'xs'} type={'soft-blue'}>Ücretsiz</Text> : <Text size={'xs'} type={'soft-blue'}>{payAmount} TL</Text>}
        </div>
      </div>
    </div>
  )
}
export default CurrentPlanCard
