import Text from '../text'
import Avatar from '../avatar'
import Button from '../button'
import * as React from 'react'
import { UserRole } from 'types'
import useAuth from 'hooks/useAuth'
import Cropper from 'react-easy-crop'
import { getUserRole } from 'utils/auth'
import {TailSpin} from 'react-loader-spinner'
import { Point } from 'react-easy-crop/types'
import { AuthContextProps } from 'providers/auth'
import { CropType, getCroppedImg, readFile } from 'utils/image'
import Modal, { ModalSize, ModalType } from 'components/atoms/modal'

import styles from './style.module.css'

type SimpleImageSelectorProps = {
    fullName?: string
    onImageCropped: (image: string) => void
    type?: 'BASE64' | 'BLOB'
    loading?: boolean
    initialImage?: string
}

const SimpleImageSelector = ({ fullName, onImageCropped, loading, initialImage, type = 'BLOB' }: SimpleImageSelectorProps) => {
    const auth = useAuth() as AuthContextProps
    const userRole = getUserRole()
    const inputRef = React.useRef(null)

    const [imageSrc, setImageSrc] = React.useState<string | undefined>(undefined)
    const [croppedImageSrc, setCroppedImageSrc] = React.useState<string | undefined>(initialImage)
    const [crop, setCrop] = React.useState<Point>({ x: 0, y: 0 })
    const [zoom, setZoom] = React.useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = React.useState<CropType | null>(null)

    const selectFile = () => {
        // @ts-ignore
        inputRef.current?.click()
    }

    const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || e.target.files.length === 0) return

        const file = e.target.files[0]
        let imageDataUrl = await readFile(file)

        setImageSrc(imageDataUrl)
    }

    const onCropComplete = React.useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const onCropFinished = React.useCallback(async () => {
        if (!croppedAreaPixels) return
        try {
            const croppedImage = await getCroppedImg(imageSrc ?? null, croppedAreaPixels, type)
            onImageCropped(croppedImage)
            setCroppedImageSrc(croppedImage)
            setImageSrc(undefined)
        } catch {
        }
    }, [imageSrc, croppedAreaPixels, onImageCropped, type])

    const onClose = React.useCallback(() => {
        setImageSrc(undefined)
    }, [])

    return (
        <>
            <div className={styles.wrapper}>
                {!loading && <Avatar size={6} url={croppedImageSrc ?? ' '}
                                                  fallbackSrc={`https://ui-avatars.com/api/?size=128&name=${fullName ?? auth.user?.name + ' ' + auth.user?.surname}`} />}
                {loading && <TailSpin color={'#334362'} height={96} width={96} />}

                <div className={styles.labels}>
                    <Text size='xl' weight='bold'>
                        Profil Fotoğrafı Yükle
                    </Text>
                    <Text>Lütfen geçerli resim formatlarında (JPEG, PNG) ve en az {userRole === UserRole.ROLE_PERSONAL_TRAINER ? '950px' : '250px'} yükseklik ve genişliğinde resim
                        yükleyiniz.</Text>
                </div>
                <Button onClick={selectFile} type='danger' variation='block'>
                    Fotoğraf Seç
                </Button>
            </div>
            <div className={styles.hidden}>
                <input type='file' ref={inputRef} onChange={onFileChange} accept='image/*' />
            </div>
            <Modal isVisible={!!imageSrc} onModalClose={onClose} size={ModalSize.Small} type={ModalType.Centered}>
                <div className={styles.modal}>
                    <div className={styles['crop-container']}>
                        {imageSrc &&
                        <Cropper image={imageSrc} crop={crop} zoomSpeed={0.1} zoom={zoom} aspect={1} onCropChange={setCrop} onCropComplete={onCropComplete}
                                 onZoomChange={setZoom} />}
                    </div>
                    <div className={styles['modal-actions']}>
                        <Button variation='block' type='gradient' onClick={onCropFinished}>
                            Kırp & Kaydet
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default SimpleImageSelector
