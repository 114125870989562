import * as React from 'react'
import classnames from 'classnames'

import styles from './style.module.css'
import { Loader2Line } from 'components/icons'

interface IAnchor extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
}

interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
}

type TButton = IAnchor | IButton

type ButtonType =
    | 'default'
    | 'dimmed'
    | 'gradient'
    | 'danger'
    | 'danger-2'
    | 'danger-3'
    | 'danger-4'
    | 'warning'
    | 'warning-fill'
    | 'success'
    | 'success-2'
    | 'dimmed-link'
    | 'warning-link'
    | 'danger-link'
    | 'pink'
    | 'pink-link'
    | 'gray'
    | 'gray-link'
    | 'light'
    | 'blured'
    | 'black-opacity'
type ButtonVariation = 'default' | 'block' | 'circle' | 'wide' | 'icon'
type ButtonIconPlacement = 'absolute' | 'relative'

export type ButtonProps = {
    as?: 'button' | 'anchor'
    type?: ButtonType
    size?: 'xSmall' | 'small' | 'default' | 'large'
    variation?: ButtonVariation
    alignment?: 'center' | 'left' | 'right'
    leftIcon?: React.ReactNode
    rightIcon?: React.ReactNode
    leftIconPlacement?: ButtonIconPlacement
    rightIconPlacement?: ButtonIconPlacement
    disabled?: boolean
    loading?: boolean
    unClickable?: boolean
}

const Button = React.memo((
    {
        as = 'button',
        type = 'default',
        size = 'default',
        variation = 'default',
        alignment = 'center',
        leftIcon = null,
        rightIcon = null,
        leftIconPlacement = 'absolute',
        rightIconPlacement = 'absolute',
        children,
        disabled = false,
        loading = false,
        onClick,
        className,
        unClickable,
        ...props
    }: ButtonProps & TButton) => {

    const onClickHandler = (event: any) => {
        if (typeof onClick === 'function' && !disabled && !loading) {
            onClick(event)
        }
    }

    const Wrapper = (props: TButton) => {
        if (as === 'button') return <button {...(props as IButton)}>{props.children}</button>
        return <a {...(props as IAnchor)}>{props.children}</a>
    }

    const Icon = ({ icon, placement, location }: { icon: React.ReactNode; location: 'left' | 'right'; placement?: 'absolute' | 'relative' }) => {
        if (icon == null) return null
        if (!placement) placement = 'absolute'

        return <span className={classnames(styles.icon, styles[placement], styles[location])}>{icon}</span>
    }

    return (
        <Wrapper
            className={classnames(
                styles.button,
                styles[`type-${type}`],
                styles[`variation-${variation}`],
                styles[`alignment-${alignment}`],
                styles[`size-${size}`],
                {
                    [styles.disabled]: disabled,
                    [styles.loading]: loading,
                    [styles.leftPadding]: leftIconPlacement === 'absolute' && !!leftIcon,
                    [styles.rightPadding]: rightIconPlacement === 'absolute' && !!rightIcon,
                    [styles.unClick]: unClickable,
                },
                className,
            )}
            onClick={onClickHandler}
            {...props}
        >
            <Icon icon={leftIcon} placement={leftIconPlacement} location={'left'} />
            {loading ? <Loader2Line className={styles.loadingIcon} /> : children}
            <Icon icon={rightIcon} placement={rightIconPlacement} location={'right'} />
        </Wrapper>
    )
})

export default Button
