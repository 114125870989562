import * as React from "react";

function SvgTurkishLiraCircle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <path d="M256 0C114.848 0 0 114.848 0 256s114.848 256 256 256 256-114.848 256-256S397.152 0 256 0zm0 480C132.512 480 32 379.52 32 256S132.512 32 256 32s224 100.48 224 224-100.512 224-224 224z" />
      <path d="M376.704 290.592c-7.328-4.768-17.312-2.688-22.112 4.704C322.944 344 278.24 352 256 352.672V268.48l51.872-12.96c8.608-2.144 13.792-10.816 11.648-19.392-2.144-8.608-10.912-13.824-19.392-11.648L256 235.52v-31.04l51.872-12.96c8.608-2.144 13.792-10.816 11.648-19.392s-10.912-13.856-19.392-11.648L256 171.52V144c0-8.832-7.168-16-16-16s-16 7.168-16 16v35.52l-51.872 12.96c-8.608 2.144-13.792 10.848-11.648 19.392C162.272 219.168 168.8 224 176 224c1.28 0 2.592-.16 3.872-.48L224 212.48v31.04l-51.872 12.96c-8.608 2.144-13.792 10.848-11.648 19.392C162.272 283.168 168.8 288 176 288c1.28 0 2.592-.16 3.872-.48L224 276.48V368c0 7.904 5.792 14.656 13.6 15.84.864.128 6.24.864 14.688.864 27.52 0 87.584-8.032 129.12-72 4.8-7.392 2.72-17.312-4.704-22.112z" />
    </svg>
  );
}

export default SvgTurkishLiraCircle;
