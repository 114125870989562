import * as React from 'react'
import classNames from 'classnames'
import { Loader2Line, Save3Fill } from 'components/icons'

import styles from './style.module.css'

type InputSaveButtonProps = {
    loading?: boolean
    onSubmit: () => void
}

const InputSaveButton = ({ loading, onSubmit }: InputSaveButtonProps) => {
    return (
        <button
            onClick={onSubmit}
            className={classNames(styles.button, {
                [styles.loading]: loading,
            })}
        >
            {loading ? <Loader2Line /> : <Save3Fill />}
        </button>
    )
}

export default InputSaveButton
