import * as React from "react";

function SvgLogoFull(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 602.47 156.75"
      {...props}
    >
      <defs>
        <style>{".logo-full_svg__logo-full_svg__cls-2{fill:#e1251b}"}</style>
      </defs>
      <g id="logo-full_svg__logo-full_svg__katman_2" data-name="katman 2">
        <g id="logo-full_svg__logo-full_svg__katman_1-2" data-name="katman 1">
          <path
            d="M419.12 28.66l-1.27 4.73h17.26l41.23.08c5.31 0 10.82-4.31 12.25-9.62l1.25-4.63-58.6-.11c-5.24.02-10.7 4.29-12.12 9.55zm49.31 34.12l-41.23-.08 2.38-8.82 28.83.06c5.32 0 10.82-4.32 12.26-9.63l.71-2.65h-17.76l-28.35-.06c-5.3 0-10.8 4.31-12.23 9.61l-4.38 16.19c-1.42 5.26 1.72 9.55 7 9.56l58.6.11 1.25-4.63c1.4-5.31-1.76-9.65-7.08-9.66zM311.67 18.91l-15.61 57.82 17.26.03 15.61-57.82-17.26-.03zm-97.32-.19c-5.31 0-10.82 4.32-12.25 9.62l-6.26 23.18-22.43-32.88h-17.27l-15.61 57.79h7.62c5.31 0 10.82-4.32 12.25-9.63l6.26-23.17 22.43 32.87h17.27L222 18.74zm172.88 9.98L381 51.87 358.53 19h-17.26l-15.62 57.79h7.63c5.31 0 10.81-4.31 12.25-9.62L351.78 44l22.44 32.87h17.26l15.62-57.78h-7.62c-5.32-.02-10.82 4.3-12.25 9.61zM137.11 18.57l-52.67-.1c-5.26 0-10.7 4.27-12.12 9.52q-5.23 19.38-10.46 38.75c-1.42 5.25 1.71 9.54 7 9.55l52.66.1c5.26 0 10.71-4.26 12.12-9.51l10.47-38.75c1.38-5.25-1.75-9.55-7-9.56zm-19.49 43.54L80.4 62c5-18.55 3.18-11.75 7.91-29.28l37.22.07c-4.73 17.57-2.9 10.77-7.91 29.32zm161.84.31l-38.91-.08 9.15-33.9c1.44-5.31-1.73-9.65-7-9.66h-7.62q-6.57 24.35-13.15 48.69c-1.36 5 1.64 9.14 6.68 9.15l56.69.11 1.24-4.63c1.4-5.33-1.76-9.67-7.08-9.68z"
            fill="#020a0a"
          />
          <path
            className="logo-full_svg__logo-full_svg__cls-2"
            d="M515.59 117.08h-22.36l-.87 3.24c-1.43 5.31 1.73 9.65 7 9.66h7.57l-1.66 6.14L471 136l5.34-19.76h-7.64c-5.31 0-10.8 4.31-12.23 9.61l-4.13 15.28c-1.35 5 1.65 9.14 6.68 9.15l47.58.09.06-.07c5 0 10.18-4.1 11.53-9.11l1.37-5.08 2.67-9.88c1.39-5.03-1.6-9.14-6.64-9.15zM247.06 92.02l-15.61 57.82 17.27.03 15.61-57.82-17.27-.03zm110.08.21l-15.62 57.82 17.27.03 15.61-57.81-17.26-.04zm-159.94-.31a12.92 12.92 0 00-9.91 5.13l-42 52.62h12.68a12.87 12.87 0 009.94-5.16l3.29-4.16 9.72-12.28 7.81-9.87 7-8.86 4.71 18.77h-3a12.86 12.86 0 00-9.94 5.17l-5.5 7.12h21.61l1 4.17c.82 3.26 3.47 5.19 7.15 5.19h12.67L209.56 92zM70.69 96.31l1.25-4.63-58.44-.11c-5.32 0-10.82 4.32-12.25 9.63L0 105.82l25.41.05-11.76 43.55h17.27l11.76-43.52h15.76c5.31.04 10.82-4.28 12.25-9.59zm264.18-4.12c-5.31 0-10.82 4.31-12.25 9.62L316.36 125l-22.43-32.89h-17.27l-15.61 57.79h7.62c5.31 0 10.82-4.31 12.25-9.62l6.26-23.17L309.61 150h17.27l15.61-57.8zM446 92.4c-5.32 0-10.82 4.31-12.25 9.62l-6.26 23.17L405 92.32h-17.27l-15.61 57.82h7.62c5.31 0 10.81-4.31 12.25-9.62l6.25-23.17 22.44 32.87h17.27l15.61-57.82zm-303.07-.59l-52.16-.1C85.46 91.7 80 96 78.53 101.34L77.28 106l52.72.1-2.16 8c-14.57 0-29.11.1-43.68.12-5 0-10.21 4.1-11.57 9.12q-3.54 13.11-7.07 26.21h18.21Q86.85 138 90 126.49h21.83l6.32 23.15h18.34l-6.16-23.22h3.32c5 0 10.22-4.09 11.58-9.12l4.37-16.3c1.4-5.07-1.6-9.18-6.67-9.19zm412.88-46.9a14.36 14.36 0 10-11.88-16.44 14.4 14.4 0 0011.88 16.44z"
          />
          <path
            className="logo-full_svg__logo-full_svg__cls-2"
            d="M595.26 133.21q-10.78-17.27-21.55-34.53L563.31 82l23.51-15.51c4.32-2.85 10.87-5.72 11.6-11.49a9.39 9.39 0 00-11.55-10.18c-2 .51-3.75 1.87-5.46 3L561 61.33l-7.51 5q-15.2-24.4-30.39-48.73l-6.58-10.55c-1.76-2.81-3.39-5.59-6.87-6.63-8.5-2.53-14.52 6.83-10.44 14 1.25 2.21 2.7 4.33 4.05 6.49L538 76.51l-14.1 9.31-7.3-11.06c-2.22-3.36-2.61-4.8-6.51-5.33-1.89-.25-13.59-3.69-17.6-4.62-2.25-.52-3.15 2.36-2.15 3.88Q497.66 79.8 505 90.92c4 6.06 9.64 19.61 19 16.61 4.62-1.48 9.08-5.55 13-8.16l10.78-7.11 3 4.75q17.26 27.66 34.54 55.33a3.08 3.08 0 00.35.44c2.9 3.64 7.6 5.21 12 2.87 10.25-5.53 1.54-16.11-2.41-22.44zM495.18 93l-.32-.47h-20.2c-5 0-10.25 4.08-11.61 9.11l-1.38 5.14 42.94.08c-4.03-3.86-7.03-10.24-9.43-13.86z"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgLogoFull;
