import * as React from 'react'
import classnames from 'classnames'
import useAuth from 'hooks/useAuth'
import { getUserRole } from 'utils/auth'
import { MenuItem, UserRole } from 'types'
import { NavLink } from 'react-router-dom'
import { AuthContextProps } from 'providers/auth'
import useNotification from 'hooks/useNotification'
import { CalendarCheckLine, FlashlightFill, Home3Line, Notification3Line, RecordCircleLine, User3Line } from '../../icons'

import styles from './style.module.css'

export default function TabBar() {
    const auth = useAuth() as AuthContextProps
    const userRole = getUserRole()
    const notifications = useNotification()

    const renderItems = React.useMemo(() => {
        const items: MenuItem[] = [
            { url: '/', label: 'Anasayfa', icon: <Home3Line />, active: false, exact: true },
            { url: '/antrenmanlar', label: 'Antrenmanlarım', icon: <CalendarCheckLine />, active: false },
            { url: '/canli-yayin-takvimi', label: 'Canlı Yayınlar', icon: <RecordCircleLine style={{ fill: 'red' }} />, active: false },
            {
                url: '/bildirimler',
                label: 'Bildirimler',
                icon: <>
                    <div className={classnames({ [styles.new]: notifications.hasNewNotification })} />
                    <Notification3Line /></>,
                active: false,
            },
            { url: '/profilim', label: 'Profilim', icon: <User3Line />, active: false },
        ]

        if (userRole === UserRole.ROLE_PERSONAL_TRAINER) {
            items[1] = { url: '/yayin-takvimi', label: 'Yayın Takvimi', icon: <CalendarCheckLine />, active: false }
            items[3] = { url: '/performans', label: 'Performans', icon: <FlashlightFill />, active: false }
        } else {
            items[1] = { url: '/antrenmanlar', label: 'Antrenmanlarım', icon: <CalendarCheckLine />, active: false }
            items[3] = {
                url: '/bildirimler', label: 'Bildirimler', icon: <>
                    <div className={classnames({ [styles.new]: notifications.hasNewNotification })} />
                    <Notification3Line /></>, active: false,
            }
        }

        return (
            items.map((item, index) => {
                if (index !== 0 && !auth.isLoggedIn) {
                    return (<div onClick={() => auth.showModal?.('entry')} className={styles.button} key={index.toString() + 'tabItem'}>
                        {item.icon}
                    </div>)
                }
                return (
                    <NavLink
                        to={{
                            pathname: item.url,
                        }}
                        className={styles.button}
                        activeClassName={styles.active}
                        exact={item.exact}
                        key={index.toString()}
                    >
                        {item.icon}
                    </NavLink>
                )
            })
        )
    }, [auth, userRole, notifications.hasNewNotification])

    return (
        <div className={classnames(styles['tab-bar'])}>
            {renderItems}
        </div>
    )
}
