import * as React from 'react'
import Config from '../config'
import classNames from 'classnames'
import useApi from '../hooks/useApi'
import { isUndefined } from 'lodash'
import useMedia from 'hooks/useMedia'
import useQuery from 'hooks/useQuery'
import useAuth from '../hooks/useAuth'
import Vimeo from '@u-wave/react-vimeo'
import Text from 'components/atoms/text'
import { use100vh } from 'react-div-100vh'
import { Helmet } from 'react-helmet-async'
import Button from 'components/atoms/button'
import Card from 'components/molecules/card'
import ErrorBox from 'components/atoms/error-box'
import { Link, useHistory } from 'react-router-dom'
import { AuthContextProps } from '../providers/auth'
import { ApiContextInterface } from '../providers/api'
import ErrorCard from 'components/organisms/error-card'
import Modal, { ModalType } from 'components/atoms/modal'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'
import PlanCardContent from 'components/molecules/plan-card-content'
import PlanCardHeading from 'components/molecules/plan-card-heading'
import ImageWithFallback from 'components/atoms/image-with-fallback'
import TrainingNavigation from 'components/molecules/training-navigation'
import { ArrowDownSLine, ArrowUpSLine, CheckLine } from 'components/icons'
import TrainingSelectorCard from 'components/molecules/training-selector-card'
import PackageCourseTemplate, { styles } from 'components/templates/package-course'
import { GetPackageCourseDetailsQueryResult, InfinityPagination, PackageCourseListItem } from '@tetahq/bironbir_api_client'

const PackageCourseScreen: React.FC = () => {
    const api = useApi() as ApiContextInterface
    const isMobile = useMedia('(max-width: 1024px)')
    const height = use100vh()
    const params = useQuery()
    const history = useHistory()
    const auth = useAuth() as AuthContextProps

    const courseParam = params.get('course')
    const trainingParam = params.get('training')

    const [courses, setCourses] = React.useState<PackageCourseListItem[]>([])
    const [coursesPagination, setCoursesPagination] = React.useState<InfinityPagination>({ isNextPageAvailable: false })

    const [currentCourse, setCurrentCourse] = React.useState<number | undefined>(courseParam ? parseInt(courseParam) : undefined)
    const [currentTraining, setCurrentTraining] = React.useState<number | undefined>(trainingParam ? parseInt(trainingParam) : undefined)

    const [selectedCourse, setSelectedCourse] = React.useState<GetPackageCourseDetailsQueryResult | undefined>(undefined)

    const [controlsShown, setControlsShown] = React.useState(true)

    const [loading, setLoading] = React.useState(true)
    const [videoShow, setVideoShow] = React.useState(false)

    const getCoursesNextPage = () => {
        if (coursesPagination.isNextPageAvailable && auth.isLoggedIn) {
            (async () => {
                try {
                    const { data: { packageCourses, pagination } } = await api.PackageCourse.listPackageCourses(coursesPagination.firstIdOfNextPage, true)
                    if (packageCourses && pagination) {
                        setCourses((x) => [...x, ...(packageCourses)])
                        setCoursesPagination(pagination)
                    }
                } catch {
                    setCoursesPagination({ isNextPageAvailable: false })
                }
            })()
        }
    }

    React.useEffect(() => {
        if (!loading && courseParam === null && trainingParam === null && courses.length > 0) {
            setCurrentCourse(courses[0].id)
        }
    }, [loading, courseParam, trainingParam, courses])

    React.useEffect(() => {
            if (courseParam !== null) {
                if (parseInt(courseParam) !== currentCourse) {
                    setCurrentCourse(parseInt(courseParam))
                }
            } else {
                setCurrentCourse(undefined)
                setCurrentTraining(undefined)
            }
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [courseParam])

    React.useEffect(() => {
            if (trainingParam !== null) {
                if (parseInt(trainingParam) !== currentTraining) {
                    setCurrentTraining(parseInt(trainingParam))
                }
            } else {
                setCurrentTraining(undefined)
            }
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [trainingParam])

    React.useEffect(() => {
            if (typeof currentTraining !== 'undefined' && trainingParam !== currentTraining.toString()) {
                params.set('training', currentTraining.toString())
                history.replace({ search: params.toString() })
            }
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [currentTraining])

    React.useEffect(() => {
            if (typeof currentCourse !== 'undefined' && courseParam !== currentCourse.toString()) {
                params.set('course', currentCourse.toString())
                params.delete('training')
                history.replace({ search: params.toString() })
                setCurrentTraining(undefined)
            }
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [currentCourse, courseParam, selectedCourse])

    React.useEffect(() => {
            if (selectedCourse && selectedCourse.trainings?.[0]?.id && !trainingParam) {
                params.set('training', selectedCourse.trainings[0].id.toString())
                setCurrentTraining(selectedCourse.trainings[0].id)
                history.replace({ search: params.toString() })
            }
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedCourse])

    React.useEffect(() => {
        (async () => {
            setLoading(true)

            if (auth.isLoggedIn) {
                try {
                    const { data } = await api.PackageCourse.listPackageCourses(undefined, true)
                    if (data.packageCourses && data.pagination) {
                        setCourses(data.packageCourses)
                        setCoursesPagination(data.pagination)
                    }
                } catch {
                }
            }

            setLoading(false)
        })()
    }, [api.PackageCourse, auth.isLoggedIn])

    const getCourseDetail = React.useCallback(async (courseId: number) => {
            if (auth.isLoggedIn) {
                try {
                    const { data } = await api.PackageCourse.getPackageCourseDetails(courseId)

                    if (!data.isPurchased) {
                        history.replace({ pathname: `/ders-paketi/${data.id}` })
                    }

                    setSelectedCourse(data)
                } catch {
                }
            }
        },
        [api.PackageCourse, history, auth.isLoggedIn])

    React.useEffect(() => {
        if (typeof currentCourse !== 'undefined') {
            (async () => {
                await getCourseDetail(parseInt(currentCourse.toString()))
            })()
        }
    }, [currentCourse, getCourseDetail])

    const videoEndHandler = async () => {
        if (typeof selectedCourse !== 'undefined' && typeof selectedCourse.trainings !== 'undefined' && typeof currentTraining !== 'undefined') {
            if (!selectedCourse.trainings.find(x => x.id === currentTraining)?.isWatched) {
                try {
                    await api.PackageCourse.packageCourseTrainingWatched({ packageCourseTrainingId: currentTraining })
                } catch {
                }
            }
        }
    }

    if (loading) {
        return (
            <Helmet>
                <title>Yükleniyor... | {Config.app.name}</title>
            </Helmet>
        )
    }

    if (!auth.isLoggedIn) {
        return (
            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <ErrorCard type={'401'} />
            </div>
        )
    }

    if (!courses || courses.length < 1) {
        return (
            <PackageCourseTemplate isMobile={isMobile}>
                <Helmet>
                    <title>Antrenmanınız Bulunmuyor | {Config.app.name}</title>
                </Helmet>
                <Modal isVisible={true} onModalClose={() => []} type={ModalType.ActionSheet}>
                    <Card type='light' heading={{ title: 'Antrenmanınız Bulunmuyor', divider: true }}>
                        <Text size='base' type={'default'} weight={'medium'} className={styles['text-center']}>
                            Yeni Bir Antrenman Paketi İçin <br /> Anasayfada Bulunan <b>Antrenman Paketleri</b> Göz Atın!
                        </Text>
                        <div className={styles['card-buttons']}>
                            <Link to={'/'}>
                                <Button type='danger-2' variation='block'>
                                    Anasayfa
                                </Button>
                            </Link>
                        </div>
                    </Card>
                </Modal>
            </PackageCourseTemplate>
        )
    }

    return (
        <PackageCourseTemplate isMobile={isMobile}>
            <Helmet>
                <title>Antrenmanlarım | {Config.app.name}</title>
            </Helmet>
            {isMobile && (
                <>
                    <div className={styles.controls}>
                        <Swiper
                            grabCursor
                            slidesPerView={'auto'}
                            slidesOffsetBefore={16}
                            onSlideChange={(sw) => {
                                if (sw.isEnd) {
                                    getCoursesNextPage()
                                }
                            }}
                        >
                            {courses.map((course) => (
                                <SwiperSlide
                                    key={course.id + 'plan'}
                                    onClick={() => setCurrentCourse(course.id)}
                                    className={classNames(styles.controlButton, { [styles.active]: course.id === currentCourse })}
                                >
                                    <ImageWithFallback
                                        src={course.coverPhoto?.coverImage1X}
                                        srcSet={course.coverPhoto ? `${course.coverPhoto.coverImage1X}, ${course.coverPhoto.coverImage2X} 1.5x, ${course.coverPhoto.coverImage3X} 2x, ${course.coverPhoto.coverImage4X} 3x,` : undefined}
                                        fallbackSrc={'/images/fallbacks/250-250.png'}
                                    />
                                    {(selectedCourse?.id === course.id && selectedCourse?.trainings?.find(x => !x.isWatched) == null) && (
                                        <CheckLine />
                                    )}
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>

                    {!isUndefined(selectedCourse) && !isUndefined(selectedCourse.trainings) && !isUndefined(currentTraining) && (
                        <div className={styles.items}>
                            {selectedCourse.trainings.map((training) => (
                                    <PlanCardHeading
                                        training={training}
                                        initialStatus={training.id === currentTraining}
                                        key={`${training.id}training`}
                                        index={training.id ?? 0}
                                        onActive={setCurrentTraining}
                                        onEnd={videoEndHandler}
                                        videoShow={videoShow}
                                        onModalClose={() => setVideoShow(false)}
                                    >
                                        <PlanCardContent onClickSet={() => {
                                            // Setlere Basıldığında Video Başlamasını aktifleştirmek...
                                            // setVideoShow(true)
                                        }} sets={training.sets ?? []} />
                                    </PlanCardHeading>
                                ),
                            )}
                        </div>
                    )}

                    {!isUndefined(selectedCourse) && !isUndefined(selectedCourse.trainings) && selectedCourse.trainings.length < 1 && (
                        <ErrorBox text={'Antrenman Paketi İçeriği Henüz Yayınlanmadı!'} style={{ marginTop: '1rem' }} />
                    )}
                </>
            )}

            {!isMobile && (
                <div style={{ height: `calc(${height ? height.toString() + 'px' : '100vh'} - 7.5rem)`, overflow: 'visible' }}>
                    <div className={styles.controls}>
                        {controlsShown && (
                            <Swiper
                                className={styles.swiper}
                                grabCursor
                                slidesPerView={'auto'}
                                onSlideChange={(sw) => {
                                    if (sw.isEnd) {
                                        getCoursesNextPage()
                                    }
                                }}
                            >
                                {courses.map((r) => (
                                    <SwiperSlide key={`${r.id}_courses`} style={{ width: '17rem' }}>
                                        <TrainingSelectorCard
                                            completed={(selectedCourse?.id === r.id && selectedCourse?.trainings?.find(x => !x.isWatched) == null) ?? false}
                                            onClick={() => setCurrentCourse(r.id)}
                                            selected={currentCourse ? currentCourse === r.id : false}
                                            course={r}
                                        />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        )}

                        <div className={styles.controlActions}>
                            <Button type={controlsShown ? 'dimmed' : 'danger'} onClick={() => setControlsShown(!controlsShown)} size='small' variation='circle'>
                                {controlsShown ? <ArrowUpSLine /> : <ArrowDownSLine />}
                            </Button>
                        </div>
                    </div>

                    {!isUndefined(selectedCourse) && !isUndefined(selectedCourse.trainings) && !isUndefined(currentTraining) && (
                        <>
                            <TrainingNavigation
                                trainings={selectedCourse.trainings}
                                currentTraining={currentTraining}
                                onNext={(id) => setCurrentTraining(id)}
                                onPrevious={(id) => setCurrentTraining(id)}
                            />

                            <div className={styles.content}>
                                <div className={styles.sets}>
                                    <PlanCardContent sets={selectedCourse.trainings.find(x => x.id === currentTraining)?.sets ?? []} scrollable />
                                </div>

                                <div className={styles.videoArea}>
                                    {selectedCourse.trainings.find(x => x.id === currentTraining)?.vimeoVideoInformation?.vimeoEmbedUrl ? (
                                        <Vimeo
                                            className={styles.videoWrapper}
                                            video={selectedCourse.trainings.find(x => x.id === currentTraining)?.vimeoVideoInformation?.vimeoEmbedUrl ?? ' '}
                                            width={'100%'}
                                            height={'auto'}
                                            onEnd={videoEndHandler}
                                            responsive
                                        />
                                    ) : (
                                        <ErrorBox text={'Video yüklenirken hata oluştu. Lütfen bizimle iletişime geçiniz.'} size={'sm'} />
                                    )}
                                </div>
                            </div>
                        </>
                    )}

                    {!isUndefined(selectedCourse) && !isUndefined(selectedCourse.trainings) && selectedCourse.trainings.length < 1 && (
                        <ErrorBox text={'Antrenman Paketi İçeriği Henüz Yayınlanmadı!'} style={{ marginTop: '1rem' }} />
                    )}
                </div>
            )}
        </PackageCourseTemplate>
    )
}

export default PackageCourseScreen
