import * as React from 'react'
import Text from '../../atoms/text'
import Input from '../../atoms/input'
import AgoraHost from '../agora-host'
import Avatar from '../../atoms/avatar'
import Button from '../../atoms/button'
import { use100vh } from 'react-div-100vh'
import useChat from '../../../hooks/useChat'
import AgoraAudience from '../agora-audience'
import ChatCard from '../../molecules/chat-card'
import { numberFormat } from 'utils/number-helpers'
import { ChatContextProps } from '../../../providers/chat'
import FloatingHearts from '../../molecules/floating-hearts'
import { PersonalTrainerDetails } from '@tetahq/bironbir_api_client'
import { Chat4Line, ChatOffLine, CloseLine, EyeLine, HeartFill, PictureInPicture, PictureInPictureExit, SendPlaneFill } from '../../icons'

import styles from './style.module.css'
import classnames from 'classnames'
import useMedia from '../../../hooks/useMedia'
import { HubConnectionState } from '@microsoft/signalr'
import { useParams } from 'react-router-dom'
import ToggleButton from '../../atoms/toggle-button'

export type RoomInfo = {
    host: PersonalTrainerDetails,
    type: string,
    viewer: number
    isChatActivate: boolean
    owner: boolean
    token?: string
    uid: string
    channelName: string
    startedAt: string
    broadcastId: number
}

type LiveModalProps = {
    onComplete: () => void
    live: RoomInfo
    sendMessage: (text: string) => void
    sendLike: () => void
    closeChat: (isOpen: boolean) => void
}

const maxMessageLength = 512

const LiveModal: React.FC<LiveModalProps> = ({ onComplete, live, sendMessage, sendLike, closeChat }) => {
    const chat = useChat() as ChatContextProps
    const height = use100vh()
    const isMobile = useMedia('(max-width: 1024px)')

    const [viewer, setViewer] = React.useState(0)
    const [message, setMessage] = React.useState('')
    const [isChatActivate, setChatActivate] = React.useState(live.isChatActivate)
    const [hideAll, setHideAll] = React.useState(false)

    const [hearts, setHearts] = React.useState(0)

    const messagesEndRef = React.useRef<HTMLDivElement>(null)

    const [fullScreenLive, setFullScreenLive] = React.useState(false)

    const { id } = useParams<{ id?: string }>()

    // Scroll To Bottom in Messages
    React.useEffect(() => {
        if (messagesEndRef.current) {
            const scrollHeight = messagesEndRef.current.scrollHeight
            const height = messagesEndRef.current.clientHeight
            const maxScrollTop = scrollHeight - height
            messagesEndRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0
        }
    }, [chat.state.chatMessages])

    React.useEffect(() => {
        setChatActivate(chat.state.chatAvailability)
    }, [chat.state.chatAvailability])

    React.useEffect(() => {
        setViewer(chat.state.onlineUsers > 0 ? chat.state.onlineUsers - 1 : 0)
    }, [chat.state.onlineUsers])

    React.useEffect(() => {
        setHearts(chat.state.likes)
    }, [chat.state.likes])

    const closeModal = React.useCallback(() => {
        onComplete && onComplete()
    }, [onComplete])

    const sendMsg = () => {
        if (isChatActivate && message.length > 1 && message.length < maxMessageLength + 1) {
            sendMessage(message)
            setMessage('')
        }
    }

    const chatActivateHandler = () => {
        setChatActivate(x => {
            closeChat(!x)
            return !x
        })
    }

    const chatLikeHandler = () => {
        setHearts(x => x + 1)
        sendLike()
    }

    const renderCloseButton = React.useMemo(() => {
            if (live.owner) {
                return <Button type={'danger-4'} variation={'default'} size={'xSmall'} onClick={closeModal}>Bitir</Button>
            }

            return <Button type={'black-opacity'} variation={'icon'} size={'xSmall'} onClick={closeModal}><CloseLine /></Button>
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [live.owner])

    const renderAvatarButton = React.useMemo(() => {
        return (
            <div className={styles.topLeftArea}>
                <Button type={'black-opacity'} size={'xSmall'} unClickable>{<Avatar size={1.5} user={live.host} rounded />}
                    <Text className={styles.avatarText} type={'white'}>{live.host.name}</Text>
                </Button>

                <Button type={'danger-4'} variation={'default'} size={'xSmall'} unClickable>{live.type}</Button>
            </div>
        )
    }, [live.host, live.type])

    const renderInput = React.useMemo(() => {
            if (!isChatActivate) {
                return <div />
            }
            return (
                <Input disabled={chat.state.connectionState !== HubConnectionState.Connected} value={message} onChangeText={setMessage} enterKeyPress={sendMsg}
                       maxLength={maxMessageLength} trailingComponent={(
                    <div className={styles.sendButton} onClick={sendMsg}>
                        <div><SendPlaneFill /></div>
                    </div>
                )} />
            )
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [message, isChatActivate, chat.state.connectionState])

    const renderChatButton = React.useMemo(() => {
            if (live.owner) {
                return <Button type={isChatActivate ? 'black-opacity' : 'danger'} style={{ zIndex: 2 }} variation={'default'} onClick={chatActivateHandler}>{isChatActivate ?
                    <ChatOffLine /> :
                    <Chat4Line />}</Button>
            }

            return <Button type={'black-opacity'} style={{ zIndex: 2 }} variation={'default'} size={'large'} onClick={chatLikeHandler}><HeartFill /></Button>
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [live.owner, isChatActivate])

    const renderLikes = React.useMemo(() => {
        return <FloatingHearts count={hearts} />
    }, [hearts])

    const handleHideClick = ((hide: boolean, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault()
        if (event.target === event.currentTarget) {
            setHideAll(hide)
        } else {
            setHideAll(false)
        }
    })

    return (
        <div className={styles.container}>
            <div className={classnames(styles.wrapper, { [styles.fullScreen]: fullScreenLive && !isMobile })} style={{ height: height ?? '100vh' }}>

                <div className={classnames(styles.video, { [styles.fullScreen]: fullScreenLive && !isMobile })}>
                    {live.owner ? (
                        <AgoraHost room={live} onlyVideo={hideAll} onClick={() => setHideAll(x => !x)} />
                    ) : (
                        <AgoraAudience room={live} onlyVideo={hideAll} onClick={() => setHideAll(x => !x)} />
                    )}
                </div>

                <div className={classnames(styles.chat, {
                    [styles.hideAll]: hideAll && (fullScreenLive || isMobile),
                    [styles.notHideAll]: !hideAll && (fullScreenLive || isMobile),
                }, { [styles.fullScreen]: fullScreenLive && !isMobile })}
                     onClick={(event) => handleHideClick(!hideAll, event)}>
                    <div className={styles.topLeft}>
                        {renderAvatarButton}
                    </div>
                    <div className={styles.topRight}>

                        {!isMobile && (<ToggleButton active={fullScreenLive} onChange={() => setFullScreenLive(x => !x)} inactiveIcon={<PictureInPictureExit />}
                                                     activeIcon={<PictureInPicture />} />)}

                        <Button type={'black-opacity'} size={'xSmall'} unClickable>{<EyeLine style={{ marginRight: '0.5rem' }} />}{numberFormat(viewer)}</Button>

                        {renderCloseButton}

                    </div>

                    {isChatActivate && (
                        <div className={styles.chatArea} ref={messagesEndRef}>
                            {chat.state.chatMessages.map((msg) => (
                                <ChatCard message={msg} key={msg.messageTime + 'msg'} />
                            ))}
                        </div>
                    )}

                    {chat.state.connectionState !== HubConnectionState.Connected && <div className={styles.reConnect}>
                        <Button type={'warning-fill'} size={'xSmall'} loading={chat.state.connectionState === HubConnectionState.Connecting} onClick={() => {
                            chat.client?.Connect().then(() => {
                                chat.methods.joinToRoom(id!)
                            })
                        }}>Sohbet Bağlantısı Kesildi. Yeniden Bağlanmak İçin Dokun
                        </Button>
                    </div>}

                    <div className={styles.bottom}>
                        {renderInput}
                        {renderChatButton}
                        {renderLikes}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LiveModal
