import moment from 'moment'
import * as React from 'react'
import Config from '../config'
import { UserRole } from 'types'
import useApi from 'hooks/useApi'
import Moment from 'react-moment'
import classnames from 'classnames'
import useAuth from 'hooks/useAuth'
import useQuery from 'hooks/useQuery'
import useMedia from 'hooks/useMedia'
import Calendar from 'react-calendar'
import { toast } from 'react-toastify'
import Text from 'components/atoms/text'
import { getUserRole } from 'utils/auth'
import { TailSpin, ThreeDots } from 'react-loader-spinner'
import Input from 'components/atoms/input'
import { Helmet } from 'react-helmet-async'
import Card from 'components/molecules/card'
import Divider from 'components/atoms/divider'
import { roundHalf } from 'utils/number-helpers'
import { AuthContextProps } from 'providers/auth'
import ErrorBox from 'components/atoms/error-box'
import { ApiContextInterface } from 'providers/api'
import ErrorCard from 'components/organisms/error-card'
import { useHistory, useParams } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import ActivityCard from 'components/molecules/activity-card'
import { CalendarTodoLine, FileList3Line, FireLine, FlagLine, MailCheckLine, StarFill, User3Line, UserHeartLine } from 'components/icons'
import MyProfileTemplate from 'components/templates/my-profile'
import Modal, { ModalSize, ModalType } from 'components/atoms/modal'
import ImageWithFallback from 'components/atoms/image-with-fallback'
import NewPasswordCard from 'components/molecules/new-password-card'
import AuthModalNavbar from 'components/molecules/auth-modal-navbar'
import ConfirmModalCard from 'components/organisms/confirm-modal-card'
import InputWithActions from 'components/molecules/input-with-actions'
import ProfileNavigation from 'components/organisms/profile-navigation'
import SimpleImageSelector from 'components/atoms/simple-image-selector'
import { ActivityListItem, InfinityPagination } from '@tetahq/bironbir_api_client'

import styles from 'components/templates/my-profile/style.module.css'

const MyProfileScreen: React.FC = () => {
    const api = useApi() as ApiContextInterface
    const auth = useAuth() as AuthContextProps
    const params = useQuery()
    const history = useHistory()
    const userRole = getUserRole()
    const { page } = useParams<{ page?: string }>()
    const isMobile = useMedia('(max-width: 1024px)')

    const [apiLoading, setApiLoading] = React.useState<boolean>(false)

    const [passwordModalShown, setPasswordModal] = React.useState<boolean>(false)
    const [showExportModal, setExportModal] = React.useState<boolean>(false)
    const [showDeleteMyAccountModal, setShowDeleteMyAccountModal] = React.useState<boolean>(false)

    const [activities, setActivities] = React.useState<ActivityListItem[]>([])
    const [activitiesPagination, setActivitiesPagination] = React.useState<InfinityPagination>({ isNextPageAvailable: false })
    const [activeTab, setActiveTab] = React.useState<number>(0)

    const [height, setHeight] = React.useState('')
    const [weight, setWeight] = React.useState('')
    const [description, setDescription] = React.useState('')
    const [age, setAge] = React.useState<Date | undefined>(undefined)
    const [showDateModal, setShowDateModal] = React.useState<boolean>(false)

    const [avatarLoading, setAvatarLoading] = React.useState<boolean>(false)
    const [heightLoading, setHeightLoading] = React.useState<boolean>(false)
    const [weightLoading, setWeightLoading] = React.useState<boolean>(false)
    const [birthDateLoading, setBirthDateLoading] = React.useState<boolean>(false)
    const [descriptionLoading, setDescriptionLoading] = React.useState<boolean>(false)

    React.useEffect(() => {
        const getParam = params.get('sifre')
        if (getParam === 'degistir') {
            setPasswordModal(true)
        } else {
            setPasswordModal(false)
        }
    }, [params])

    React.useEffect(() => {
        const getParam = params.get('hesap')
        switch (getParam) {
            case 'bilgilerimi-disari-aktar':
                setExportModal(true)
                break
            case 'hesabimi-sil':
                setShowDeleteMyAccountModal(true)
                break
            default:
                setExportModal(false)
                setShowDeleteMyAccountModal(false)
                break
        }
    }, [params])

    React.useEffect(() => {
        if (auth.user) {
            setWeight(auth.user.weight?.toString() ?? '')
            setHeight(auth.user.height?.toString() ?? '')
            setDescription(auth.user.description ?? '')
            setAge(auth.user.birthdate ? new Date(auth.user.birthdate) : undefined)
        }
    }, [auth.user])

    React.useEffect(() => {
        if (typeof page === 'undefined' && auth.isLoggedIn) {
            (async () => {
                setApiLoading(true)
                try {
                    const { data } = await api.Account.listMyActivities()
                    setActivities(data.activities)
                    setActivitiesPagination(data.pagination)
                } catch {
                }
                setApiLoading(false)
            })()
        } else {
            setActivities([])
        }
    }, [page, api.Account, auth.isLoggedIn])

    const exportModalConfirm = async () => {
        setApiLoading(true)
        try {
            await api.System.exportAccountData()
            params.delete('hesap')
            history.replace({ search: params.toString() })
            toast.success('İndirme talebiniz alındı.')
        } catch {
        }
        setApiLoading(false)
    }

    const deleteMyAccountConfirm = async () => {
        setApiLoading(true)
        try {
            await api.Account.deleteMyAccount()
            params.delete('hesap')
            history.replace({ search: params.toString() })
            auth.logout?.()
            toast.success('Hesabınız Silindi.')
        } catch {
        }
        setApiLoading(false)
    }

    const newPasswordConfirm = async (newPassword: string) => {
        setApiLoading(true)
        try {
            await api.Account.changeMyPassword({ newPassword })
            toast.success('Şifreniz Başarıyla Değiştirildi')
            params.delete('sifre')
            history.replace({ search: params.toString() })
        } catch {
        }
        setApiLoading(false)
    }

    const renderActivities = React.useMemo(() => {
        if (!auth.user) {
            return <></>
        }

        const loadNextActivities = async () => {
            try {
                const { data } = await api.Account.listMyActivities(activitiesPagination.firstIdOfNextPage)
                setActivities(x => [...x, ...data.activities])
                setActivitiesPagination(data.pagination)
            } catch {
            }
        }

        return (
            <div>
                {!isMobile && (
                    <Card type={'light'}>
                        <Text size='xl' weight={'medium'}>Aktivite</Text>
                    </Card>
                )}
                <InfiniteScroll
                    next={loadNextActivities}
                    hasMore={activitiesPagination.isNextPageAvailable as boolean}
                    loader={<div className={styles.center}><ThreeDots color={'#334362'} height={50} width={50} /></div>}
                    dataLength={activities.length}
                    endMessage={<ErrorBox style={{ marginTop: '1rem' }} text={'Daha Fazla Aktivite Yok'} size={'sm'} align={'center'} />}
                >
                    <Card type={'light'} paddingles>
                        {activities.map((activity, i) => (
                            <ActivityCard activity={activity} isLastItem={i === activities.length - 1} key={activity.id + 'activity'} />
                        ))}
                    </Card>
                </InfiniteScroll>
            </div>
        )
    }, [auth.user, activities, activitiesPagination, isMobile, api.Account])

    const renderAbout = React.useMemo(() => {
            if (!auth.user) {
                return <></>
            }

            return (
                <div>
                    {userRole === UserRole.ROLE_PERSONAL_TRAINER && (
                        <div className={styles.descriptionCard}>
                            <Card type={'light'}>
                                {!isMobile && (
                                    <>
                                        <Text size='xl' weight={'medium'}>Kısa Açıklama</Text>
                                        <Divider />
                                    </>
                                )}
                                <Text type={'soft-blue'} weight={'medium'}>{auth.user.description}</Text>
                            </Card>
                        </div>
                    )}

                    <Card type={'light'}>
                        {!isMobile && (
                            <>
                                <Text size='xl' weight={'medium'}>Hakkında</Text>
                                <Divider />
                            </>
                        )}

                        <div className={styles['about-card-wrapper']}>
                            <div className={styles['about-card']}>
                                <User3Line />
                                <Text type={'default'} weight={'medium'}>Kullanıcı ID</Text>
                                <Text type={'soft-blue'} weight={'medium'}>{auth.user.id}</Text>
                            </div>

                            <div className={styles['about-card']}>
                                <FileList3Line />
                                <Text type={'default'} weight={'medium'}>Kayıt Tarihi</Text>
                                <Text type={'soft-blue'} weight={'medium'}><Moment format={'DD.MM.YYYY'}>{auth.user.accountCreatedAt}</Moment></Text>
                            </div>

                            <div className={styles['about-card']}>
                                <MailCheckLine />
                                <Text type={'default'} weight={'medium'}>E Posta</Text>
                                <Text type={'soft-blue'} weight={'medium'}>{auth.user.email}</Text>
                            </div>

                            {auth.user.currentPremiumStartedAt && <div className={styles['about-card']}>
                                <FireLine />
                                <Text type={'default'} weight={'medium'}>Premium Başlangıç</Text>
                                <Text type={'soft-blue'} weight={'medium'}><Moment format={'DD.MM.YYYY'}>{auth.user.currentPremiumStartedAt}</Moment></Text>
                            </div>}

                            {auth.user.currentPremiumEndsAt && <div className={styles['about-card']}>
                                <FlagLine />
                                <Text type={'default'} weight={'medium'}>Premium Bitiş</Text>
                                <Text type={'soft-blue'} weight={'medium'}><Moment format={'DD.MM.YYYY'}>{auth.user.currentPremiumEndsAt}</Moment></Text>
                            </div>}

                            <div className={styles['about-card']}>
                                <CalendarTodoLine />
                                <Text type={'default'} weight={'medium'}>Doğum Tarihi</Text>
                                <Text type={'soft-blue'} weight={'medium'}>{auth.user.birthdate ? <Moment format={'DD.MM.YYYY'}>{auth.user.birthdate}</Moment> : 'Belirlenmedi'}</Text>
                            </div>

                            <div className={styles['about-card']}>
                                <UserHeartLine />
                                <Text type={'default'} weight={'medium'}>Boy</Text>
                                <Text type={'soft-blue'} weight={'medium'}>{auth.user.height ?? 'Belirlenmedi'}</Text>
                            </div>

                            <div className={styles['about-card']}>
                                <UserHeartLine />
                                <Text type={'default'} weight={'medium'}>Kilo</Text>
                                <Text type={'soft-blue'} weight={'medium'}>{auth.user.weight ?? 'Belirlenmedi'}</Text>
                            </div>
                        </div>
                    </Card>
                </div>
            )
        }
        , [auth, isMobile, userRole])

    const renderUpdateMyProfile = React.useMemo(() => {

        if (typeof auth.user === 'undefined') {
            return <></>
        }

        const updateAvatar = async (image: string) => {
            setAvatarLoading(true)
            try {
                const { data } = await api.Account.updateMyAvatar({ newAvatar: image })
                toast.success('Avatarınız Güncellendi')
                auth.setUser?.((x: any) => {
                    if (x && x.avatar) {
                        x.avatar.avatar1X = data.avatars.avatar1X
                        x.avatar.avatar2X = data.avatars.avatar2X
                        x.avatar.avatar3X = data.avatars.avatar3X
                        x.avatar.avatar4X = data.avatars.avatar4X
                    }
                    return x
                })
            } catch {
                console.log(image)
            }
            setAvatarLoading(false)
        }

        const updateHeight = async () => {
            setHeightLoading(true)
            try {
                await api.Account.updateMyHeight({ newHeight: parseInt(height) })
                toast.success('Boyunuz Güncellendi')
                auth.setUser?.((x: any) => {
                    if (x) {
                        x.height = parseInt(height)
                    }
                    return x
                })
            } catch {
                toast.error('Boyunuz Güncellenemedi, Tekrar Deneyin')
            }
            setHeightLoading(false)
        }

        const updateWeight = async () => {
            setWeightLoading(true)
            try {
                await api.Account.updateMyWeight({ newWeight: parseInt(weight) })
                toast.success('Kilonuz Güncellendi')
                auth.setUser?.((x: any) => {
                    if (x) {
                        x.weight = parseInt(weight)
                    }
                    return x
                })
            } catch {
                toast.error('Kilonuz Güncellenemedi, Tekrar Deneyin')
            }
            setWeightLoading(false)
        }

        const updateBirthdate = async (date: Date) => {
            setBirthDateLoading(true)
            try {
                await api.Account.updateMyBirtdate({ newBirthdate: moment(date).format('YYYY-MM-DD') })
                toast.success('Yaşınız Güncellendi')
                auth.setUser?.((x: any) => {
                    if (x) {
                        x.birthdate = moment(date).format('YYYY-MM-DD')
                    }
                    return x
                })
            } catch {
                toast.error('Yaşınız Güncellenemedi, Tekrar Deneyin')
            }
            setBirthDateLoading(false)
        }

        const updateDescription = async () => {
            setDescriptionLoading(true)
            try {
                await api.PersonalTrainer.updateMyDescription({ newDescription: description })
                toast.success('Açıklamanız Güncellendi')
                auth.setUser?.((x: any) => {
                    if (x) {
                        x.description = description
                    }
                    return x
                })
            } catch {
                toast.error('Açıklamanız Güncellenemedi, Tekrar Deneyin')
            }
            setDescriptionLoading(false)
        }

        return (
            <div>
                {!isMobile && (
                    <Card type={'light'}>
                        <Text size='xl' weight={'medium'}>Profilimi Güncelle</Text>
                    </Card>
                )}
                <div className={styles['update-my-profile']}>
                    <SimpleImageSelector loading={avatarLoading} onImageCropped={updateAvatar} type={'BASE64'} initialImage={auth.user.avatar?.avatar2X} />

                    <InputWithActions
                        onSubmit={updateHeight}
                        onReverse={() => {
                            if (auth.user && auth.user.height) {
                                setHeight(auth.user.height.toString())
                            }
                        }}
                        disableActions={height === null || height === '' || (parseInt(height) < 100 || parseInt(height) > 300)}
                        loading={heightLoading}
                        initialValue={auth.user.height?.toString()}
                        value={height}
                        onChangeText={setHeight}
                        label='Boyunuz (cm)'
                        type='numeric'
                        format='###'
                    />
                    {height && height !== '' && (parseInt(height) < 100 || parseInt(height) > 300) &&
                        <ErrorBox text={'Lütfen geçerli bir değer giriniz (100cm - 300cm)'} size={'sm'} align={'left'} />}

                    <InputWithActions
                        onSubmit={updateWeight}
                        onReverse={() => {
                            if (auth.user && auth.user.weight) {
                                setWeight(auth.user.weight.toString())
                            }
                        }}
                        disableActions={weight === null || weight === '' || (parseInt(weight) < 30 || parseInt(weight) > 300)}
                        loading={weightLoading}
                        value={weight}
                        initialValue={auth.user.weight?.toString()}
                        onChangeText={setWeight}
                        label='Kilonuz (kg)'
                        type='numeric'
                        format='###'
                    />
                    {weight && weight !== '' && (parseInt(weight) < 30 || parseInt(weight) > 300) &&
                        <ErrorBox text={'Lütfen geçerli bir değer giriniz (30kg - 300kg)'} size={'sm'} align={'left'} />}

                    <div onClick={() => setShowDateModal(x => !x)}>
                        <Input
                            className={styles.dateInput}
                            value={typeof age !== 'undefined' ? moment(age).format('YYYY-MM-DD') : ''}
                            label={'Doğum Tarihiniz'}
                            disabled
                            trailingComponent={(
                                birthDateLoading && <div className={styles.inputTrailing}><TailSpin color={'#334362'} height={28} width={32} /></div>
                            )}
                        />
                    </div>

                    {userRole === UserRole.ROLE_PERSONAL_TRAINER && (
                        <>
                            <InputWithActions
                                onSubmit={updateDescription}
                                onReverse={() => {
                                    if (auth.user && auth.user.description) {
                                        setDescription(auth.user.description)
                                    }
                                }}
                                disableActions={description.length < 1}
                                loading={descriptionLoading}
                                initialValue={auth.user.description}
                                value={description}
                                onChangeText={setDescription}
                                label='Hakkında Açıklaması'
                                maxLength={255}
                                maxCharCount={255}
                                rows={8}
                                multiline
                            />
                            {description.length < 1 &&
                                <ErrorBox text={'Lütfen bir açıklama giriniz'} size={'sm'} align={'left'} />
                            }
                        </>
                    )}

                    <Modal isVisible={showDateModal} onModalClose={() => setShowDateModal(false)} size={ModalSize.Small} type={ModalType.Centered} transparent>
                        <Calendar
                            locale={'tr-TR'}
                            value={age}
                            maxDate={moment().subtract(18, 'years').toDate()}
                            minDate={moment().subtract(100, 'years').toDate()}
                            defaultActiveStartDate={moment().subtract(18, 'years').toDate()}
                            className={styles.calendar}
                            onClickDay={(value) => {
                                setAge(value)
                                updateBirthdate(value)
                                setShowDateModal(false)
                            }}
                        />
                    </Modal>

                </div>
            </div>
        )
    }, [auth, showDateModal, age, height, weight, description, isMobile, heightLoading, weightLoading, descriptionLoading, birthDateLoading, avatarLoading, userRole, api.Account, api.PersonalTrainer])

    if (!auth.isLoggedIn) {
        return (
            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <ErrorCard type={'401'} />
            </div>
        )
    }

    return (
        <MyProfileTemplate>
            <Helmet>
                <title>{page === 'profilimi-guncelle' ? 'Profilimi Güncelle' : 'Profilim'} | {Config.app.name}</title>
            </Helmet>

            {/*Mobile View*/}

            {isMobile && <div className={styles['profile-card']}>

                {/*My Profile Page*/}

                {typeof page === 'undefined' &&
                    <>
                        <ImageWithFallback src={auth.user?.avatar?.avatar4X ?? ' '}
                                           fallbackSrc={`https://ui-avatars.com/api/?size=512&name=${auth.user?.name + ' ' + auth.user?.surname}`} />
                        <div className={styles['mobile-area']}>
                            <div className={styles['mobile-user-card']}>
                                <div>
                                    <Text weight={'medium'}>{auth.user?.name + ' ' + auth.user?.surname}</Text>
                                </div>
                                {userRole === UserRole.ROLE_CUSTOMER && auth.user?.isPremium && <div className={styles.badge}><StarFill /> Premium</div>}
                                {userRole === UserRole.ROLE_PERSONAL_TRAINER && <div className={styles.badge}><StarFill /> {roundHalf(auth.user?.rate)}</div>}
                            </div>

                            <div className={styles.tabs}>
                                <div onClick={() => setActiveTab(0)} className={classnames(styles.tab, { [styles.activeTab]: activeTab === 0 })}>Aktivite</div>
                                <div onClick={() => setActiveTab(1)} className={classnames(styles.tab, { [styles.activeTab]: activeTab === 1 })}>Hakkında</div>
                            </div>

                            {activeTab === 0 && renderActivities}

                            {activeTab === 1 && renderAbout}

                        </div>
                    </>
                }

                {/*Update My Profile Page*/}

                {page === 'profilimi-guncelle' && (
                    <div className={styles['web-update-profile']}>
                        {renderUpdateMyProfile}
                    </div>
                )}

            </div>}

            {/*Web View*/}

            {!isMobile && <div className={styles['web-container']}>
                <div className={styles['web-wrapper']}>

                    <ProfileNavigation
                        onNewPasswordClick={() => {
                            params.set('sifre', 'degistir')
                            history.replace({ search: params.toString() })
                        }}
                        onExportMyDataClick={() => {
                            params.set('hesap', 'bilgilerimi-disari-aktar')
                            history.replace({ search: params.toString() })
                        }}
                        onDeleteMyAccountClick={() => {
                            params.set('hesap', 'hesabimi-sil')
                            history.replace({ search: params.toString() })
                        }}
                    />

                    {/*My Profile Page*/}

                    {typeof page === 'undefined' && <div className={styles['web-activities']}>

                        {renderActivities}

                        {renderAbout}

                    </div>}

                    {/*Update My Profile Page*/}

                    {page === 'profilimi-guncelle' && (
                        <div className={styles['web-update-profile']}>
                            {renderUpdateMyProfile}
                        </div>
                    )}

                </div>
            </div>}

            {/*Modals*/}

            <Modal
                isVisible={passwordModalShown}
                onModalClose={() => {
                    params.delete('sifre')
                    history.replace({ search: params.toString() })
                }} size={ModalSize.Small}
            >
                <div className={styles.modalWrapper}>
                    <AuthModalNavbar
                        canClose={true}
                        canGoBack={false}
                        title={'Yeni Şifre'}
                        webTitle={'Yeni Şifre'}
                        description={'Lütfen yeni şifrenizi belirleyiniz.'}
                        onClose={() => {
                            params.delete('sifre')
                            history.replace({ search: params.toString() })
                        }}
                    />
                    <NewPasswordCard
                        loading={apiLoading}
                        onConfirm={newPasswordConfirm}
                        hideRecoverCode
                    />
                </div>
            </Modal>

            <ConfirmModalCard
                loading={apiLoading}
                isVisible={showExportModal}
                title='Bilgilerinizi Dışa Aktarın!'
                description='Bilgilerinizi toplamamız biraz zaman alıcak, hazır olduğunda mail ile bilgilendirileceksiniz.'
                confirmButtonText='Bilgilerimi İndir'
                onConfirmButtonClick={exportModalConfirm}
                onModalClose={() => {
                    params.delete('hesap')
                    history.replace({ search: params.toString() })
                }}
                onCancelButtonClick={() => {
                    params.delete('hesap')
                    history.replace({ search: params.toString() })
                }}
            />

            <ConfirmModalCard
                loading={apiLoading}
                isVisible={showDeleteMyAccountModal}
                title='Hesabımı Sil!'
                description='Bu işlem geri alınamaz! Emin misiniz?'
                confirmButtonText='Hesabımı Sil'
                onConfirmButtonClick={deleteMyAccountConfirm}
                onModalClose={() => {
                    params.delete('hesap')
                    history.replace({ search: params.toString() })
                }}
                onCancelButtonClick={() => {
                    params.delete('hesap')
                    history.replace({ search: params.toString() })
                }}
            />
        </MyProfileTemplate>
    )
}

export default MyProfileScreen
