import * as React from 'react'
import classnames from 'classnames'

import styles from './style.module.css'

export { styles }

export type MyProfileTemplateProps = {
    children: React.ReactNode
}

const MyProfileTemplate: React.FC<MyProfileTemplateProps> = ({ children }) => {
    return <div className={classnames(styles.container)}>{children}</div>
}

export default MyProfileTemplate
