import * as React from "react";

function SvgPageBanned(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={319.69}
      height={291.719}
      viewBox="0 0 319.69 291.719"
      {...props}
    >
      <path
        data-name="Path 7081"
        d="M2.11 266.214c-.993 0-1.591-.818-1.591-2.2V116.141L181.642 11.588v147.278a7.851 7.851 0 01-3.516 6.154L3.551 265.766a2.928 2.928 0 01-1.441.448z"
        fill="#fafafa"
      />
      <path
        data-name="Path 7082"
        d="M181.098 12.475v146.39a7.428 7.428 0 01-3.3 5.705L3.297 265.317a2.436 2.436 0 01-1.187.387c-.976 0-1.081-1.187-1.081-1.688V116.439L181.098 12.475m1.029-1.758L.003 115.841v148.175c0 1.758.879 2.716 2.11 2.716a3.446 3.446 0 001.7-.519L178.32 165.458a8.422 8.422 0 003.807-6.593V10.695z"
        fill="#e0e0e0"
      />
      <path
        data-name="Path 7083"
        d="M.005 115.841v24.747l182.121-105.15V10.695z"
        fill="#ff435b"
      />
      <path
        data-name="Path 7084"
        d="M14.285 117.16v3.516c0 .721.519 1.02 1.143.659l55.991-32.326v-6.152l-55.991 32.325a2.558 2.558 0 00-1.143 1.978z"
        fill="#fafafa"
      />
      <path
        data-name="Path 7085"
        d="M77.424 79.395c.633-.369 1.143-.07 1.143.659v3.516a2.541 2.541 0 01-1.143 1.978l-6 3.464v-6.155z"
        fill="#455a64"
      />
      <path
        data-name="Path 7086"
        d="M.003 107.868a8.422 8.422 0 013.807-6.593L178.32.519c2.1-1.213 3.807-.229 3.807 2.2v7.974L.003 115.841z"
        fill="#455a64"
      />
      <path
        data-name="Path 7087"
        d="M161.878 14.155a3.93 3.93 0 00-1.758 3.086c0 1.143.8 1.6 1.758 1.037a3.947 3.947 0 001.758-3.094c.042-1.152-.758-1.601-1.758-1.029z"
        fill="#ff435b"
      />
      <path
        data-name="Path 7088"
        d="M167.577 10.883a3.93 3.93 0 00-1.758 3.086c0 1.143.8 1.6 1.758 1.037a3.965 3.965 0 001.758-3.094c0-1.143-.809-1.6-1.758-1.029z"
        fill="#ff435b"
      />
      <path
        data-name="Path 7089"
        d="M173.194 7.614a3.93 3.93 0 00-1.758 3.086c0 1.143.8 1.6 1.758 1.037a3.965 3.965 0 001.758-3.094c.027-1.143-.773-1.6-1.758-1.029z"
        fill="#ff435b"
      />
      <g data-name="Group 6537" opacity={0.1}>
        <path
          data-name="Path 7090"
          d="M124.983 51.543a8.633 8.633 0 00-3.894 6.769c0 2.488 1.758 3.516 3.894 2.251a8.615 8.615 0 003.9-6.76c.012-2.488-1.746-3.499-3.9-2.26z"
        />
        <path
          data-name="Path 7091"
          d="M107.137 61.855a8.615 8.615 0 00-3.9 6.76c0 2.488 1.758 3.516 3.9 2.259a8.633 8.633 0 003.894-6.769c0-2.487-1.74-3.481-3.894-2.25z"
        />
        <path
          data-name="Path 7092"
          d="M142.838 41.283a8.615 8.615 0 00-3.895 6.759c0 2.488 1.758 3.516 3.894 2.251a8.606 8.606 0 003.9-6.76c.004-2.531-1.737-3.542-3.899-2.25z"
        />
        <path
          data-name="Path 7093"
          d="M160.702 30.91a8.615 8.615 0 00-3.9 6.76c0 2.488 1.758 3.516 3.9 2.251a8.615 8.615 0 003.9-6.76c.003-2.488-1.747-3.499-3.9-2.251z"
        />
      </g>
      <path
        data-name="Path 7094"
        d="M18.127 142.514l42.382-24.475c2.1-1.213 3.807-.229 3.807 2.2v40.694a8.422 8.422 0 01-3.807 6.593L18.127 191.99c-2.1 1.213-3.807.229-3.807-2.2v-40.683a8.422 8.422 0 013.807-6.593z"
        fill="#e0e0e0"
      />
      <path
        data-name="Path 7095"
        d="M78.786 107.464l88.79-51.235c2.1-1.222 3.807-.237 3.807 2.2v40.68a8.422 8.422 0 01-3.807 6.593l-88.79 51.235c-2.1 1.213-3.807.229-3.807-2.2v-40.68a8.422 8.422 0 013.807-6.593z"
        fill="#e0e0e0"
      />
      <path
        data-name="Path 7096"
        d="M18.127 204.359l67.375-38.9c2.11-1.213 3.815-.352 3.815 1.925a8.167 8.167 0 01-3.807 6.321l-67.384 38.9c-2.1 1.213-3.807.352-3.807-1.925a8.167 8.167 0 013.808-6.321z"
        fill="#e0e0e0"
      />
      <path
        data-name="Path 7097"
        d="M18.127 218.794l67.375-38.9c2.11-1.213 3.815-.352 3.815 1.925a8.167 8.167 0 01-3.807 6.321l-67.384 38.9c-2.1 1.213-3.807.352-3.807-1.925a8.167 8.167 0 013.808-6.321z"
        fill="#e0e0e0"
      />
      <path
        data-name="Path 7098"
        d="M18.127 233.221l67.375-38.9c2.11-1.213 3.815-.352 3.815 1.925a8.167 8.167 0 01-3.807 6.321l-67.384 38.9c-2.1 1.213-3.807.352-3.807-1.925a8.167 8.167 0 013.807-6.321z"
        fill="#e0e0e0"
      />
      <path
        data-name="Path 7099"
        d="M49.995 184.289a5.073 5.073 0 012.286-3.956l116.843-67.454a5.046 5.046 0 014.571 0l2.576 1.486a5.046 5.046 0 012.277 3.956v89.564a5.064 5.064 0 01-2.277 3.956L59.445 279.295a5.046 5.046 0 01-4.571 0l-2.576-1.486a5.064 5.064 0 01-2.286-3.956z"
        fill="#ff435b"
      />
      <path
        data-name="Path 7100"
        d="M50.654 182.038l6.488 3.745 120.729-69.714a4.809 4.809 0 00-1.609-1.714l-2.532-1.477a5.011 5.011 0 00-4.554 0L52.283 180.331a4.73 4.73 0 00-1.629 1.707z"
        fill="#fff"
        opacity={0.4}
      />
      <path
        data-name="Path 7101"
        d="M49.995 184.292v89.564a5.064 5.064 0 002.286 3.956l2.576 1.486a3.369 3.369 0 00.624.281 5.086 5.086 0 00.941.22 5.626 5.626 0 001.8-.07 3.341 3.341 0 00.448-.114l.273-.105a1.389 1.389 0 01-1.1 0 1.582 1.582 0 01-.694-1.5v-89.59a2.013 2.013 0 011.187-1.952l-1.187-.686-6.5-3.745a4.677 4.677 0 00-.654 2.255z"
        opacity={0.1}
      />
      <path
        data-name="Path 7102"
        d="M57.151 185.783l54.109 20.22a8.791 8.791 0 0010.435-3.437l8.686-13.31 47.929-73.432-46.2 73.933 46.435 18.171a4.72 4.72 0 01-.712 2.277l-45.846-20.22-10.125 16.13a8.791 8.791 0 01-11.006 3.376l-7.191-3.174-45.819 73.2a1.582 1.582 0 01-.694-1.5v-1.486l46.312-70.268z"
        opacity={0.2}
      />
      <path
        data-name="Path 7103"
        d="M153.554 82.963v205.625c0 2.426 1.705 3.411 3.807 2.2l92.377-53.336a8.422 8.422 0 003.807-6.593V25.232c0-2.426-1.705-3.411-3.807-2.2l-92.377 53.336a8.4 8.4 0 00-3.807 6.593z"
        fill="#fafafa"
        stroke="#e0e0e0"
        strokeMiterlimit={10}
        strokeWidth={0.797}
      />
      <path
        data-name="Path 7104"
        d="M158.905 86.787a2.1 2.1 0 01.993-1.6l10.549-6.075c.545-.316.985-.105.985.466a2.092 2.092 0 01-.985 1.6l-10.542 6.075c-.548.316-1 .106-1-.466z"
        fill="#455a64"
      />
      <path
        data-name="Path 7105"
        d="M158.905 90.883a2.092 2.092 0 01.993-1.6l10.549-6.075c.545-.316.985-.105.985.457a2.092 2.092 0 01-.985 1.609l-10.542 6.067c-.548.314-1 .114-1-.458z"
        fill="#455a64"
      />
      <path
        data-name="Path 7106"
        d="M158.905 94.98a2.092 2.092 0 01.993-1.6l10.549-6.075c.545-.316.985-.114.985.457a2.075 2.075 0 01-.985 1.6l-10.542 6.075c-.548.352-1 .114-1-.457z"
        fill="#455a64"
      />
      <path
        data-name="Path 7107"
        d="M246.406 42.25v-1.644a.114.114 0 00-.167-.105l-1.266.571a.149.149 0 01-.211-.1 2.7 2.7 0 00-.281-.624.343.343 0 010-.308l.879-1.705a.185.185 0 00-.079-.246l-.879-.527a.193.193 0 00-.246.062l-1.055 1.582a.325.325 0 01-.273.132 3.13 3.13 0 00-.686.07.149.149 0 01-.193-.132l-.141-1.38a.1.1 0 00-.167-.088l-1.424.818a.378.378 0 00-.167.264l-.141 1.582a.572.572 0 01-.15.3 7.916 7.916 0 00-.791.879.264.264 0 01-.281.079l-.879-.316a.22.22 0 00-.255.1l-1.046 1.811a.229.229 0 00.044.264l.738.633a.281.281 0 01.07.281 8.846 8.846 0 00-.334 1.108.545.545 0 01-.176.273l-1.3.879a.387.387 0 00-.149.281v1.635a.106.106 0 00.167.105l1.266-.571a.158.158 0 01.211.105 2.7 2.7 0 00.281.624.343.343 0 010 .308l-.879 1.7a.2.2 0 00.079.255l.879.527a.193.193 0 00.246-.062l1.055-1.582a.325.325 0 01.273-.132 3.129 3.129 0 00.686-.07.149.149 0 01.193.132l.141 1.38c0 .1.079.141.167.088l1.424-.818a.4.4 0 00.167-.273l.141-1.582a.518.518 0 01.149-.29 7.91 7.91 0 00.791-.879.264.264 0 01.281-.079l.879.316a.22.22 0 00.255-.1l1.046-1.811a.229.229 0 00-.053-.273l-.73-.624a.281.281 0 01-.07-.281 8.842 8.842 0 00.334-1.108.475.475 0 01.176-.273l1.3-.879a.4.4 0 00.151-.282zm-5.565 5.187c-1.327.774-2.4.149-2.4-1.38a5.275 5.275 0 012.4-4.167c1.327-.765 2.4-.141 2.4 1.389a5.275 5.275 0 01-2.4 4.158z"
        fill="#37474f"
      />
      <path
        data-name="Path 7108"
        d="M203.55 82.778c10.8-6.233 19.543-1.178 19.543 11.288s-8.747 27.613-19.543 33.846-19.543 1.178-19.543-11.279 8.747-27.622 19.543-33.855z"
        fill="#455a64"
      />
      <path
        data-name="Path 7109"
        d="M203.418 92.589c3.516-2.022 6.338-.387 6.338 3.657a14.013 14.013 0 01-6.338 10.971c-3.516 2.022-6.347.387-6.347-3.657a14 14 0 016.347-10.971z"
        fill="#ff435b"
      />
      <path
        data-name="Path 7110"
        d="M215.928 114.145c0-6.637-4.668-9.336-10.418-6.013l-3.648 2.075c-5.749 3.323-10.418 11.428-10.418 18.048v1.82a10.928 10.928 0 003.358.519 17.706 17.706 0 008.712-2.637 38.514 38.514 0 0012.413-12.264z"
        fill="#ff435b"
      />
      <path
        data-name="Path 7111"
        d="M185.915 146.452l35.288-20.369c1.046-.607 1.9-.176 1.9.967a4.088 4.088 0 01-1.9 3.156l-35.279 20.369c-1.055.615-1.908.176-1.908-.958a4.079 4.079 0 011.899-3.165z"
        fill="#ff435b"
      />
      <path
        data-name="Path 7112"
        d="M182.125 161.027l42.848-24.8c1.055-.607 1.908-.176 1.908.967a4.079 4.079 0 01-1.908 3.156l-42.848 24.8c-1.055.607-1.908.176-1.908-.967a4.079 4.079 0 011.908-3.156z"
        fill="#455a64"
      />
      <path
        data-name="Path 7113"
        d="M226.116 156.219l12-6.927c.633-.36 1.143-.193 1.143.378a2.3 2.3 0 01-1.143 1.688l-12 6.927c-.624.36-1.143.193-1.143-.369a2.321 2.321 0 011.143-1.697z"
        fill="#37474f"
      />
      <path
        data-name="Path 7114"
        d="M166.169 186.716l17.626-10.171c1.046-.607 1.9-.114 1.9 1.1v12.1a4.22 4.22 0 01-1.9 3.3l-17.626 10.163c-1.046.607-1.9.114-1.9-1.1v-12.1a4.22 4.22 0 011.9-3.292z"
        fill="#ff435b"
      />
      <path
        data-name="Path 7115"
        d="M168.982 188.404l12-6.927c.633-.36 1.143-.193 1.143.369a2.3 2.3 0 01-1.143 1.7l-12 6.927c-.633.36-1.143.193-1.143-.369a2.3 2.3 0 011.143-1.7z"
        fill="#fafafa"
      />
      <path
        data-name="Path 7116"
        d="M167.839 194.505v3.516c0 .73.51 1.02 1.143.659l12-6.927a2.523 2.523 0 001.143-1.978v-3.517c0-.73-.51-1.02-1.143-.659l-12 6.892a2.541 2.541 0 00-1.143 2.014z"
        fill="#fafafa"
      />
      <path
        data-name="Path 7117"
        d="M194.741 170.223l17.582-10.171c1.055-.607 1.908-.114 1.908 1.1v12.1a4.2 4.2 0 01-1.908 3.3l-17.582 10.171c-1.055.607-1.9.114-1.9-1.1v-12.1a4.193 4.193 0 011.9-3.3z"
        fill="#ff435b"
      />
      <path
        data-name="Path 7118"
        d="M197.554 171.911l12-6.927c.624-.36 1.134-.193 1.134.369a2.312 2.312 0 01-1.134 1.7l-12 6.927c-.633.36-1.143.193-1.143-.378a2.294 2.294 0 011.143-1.688z"
        fill="#fafafa"
      />
      <path
        data-name="Path 7119"
        d="M196.411 178.013v3.516c0 .73.51 1.02 1.143.659l12-6.927a2.541 2.541 0 001.134-1.978v-3.516c0-.73-.519-1.02-1.143-.659l-12 6.927a2.523 2.523 0 00-1.134 1.978z"
        fill="#fafafa"
      />
      <path
        data-name="Path 7120"
        d="M223.312 153.731l17.582-10.18c1.046-.607 1.9-.114 1.9 1.1v12.105a4.22 4.22 0 01-1.9 3.3l-17.582 10.171c-1.055.607-1.908.114-1.908-1.1v-12.1a4.2 4.2 0 011.908-3.296z"
        fill="#ff435b"
      />
      <path
        data-name="Path 7121"
        d="M226.116 155.421l12-6.883c.633-.369 1.143-.2 1.143.369a2.268 2.268 0 01-1.143 1.688l-12 6.927c-.624.369-1.143.2-1.143-.369a2.3 2.3 0 011.143-1.732z"
        fill="#fafafa"
      />
      <path
        data-name="Path 7122"
        d="M224.973 161.52v3.516c0 .721.519 1.02 1.143.659l12-6.936a2.514 2.514 0 001.143-1.978v-3.516c0-.73-.51-1.029-1.143-.659l-12 6.927a2.532 2.532 0 00-1.143 1.987z"
        fill="#fafafa"
      />
      <path
        data-name="Path 7123"
        d="M168.077 214.469l70.944-40.958c2.11-1.213 3.807-.352 3.807 1.925a8.141 8.141 0 01-3.807 6.317l-70.944 40.958c-2.1 1.213-3.807.352-3.807-1.925a8.167 8.167 0 013.807-6.317z"
        fill="#e0e0e0"
      />
      <path
        data-name="Path 7124"
        d="M168.077 230.97l70.944-40.958c2.11-1.213 3.807-.352 3.807 1.925a8.141 8.141 0 01-3.807 6.321l-70.944 40.967c-2.1 1.213-3.807.352-3.807-1.934a8.167 8.167 0 013.807-6.321z"
        fill="#e0e0e0"
      />
      <path
        data-name="Path 7125"
        d="M191.172 238.254l24.753-14.29c1.055-.607 1.908-.114 1.908 1.1v7.974a4.2 4.2 0 01-1.908 3.3l-24.756 14.294c-1.055.607-1.908.114-1.908-1.1v-7.978a4.2 4.2 0 011.908-3.3z"
        fill="#ff435b"
      />
      <path
        data-name="Path 7126"
        d="M169.624 215.691a3.947 3.947 0 00-1.758 3.094c0 1.134.8 1.6 1.758 1.029a3.947 3.947 0 001.758-3.094c.027-1.134-.773-1.6-1.758-1.029z"
        fill="#fafafa"
      />
      <path
        data-name="Path 7127"
        d="M175.289 212.421a3.93 3.93 0 00-1.758 3.094c0 1.134.791 1.6 1.758 1.029a3.947 3.947 0 001.758-3.095c.023-1.134-.777-1.617-1.758-1.028z"
        fill="#fafafa"
      />
      <path
        data-name="Path 7128"
        d="M180.938 209.195a3.947 3.947 0 00-1.758 3.094c0 1.134.8 1.6 1.758 1.029a3.947 3.947 0 001.758-3.094c.027-1.179-.773-1.645-1.758-1.029z"
        fill="#fafafa"
      />
      <path
        data-name="Path 7129"
        d="M186.6 205.88a3.939 3.939 0 00-1.758 3.094c0 1.134.8 1.6 1.758 1.029a3.947 3.947 0 001.758-3.094c.026-1.135-.774-1.601-1.758-1.029z"
        fill="#fafafa"
      />
      <path
        data-name="Path 7130"
        d="M192.252 202.61a3.947 3.947 0 00-1.758 3.094c0 1.134.8 1.6 1.758 1.029a3.947 3.947 0 001.758-3.094c.027-1.134-.773-1.6-1.758-1.029z"
        fill="#fafafa"
      />
      <path
        data-name="Path 7131"
        d="M197.905 199.34a3.947 3.947 0 00-1.758 3.094c0 1.134.8 1.6 1.758 1.029a3.93 3.93 0 001.758-3.094c.027-1.135-.765-1.601-1.758-1.029z"
        fill="#fafafa"
      />
      <path
        data-name="Path 7132"
        d="M203.566 196.069a3.947 3.947 0 00-1.758 3.094c0 1.134.8 1.6 1.758 1.029a3.947 3.947 0 001.758-3.094c.053-1.09-.773-1.6-1.758-1.029z"
        fill="#fafafa"
      />
      <path
        data-name="Path 7133"
        d="M169.624 232.201a3.965 3.965 0 00-1.758 3.094c0 1.143.8 1.6 1.758 1.029a3.93 3.93 0 001.758-3.086c.027-1.143-.773-1.6-1.758-1.037z"
        fill="#fafafa"
      />
      <path
        data-name="Path 7134"
        d="M175.289 228.93a3.938 3.938 0 00-1.758 3.094c0 1.143.791 1.6 1.758 1.029a3.93 3.93 0 001.758-3.086c.023-1.142-.777-1.599-1.758-1.037z"
        fill="#fafafa"
      />
      <path
        data-name="Path 7135"
        d="M180.938 225.66a3.965 3.965 0 00-1.758 3.094c0 1.143.8 1.6 1.758 1.029a3.93 3.93 0 001.758-3.086c.027-1.143-.773-1.609-1.758-1.037z"
        fill="#fafafa"
      />
      <path
        data-name="Path 7136"
        d="M186.6 222.381a3.947 3.947 0 00-1.758 3.095c0 1.143.8 1.6 1.758 1.029a3.93 3.93 0 001.758-3.086c.026-1.135-.774-1.6-1.758-1.038z"
        fill="#fafafa"
      />
      <path
        data-name="Path 7137"
        d="M192.252 219.12a3.947 3.947 0 00-1.758 3.094c0 1.143.8 1.6 1.758 1.029a3.93 3.93 0 001.758-3.086c.027-1.143-.773-1.609-1.758-1.037z"
        fill="#fafafa"
      />
      <path
        data-name="Path 7138"
        d="M197.905 215.849a3.947 3.947 0 00-1.758 3.094c0 1.143.8 1.6 1.758 1.029a3.912 3.912 0 001.758-3.094c.027-1.134-.765-1.6-1.758-1.029z"
        fill="#fafafa"
      />
      <path
        data-name="Path 7139"
        d="M203.566 212.579a3.947 3.947 0 00-1.758 3.094c0 1.143.8 1.6 1.758 1.029a3.938 3.938 0 001.758-3.094c.053-1.135-.773-1.601-1.758-1.029z"
        fill="#fafafa"
      />
      <path
        data-name="Path 7140"
        d="M282.907 249.748a17.1 17.1 0 01-8.659-2.286c-6.338-3.666-9.969-11.314-9.969-21.011 0-17.037 10.831-36.633 24.615-44.615 7.446-4.3 14.839-4.765 20.826-1.31 6.33 3.657 9.969 11.314 9.969 21 0 17.037-10.831 36.641-24.659 44.624a24.527 24.527 0 01-12.123 3.6zm18.163-59.235a12.975 12.975 0 00-6.022 1.978c-10.022 5.758-18.461 21.327-18.461 33.96 0 5.116 1.433 9 3.833 10.382 2.734 1.582 6.541-.141 8.554-1.31 10.048-5.793 18.523-21.362 18.523-34 0-5.116-1.433-8.993-3.833-10.382a4.994 4.994 0 00-2.593-.633z"
        fill="#455a64"
      />
      <path
        data-name="Path 7141"
        d="M309.65 204.254c0-9.574-6.031-13.108-6.031-13.108 2.4 1.389 3.833 5.275 3.833 10.382 0 12.633-8.475 28.2-18.523 34-2.013 1.169-5.811 2.892-8.554 1.31 0 0 5.09 3.165 12.123-.677 10.181-5.551 17.152-19.274 17.152-31.907z"
        fill="#37474f"
      />
      <path
        data-name="Path 7142"
        d="M299.98 84.615a6.347 6.347 0 00-12.686 0v100.843a3.174 3.174 0 001.855 2.637 9.943 9.943 0 008.967 0 3.165 3.165 0 001.864-2.637V84.631z"
        fill="#37474f"
      />
      <path
        data-name="Path 7143"
        d="M294.916 107.155a8.105 8.105 0 00-3.666-6.347 2.549 2.549 0 00-2.637-.229l-15.631 9.011-.378.22a.589.589 0 00-.158.1 5.49 5.49 0 00-.747.607 5.187 5.187 0 006.11 8.246l.3-.176 15.745-9.064a2.576 2.576 0 001.064-2.365z"
        fill="#455a64"
      />
      <path
        data-name="Path 7144"
        d="M294.916 132.404a8.105 8.105 0 00-3.666-6.338 2.532 2.532 0 00-2.637-.237l-18.338 10.549-.378.22a.685.685 0 00-.158.1 5 5 0 00-.747.607 5.2 5.2 0 001.16 8.246 5.274 5.274 0 002.479.615 5.169 5.169 0 002.47-.615c.105-.053.2-.123.3-.176l18.461-10.549a2.576 2.576 0 001.055-2.418z"
        fill="#455a64"
      />
    </svg>
  );
}

export default SvgPageBanned;
