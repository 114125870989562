import { createContext, Dispatch, FC, ReactNode, SetStateAction, useState } from 'react'

type PageContextProps = {
    leadIcon: ReactNode
    setLeadIcon: Dispatch<SetStateAction<ReactNode>>
    leadClick: any
    setLeadClick: Dispatch<SetStateAction<any>>
}

export const PageContext = createContext<Partial<PageContextProps>>({})

export const PageProvider: FC = ({ children }) => {
    const [leadIcon, setLeadIcon] = useState<ReactNode | undefined>(undefined)
    const [leadClick, setLeadClick] = useState(() => () => {
    })

    return <PageContext.Provider value={{ leadIcon, setLeadIcon, leadClick, setLeadClick }}>{children}</PageContext.Provider>
}
