import * as React from 'react'
import Config from '../config'
import classnames from 'classnames'
import { Helmet } from 'react-helmet-async'
import { Disclosure } from '@headlessui/react'
import { ArrowDownSLine, ArrowUpSLine } from 'components/icons'
import SSSTemplate, { styles } from 'components/templates/s-s-s'
import useAppInfo from '../hooks/useAppInfo'
import ReactMarkdown from 'react-markdown'

const SSSScreen: React.FC = () => {
  const appInfo = useAppInfo()

  return (
    <SSSTemplate>
      <Helmet>
        <title>Sıkça Sorulan Sorular | {Config.app.name}</title>
      </Helmet>

      <div className={styles.title}><h2>Sıkça Sorulan Sorular</h2></div>

      <div className={styles.area}>
        {appInfo.faqs?.map((faq, i) => {
          return <Disclosure as='div' className={styles.disclosure} key={i.toString() + '_faq'}>
            {({ open }) => (
              <>
                <Disclosure.Button className={classnames(styles.button, { [styles.open]: open })}>
                  <span>{faq.title}</span>
                  {open ? <ArrowUpSLine /> : <ArrowDownSLine />}
                </Disclosure.Button>
                <Disclosure.Panel className={styles.panel}>
                  <ReactMarkdown>{faq.content}</ReactMarkdown>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        })}
      </div>
    </SSSTemplate>
  )
}

export default SSSScreen
