import semver from 'semver'
import Config from './config'
import * as React from 'react'
import Providers from 'providers'
import useApi from 'hooks/useApi'
import useMedia from 'hooks/useMedia'
import HomeScreen from 'screens/home'
import LiveScreen from 'screens/live'
import SSSScreen from 'screens/s-s-s'
import useAppInfo from 'hooks/useAppInfo'
import { Device } from '@capacitor/device'
import ScrollToTop from './hooks/scrollToTop'
import NotFoundScreen from 'screens/not-found'
import * as CapacitorApp from '@capacitor/app'
import { Route, Switch } from 'react-router-dom'
import MyProfileScreen from 'screens/my-profile'
import TabBar from 'components/organisms/tab-bar'
import { ApiContextInterface } from 'providers/api'
import NotificationScreen from 'screens/notifications'
import ErrorCard from 'components/organisms/error-card'
import AuthModal from 'components/organisms/auth-modal'
import { SplashScreen } from '@capacitor/splash-screen'
import { StatusBar, Style } from '@capacitor/status-bar'
import PackageCourseScreen from 'screens/package-course'
import MyPerformanceScreen from 'screens/my-performance'
import PaymentStatusScreen from './screens/payment-status'
import PersonalTrainerScreen from 'screens/personal-trainer'
import PaymentModal from 'components/organisms/payment-modal'
import NotFoundTemplate from 'components/templates/not-found'
import NavigationBar from 'components/organisms/navigation-bar'
import BroadcastCalendarScreen from 'screens/broadcast-calendar'
import AdvertiseModal from 'components/organisms/advertise-modal'
import MultiLoginDetectScreen from './screens/multi-login-detect'
import { BroadcastFixtureScreen } from 'screens/broadcast-fixture'
import MembershipPackagesScreen from 'screens/membership-packages'
import PackageCoursePreviewScreen from 'screens/package-course-preview'
import { AppStatusQueryResult } from '@tetahq/bironbir_api_client/models'
// import * as Sentry from '@sentry/capacitor'

const capacitorApp = CapacitorApp.App

export default function App() {
    const api = useApi() as ApiContextInterface
    const appInfo = useAppInfo()
    const isMobile = useMedia('(max-width: 1024px)')

    const [loading, setLoading] = React.useState(true)
    const [updateRequired, setUpdateRequired] = React.useState(false)

    React.useEffect(() => {
            (async () => {
                setLoading(true)

                if ((window as any).plugins) {
                    const anyWin = (window as any)
                    anyWin.plugins.OneSignal.setLogLevel({ logLevel: 6, visualLevel: 0 })

                    const notificationOpenedCallback = function(jsonData: any) {
                        console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData))
                    }

                    const iosSettings: any = {
                        kOSSettingsKeyAutoPrompt: false,
                        kOSSettingsKeyInAppLaunchURL: false,
                    }

                    try {
                        anyWin.plugins.OneSignal
                            .startInit(Config.oneSignal.appId)
                            .handleNotificationOpened(notificationOpenedCallback)
                            .iOSSettings(iosSettings)
                            .inFocusDisplaying(anyWin.plugins.OneSignal.OSInFocusDisplayOption.Notification)
                            .endInit()

                        anyWin.plugins.OneSignal.promptForPushNotificationsWithUserResponse()

                        anyWin.plugins.OneSignal.addSubscriptionObserver(async (state: any) => {
                            if (!state.from.subscribed && state.to.subscribed) {
                                appInfo.setPlayerId?.(state.to.userId)
                            }
                        })
                    } catch (e) {
                        console.log(e)
                    }
                }

                const { platform } = await Device.getInfo()

                if (platform !== 'web') {
                    if (platform === 'ios') {
                        await StatusBar.setStyle({ style: Style.Light })
                    }

                    await SplashScreen.show({
                        autoHide: false,
                    })
                }

                // if (platform === 'android') {
                //     await StatusBar.setOverlaysWebView({ overlay: true })
                // }

                let appStatus: null | AppStatusQueryResult = null

                try {
                    const { data } = await api.AppInfo.getAppStatus()
                    appStatus = data
                    // Sentry.captureMessage(JSON.stringify(data))
                    appInfo.setCurrentAndroidVersion?.(data.currentAndroidVersion)
                    appInfo.setCurrentIOSVersion?.(data.currentIOSVersion)
                    appInfo.setMinimumAndroidVersion?.(data.minimumAndroidVersion)
                    appInfo.setMinimumIOSVersion?.(data.minimumIOSVersion)
                    appInfo.setInMaintenance?.(data.inMaintenance)
                    appInfo.setFitnessTargetOptions?.(data.fitnessTargetOptions)
                    appInfo.setHearFromOptions?.(data.hearFromOptions)
                    appInfo.setFaqs?.(data.faqs)
                } catch {
                    appInfo.setInMaintenance?.(true)
                }

                if (appStatus !== null) {
                    if (platform !== 'web') {
                        try {
                            const { version } = await capacitorApp.getInfo()

                            if ((platform === 'android' && semver.lt(version, appStatus.minimumAndroidVersion!)) ||
                                (platform === 'ios' && semver.lt(version, appStatus.minimumIOSVersion!))) {
                                setUpdateRequired(true)
                            }
                        } catch {
                        }
                    }
                }

                try {
                    const { data } = await api.LegalDocument.getAllLegalDocuments()
                    if (data.legalDocuments) {
                        appInfo.setLegalDocuments?.(data.legalDocuments)
                    }
                } catch (e) {
                    console.log(e)
                }

                if (platform !== 'web') {
                    await SplashScreen.hide()
                    await StatusBar.show()

                    CapacitorApp.App.addListener('backButton', ({ canGoBack }) => {
                        if (!canGoBack) {
                            CapacitorApp.App.exitApp()
                        } else {
                            window.history.back()
                        }
                    })
                }

                setLoading(false)
            })()
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [])

    if (loading) return null

    if (updateRequired) return (
        <NotFoundTemplate fullPage>
            <title>Güncelleme Gerekiyor</title>
            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <ErrorCard type={'updateRequired'} />
            </div>
        </NotFoundTemplate>
    )

    if (appInfo.inMaintenance) return (
        <NotFoundTemplate fullPage>
            <title>Sistem Bakımda</title>
            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <ErrorCard type={'maintenance'} />
            </div>
        </NotFoundTemplate>
    )

    return (
        <Providers>
            <NavigationBar />
            <ScrollToTop />
            <Switch>
                <Route exact path='/'>
                    <HomeScreen />
                </Route>
                <Route exact path='/abonelik'>
                    <MembershipPackagesScreen />
                </Route>
                <Route exact path='/antrenmanlar'>
                    <PackageCourseScreen />
                </Route>
                <Route exact sensitive path='/antrenor/:id'>
                    <PersonalTrainerScreen />
                </Route>
                <Route exact path='/bildirimler'>
                    <NotificationScreen />
                </Route>
                <Route exact path='/canli-yayin-takvimi'>
                    <BroadcastFixtureScreen />
                </Route>
                <Route exact sensitive path='/ders-paketi/:id'>
                    <PackageCoursePreviewScreen />
                </Route>
                <Route exact sensitive path='/canli-yayin/:id'>
                    <LiveScreen />
                </Route>
                <Route exact path='/performans'>
                    <MyPerformanceScreen />
                </Route>
                <Route exact path='/profilim'>
                    <MyProfileScreen />
                </Route>
                <Route exact sensitive path='/profilim/:page'>
                    <MyProfileScreen />
                </Route>
                <Route exact path='/s-s-s'>
                    <SSSScreen />
                </Route>
                <Route exact path='/yayin-takvimi'>
                    <BroadcastCalendarScreen />
                </Route>
                <Route exact path='/odeme-basarili'>
                    <PaymentStatusScreen status={'success'} />
                </Route>
                <Route exact path='/odeme-basarisiz'>
                    <PaymentStatusScreen status={'error'} />
                </Route>
                <Route exact path='/supheli-giris'>
                    <MultiLoginDetectScreen />
                </Route>
                <Route>
                    <NotFoundScreen />
                </Route>
            </Switch>

            {isMobile && <TabBar />}

            <AuthModal />
            <AdvertiseModal />
            <PaymentModal />
        </Providers>
    )
}
