import * as React from 'react'
import Config from '../config'
import { UserRole } from 'types'
import useApi from 'hooks/useApi'
import useAuth from 'hooks/useAuth'
import classnames from 'classnames'
import useMedia from 'hooks/useMedia'
import { Link, useHistory } from 'react-router-dom'
import Text from 'components/atoms/text'
import { getUserRole } from 'utils/auth'
import { TailSpin, ThreeDots } from 'react-loader-spinner'
import Input from 'components/atoms/input'
import { Helmet } from 'react-helmet-async'
import useDebounce from 'hooks/useDebounce'
import Card from 'components/molecules/card'
import Avatar from 'components/atoms/avatar'
import { AuthContextProps } from 'providers/auth'
import { ApiContextInterface } from 'providers/api'
import ErrorCard from 'components/organisms/error-card'
import InfiniteScroll from 'react-infinite-scroll-component'
import { ArrowRightSLine, Search2Line } from 'components/icons'
import PerformanceCard from 'components/organisms/performance-card'
import MyPerformanceTemplate from 'components/templates/my-performance'
import { GetMyStatisticsQueryResult, GetStatisticsOfPersonalTrainerQueryResult, InfinityPagination, PersonalTrainerListItem, Statistics } from '@tetahq/bironbir_api_client'

import styles from 'components/templates/my-performance/style.module.css'

const MyPerformanceScreen: React.FC = () => {
    const api = useApi() as ApiContextInterface
    const auth = useAuth() as AuthContextProps
    const history = useHistory()
    const userRole = getUserRole()
    const isMobile = useMedia('(max-width: 1024px)')

    const [myStatistics, setMyStatistics] = React.useState<GetMyStatisticsQueryResult | undefined>(undefined)
    const [ptStatistics, setPtStatistics] = React.useState<GetStatisticsOfPersonalTrainerQueryResult | undefined>(undefined)
    const [statistic, setStatistic] = React.useState<Statistics | undefined>(undefined)

    const [selectedIndex, setSelectedIndex] = React.useState<number>(0)

    const [prefix, setPrefix] = React.useState('')
    const [selectedTrainer, setSelectedTrainer] = React.useState<PersonalTrainerListItem | undefined>(undefined)

    const [trainers, setTrainers] = React.useState<PersonalTrainerListItem[]>([])
    const [pagination, setPagination] = React.useState<InfinityPagination>({ isNextPageAvailable: false })

    const [loading, setLoading] = React.useState(false)

    const [query, setQuery] = React.useState<string>('')
    const debouncedQuery = useDebounce<string>(query, 500)

    const [activeTab, setActiveTab] = React.useState<number>(0)

    React.useEffect(() => {
            if (typeof query === 'undefined') return

            if (debouncedQuery !== '' && auth.isLoggedIn) {
                (async () => {
                    setLoading(true)
                    try {
                        const { data } = await api.PersonalTrainer.listPersonalTrainers(undefined, debouncedQuery)
                        if (data.personalTrainers && data.pagination) {
                            setTrainers(data.personalTrainers)
                            setPagination(data.pagination)
                        } else {
                            setTrainers([])
                        }
                    } catch {

                    }
                    setLoading(false)
                })()
            } else {
                setTrainers([])
            }
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [debouncedQuery, api.PersonalTrainer, auth.isLoggedIn])

    React.useEffect(() => {
        (async () => {
            if (auth.isLoggedIn) {
                try {
                    const { data } = await api.PersonalTrainer.getMyStatistics()
                    setMyStatistics(data)
                } catch {
                }
            }
        })()
    }, [api.PersonalTrainer, auth.isLoggedIn])

    React.useEffect(() => {
        (async () => {
            if (selectedTrainer && selectedTrainer.metadataId) {
                if (selectedTrainer.metadataId === auth.user?.metadataId) {
                    setPtStatistics(undefined)
                    setSelectedTrainer(undefined)
                    setActiveTab(0)
                    return undefined
                }

                try {
                    const { data } = await api.PersonalTrainer.getStatisticsOfPersonalTrainer(selectedTrainer.metadataId)
                    setPtStatistics(data)
                    setActiveTab(0)
                } catch {
                }
            }
        })()
    }, [selectedTrainer, api.PersonalTrainer, auth.user])

    React.useEffect(() => {
        const computedStatistic = ptStatistics ?? myStatistics
        if (!computedStatistic) return undefined
        switch (selectedIndex) {
            case 1:
                if (myStatistics && !ptStatistics) {
                    setStatistic(myStatistics.personalTrainerEarnings)
                    setPrefix('TL')
                }
                break
            case 2:
                setStatistic(computedStatistic.liveStreamCount)
                setPrefix('Canlı Yayın')
                break
            case 3:
                setStatistic(computedStatistic.liveStreamDuration)
                setPrefix('Dakika')
                break
            case 4:
                setStatistic(computedStatistic.burntCalories)
                setPrefix('Kalori')
                break
            case 5:
                setStatistic(computedStatistic.uniqueUsersJoinedToBroadcasts)
                setPrefix('Kişi')
                break
            case 6:
                setStatistic(computedStatistic.referenceCount)
                setPrefix('Kişi')
                break
            default:
                setStatistic(undefined)
                setPrefix('')
                break
        }
    }, [selectedIndex, myStatistics, ptStatistics])

    React.useEffect(() => {
        if (auth.isLoggedIn && userRole !== UserRole.ROLE_PERSONAL_TRAINER) {
            history.push({ pathname: '/' })
        }
    }, [history, auth, userRole])

    const renderCards = React.useMemo(() => {
        const computedStatistic = ptStatistics ?? myStatistics
        if (!myStatistics && !ptStatistics) {
            return <></>
        }

        return (
            <div className={styles.cards}>
                {myStatistics && !ptStatistics && (
                    <PerformanceCard
                        performance={myStatistics.personalTrainerEarnings}
                        type={'TOTAL_EARNINGS'}
                        selected={selectedIndex === 1}
                        onClick={() => setSelectedIndex(x => x !== 1 ? 1 : 0)}
                    />
                )}
                {computedStatistic && (
                    <>
                        <PerformanceCard
                            performance={computedStatistic.liveStreamCount}
                            type={'LIVE_STREAM_COUNT'}
                            selected={selectedIndex === 2}
                            onClick={() => setSelectedIndex(x => x !== 2 ? 2 : 0)}
                        />
                        <PerformanceCard
                            performance={computedStatistic.liveStreamDuration}
                            type={'LIVE_STREAM_DURATION'}
                            selected={selectedIndex === 3}
                            onClick={() => setSelectedIndex(x => x !== 3 ? 3 : 0)}
                        />
                        <PerformanceCard
                            performance={computedStatistic.burntCalories}
                            type={'BURNT_CALORIES'}
                            selected={selectedIndex === 4}
                            onClick={() => setSelectedIndex(x => x !== 4 ? 4 : 0)}
                        />
                        <PerformanceCard
                            performance={computedStatistic.uniqueUsersJoinedToBroadcasts}
                            type={'UNIQUE_USERS_JOINED_TO_BROADCASTS'}
                            selected={selectedIndex === 5}
                            onClick={() => setSelectedIndex(x => x !== 5 ? 5 : 0)}
                        />
                        <PerformanceCard
                            performance={computedStatistic.referenceCount}
                            type={'REFERENCE_COUNT'}
                            selected={selectedIndex === 6}
                            onClick={() => setSelectedIndex(x => x !== 6 ? 6 : 0)}
                        />
                    </>
                )}
            </div>
        )
    }, [ptStatistics, myStatistics, selectedIndex])

    const renderSearch = React.useMemo(() => {
        const nextTrainers = async () => {
            if (pagination.isNextPageAvailable) {
                try {
                    const { data: { pagination: dataPagination, personalTrainers } } = await api.PersonalTrainer.listPersonalTrainers(pagination.firstIdOfNextPage, debouncedQuery)
                    if (personalTrainers && dataPagination) {
                        setTrainers(x => [...x, ...(personalTrainers)])
                        setPagination(dataPagination)
                    }
                } catch {
                    setPagination({ isNextPageAvailable: false })
                }
            }
        }

        return (
            <>
                <Input onChangeText={setQuery} placeholder='Arama yap...' background={'gray'}
                       leadingComponent={loading ? <TailSpin color={'#334362'} height={50} width={50} /> : <Search2Line />} borderless />

                <InfiniteScroll
                    next={nextTrainers}
                    hasMore={pagination.isNextPageAvailable ?? true}
                    loader={<div className={styles.center}><ThreeDots color={'#334362'} height={50} width={50} /></div>}
                    dataLength={trainers.length}
                    height={trainers.length > 0 ? 376 : '100%'}
                    className={styles.searchResult}
                >
                    {trainers.map((trainer) => (
                        <div onClick={() => setSelectedTrainer(trainer)} key={trainer.id + '_trainer'}>
                            <Avatar size={3} user={trainer} rounded />
                            <Text weight={'medium'}>{trainer.fullName}</Text>
                            <ArrowRightSLine />
                        </div>
                    ))}
                </InfiniteScroll>
            </>
        )
    }, [trainers, loading, pagination, api.PersonalTrainer, debouncedQuery])

    if (!auth.isLoggedIn) {
        return (
            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <ErrorCard type={'401'} />
            </div>
        )
    }

    return (
        <MyPerformanceTemplate>
            <Helmet>
                <title>Performans | {Config.app.name}</title>
            </Helmet>

            {isMobile && (
                <div className={styles.mobileWrapper}>
                    <div className={styles.tabs}>
                        <div onClick={() => setActiveTab(0)}
                             className={classnames(styles.tab, { [styles.activeTab]: activeTab === 0 })}>{selectedTrainer ? `${selectedTrainer.fullName} Performans Özeti` : 'Performans Özetim'}</div>
                        <div onClick={() => setActiveTab(1)} className={classnames(styles.tab, { [styles.activeTab]: activeTab === 1 })}>Antrenörlerde Ara</div>
                    </div>

                    {activeTab === 0 && (
                        <div>{renderCards}</div>
                    )}

                    {activeTab === 1 && (
                        <div>{renderSearch}</div>
                    )}
                </div>
            )}

            {!isMobile && (
                <div className={styles.wrapper}>
                    <div>
                        <Card
                            type={'light'}
                            heading={{
                                title: 'Diğer Antrenörlerde Ara',
                                alignment: 'left',
                                size: 'lg',
                                divider: true,
                            }}
                        >
                            {renderSearch}
                        </Card>
                    </div>
                    <div className={styles.performanceWrapper}>
                        <div className={styles.userCard}>
                            {selectedTrainer && (
                                <Link to={'/antrenor/' + selectedTrainer.metadataId}>
                                    <Avatar user={selectedTrainer} size={3} rounded />
                                    <div>
                                        <Text weight={'medium'}>{selectedTrainer.fullName}</Text>
                                        <Text size={'xs'} weight={'medium'}>Personal Trainer</Text>
                                    </div>
                                </Link>
                            )}
                            <Text size={'xl'} weight={'medium'} type={'default'}>{selectedTrainer ? 'Performans Özeti' : 'Performans Özetim'}</Text>
                        </div>

                        <div>
                            {renderCards}

                            {statistic && (
                                <div className={styles.performance}>
                                    <div>
                                        <Text size={'4xl'} weight={'bold'}>{statistic.daily}</Text>
                                        <Text size={'sm'} weight={'medium'}>{prefix} Bugün</Text>
                                    </div>
                                    <div>
                                        <Text size={'4xl'} weight={'bold'}>{statistic.weekly}</Text>
                                        <Text size={'sm'} weight={'medium'}>{prefix} Bu Hafta</Text>
                                    </div>
                                    <div>
                                        <Text size={'4xl'} weight={'bold'}>{statistic.monthly}</Text>
                                        <Text size={'sm'} weight={'medium'}>{prefix} Bu Ay</Text>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}

        </MyPerformanceTemplate>
    )
}
export default MyPerformanceScreen
