import * as React from 'react'
import Moment from 'react-moment'
import Text from '../../atoms/text'
import classnames from 'classnames'
import styles from './style.module.css'
import { useHistory } from 'react-router-dom'
import useNotification from 'hooks/useNotification'
import { NotificationListItem } from '@tetahq/bironbir_api_client'
import { BroadcastLine, MedalLine, Notification3Line, TimerFlashLine } from '../../icons'
import moment from 'moment-timezone'

type NotificationCardProps = {
    notification: NotificationListItem
}

const NotificationCard = ({ notification }: NotificationCardProps) => {
    const history = useHistory()
    const [icon, setIcon] = React.useState<React.ReactNode>(<Notification3Line />)
    const not = useNotification()

    React.useEffect(() => {
        switch (notification.type) {
            case 'WEEKLY_BROADCAST_FIXTURE':
                setIcon(<TimerFlashLine />)
                break
            case 'BROADCAST_STARTING':
                setIcon(<BroadcastLine />)
                break
            case 'JOINED_TO_BROADCAST':
                setIcon(<MedalLine />)
                break
            case 'OTHER':
                setIcon(<Notification3Line />)
                break
        }
    }, [notification])

    const clickHandler = () => {
        not.setAsRead?.(notification)
        if (notification.type === 'BROADCAST_STARTING') {
            history.push({ pathname: `/canli-yayin/${notification.agoraRoomId}` })
        }
    }

    return (
        <div className={classnames(styles.notification, { [styles.unRead]: !notification.didRead })} onClick={clickHandler}>
            <div className={styles.icon}>{icon}</div>
            <div className={styles.texts}>
                <Text weight={'medium'}>{notification.title}</Text>
                <Text size={'xs'} type={'soft-blue'}>{notification.description}</Text>
            </div>
            <Text size={'xs'} type={'dimmed'} weight={'medium'}><Moment locale='tr' interval={1000} ago fromNow>{moment(notification.sentAt)}</Moment></Text>
        </div>
    )
}

export default NotificationCard
