import * as React from 'react'
import classnames from 'classnames'
import useAuth from 'hooks/useAuth'
import { AuthContextProps } from 'providers/auth'
import { LinkProps, useHistory } from 'react-router-dom'

import styles from './style.module.css'

export type AuthLinkProps = {
    to?: string
    onClick?: () => void
    children: React.ReactNode
    disabled?: boolean
}

export default function AuthLink({ to, onClick, children, disabled, ...props }: AuthLinkProps & (LinkProps | JSX.IntrinsicElements['div'])) {
    const auth = useAuth() as AuthContextProps
    const history = useHistory()

    if (!auth.isLoggedIn) {
        return (
            <div onClick={() => !disabled && auth.showModal?.('login')} className={classnames({[styles.disabled]: disabled}, props.className)}>
                {children}
            </div>
        )
    }

    return (
        <div onClick={() => !disabled && onClick ? onClick() : to && !disabled ? history.push({ pathname: to }) : undefined} className={classnames({[styles.disabled]: disabled}, props.className)} {...props as any}>
            {children}
        </div>
    )
}
