import App from './App'
import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
import '@capacitor/core'
import { ApiProvider } from './providers/api'
import { AppInfoProvider } from './providers/appInfo'
// import * as Sentry from "@sentry/capacitor";
import * as SentryReact from '@sentry/react'
import { Device } from '@capacitor/device'

import './index.css'
import 'moment/locale/tr'
import './styles/base.css'
import 'rc-slider/assets/index.css'
import 'react-calendar/dist/Calendar.css'
import 'swiper/swiper-bundle.min.css'
import 'react-lazy-load-image-component/src/effects/blur.css'

import { Integrations } from '@sentry/tracing'
import Config from './config'

(async () => {
    const { platform } = await Device.getInfo()

    if (['android', 'ios'].indexOf(platform) > -1) {
        // Sentry.init({
        //     dsn: 'https://ae1c435f8ebb494c80d52e277c603020@o396942.ingest.sentry.io/5858155'
        // }, SentryReact.init);
    } else {
        SentryReact.init({
            dsn: Config.sentry.url,
            integrations: [new Integrations.BrowserTracing()],
            tracesSampleRate: 0.25,
        })
    }
})()


ReactDOM.render(
    <React.StrictMode>
        <ApiProvider>
            <AppInfoProvider>
                <App />
            </AppInfoProvider>
        </ApiProvider>
    </React.StrictMode>,
    document.getElementById('root'),
)

reportWebVitals(console.log)
