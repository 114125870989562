import * as React from 'react'
import { MutatingDots } from 'react-loader-spinner'
import ErrorBox from '../../atoms/error-box'
import { BroadcastProgramListItem } from '@tetahq/bironbir_api_client'
import BroadcastCard, { eBroadcastCardType } from '../../molecules/broadcast-card'

import styles from './style.module.css'

export interface BroadcastFixtureListProps {
    broadcasts: BroadcastProgramListItem[]
    isLoading?: boolean
    onClick: (item: BroadcastProgramListItem) => void
}

export const BroadcastFixtureList: React.FC<BroadcastFixtureListProps> = ({ broadcasts, isLoading, onClick }) => {
    const broadcastClick = (item: BroadcastProgramListItem) => {
        onClick(item)
    }

    const renderEmptyList = React.useMemo(() => {
        if (broadcasts.length > 0 || isLoading) {
            return null
        }

        return (
            <ErrorBox text={'Güne ait yayın programı henüz bulunmamaktadır.'} size={'sm'} align={'center'} />
        )
    }, [broadcasts, isLoading])

    const renderLoader = React.useMemo(() => {
        if (!isLoading) {
            return null
        }

        return (
            <div className={styles.listLoader}>
                <MutatingDots color={'#334362'} secondaryColor={'#e3352a'} height={100} width={100} />
            </div>
        )
    }, [isLoading])

    const renderList = (() => {
        if (broadcasts.length === 0 || isLoading) {
            return null
        }

        const mappedResults = broadcasts.map((item) => {
            return (
                <BroadcastCard
                    onClick={() => broadcastClick(item)}
                    key={`${item.id}_broadcast`}
                    mode={eBroadcastCardType.BROADCAST_FIXTURE}
                    broadcast={item}
                />
            )
        })

        return (
            <div className={styles.broadcastsGrid}>
                {mappedResults}
            </div>
        )
    })()

    return (
        <div className={styles.listWrapper}>
            {renderEmptyList}
            {renderLoader}
            {renderList}
        </div>
    )
}
