import * as React from 'react'
import Moment from 'react-moment'
import Text from '../../atoms/text'
import moment from 'moment-timezone'
import Button from '../../atoms/button'
import PlayCircle from '../../icons/PlayCircle'
import { BroadcastLine, TimerFlashLine } from '../../icons'
import { BroadcastProgramFixtureListItem } from '@tetahq/bironbir_api_client/models/broadcast-program-fixture-list-item'

import styles from './style.module.css'

type BroadcastCalendarCardProps = {
    broadcast: BroadcastProgramFixtureListItem
    onClick: (broadcast: BroadcastProgramFixtureListItem, isLive?: boolean, isEnded?: boolean) => void
}

const BroadcastCalendarCard = ({ broadcast, onClick }: BroadcastCalendarCardProps) => {
    const startMoment = moment(broadcast.startsAt)
    const endMoment = moment(broadcast.endsAt)
    const isLive = moment().isBetween(startMoment, endMoment, 'seconds', '[]')

    const renderButton = React.useMemo(() => {
        if ((isLive && !broadcast.isEnded) || broadcast.isLive) {
            return <Button size={'small'} variation={'block'} type={'danger-2'} onClick={() => onClick(broadcast)}><Text
                type={'white'} weight={'medium'}>{broadcast.isLive ? 'Yayına Dön' : 'Yayına Başla'}</Text></Button>
        } else {
            if (broadcast.isEnded || moment(broadcast.endsAt).isBefore()) {
                return <Button size={'large'} variation={'block'} type={'danger'} onClick={() => onClick(broadcast)}><Text
                    type={'danger-2'} weight={'medium'}>{broadcast.caloriesBurnt ? broadcast.caloriesBurnt.toString() + 'kcal' : 'Kalori Belirlenmedi'}</Text></Button>
            } else {
                return <Button variation={'block'} type={'warning'} onClick={() => onClick(broadcast)}><Text type={'warning'} weight={'medium'}>Yakında</Text></Button>
            }
        }
    }, [broadcast, onClick, isLive])

    const renderCardCircleButton = React.useMemo(() => {
        if (isLive && !broadcast.isEnded) {
            return <BroadcastLine />
        } else {
            if (broadcast.isEnded) {
                return <PlayCircle />
            } else {
                return <TimerFlashLine />
            }
        }
    }, [broadcast, isLive])

    return (
        <div className={styles['card']}>
            <Button as={'anchor'} variation={'circle'} type={!broadcast.isLive ? 'warning-fill' : 'danger-2'} onClick={() => onClick(broadcast)}>
                {renderCardCircleButton}
            </Button>
            <Text weight={'medium'} className={styles['broadcastName']}>{broadcast.title}</Text>
            <Text weight={'medium'}><Moment format={'DD.MM.YYYY - HH:mm'} className={styles['dateText']}>{broadcast.startsAt}</Moment></Text>
            <div className={styles['buttonInspector']}>
                {renderButton}
            </div>
        </div>
    )
}

export default BroadcastCalendarCard
