import * as React from 'react'
import classnames from 'classnames'

import styles from './style.module.css'

type ToggleProps = {
    active?: boolean
    onChange: (value: boolean) => void
    activeIcon: React.ReactNode
    inactiveIcon: React.ReactNode
}

const ToggleButton = ({ active: initialStatus, onChange, activeIcon, inactiveIcon }: ToggleProps) => {
    const [active, setActive] = React.useState<boolean>(initialStatus ?? false)

    const toggle = () => {
        setActive(r => !r)
        onChange(active)
    }

    React.useEffect(() => {
        if(initialStatus){
            setActive(initialStatus)
        }
    },[initialStatus])

    return (
        <button
            onClick={toggle}
            className={classnames(styles.button, {
                [styles.active]: active,
                [styles.inactive]: !active,
            })}
        >
            <div className={classnames(styles.iconWrapper)}>{active ? activeIcon : inactiveIcon}</div>
        </button>
    )
}

export default ToggleButton
