import * as React from 'react'
import styles from './style.module.css'
import Text from '../../atoms/text'
import Button from '../../atoms/button'
import { CloseLine, FireLine } from '../../icons'
import Input from '../../atoms/input'
import Modal, { ModalSize, ModalType } from '../../atoms/modal'
import Card from '../../molecules/card'

type BroadcastCalorieAmountModalProps = {
    onSubmit?: (calorie: number) => void
    isVisible: boolean
    onClose: () => void
    broadcastDuration: number
    broadcastType: string
}

const BroadcastCalorieAmountModal = ({ onSubmit, isVisible, onClose, broadcastDuration, broadcastType }: BroadcastCalorieAmountModalProps) => {
    const [value, setValue] = React.useState<string | undefined>(undefined)

    const submitHandler = () => {
        if (typeof onSubmit === 'function' && typeof value !== 'undefined') {
            onSubmit(parseInt(value))
        }
    }
    return (
        <Modal isVisible={isVisible} onModalClose={onClose} type={ModalType.Fullscreen} size={ModalSize.Large}>
            <Card
                type='light'
                heading={{
                    trailing: <div className={styles.back} onClick={onClose}><CloseLine /></div>,
                    title: `${broadcastType} - ${broadcastDuration.toString()} Dakika`,
                    alignment: 'center',
                    size: 'lg',
                    divider: true,
                }}
            >
                <div className={styles['moduleInspector']}>
                    <div className={styles['module']}>
                        <Text size={'xl'} className={styles['subText']}>Yakılan Kaloriyi Ekleyin</Text>

                        <div>
                            <div className={styles['caloryIconInspector']}>
                                <Button as={'anchor'} variation={'circle'} type={'danger-2'}>
                                    <FireLine />
                                </Button>
                            </div>

                            <Input value={value} onChangeText={setValue} title={'Kalori'} placeholder={'Kalori'} format='####' />

                            <div className={styles['button']}>
                                <Button onClick={submitHandler} variation={'block'} type={'danger-2'}>
                                    Bilgileri Kaydet
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        </Modal>
    )
}

export default BroadcastCalorieAmountModal
