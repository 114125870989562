import * as React from 'react'
import classNames from 'classnames'

import styles from './style.module.css'
export { styles }

type PackageCoursePreviewTemplateProps = {
    isMobile: boolean
}

const PackageCoursePreviewTemplate: React.FC<PackageCoursePreviewTemplateProps> = ({ isMobile, children }) => {

    return (
        <div
            className={classNames({
                [styles.wrapper]: isMobile,
                [styles.wideWrapper]: !isMobile,
            })}
        >
            {children}
        </div>
    )
}

export default PackageCoursePreviewTemplate
