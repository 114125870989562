import * as React from 'react'

function PlayCircle(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
            <path id='Path_5315' data-name='Path 5315' className='cls-1'
                  d='M12,22A10,10,0,1,1,22,12,10,10,0,0,1,12,22Zm0-2a8,8,0,1,0-8-8A8,8,0,0,0,12,20ZM10.622,8.415,15.5,11.667a.4.4,0,0,1,0,.666l-4.88,3.252A.4.4,0,0,1,10,15.253V8.747a.4.4,0,0,1,.622-.332Z'
                  transform='translate(-2 -2)' />
        </svg>
    )
}

export default PlayCircle

