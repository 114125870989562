import * as React from 'react'
import classnames from 'classnames'

import styles from './style.module.css'

export type MembershipPackagesTemplateProps = {
    children: React.ReactNode
}

const MembershipPackagesTemplate: React.FC<MembershipPackagesTemplateProps> = ({ children }) => {
    return <div className={classnames(styles.home)}>{children}</div>
}

export default MembershipPackagesTemplate
