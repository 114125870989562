import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import styles from './style.module.css'

type ImageWithFallbackProps = {
    fallbackSrc: string
}

const ImageWithFallback = ({ fallbackSrc, ...props }: ImageWithFallbackProps & JSX.IntrinsicElements['img']) => {
    let { src, srcSet, alt } = props

    const [computedSrc, setSrc] = React.useState<string | undefined>(undefined)
    const [computedSrcSet, setSrcSet] = React.useState<string | undefined>(undefined)

    React.useEffect(() => {
        setSrc(src)
    }, [src])

    React.useEffect(() => {
        setSrcSet(srcSet)
    }, [srcSet])

    const loadFallback = React.useCallback(() => {
        setSrc(fallbackSrc)
        setSrcSet(fallbackSrc)
    }, [fallbackSrc])

    if (!computedSrc) return null
    return (
            <LazyLoadImage
                className={styles.lazy}
                alt={alt}
                srcSet={srcSet ? computedSrcSet : undefined}
                src={computedSrc}
                effect={'blur'}
                onError={loadFallback}
            />
    )
}

export default ImageWithFallback
