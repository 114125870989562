import * as React from 'react'
import useMedia from 'hooks/useMedia'
import Text from 'components/atoms/text'
import Button from 'components/atoms/button'
import { ArrowLeftSLine, CloseLine, LogoFull } from 'components/icons'

import styles from './style.module.css'

type AuthModalNavbarProps = {
    title?: string
    webTitle?: string
    description?: string
    canGoBack?: boolean
    canClose?: boolean
    onGoBack?: () => void
    onClose?: () => void
}

const AuthModalNavbar: React.FC<AuthModalNavbarProps> = ({ title, webTitle, description, canGoBack, onGoBack, onClose, canClose }) => {
    const isMobile = useMedia('(max-width: 1024px)')

    return (
        <>
            <div className={styles.wrapper}>
                <div className={styles.top}>
                    {isMobile && canGoBack && (
                        <div className={styles['back-button']}>
                            <Button
                                onClick={() => {
                                    typeof onGoBack === 'function' && onGoBack()
                                }}
                                variation='icon'
                            >
                                <ArrowLeftSLine />
                            </Button>
                        </div>
                    )}
                    {isMobile && <LogoFull width={164} height={56} />}
                    {isMobile && canClose && (
                        <div className={styles['close-button']}>
                            <Button
                                onClick={() => {
                                    typeof onClose === 'function' && onClose()
                                }}
                                variation='icon'
                            >
                                <CloseLine />
                            </Button>
                        </div>
                    )}
                </div>

                {isMobile && (title || description) && (
                    <div className={styles.content}>
                        {title && (
                            <Text weight='bold' size='2xl'>
                                {title}
                            </Text>
                        )}
                        {description && <Text type='dimmed'>{description}</Text>}
                    </div>
                )}
            </div>
            {!isMobile &&
            <div className={styles.webWrapper}>
                {canGoBack && (
                    <div onClick={onGoBack} className={styles['back-button']}>
                        <ArrowLeftSLine />
                    </div>
                )}

                <div onClick={onClose} className={styles['close']}>
                    <CloseLine />
                </div>

                {webTitle && (
                    <Text size='3xl' weight='medium'>
                        {webTitle}
                    </Text>
                )}
            </div>}
        </>
    )
}

export default AuthModalNavbar
