import * as React from 'react'
import Text from 'components/atoms/text'
import ImageWithFallback from '../../atoms/image-with-fallback'
import { PackageCourseListItem } from '@tetahq/bironbir_api_client'

import styles from './style.module.css'

type PackagesCoursesCardProps = {
    onClick?: () => void
    packageCourse: PackageCourseListItem
}

const PackagesCoursesCard = ({ onClick, packageCourse }: PackagesCoursesCardProps) => {
    const onClickHandler = () => {
        onClick && onClick()
    }

    return (
        <div onClick={onClickHandler} className={styles['card']}>
            {/*<div className={styles.backdrop} />*/}
            <ImageWithFallback
                src={packageCourse.coverPhoto?.coverImage4X}
                srcSet={packageCourse.coverPhoto ? `${packageCourse.coverPhoto.coverImage1X}, ${packageCourse.coverPhoto.coverImage2X} 1.5x, ${packageCourse.coverPhoto.coverImage3X} 2x, ${packageCourse.coverPhoto.coverImage4X} 3x,` : undefined}
                fallbackSrc={'/images/fallbacks/250-250.png'}
                alt=''
            />
            <div className={styles.name}><Text size='xs' type='default' weight='medium'>{packageCourse.name}</Text></div>
            <div className={styles.description}><Text size='2xs' type='default'>{packageCourse.description}</Text></div>
            <div className={styles.trainer}><Text size='xs' type='default' weight='medium'>{packageCourse.personalTrainer?.fullName}</Text></div>
        </div>
    )
}

export default PackagesCoursesCard
