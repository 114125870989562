import * as React from 'react'
import useApi from 'hooks/useApi'
import classnames from 'classnames'
import Input from '../../atoms/input'
import Avatar from '../../atoms/avatar'
import Text from 'components/atoms/text'
import { TailSpin } from 'react-loader-spinner'
import useAppInfo from 'hooks/useAppInfo'
import { Listbox } from '@headlessui/react'
import useDebounce from 'hooks/useDebounce'
import Button from 'components/atoms/button'
import Checkbox from 'components/atoms/checkbox'
import ListCard from 'components/atoms/list-card'
import { ApiContextInterface } from 'providers/api'
import { ArrowDownSLine, Search2Line } from 'components/icons'
import { HearFromOption, InfinityPagination, PersonalTrainerListItem } from '@tetahq/bironbir_api_client'

import styles from './style.module.css'

type WhoReferencedCardProps = {
    loading?: boolean
    onConfirm: (channels: HearFromOption[], personalTrainerId?: number) => void
}

const WhoReferencedCard: React.FC<WhoReferencedCardProps> = ({ loading, onConfirm }) => {
    const api = useApi() as ApiContextInterface
    const appInfo = useAppInfo()

    const [query, setQuery] = React.useState<string>('')
    const debouncedQuery = useDebounce<string>(query, 500)

    const [trainerLoading, setTrainerLoading] = React.useState<boolean>(true)
    const [trainers, setTrainers] = React.useState<PersonalTrainerListItem[]>([])
    const [pagination, setPagination] = React.useState<InfinityPagination>({ isNextPageAvailable: false })
    const [selectedTrainer, setSelectedTrainer] = React.useState<PersonalTrainerListItem | null>(null)
    const [selectedChannels, setSelectedChannels] = React.useState<HearFromOption[]>([])

    const onListItemToggled = (item: HearFromOption, status: boolean) => {
        if (status) {
            setSelectedChannels([...selectedChannels, item])
        } else {
            setSelectedChannels(selectedChannels.filter(r => r !== item))
        }

        if (item.id === 1 && !status) {
            setSelectedTrainer(null)
        }
    }

    const handleSubmit = () => {
        onConfirm(selectedChannels, selectedTrainer?.metadataId)
    }

    const handleSelectPersonalTrainerReference = React.useMemo(() => {
        return selectedChannels.find(x => x.id === 1)
    }, [selectedChannels])

    const getTrainersNextPage = async () => {
        if (pagination.isNextPageAvailable) {
            setTrainerLoading(true)
            try {
                await api.PersonalTrainer.listPersonalTrainers(pagination.firstIdOfNextPage, debouncedQuery !== '' ? debouncedQuery : undefined).then(result => {
                    const data = result.data.personalTrainers
                    const pagination = result.data.pagination

                    if (data && pagination) {
                        setTrainers((x) => [...x, ...(data)])
                        setPagination(pagination)
                    }
                })
            } catch {
                setPagination({ isNextPageAvailable: false })
            }
            setTrainerLoading(false)
        }
    }

    React.useEffect(() => {
        (async () => {
            setTrainerLoading(true)
            try {
                const { data } = await api.PersonalTrainer.listPersonalTrainers(undefined, debouncedQuery !== '' ? debouncedQuery : undefined)

                setTrainers(data.personalTrainers ?? [])
                setPagination(data.pagination ?? { isNextPageAvailable: false })
            } catch {
            }
            setTrainerLoading(false)
        })()
    }, [api.PersonalTrainer, debouncedQuery])

    const handleScroll = (e: any) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
        if (bottom) {
            getTrainersNextPage()
        }
    }

    return (
        <>
            <div className={styles.channels}>
                <ListCard>
                    {appInfo.hearFromOptions?.map((r) =>
                        (<ListCard.Item key={r.id?.toString() + '_hearfrom'} >
                            <Checkbox label={r.from} checked={selectedChannels.includes(r)} onChange={s => onListItemToggled(r, s)} reverse />
                        </ListCard.Item>),
                    )}
                </ListCard>
            </div>

            {handleSelectPersonalTrainerReference && (
                <div className={styles.trainers}>
                    <Listbox value={selectedTrainer} onChange={setSelectedTrainer}>
                        {({ open }) => {
                            return (
                                <div className={styles.trainersWrapper}>
                                    <Listbox.Button as={React.Fragment}>
                                        <div className={classnames(styles.trainerInputWrapper, { [styles.open]: open })}>
                                            {selectedTrainer ? (
                                                <div className={styles.selectedTrainerWrapper}>
                                                    <div className={styles.selectedTrainer}>
                                                        <Avatar user={selectedTrainer} size={2} rounded />
                                                        {selectedTrainer.fullName}
                                                    </div>
                                                </div>
                                            ) : <Input
                                                value={query}
                                                placeholder='Devam Etmeden Önce Eğitmeninizi Seçiniz..'
                                                onChangeText={setQuery}
                                                borderless
                                            />}

                                            {open ? <ArrowDownSLine className={styles.trainerInputIcon} /> : <Search2Line className={styles.trainerInputIcon} />}
                                        </div>
                                    </Listbox.Button>
                                    <Listbox.Options as='div' className={styles.trainerList}>
                                        <div className={styles.trainerListScroller} onScroll={handleScroll}>
                                            {trainers.map((person) => (
                                                <Listbox.Option as='div' className={styles.trainerListItem} key={person.id + 'person'} value={person}>
                                                    <Avatar user={person} size={1.5} rounded />
                                                    <Text type='dimmed' weight='medium'>
                                                        {person.fullName}
                                                    </Text>
                                                </Listbox.Option>
                                            ))}

                                            {trainerLoading && <div
                                                style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                                <TailSpin color={'#334362'} height={40} width={50} />
                                            </div>}
                                        </div>
                                    </Listbox.Options>
                                </div>
                            )
                        }}
                    </Listbox>
                </div>
            )}

            <div className={styles.actions}>
                <Button loading={loading} onClick={handleSubmit} type='danger-2' variation='block'
                        disabled={selectedChannels.length < 1 || (handleSelectPersonalTrainerReference && selectedTrainer === null)}>
                    Devam Et
                </Button>
            </div>
        </>
    )
}

export default WhoReferencedCard
