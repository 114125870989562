import jwt, { JwtPayload } from 'jsonwebtoken'
import { UserRole } from 'types'

export function getUser() {
    const token = localStorage.getItem('token')

    if (token) {
        return jwt.decode(token, { complete: true })
    }

    return null
}

export function getUserRole(): UserRole | null {
    const user = getUser()
    if(user){
        const payload = user.payload as JwtPayload;
        const roles = payload.auth.split(",")
        return roles[0]
    }else{
        return null
    }
}
