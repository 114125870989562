import * as React from 'react'
import useApi from 'hooks/useApi'
import Text from '../../atoms/text'
import Input from '../../atoms/input'
import { toast } from 'react-toastify'
import Button from 'components/atoms/button'
import { ApiContextInterface } from 'providers/api'
import { replaceNumber, validatePhoneNumber } from 'utils/validation-helpers'
import AuthModalNavbar from '../../molecules/auth-modal-navbar'
import Modal, { ModalSize, ModalType } from 'components/atoms/modal'

import styles from './style.module.css'
import useAuth from '../../../hooks/useAuth'
import { AuthContextProps } from '../../../providers/auth'

export type PhoneNumberModalProps = {
    isVisible: boolean
    onModalClose: (confirm?: boolean) => void
}

const PhoneNumberModal = ({ isVisible, onModalClose }: PhoneNumberModalProps) => {
    const api = useApi() as ApiContextInterface
    const auth = useAuth() as AuthContextProps
    const [phoneNumber, setPhoneNumber] = React.useState('')
    const [loading, setLoading] = React.useState(false)

    const submitHandler = async () => {
        setLoading(true)
        try {
            await api.Account.updateMyPhoneNumber({ phoneNumber: replaceNumber(phoneNumber) })
            toast.success('Telefon numaranız başarıyla güncellendi')
            onModalClose()

            auth.setUser?.((x: any) => {
                if (x) {
                    x.phoneNumber = replaceNumber(phoneNumber)
                }
                return x
            })
        } catch {
        }
        setLoading(false)
    }

    return (
        <Modal isVisible={isVisible} onModalClose={onModalClose} type={ModalType.ActionSheet} size={ModalSize.Medium}>
            <div className={styles.phoneModal}>
                <AuthModalNavbar
                    canClose={true}
                    canGoBack={false}
                    title={'Telefon Numaranızı Güncelleyin'}
                    webTitle={'Telefon Numaranızı Güncelleyin'}
                    onClose={onModalClose}
                />
                <Text type='default'>Devam edebilmeniz için telefon numaranızı güncellemeniz gerekmektedir.</Text>
                <Input value={phoneNumber}
                       onChangeText={setPhoneNumber}
                       label='Telefon Numarası'
                       type='tel'
                       name='tel'
                       autoComplete='tel-national'
                       format='(###) ###-####'
                />

                <div>
                    <Button onClick={submitHandler} variation={'block'} type={'danger-2'} loading={loading} disabled={!validatePhoneNumber(phoneNumber)}>
                        Bilgileri Kaydet
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default PhoneNumberModal
