import * as React from 'react'
import styles from './style.module.css'
import Text from '../../atoms/text'
import Button from '../../atoms/button'
import Modal, { ModalSize, ModalType } from '../../atoms/modal'
import AuthModalNavbar from '../../molecules/auth-modal-navbar'
import useQuery from '../../../hooks/useQuery'
import { useHistory } from 'react-router-dom'
import useAppInfo from '../../../hooks/useAppInfo'
import ReactMarkdown from 'react-markdown'
import { kebabCase } from 'lodash'

const LegalDocumentModal = () => {
    const params = useQuery()
    const history = useHistory()
    const appInfo = useAppInfo()
    const [showLegalDocModal, setLegalDocModal] = React.useState<string | undefined>(undefined)
    const [text, setText] = React.useState<string>('')

    React.useEffect(() => {
        if (appInfo.legalDocuments) {
            const find = appInfo.legalDocuments.find(x => x.title === showLegalDocModal)
            if (find && find.content) {
                setText(find.content)
            }
        }
    }, [appInfo, showLegalDocModal])

    React.useEffect(() => {
            const getParam = params.get('dokuman')
            if (getParam !== null) {
                const document = appInfo.legalDocuments?.find(x => kebabCase(x.title) === getParam)
                setLegalDocModal(document ? document.title : undefined)
            } else {
                setLegalDocModal(undefined)
            }
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [params, appInfo.legalDocuments])

    return (
        <Modal isVisible={typeof showLegalDocModal !== 'undefined'} onModalClose={() => {
            params.delete('dokuman')
            history.replace({ search: params.toString() })
        }} size={ModalSize.Large} type={ModalType.Fullscreen}>
            <div className={styles.modal}>
                <AuthModalNavbar
                    title={showLegalDocModal && appInfo.legalDocuments?.find(x => x.title === showLegalDocModal)?.title}
                    webTitle={showLegalDocModal && appInfo.legalDocuments?.find(x => x.title === showLegalDocModal)?.title}
                    canGoBack={false}
                    canClose
                    onClose={() => {
                        params.delete('dokuman')
                        history.replace({ search: params.toString() })
                    }}
                />
                <div className={styles.contentWrapper}>
                    <Text size='sm'>
                        {showLegalDocModal && <ReactMarkdown>{text}</ReactMarkdown>}
                    </Text>
                </div>
                <Button
                    variation={'wide'}
                    type={'danger-2'}
                    onClick={() => {
                        params.delete('dokuman')
                        history.replace({ search: params.toString() })
                    }}
                >
                    Pencereyi Kapat
                </Button>

            </div>
        </Modal>
    )
}

export default LegalDocumentModal
