import * as React from 'react'
import Text from '../../atoms/text'
import Button from 'components/atoms/button'
import PasswordInput from '../password-input'

import styles from './style.module.css'
import ReactCodeInput from 'react-verification-code-input'

type NewPasswordCardProps = {
    onConfirm: (password: string, code?: string) => void
    loading?: boolean
    hideRecoverCode?: boolean
}

const NewPasswordCard: React.FC<NewPasswordCardProps> = ({ loading, onConfirm, hideRecoverCode }) => {
    const [password, setPassword] = React.useState('')
    const [passwordAgain, setPasswordAgain] = React.useState('')
    const [code, setCode] = React.useState('')

    const [isSame, setSame] = React.useState(false)

    React.useEffect(() => {
        setSame(password.length > 1 && passwordAgain.length > 1 && password === passwordAgain)
    }, [password, passwordAgain, isSame])

    const handleNewPassword = () => {
        if (password.length < 6 || !isSame) return
        onConfirm(password, code)
        setPassword('')
        setPasswordAgain('')
    }

    return (
        <>
            <div className={styles.inputs}>
                {!hideRecoverCode && <ReactCodeInput onChange={setCode} className={styles.input} fields={6} fieldWidth={52} fieldHeight={52} />}
                <PasswordInput value={password} onChangeText={setPassword} label='Şifreniz' name={'password'} autoComplete={'new-password'} maxLength={64}
                               status={password.length > 1 && password.length < 6 ? 'danger' : 'default'} />
                <PasswordInput value={passwordAgain} onChangeText={setPasswordAgain} label='Şifreniz Tekrar' name={'password'} autoComplete={'new-password'} maxLength={64}
                               status={passwordAgain.length > 1 && !isSame ? 'danger' : 'default'} />
                <Text>Şifre en az 6 en fazla 64 karakterden oluşmalıdır.</Text>
            </div>

            <div className={styles.actions}>
                <Button loading={loading} onClick={handleNewPassword} type='danger-2' variation='block'
                        disabled={password.length < 6 || !isSame || (!hideRecoverCode && code.length !== 6)}>
                    Şifremi Değiştir
                </Button>
            </div>
        </>
    )
}

export default NewPasswordCard
