import * as React from 'react'
import Input from 'components/atoms/input'
import Button from 'components/atoms/button'

import styles from './style.module.css'
import { validateEmail } from '../../../utils/validation-helpers'
import ErrorBox from 'components/atoms/error-box'

type ForgottenPasswordCardProps = {
    loading?: boolean
    onConfirm: (email: string) => void
}

const ForgottenPasswordCard: React.FC<ForgottenPasswordCardProps> = ({ loading, onConfirm }) => {
    const [email, setEmail] = React.useState('')

    const handlePasswordReset = () => {
        onConfirm(email)
        setEmail('')
    }

    return (
        <>
            <div className={styles.inputs}>
                <Input value={email} onChangeText={setEmail} label="E-Posta Adresiniz" />
                {email && email !== '' && !validateEmail(email) && <ErrorBox text={'Geçerli bir email adresi giriniz'} size={'sm'} align={'left'} />}
            </div>

            <div className={styles.actions}>
                <Button loading={loading} onClick={handlePasswordReset} type="danger-2" variation="block">
                    Şifremi Sıfırla
                </Button>
            </div>
        </>
    )
}

export default ForgottenPasswordCard
