import * as React from 'react'
import Text from 'components/atoms/text'
import Input, { InputProps } from 'components/atoms/input'
import Button from 'components/atoms/button'

import styles from './style.module.css'
import PasswordInput from '../password-input'
import { validateEmail } from '../../../utils/validation-helpers'
import ErrorBox from '../../atoms/error-box'

type LoginCardProps = {
    loading?: boolean
    onConfirm: (email: string, password: string) => void
    onForgottenPasswordClicked: () => void
    onRegisterClicked: () => void
    status?: InputProps['status']
}

const LoginCard: React.FC<LoginCardProps> = ({ loading, onConfirm, onForgottenPasswordClicked, onRegisterClicked, status }) => {
    const [email, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')

    React.useEffect(() => {
        setEmail(email.replace(/\s/g, ''))
    }, [email])

    const handleEnter = () => {
        if (email !== '' && password !== '') {
            handleLogin()
        }
    }

    const handleLogin = () => {
        onConfirm(email, password)
    }

    const handleForgottenPasswordClick = (e: React.MouseEvent) => {
        e.preventDefault()
        onForgottenPasswordClicked()
    }

    const handleRegisterClick = (e: React.MouseEvent) => {
        e.preventDefault()
        onRegisterClicked()
    }

    return (
        <>
            <div className={styles.inputs}>
                <Input value={email} onChangeText={setEmail} label='E-Posta Adresiniz' status={status ?? 'default'} name='email' autoComplete='email' enterKeyPress={handleEnter} />
                {email && email !== '' && !validateEmail(email) && <ErrorBox text='Geçerli bir email adresi giriniz' size={'sm'} align='left' />}
                <PasswordInput onChangeText={setPassword} name='password' autoComplete='password' label='Şifreniz' status={status ?? 'default'} enterKeyPress={handleEnter} />
            </div>

            <div className={styles.controls}>
                <div onClick={handleRegisterClick}>
                    <Text type='danger' size='sm' clickable>
                        Hesap Oluştur
                    </Text>
                </div>
                <div onClick={handleForgottenPasswordClick}>
                    <Text type='danger' size='sm' clickable>
                        Şifreni mi unuttun?
                    </Text>
                </div>
            </div>

            <div className={styles.actions}>
                <Button loading={loading} onClick={handleLogin} type='danger-2' variation='block'>
                    Giriş Yap
                </Button>
            </div>
        </>
    )
}

export default LoginCard
