import * as React from 'react'
import { UserRole } from 'types'
import classnames from 'classnames'
import Button from '../../atoms/button'
import { getUserRole } from 'utils/auth'
import Avatar from 'components/atoms/avatar'
import Card from 'components/molecules/card'
import Divider from 'components/atoms/divider'
import { InformationFill } from 'components/icons'
import { GetPublicPersonalTrainerDetailsQueryResult } from '@tetahq/bironbir_api_client'

import styles from './style.module.css'

type ProfileNavigationProps = {
    personalTrainer: GetPublicPersonalTrainerDetailsQueryResult
    reportClick: () => void
    Stars: JSX.Element
}

const PTProfileNavigation = ({ personalTrainer, reportClick, Stars }: ProfileNavigationProps) => {
    const userRole = getUserRole()

    if (typeof personalTrainer === 'undefined') {
        return <></>
    }

    return (
        <div>
            <Card type={'light'}>
                <div className={classnames(styles.user)}>
                    <Avatar user={personalTrainer} rounded={true} size={3.5} />
                    <div>
                        <div className={classnames(styles.username)}>{personalTrainer.name + ' ' + personalTrainer.surname}</div>
                    </div>
                    <div className={styles.badge}>{Stars}</div>
                </div>

                {userRole !== UserRole.ROLE_PERSONAL_TRAINER && (
                    <>
                        <Divider />

                        <div style={{ width: '11rem' }}>
                            <Button leftIcon={<InformationFill />} type={'danger-link'} variation={'block'} onClick={reportClick}>Sorun Bildir</Button>
                        </div>
                    </>
                )}
            </Card>

        </div>
    )
}

export default PTProfileNavigation
