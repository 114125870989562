import * as React from 'react'
import Text from '../../atoms/text'
import classnames from 'classnames'
import { SubscriptionPlanListItem } from '@tetahq/bironbir_api_client'

import styles from './style.module.css'

type PackageMobilePurchaseCardProps = {
    onClick?: () => void
    plan: SubscriptionPlanListItem
    currentPackage: boolean
    platform: 'ios' | 'android' | 'web'
}

const MobilePackagePurchaseCard = ({ onClick, plan, currentPackage,platform }: PackageMobilePurchaseCardProps) => {
    const onClickHandler = () => {
        if (!plan.owned) {
            onClick && onClick()
        }
    }

    return (
        <div onClick={onClickHandler} className={classnames(styles.card, { [styles.current]: currentPackage }, { [styles.owned]: plan.owned })}>
            <div className={classnames(styles.packageTypeText, { [styles.current]: currentPackage })}>
                <Text type={currentPackage ? 'white' : 'danger'} size={'2xs'}>{plan.owned ? 'Mevcut Paket' : plan.name}</Text>
            </div>
            <div className={styles['cardInfo']}>
                <Text type={currentPackage ? 'danger' : 'dimmed'} size={'2xl'} weight={'bold'} className={styles['payAmountText']}><small>₺</small>{platform === 'ios' ? plan.iosPrice : plan.price}</Text>
                <Text type={currentPackage ? 'danger' : 'dimmed'} size={'sm'} weight={'default'}>{plan.durationAsMonths.toString()} Aylık Paket</Text>
            </div>
        </div>
    )
}
export default MobilePackagePurchaseCard
