import * as React from 'react'
import Text from 'components/atoms/text'
import Button from 'components/atoms/button'
import { PackageCourseTrainingListItem } from '@tetahq/bironbir_api_client'
import styles from './style.module.css'

type TrainingNavigationProps = {
    trainings: PackageCourseTrainingListItem[]
    currentTraining: number
    onNext: (id: number) => void
    onPrevious: (id: number) => void
}

const TrainingNavigation: React.FC<TrainingNavigationProps> = ({ trainings, currentTraining, onNext, onPrevious }) => {
    const selectedTraining = trainings.find(x => x.id === currentTraining)
    const findIndex = trainings.findIndex(x => x.id === currentTraining)

    const nextAvailable = React.useMemo(() => {
        return trainings[findIndex + 1]
    }, [trainings, findIndex])

    const previousAvailable = React.useMemo(() => {
        return trainings[findIndex - 1]
    }, [trainings, findIndex])

    if (trainings.length < 1 || !selectedTraining) {
        return <></>
    }

    return (
        <div className={styles.wrapper}>
            <Button variation='wide' type='dimmed' disabled={!previousAvailable} onClick={() => onPrevious(trainings[findIndex - 1].id ?? 0)}>
                Önceki
            </Button>
            <div className={styles.title}>
                <Text size='lg' weight='medium'>
                    {selectedTraining.header}
                </Text>
                <Text type='danger' weight='bold'>
                    {selectedTraining.subheader}
                </Text>
            </div>
            <Button variation='wide' type='danger-2' disabled={!nextAvailable} onClick={() => onNext(trainings[findIndex + 1].id ?? 0)}>
                Sonraki
            </Button>
        </div>
    )
}

export default React.memo(TrainingNavigation)
