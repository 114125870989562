import { ReactNode } from 'react'

export interface ChatUser {
    id: string;
    avatar: {
        avatar1X: string;
        avatar2X: string;
        avatar3X: string;
        avatar4X: string;
    }
    fullName: string;
}

export enum UserRole {
    ROLE_PERSONAL_TRAINER = 'ROLE_PERSONAL_TRAINER',
    ROLE_CUSTOMER = 'ROLE_CUSTOMER',
    ROLE_ADMIN = 'ROLE_ADMIN',
    ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
}

export interface DecodedJwtData {
    sub: string; // "customer@bironbir.com",
    auth: string; // "ROLE_CUSTOMER",
    userId: number; // 2,
    userGuid: string; // "4f132200-f0aa-4e14-94b4-66ac1932d8ea",
    exp: number; // 1626376918
}

export type MenuItem = {
    url?: string
    label: string
    icon: ReactNode
    active: boolean
    clickHandler?: Function
    exact?: boolean
}

export enum NotificationType {
    TARGET_COMPLETE = 0,
    MESSAGE = 1,
    APPROACHING_LESSON = 2,
    IN_LIVE = 3,
    EARN_BADGE = 4,
    OTHER = 5
}

export type Notification = {
    id: string
    title: string
    content: string
    type: NotificationType
    sentAt: string
    url?: string
    isRead?: boolean
}

export type Product = {
    id: number
    title: string
    content: string
    description: string
    price: number
    type: 'packageCourse' | 'subscription'
}
