import * as React from 'react'
import classnames from 'classnames'

import styles from './style.module.css'

const Divider = () => {
    return <div className={classnames(styles.divider)} />
}

export default Divider
