import Config from 'config'
import * as React from 'react'
import { kebabCase } from 'lodash'
import useAuth from 'hooks/useAuth'
import usePage from 'hooks/usePage'
import classnames from 'classnames'
import useQuery from 'hooks/useQuery'
import useMedia from 'hooks/useMedia'
import Avatar from '../../atoms/avatar'
import { getUserRole } from 'utils/auth'
import Text from 'components/atoms/text'
import useAppInfo from 'hooks/useAppInfo'
import ContactModal from '../contact-modal'
import Button from 'components/atoms/button'
import { MenuItem, UserRole } from 'types/index'
import { AuthContextProps } from 'providers/auth'
import useNotification from 'hooks/useNotification'
import LegalDocumentModal from '../legal-document-modal'
import NotificationButton from '../../molecules/notification-button'
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom'
import NavigationMenuItem from 'components/molecules/navigation-menu-item'
import {
    ArrowLeftSLine,
    ArrowRightSLine,
    CalendarCheckLine,
    CustomerService2Line, DraftLine,
    FlashlightFill,
    Home3Line,
    LogoFull,
    LogoutBoxLine,
    MedalLine,
    QuestionLine,
    RecordCircleLine,
    StarHalfLine,
    UserSettingsLine,
} from 'components/icons'

import styles from './style.module.css'

const items: MenuItem[] = [
    { url: '/', label: 'Anasayfa', icon: <Home3Line />, active: false, exact: true },
    { url: '/antrenmanlar', label: 'Antrenmanlarım', icon: <CalendarCheckLine />, active: false },
    { url: '/canli-yayin-takvimi', label: 'Canlı Yayınlar', icon: <RecordCircleLine style={{ fill: 'red' }} />, active: false },
    { url: '/abonelik', label: 'Abonelik', icon: <MedalLine />, active: false },
]

const NavigationBar = () => {
    const location = useLocation()
    const history = useHistory()
    const appInfo = useAppInfo()
    const auth = useAuth() as AuthContextProps
    const page = usePage()
    const params = useQuery()
    const userRole = getUserRole()
    const notifications = useNotification()
    const isMobile = useMedia('(max-width: 1024px)')

    const [title, setTitle] = React.useState('')
    const [showAccountSettingsModal, setShowAccountSettingsModal] = React.useState(false)
    const [showContact, setShowContact] = React.useState(false)
    const [showBackButton, setShowBackButton] = React.useState(false)

    const menuShown = params.get('menuShown')
    const dropdownShown = React.useMemo(() => menuShown === 'true' && isMobile, [menuShown, isMobile])
    const setDropdownShown = React.useCallback((isShown) => {
        if (isShown) {
            params.set('menuShown', 'true')
            history.push({ search: params.toString() })
        } else {
            params.delete('menuShown')
            history.replace({ search: params.toString() })
        }
    }, [history, params])

    const showModal = (initialPage: string) => {
        if (typeof auth.showModal === 'function') auth.showModal(initialPage)
    }

    React.useEffect(() => {
        document.body.style.overflow = dropdownShown ? 'hidden' : 'auto'

        if (!dropdownShown) {
            setShowAccountSettingsModal(false)
        }
    }, [dropdownShown])

    const renderItems = React.useMemo(() => {
        if (userRole === UserRole.ROLE_PERSONAL_TRAINER) {
            items[1] = { url: '/yayin-takvimi', label: 'Yayın Takvimi', icon: <CalendarCheckLine />, active: false }
            items[3] = { url: '/performans', label: 'Performans', icon: <FlashlightFill />, active: false }
        } else {
            items[1] = { url: '/antrenmanlar', label: 'Antrenmanlarım', icon: <CalendarCheckLine />, active: false }
            items[3] = { url: '/abonelik', label: 'Abonelik', icon: <MedalLine />, active: false }
        }

        if (!auth.isLoggedIn) {
            items[1].clickHandler = (() => auth.showModal?.('login'))
            items[2].clickHandler = (() => auth.showModal?.('login'))
            items[3].clickHandler = (() => auth.showModal?.('login'))
        } else {
            items[1].clickHandler = undefined
            items[2].clickHandler = undefined
            items[3].clickHandler = undefined
        }

        return (
            items.map((item, index) => (
                <NavigationMenuItem key={index.toString()} {...item} />
            ))
        )
    }, [auth, userRole])

    React.useEffect(() => {
            if (!auth.isLoggedIn) {
                setDropdownShown(false)
            }
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [auth.isLoggedIn])

    React.useEffect(() => {
        setShowBackButton(true)

        switch (location.pathname) {
            case '/antrenmanlar':
                setTitle('Antrenmanlarım')
                setShowBackButton(false)
                break
            case '/abonelik':
                setTitle('Abonelik')
                break
            case location.pathname.match(/^\/antrenor\//)?.input:
                setTitle('Antrenör Profili')
                break
            case '/bildirimler':
                setTitle('Bildirimler')
                setShowBackButton(false)
                break
            case '/canli-yayin-takvimi':
                setTitle('Canlı Yayınlar')
                setShowBackButton(false)
                break
            case location.pathname.match(/^\/ders-paketi\//)?.input:
                setTitle('Antrenman Paket Detayları')
                break
            case '/performans':
                setTitle('Performans Özeti')
                setShowBackButton(false)
                break
            case '/profilim':
                setTitle('Profilim')
                setShowBackButton(false)
                break
            case '/profilim/profilimi-guncelle':
                setTitle('Profilimi Güncelle')
                break
            case '/s-s-s':
                setTitle('Sıkça Sorulan Sorular')
                break
            case '/yayin-takvimi':
                setTitle('Yayın Takvimi')
                setShowBackButton(false)
                break
            default:
                setTitle('')
                break
        }

        if (showAccountSettingsModal) {
            setTitle('Hesap Ayarları')
            setShowBackButton(true)
        }
    }, [showAccountSettingsModal, location])

    const handleOnBack = () => {
        if (title !== 'Hesap Ayarları') {
            history.goBack()
            setDropdownShown(false)
        } else {
            setShowAccountSettingsModal(false)
        }
    }

    return (
        <div className={styles.navigationBar}>
            <div className={styles.container}>

                {isMobile && title ? (
                    <div className={styles.titleWrapper}>
                        {showBackButton && (
                            <div onClick={handleOnBack} className={styles.backButton}>
                                <ArrowLeftSLine />
                            </div>
                        )}
                        <Text weight='bold' size='xl'>
                            {title}
                        </Text>
                    </div>
                ) : (
                    <Link to='/'>
                        <LogoFull className={styles.logo} width={141} height={56} />
                    </Link>
                )}

                <div className={styles.menu}>
                    {renderItems}
                </div>

                {!auth.isLoggedIn && (
                    <>
                        <div className={styles.menuTrigger}>
                            <button onClick={() => showModal('entry')}>
                                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'>
                                    <path fill='none' d='M0 0h24v24H0z' />
                                    {!dropdownShown && <path d='M3 4h18v2H3V4zm0 7h18v2H3v-2zm0 7h18v2H3v-2z' />}
                                    {dropdownShown && (
                                        <path
                                            d='M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z' />
                                    )}
                                </svg>
                            </button>
                        </div>
                        <div className={styles.auth}>
                            <Button onClick={() => showModal('login')} type='gray'>
                                Giriş Yap
                            </Button>
                            <Button onClick={() => showModal('registerBase')} type='danger-2'>
                                Kayıt Ol
                            </Button>
                        </div>
                    </>
                )}

                {auth.isLoggedIn && (
                    <div className={styles.icons}>
                        {!isMobile && userRole === UserRole.ROLE_CUSTOMER && notifications.notifications && <NotificationButton notifications={notifications.notifications} />}

                        {isMobile && (
                            <>
                                {page.leadIcon && (
                                    <div className={classnames(styles.menuTrigger, styles.leadButton)}>
                                        <button onClick={() => page.leadClick?.()}>
                                            {page.leadIcon}
                                        </button>
                                    </div>
                                )}
                                <div className={styles.menuTrigger}>
                                    <button onClick={() => setDropdownShown(!dropdownShown)}>
                                        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'>
                                            <path fill='none' d='M0 0h24v24H0z' />
                                            {!dropdownShown && <path d='M3 4h18v2H3V4zm0 7h18v2H3v-2zm0 7h18v2H3v-2z' />}
                                            {dropdownShown && (
                                                <path
                                                    d='M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z' />
                                            )}
                                        </svg>
                                    </button>
                                </div>
                            </>
                        )}

                        {!isMobile && <Link to={'/profilim'}>
                            <Avatar user={auth.user} size={3.5} rounded />
                        </Link>}
                    </div>
                )}

            </div>

            {dropdownShown && (!showAccountSettingsModal ? (
                <div className={styles.dropdown}>
                    <div className={styles.dropdownItems}>
                        <a href={Config.linkLy.url} rel='noreferrer' target={'_blank'} onClick={() => setDropdownShown(false)} className={styles.dropdownItem}>
                            <StarHalfLine className={styles.icon} />
                            <span>Bizi Değerlendirin</span>
                            <ArrowRightSLine className={styles.icon2} />
                        </a>

                        <Link to='/s-s-s' onClick={() => setDropdownShown(false)} className={styles.dropdownItem}>
                            <QuestionLine className={styles.icon} />
                            <span>Sıkça Sorulan Sorular</span>
                            <ArrowRightSLine className={styles.icon2} />
                        </Link>

                        <div onClick={() => setShowAccountSettingsModal(true)} className={styles.dropdownItem}>
                            <UserSettingsLine className={styles.icon} />
                            <span>Hesap Ayarları</span>
                            <ArrowRightSLine className={styles.icon2} />
                        </div>

                        {userRole === UserRole.ROLE_CUSTOMER && (
                            <Link to='/abonelik' onClick={() => setDropdownShown(false)} className={styles.dropdownItem}>
                                <MedalLine className={styles.icon} />
                                <span>Abonelik</span>
                                <ArrowRightSLine className={styles.icon2} />
                            </Link>
                        )}

                        <div onClick={() => setShowContact(true)} className={styles.dropdownItem}>
                            <CustomerService2Line className={styles.icon} />
                            <span>İletişime Geçin</span>
                            <ArrowRightSLine className={styles.icon2} />
                        </div>

                        {appInfo.legalDocuments?.map(x => (
                            <div className={styles.dropdownItem}
                                 key={x.id + '_navDokuman'}
                                 onClick={() => {
                                     params.set('dokuman', kebabCase(x.title))
                                     history.replace({ search: params.toString() })
                                 }}>
                                <DraftLine className={styles.icon} />
                                <span>{x.title}</span>
                                <ArrowRightSLine className={styles.icon2} />
                            </div>
                        ))}

                        <div onClick={() => {
                            auth.logout?.()
                            history.push({ pathname: '/' })
                            setDropdownShown(false)
                        }} className={classnames(styles.dropdownItem, styles.dropdownItemLogout)}>
                            <LogoutBoxLine className={styles.icon} />
                            <span>Oturumu Kapat</span>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={styles.dropdown}>
                    <div className={styles.dropdownItems}>
                        <div className={styles['mobile-dropdown']}>
                            <NavLink onClick={() => {
                            }} to={'/profilim?sifre=degistir'}>
                                <Text size={'lg'} weight={'medium'}>Şifrenizi Değiştirin</Text><ArrowRightSLine />
                            </NavLink>

                            <NavLink onClick={() => {
                                setDropdownShown(false)
                            }} to={'/profilim/profilimi-guncelle'}>
                                <Text size={'lg'} weight={'medium'}>Profilimi Güncelle</Text><ArrowRightSLine />
                            </NavLink>

                            {appInfo.legalDocuments?.map(x => (
                                <NavLink key={x.id + '_dokuman'} to={'/profilim?dokuman=' + kebabCase(x.title)}>
                                    <Text size={'lg'} weight={'medium'}>{x.title}</Text><ArrowRightSLine />
                                </NavLink>
                            ))}

                            {userRole === UserRole.ROLE_CUSTOMER && (
                                <NavLink onClick={() => {
                                    setDropdownShown(false)
                                }} to={'/profilim?hesap=bilgilerimi-disari-aktar'}>
                                    <Text size={'lg'} weight={'medium'}>Bilgilerimi İndir</Text><ArrowRightSLine />
                                </NavLink>
                            )}

                            {userRole === UserRole.ROLE_CUSTOMER && (
                                <NavLink className={styles.danger} onClick={() => {
                                    setDropdownShown(false)
                                }} to={'/profilim?hesap=hesabimi-sil'}>
                                    <Text size={'lg'} weight={'medium'}>Hesabımı Sil</Text><ArrowRightSLine />
                                </NavLink>
                            )}
                        </div>
                    </div>
                </div>
            ))}
            <ContactModal isVisible={showContact} onModalClose={() => setShowContact(false)} />
            <LegalDocumentModal />
        </div>
    )
}
export default NavigationBar
