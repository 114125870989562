import * as React from 'react'
import classnames from 'classnames'
import { isFunction, isString } from 'lodash'
import NumberFormat from 'react-number-format'

import styles from './style.module.css'

export interface IInput extends Omit<Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChangeText'>, 'placeholder'> {
}

export interface ITextarea extends Omit<Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'onChangeText'>, 'placeholder'> {
}

interface InputBaseProps {
    label?: string
    placeholder?: string
    onChangeText?: (text: string) => void
    status?: 'default' | 'success' | 'danger'
    multiline?: boolean
    rows?: number
    maxCharCount?: number
    borderless?: boolean
    leadingComponent?: React.ReactNode
    trailingComponent?: React.ReactNode
    background?: 'default' | 'gray'
    format?: string
    enterKeyPress?: () => void

    [key: string]: any
}

export type InputProps = InputBaseProps & (ITextarea | IInput)

const Input = (
    {
        label,
        placeholder,
        value,
        leadingComponent,
        trailingComponent,
        borderless,
        onChange,
        onChangeText,
        onFocus,
        onBlur,
        status,
        multiline,
        rows,
        maxCharCount,
        background,
        format,
        enterKeyPress,
        disabled,
        className,
        ...props
    }: InputProps) => {
    const [val, setVal] = React.useState<string>(value as string)
    const [isFocused, setIsFocused] = React.useState<boolean>(false)
    const [isLabelActive, setIsLabelActive] = React.useState<boolean>(false)

    React.useEffect(() => {
        setVal(value as string)
    }, [value])

    React.useEffect(() => {
        setIsLabelActive((isString(val) && val.length > 0) || isFocused)
    }, [val, isFocused])

    React.useEffect(() => {
            const handleEnter = (event: any) => {
                if (event.keyCode === 13 && isFunction(enterKeyPress) && !disabled) {
                    enterKeyPress()
                }
            }
            window.addEventListener('keydown', handleEnter)

            return () => {
                window.removeEventListener('keydown', handleEnter)
            }
        },  // eslint-disable-next-line react-hooks/exhaustive-deps
        [val, disabled, enterKeyPress])

    const onChangeHandler = ((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { value: currentValue } = event.target
        if (maxCharCount && currentValue.length > maxCharCount) {
            return
        }

        isFunction(onChange) && onChange(event as any)
        isFunction(onChangeText) && onChangeText(currentValue)
        setVal(currentValue)
    })

    const onFocusHandler = ((event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setIsFocused(true)
        onFocus && onFocus(event as any)
    })

    const onBlurHandler = ((event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (!event.target.value.length || event.target.value.length === 0) {
            setIsFocused(false)
        }
        onBlur && onBlur(event as any)
    })

    const Leading = () => {
        if (!leadingComponent) return null

        return <>
            <div className={classnames(styles.leading)}>{leadingComponent}</div>
            <div className={styles['leading-divider']} />
        </>
    }

    const Trailing = () => {
        if (!trailingComponent) return null

        return <div className='flex items-center justify-center pr-4'>{trailingComponent}</div>
    }

    return (
        <div className={styles['input-base']}>
            <div
                className={classnames(styles['input-wrapper'], {
                    [styles.multiline]: multiline,
                    [styles.borderless]: borderless,
                    [styles.success]: status === 'success',
                    [styles.danger]: status === 'danger',
                    [styles[`background-${background}`]]: background,
                    [styles[`disabled`]]: disabled,
                })}
            >
                <Leading />
                <div className={classnames(styles['input-container'])}>
                    {label && (
                        <div
                            className={classnames(styles.label, {
                                [styles.active]: isLabelActive,
                                [styles.multiline]: multiline,
                            })}
                        >
                            {label}
                        </div>
                    )}
                    {multiline ? (
                            <>
                                {label && <div className={styles.labelCover} />}
                                <textarea
                                    className={classnames(styles.input, className, {
                                        [styles.labeled]: !!label,
                                    })}
                                    value={value}
                                    placeholder={placeholder}
                                    rows={rows}
                                    onChange={(event) => onChangeHandler(event)}
                                    onFocus={onFocusHandler}
                                    onBlur={onBlurHandler}
                                    disabled={disabled}
                                    {...(props as any)}
                                />
                            </>
                        ) :
                        isString(format) ? (
                            <NumberFormat
                                className={classnames(styles.input, className, {
                                    [styles.labeled]: !!label,
                                })}
                                placeholder={placeholder}
                                onChange={onChangeHandler}
                                onFocus={onFocusHandler}
                                onBlur={onBlurHandler}
                                value={value as string}
                                format={format}
                                mask={''}
                                autoFocus={false}
                                disabled={disabled}
                                {...(props as any)}
                            />
                        ) : (
                            <input
                                className={classnames(styles.input, className, {
                                    [styles.labeled]: !!label,
                                })}
                                value={value}
                                placeholder={placeholder}
                                onChange={onChangeHandler}
                                onFocus={onFocusHandler}
                                onBlur={onBlurHandler}
                                disabled={disabled}
                                {...(props as any)}
                            />
                        )
                    }
                </div>
                <Trailing />
            </div>
            {maxCharCount && (
                <div className={styles.maxCharCountWrapper}>
                    <div>Maksimum Karakter sayısı</div>
                    <div>
                        {' '}
                        {`${val ?? ''}`.length} / {maxCharCount}
                    </div>
                </div>
            )}
        </div>
    )
}

export default Input
