import { FC } from 'react'
import Config from '../config'
import { Helmet } from 'react-helmet-async'
import ErrorCard from 'components/organisms/error-card'
import NotFoundTemplate from 'components/templates/not-found'

const NotFoundScreen: FC = () => {
    return (<NotFoundTemplate>
        <Helmet>
            <title>Sayfa Bulunamadı | {Config.app.name}</title>
        </Helmet>
        <div>
            <ErrorCard type={'404'} />
        </div>
    </NotFoundTemplate>)
}

export default NotFoundScreen
