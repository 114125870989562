import { FC } from 'react'
import Text from 'components/atoms/text'

import styles from './style.module.css'
import classNames from 'classnames'
import { TrainingSetItem } from '@tetahq/bironbir_api_client'
import ImageWithFallback from '../../atoms/image-with-fallback'

type PlanCardContentProps = {
    scrollable?: boolean
    sets: TrainingSetItem[]
    onClickSet?: (setId: number) => void
}

const PlanCardContent: FC<PlanCardContentProps> = ({ sets, scrollable, onClickSet }) => {
    return (
        <div className={classNames(styles.wrapper, { [styles.scrollable]: scrollable })}>
            {sets.length < 1 && (
                <Text size='base' weight='medium' type='soft-blue' className={styles.center}>Setler Henüz Belirlenmedi</Text>
            )}

            {sets.map((set, i) => (
                <div key={set.id + 'planCard'} onClick={() => onClickSet && onClickSet(set.id!)}>
                    <div className={styles.item}>
                        <ImageWithFallback src={set.coverPhoto?.coverImage4X} fallbackSrc={'/images/fallbacks/250-250.png'} alt='' />
                        <div className={styles.itemBody}>
                            <Text weight='medium'>{set.header}</Text>
                            <Text size='sm'>{set.subheader}</Text>
                        </div>
                    </div>
                    {i !== sets.length - 1 && <div className={styles.separator} />}
                </div>
            ))}
        </div>
    )
}

export default PlanCardContent
