import * as React from 'react'
import * as Api from 'utils/api-service'
import {
    Account,
    Advertise,
    AppInfo,
    BroadcastProgram,
    CustomerService,
    LegalDocument,
    Live,
    Notification,
    PackageCourse,
    PersonalTrainer,
    SubscriptionPlan,
    System,
    Chat,
    OneSignal, Carousel,
} from 'utils/api-service'

export interface ApiContextInterface {
    Account: typeof Account;
    Advertise: typeof Advertise;
    AppInfo: typeof AppInfo;
    BroadcastProgram: typeof BroadcastProgram;
    Carousel: typeof Carousel;
    CustomerService: typeof CustomerService;
    LegalDocument: typeof LegalDocument;
    Live: typeof Live;
    Notification: typeof Notification;
    PackageCourse: typeof PackageCourse;
    PersonalTrainer: typeof PersonalTrainer;
    SubscriptionPlan: typeof SubscriptionPlan;
    System: typeof System;
    Chat: typeof Chat;
    OneSignal: typeof OneSignal;
}

export const ApiContext = React.createContext<ApiContextInterface | any>({})

export const ApiProvider: React.FC = ({ children }) => {
    return <ApiContext.Provider value={Api}>{children}</ApiContext.Provider>
}
