import moment from 'moment'
import * as React from 'react'
import Calendar from 'react-calendar'
import Text from 'components/atoms/text'
import useAppInfo from 'hooks/useAppInfo'
import Input from 'components/atoms/input'
import Button from 'components/atoms/button'
import ErrorBox from '../../atoms/error-box'
import Checkbox from 'components/atoms/checkbox'
import ListCard from 'components/atoms/list-card'
import { FitnessTargetOption } from '@tetahq/bironbir_api_client'

import styles from './style.module.css'
import Modal, { ModalSize, ModalType } from '../../atoms/modal'
import classnames from 'classnames'

type BodyInformationCardProps = {
    loading?: boolean
    onConfirm: (height: string, weight: string, age: Date, goals: FitnessTargetOption[]) => void
}

const BodyInformationCard: React.FC<BodyInformationCardProps> = ({ loading, onConfirm }) => {
    const appInfo = useAppInfo()
    const [height, setHeight] = React.useState('')
    const [weight, setWeight] = React.useState('')
    const [age, setAge] = React.useState<Date | undefined>(undefined)
    const [selectedGoals, setSelectedGoals] = React.useState<FitnessTargetOption[]>([])
    const [showAgeModal, setShowAgeModal] = React.useState<boolean>(false)

    const handleSubmit = () => {
        if (age) {
            onConfirm(height, weight, age, selectedGoals)
        }
    }

    const onListItemToggled = (item: FitnessTargetOption, status: boolean) => {
        if (status) {
            setSelectedGoals([...selectedGoals, item])
        } else {
            setSelectedGoals(selectedGoals.filter(r => r !== item))
        }
    }

    return (
        <>
            <div className={styles.inputs}>
                <Input
                    value={height}
                    onChangeText={setHeight}
                    label='Boyunuz'
                    type='numeric'
                    format='###'
                    trailingComponent={
                        <div className={styles.inputTrailing}>
                            <Text type='dimmed' size='sm' weight='medium'>
                                cm
                            </Text>
                        </div>
                    }
                />
                {height && height !== '' && (parseInt(height) < 100 || parseInt(height) > 300) &&
                <ErrorBox text={'Lütfen geçerli bir değer giriniz (100cm - 300cm)'} size={'sm'} align={'left'} />}
                <Input
                    value={weight}
                    onChangeText={setWeight}
                    label='Kilonuz'
                    type='numeric'
                    format='###'
                    trailingComponent={
                        <div className={styles.inputTrailing}>
                            <Text type='dimmed' size='sm' weight='medium'>
                                kg
                            </Text>
                        </div>
                    }
                />
                {weight && weight !== '' && (parseInt(weight) < 30 || parseInt(weight) > 300) &&
                <ErrorBox text={'Lütfen geçerli bir değer giriniz (30kg - 300kg)'} size={'sm'} align={'left'} />}

                <div className={styles.dateInput} onClick={() => setShowAgeModal(x => !x)}>
                    <div className={classnames(styles.dateInputText, { [styles.active]: age })}>
                        {age ? moment(age).format('YYYY-MM-DD') : 'Doğum Tarihiniz'}
                    </div>
                </div>

            </div>

            <div className={styles.list}>
                <ListCard title='Fitness Hedefiniz'>
                    <div className={styles.itemWrapper}>
                        {appInfo.fitnessTargetOptions?.map((fitnessTarget) => (
                            <ListCard.Item
                                key={fitnessTarget.id + 'fitnessTarget'}
                                trailing={
                                    <Checkbox
                                        checked={selectedGoals.includes(fitnessTarget)}
                                        onChange={s => onListItemToggled(fitnessTarget, s)}
                                    />
                                }
                            >
                                {fitnessTarget.from}
                            </ListCard.Item>
                        ))}
                    </div>
                </ListCard>
            </div>

            <div className={styles.actions}>
                <Button
                    loading={loading}
                    onClick={handleSubmit}
                    type='danger-2'
                    variation='block'
                    disabled={(height !== '' && (parseInt(height) < 60 || parseInt(height) > 310)) || (weight !== '' && (parseInt(weight) < 30 || parseInt(weight) > 300)) || !age || selectedGoals.length < 1}
                >
                    Devam Et
                </Button>
            </div>

            <Modal isVisible={showAgeModal} onModalClose={() => setShowAgeModal(false)} size={ModalSize.Small} type={ModalType.Centered} transparent>
                <Calendar
                    locale={'tr-TR'}
                    value={age}
                    maxDate={moment().subtract(18, 'years').toDate()}
                    minDate={moment().subtract(100, 'years').toDate()}
                    defaultActiveStartDate={moment().subtract(30, 'years').toDate()}
                    className={styles.calendar}
                    onClickDay={(value) => {
                        setAge(value)
                        setShowAgeModal(false)
                    }}
                />
            </Modal>
        </>
    )
}

export default BodyInformationCard
