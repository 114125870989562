import { FC } from 'react'

import styles from './style.module.css'

export { styles }

const NotificationsTemplate: FC = ({ children }) => {
    return (
        <div className={styles.wrapper}>{children}</div>
    )
}

export default NotificationsTemplate
