import Config from 'config'
import Slider from 'rc-slider'
import * as React from 'react'
import classnames from 'classnames'
import Text from '../../atoms/text'
import Card from '../../molecules/card'
import { RoomInfo } from '../live-modal'
import ListBox from '../../atoms/listbox'
import { MutatingDots } from 'react-loader-spinner'
import useMedia from '../../../hooks/useMedia'
import ToggleButton from '../../atoms/toggle-button'
import Modal, { ModalSize, ModalType } from '../../atoms/modal'
import { CameraOffLine, FullscreenExitLine, FullscreenLine, UserSettingsLine, VolumeMuteLine, VolumeUpLine } from 'components/icons'
import AgoraRTC, { AgoraVideoPlayer, AudienceLatencyLevelType, createClient, IAgoraRTCRemoteUser } from 'custom-agora-rtc-react'

import styles from '../agora-host/style.module.css'

// @ts-ignore
const client = createClient({ mode: 'live', codec: 'vp8', role: 'audience', clientRoleOptions: {level: AudienceLatencyLevelType.AUDIENCE_LEVEL_LOW_LATENCY}, audienceLatency: 1 })

// Toggle Agora Logs (4: None)
AgoraRTC.setLogLevel(4)

const AgoraAudience = ({ room, onlyVideo, onClick }: { room: RoomInfo, onlyVideo: boolean, onClick: () => void }) => {
    const isMobile = useMedia('(max-width: 1024px)')
    // Video Call States
    const [users, setUsers] = React.useState<IAgoraRTCRemoteUser[]>([])

    // User Room States
    const [isUserJoin, setUserJoin] = React.useState<boolean>(false)

    // Settings Modal States
    const [isModalVisible, setModalVisible] = React.useState(false)
    const [availableSpeakers, setAvailableSpeakers] = React.useState<MediaDeviceInfo[]>([])
    const [maxVolume] = React.useState(200)
    const [selectedSpeaker, setSelectedSpeaker] = React.useState(0)
    const [volume, setVolume] = React.useState(maxVolume)

    const [fullVideo, setFullVideo] = React.useState(true)

    // Get Device Camera And Microphone Options
    React.useEffect(() => {
        (async () => {
            if (isModalVisible) {
                const getSpeakers = await AgoraRTC.getPlaybackDevices(false)
                if (getSpeakers.length > 0) {
                    setAvailableSpeakers(getSpeakers)
                } else {
                    setAvailableSpeakers([])
                }
            }
        })()
    }, [isModalVisible])

    React.useEffect(() => {
        (async () => {
            if (!isUserJoin && room.token) {
                try {
                    await client().join(Config.agora.appId, room.channelName, room.token, room.uid)
                    setUserJoin(true)
                } catch (e) {
                    setUserJoin(false)
                }
            }
        })()
    }, [isUserJoin, room])

    React.useEffect(() => {
            users.map((user) => {
                if (user.hasAudio) {
                    user.audioTrack?.setPlaybackDevice(availableSpeakers[selectedSpeaker].deviceId)
                }
                return user
            })
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedSpeaker])

    React.useEffect(() => {
            users.map((user) => {
                if (user.hasAudio) {
                    user.audioTrack?.setVolume(volume)
                }
                return user
            })
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [volume])

    // Video Call Effects
    React.useEffect(() => {
            client().on('user-published', async (user, mediaType) => {
                await client().subscribe(user, mediaType)

                if (mediaType === 'video') {
                    setUsers((prevUsers) => [...prevUsers, user])
                }

                if (mediaType === 'audio') {
                    user.audioTrack?.setVolume(maxVolume)
                    user.audioTrack?.play()

                    if (selectedSpeaker !== 0) {
                        user.audioTrack?.setPlaybackDevice(availableSpeakers[selectedSpeaker].deviceId)
                    }
                }

            })

            client().on('user-unpublished', (user, type) => {
                if (type === 'audio') {
                    user.audioTrack?.stop()
                }
                if (type === 'video') {
                    setUsers((prevUsers) => prevUsers.filter((User) => User.uid !== user.uid))
                }
            })

            client().on('user-left', (user) => {
                setUsers((prevUsers) => prevUsers.filter((User) => User.uid !== user.uid))
            })
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [])

    React.useEffect(() => {
        return (() => {
            client().leave()
            setUserJoin(false)
        })
    }, [])

    return (
        <div className={styles.live}>
            <div className={styles['wrapper']}>
                <div className={classnames(styles['videos'])} onClick={() => onClick()}>
                    {users.length > 0 && users.map((user) => {
                        if (user.videoTrack) {
                            return (
                                <AgoraVideoPlayer
                                    className={classnames(styles['vid'], styles.video, { [styles.fullVideo]: fullVideo })}
                                    videoTrack={user.videoTrack} key={user.uid}
                                />
                            )
                        } else {
                            return (<div className={classnames(styles['vid'], styles.video, styles['no-cam'])}>
                                <CameraOffLine />
                            </div>)
                        }
                    })}

                    {users.length === 0 && (
                        <div className={classnames(styles['vid'], styles.video, styles['no-cam'])}>
                            <MutatingDots color={'#334362'} secondaryColor={'#e3352a'} height={100} width={100} />
                            <Text weight={'medium'} size={'2xl'}>Antrenör Bekleniyor...</Text>
                        </div>
                    )}
                </div>
                <div className={classnames(styles.footer, { [styles.hide]: onlyVideo && isMobile, [styles.notHide]: !onlyVideo && isMobile })}>
                    <ToggleButton active={!isModalVisible} onChange={() => setModalVisible(x => !x)} inactiveIcon={<UserSettingsLine />} activeIcon={<UserSettingsLine />} />
                    <ToggleButton active={!fullVideo} onChange={() => setFullVideo(x => !x)} inactiveIcon={<FullscreenExitLine />} activeIcon={<FullscreenLine />} />
                </div>
            </div>

            <Modal isVisible={isModalVisible} onModalClose={() => setModalVisible(false)} type={ModalType.Centered} size={ModalSize.Small}>
                <Card heading={{ title: 'Ayarlar', divider: true }} type={'light'}>
                    <div style={{ display: 'grid', gap: '1rem' }}>

                        <Text size={'lg'} type={'default'} weight={'default'} decoration={'default'}>Ses</Text>
                        <ListBox
                            value={selectedSpeaker}
                            text={availableSpeakers[selectedSpeaker]?.label ?? 'Ses'}
                            onChange={setSelectedSpeaker}
                            options={availableSpeakers}
                            disabled={availableSpeakers.length < 2}
                            valueIndexBased
                        />

                        <div className={styles.audioSlider}>
                            <VolumeMuteLine onClick={() => setVolume(0)} />
                            <Slider onChange={setVolume} max={maxVolume} min={0} value={volume} />
                            <VolumeUpLine onClick={() => setVolume(maxVolume)} />
                        </div>
                    </div>

                </Card>
            </Modal>
        </div>
    )
}

export default React.memo(AgoraAudience)
