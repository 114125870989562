import * as React from 'react'
import classNames from 'classnames'
import Text from 'components/atoms/text'
import ImageWithFallback from '../../atoms/image-with-fallback'
import { PackageCourseListItem } from '@tetahq/bironbir_api_client'
import Badge, { BadgeSize, BadgeType } from 'components/atoms/badge'

import styles from './style.module.css'

type TrainingSelectorCardProps = {
    selected?: boolean
    completed?: boolean
    course: PackageCourseListItem
    onClick: () => void
}

const TrainingSelectorCard: React.FC<TrainingSelectorCardProps> = ({ course, selected, completed,onClick }) => {
    //TODO: Completed bilgisi PackageCourseListItem içerisinde olduğu zaman bu alanlar düzeltilecek.

    return (
        <div onClick={onClick} className={classNames(styles.wrapper, { [styles.selected]: selected })}>
            <div className={styles.heading}>
                <ImageWithFallback
                    src={course.coverPhoto?.coverImage1X}
                    srcSet={course.coverPhoto ? `${course.coverPhoto.coverImage1X}, ${course.coverPhoto.coverImage2X} 1.5x, ${course.coverPhoto.coverImage3X} 2x, ${course.coverPhoto.coverImage4X} 3x,` : undefined}
                    fallbackSrc={'/images/fallbacks/250-250.png'}
                />
                {completed && (
                    <Badge size={BadgeSize.Small} type={BadgeType.Green} rounded>
                        TAMAMLANDI
                    </Badge>
                )}
            </div>
            <Text weight='bold' className={styles.name}>{course.name}</Text>
            <Text size='sm' weight='medium' className={styles.description}>
                {course.description}
            </Text>
            <Text size='xs' weight='medium'>
                {course.personalTrainer?.fullName}
            </Text>
        </div>
    )
}

export default TrainingSelectorCard
