import Button from 'components/atoms/button'
import Text from 'components/atoms/text'
import * as React from 'react'
import ReactCodeInput from 'react-verification-code-input'

import styles from './style.module.css'
import RemainingDuration from 'components/atoms/remaining-duration'
import { useEffect } from 'react'

type VerifyEmailCardProps = {
    loading?: boolean
    onConfirm: (code: string) => void
    onCodeResend: () => void
    nextResendAt: string
    email: string
}

const VerifyEmailCard: React.FC<VerifyEmailCardProps> = ({ loading, onConfirm, onCodeResend, nextResendAt, email }) => {
    const [code, setCode] = React.useState('')
    const [timeEnd, setTimeEnd] = React.useState(false)

    useEffect(() => {
        setTimeEnd(false)
    }, [nextResendAt])

    const handleSubmit = () => {
        onConfirm(code)
    }

    const handleResend = () => {
        onCodeResend()
    }

    return (
        <>
            <div className={styles.inputWrapper}>
                <ReactCodeInput onChange={setCode} className={styles.input} fields={6} fieldWidth={52} fieldHeight={52} />
            </div>

            <div className={styles.remainingTime}>
                <Text size='sm' type='dimmed' weight='medium'>
                    <RemainingDuration date={nextResendAt} onEnd={() => setTimeEnd(true)} />
                </Text>
            </div>

            <div className={styles.resend}>
                <Text size='sm' weight='medium'>
                    {email}
                </Text>
                <Button type={'danger-link'} onClick={handleResend} disabled={!timeEnd}>
                    <Text size='sm' weight='medium' type='danger'>
                        Tekrar Gönder
                    </Text>
                </Button>
            </div>

            <div className={styles.actions}>
                <Button loading={loading} onClick={handleSubmit} type='danger-2' variation='block' disabled={code.length < 6}>
                    E-Postamı Doğrula
                </Button>
            </div>
        </>
    )
}

export default VerifyEmailCard
