import moment from 'moment'
import * as React from 'react'
import Config from '../config'
import useApi from 'hooks/useApi'
import useQuery from 'hooks/useQuery'
import { toast } from 'react-toastify'
import Text from 'components/atoms/text'
import useMedia from '../hooks/useMedia'
import { TailSpin } from 'react-loader-spinner'
import useAppInfo from 'hooks/useAppInfo'
import Input from 'components/atoms/input'
import useDebounce from 'hooks/useDebounce'
import { Helmet } from 'react-helmet-async'
import { isUndefined, kebabCase } from 'lodash'
import AuthLink from 'components/atoms/auth-link'
import ErrorBox from 'components/atoms/error-box'
import { ApiContextInterface } from 'providers/api'
import { Link, useHistory } from 'react-router-dom'
import HomeTemplate from 'components/templates/home'
import PullToRefresh from 'react-simple-pull-to-refresh'
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper'
import BroadcastCard from 'components/molecules/broadcast-card'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'
import PersonalTrainerCard from 'components/molecules/personal-trainer-card'
import PackagesCoursesCard from 'components/molecules/packages-courses-card'
import { ArrowLeftSLine, ArrowRightSLine, LogoBandColored, Search2Line } from 'components/icons'
import { BroadcastProgramListItem, CarouselImageListItem, InfinityPagination, PackageCourseListItem, PersonalTrainerListItem } from '@tetahq/bironbir_api_client'

import styles from 'components/templates/home/style.module.css'
import ImageWithFallback from '../components/atoms/image-with-fallback'
import BroadcastReplayModal from '../components/organisms/broadcast-replay-modal'
import useAuth from '../hooks/useAuth'
import { AuthContextProps } from '../providers/auth'

SwiperCore.use([Navigation, Pagination, Autoplay])

const HomeScreen: React.FC = () => {
  const api = useApi() as ApiContextInterface
  const params = useQuery()
  const appInfo = useAppInfo()
  const history = useHistory()
  const auth = useAuth() as AuthContextProps
  const isMobile = useMedia('(max-width: 1024px)')

  const [initialBroadcasts, setInitialBroadcasts] = React.useState<BroadcastProgramListItem[] | undefined>(undefined)
  const [initialTrainers, setInitialTrainers] = React.useState<PersonalTrainerListItem[] | undefined>(undefined)
  const [initialPackagesCourses, setInitialPackagesCourses] = React.useState<PackageCourseListItem[] | undefined>(undefined)

  const [broadcasts, setBroadcasts] = React.useState<BroadcastProgramListItem[]>([])
  const [trainers, setTrainers] = React.useState<PersonalTrainerListItem[]>([])
  const [packageCourses, setPackagesCourses] = React.useState<PackageCourseListItem[]>([])

  const [trainersPagination, setTrainersPagination] = React.useState<InfinityPagination>({ isNextPageAvailable: false })
  const [packageCoursePagination, setPackageCoursePagination] = React.useState<InfinityPagination>({ isNextPageAvailable: false })

  const [query, setQuery] = React.useState<string>('')
  const debouncedQuery = useDebounce<string>(query, 500)

  const [trainerLoading, setTrainerLoading] = React.useState<boolean>(true)
  const [broadcastLoading, setBroadcastLoading] = React.useState<boolean>(true)
  const [packageCourseLoading, setPackageCourseLoading] = React.useState<boolean>(true)

  const [carouselImages, setCarouselImages] = React.useState<CarouselImageListItem[] | undefined>(undefined)

  const [swipeHandle, setSwipeHandle] = React.useState(false)

  const [selectedBroadcast, setSelectedBroadcast] = React.useState<BroadcastProgramListItem | undefined>(undefined)

  const broadcastsPrevRef = React.useRef(null)
  const broadcastsNextRef = React.useRef(null)
  const trainersPrevRef = React.useRef(null)
  const trainersNextRef = React.useRef(null)
  const packageCoursesPrevRef = React.useRef(null)
  const packageCoursesNextRef = React.useRef(null)

  const getPackageCoursesNextPage = async () => {
    if (packageCoursePagination.isNextPageAvailable) {
      setPackageCourseLoading(true)
      api.PackageCourse.listPackageCourses(packageCoursePagination.firstIdOfNextPage, false, debouncedQuery !== '' ? debouncedQuery : undefined).then(result => {
        const data = result.data.packageCourses
        const pagination = result.data.pagination

        if (data && pagination) {
          setPackagesCourses((x) => [...x, ...(data)])
          setInitialPackagesCourses((x) => {
            if (x) {
              return [...x, ...(data)]
            }
          })
          setPackageCoursePagination(pagination)
        }
      }).catch(() => {
        setPackageCoursePagination({ isNextPageAvailable: false })
      }).finally(() => {
        setPackageCourseLoading(false)
      })
    }
  }

  const getTrainersNextPage = async () => {
    if (trainersPagination.isNextPageAvailable) {
      setTrainerLoading(true)
      try {
        await api.PersonalTrainer.listPersonalTrainers(trainersPagination.firstIdOfNextPage, debouncedQuery !== '' ? debouncedQuery : undefined).then(result => {
          const data = result.data.personalTrainers
          const pagination = result.data.pagination

          if (data && pagination) {
            setTrainers((x) => [...x, ...(data)])
            setInitialTrainers((x) => {
              if (x) {
                return [...x, ...(data)]
              }
            })
            setTrainersPagination(pagination)
          }
        })
      } catch {
        setTrainersPagination({ isNextPageAvailable: false })
      }
      setTrainerLoading(false)
    }
  }

  const searchInQuery = React.useCallback((text?: string) => {
    if (isUndefined(text)) {
      return false
    }

    return text.toLowerCase().indexOf(debouncedQuery.toLowerCase()) !== -1
  }, [debouncedQuery])

  const getPersonalTrainers = React.useCallback(async () => {
    setTrainerLoading(true)
    try {
      const { data } = await api.PersonalTrainer.listPersonalTrainers(undefined, debouncedQuery !== '' ? debouncedQuery : undefined)
      setTrainers(data.personalTrainers ?? [])
      isUndefined(initialTrainers) && setInitialTrainers(data.personalTrainers ?? [])
      setTrainersPagination(data.pagination ?? { isNextPageAvailable: false })
    } catch {
    }
    setTrainerLoading(false)
  }, [api.PersonalTrainer, debouncedQuery, initialTrainers])

  const getPackageCourses = React.useCallback(async () => {
    setPackageCourseLoading(true)
    try {
      const { data } = await api.PackageCourse.listPackageCourses(undefined, false, debouncedQuery !== '' ? debouncedQuery : undefined)
      setPackagesCourses(data.packageCourses ?? [])
      isUndefined(initialPackagesCourses) && setInitialPackagesCourses(data.packageCourses ?? [])
      setPackageCoursePagination(data.pagination ?? { isNextPageAvailable: false })
    } catch {
    }
    setPackageCourseLoading(false)
  }, [api.PackageCourse, debouncedQuery, initialPackagesCourses])

  const handleRefresh = async () => {
    setInitialBroadcasts(undefined)
    setInitialPackagesCourses(undefined)
    setInitialTrainers(undefined)
  }

  const CarouselWrapper = (image: CarouselImageListItem) => {
    const content = (<ImageWithFallback src={image.image.image4x} fallbackSrc='/images/fallbacks/1400-1000.png' alt='' />)

    if (image.link) {
      return image.link.startsWith('http', 0) ?
        <a href={image.link} target='_blank' rel='noreferrer' className={styles.carouselImage}>{content}</a> :
        <Link to={image.link} className={styles.carouselImage}>{content}</Link>
    } else {
      return <div className={styles.carouselImage} key={image.id + '_carousel'}>{content}</div>
    }
  }

  const BroadcastWrapper = (broadcast: BroadcastProgramListItem) => {
    if (broadcast.replayVideoHlsUrl) {
      return (<div onClick={() => setSelectedBroadcast(broadcast)} key={broadcast.id + '_broadcast'}>
        <BroadcastCard broadcast={broadcast} />
      </div>)
    }

    if (!broadcast.isLive && broadcast.isEnded && !broadcast.isUserJoined && auth.isLoggedIn && !auth.user?.isPremium) {
      return (<AuthLink
        onClick={() => toast.warning('Katılmadığınız Canlı Yayının Tekrarını İzleyebilmek İçin Premium Olmalısınız', { toastId: 'userNotJoinedBroadcast' + broadcast.id })}
        key={broadcast.id + '_broadcast'}>
        <BroadcastCard broadcast={broadcast} />
      </AuthLink>)
    }

    if ((!broadcast.isLive && broadcast.isEnded) && ((auth.isLoggedIn && auth.user?.isPremium) || broadcast.isUserJoined)) {
      return (<AuthLink
        onClick={() => toast.warning('Canlı Yayının Tekrarı Yüklenmektedir. Kısa Süre Sonra Tekrar Deneyiniz.', { toastId: 'broadcastVideoNotUploadedYet' + broadcast.id })}
        key={broadcast.id + '_broadcast'}>
        <BroadcastCard broadcast={broadcast} />
      </AuthLink>)
    }

    if (!broadcast.isLive && !broadcast.isEnded) {
      return (<div onClick={() => toast.warning('Canlı Yayın Henüz Başlamadı', { toastId: 'broadcastNotYetStarted' + broadcast.id })}
                   key={broadcast.id + '_broadcast'}>
        <BroadcastCard broadcast={broadcast} />
      </div>)
    }

    return (<AuthLink to={`/canli-yayin/${broadcast.agoraRoomId}`} key={broadcast.id + '_broadcast'}>
      <BroadcastCard broadcast={broadcast} />
    </AuthLink>)
  }

  React.useEffect(() => {
    if (isUndefined(carouselImages)) {
      (async () => {
        try {
          const { data } = await api.Carousel.listCarouselImages()
          setCarouselImages(data.carouselImages)
        } catch {
        }
      })()
    }
  }, [carouselImages, api.Carousel])

  React.useEffect(() => {
    if (isUndefined(initialTrainers)) {
      getPersonalTrainers()
    }
  }, [initialTrainers, getPersonalTrainers])

  React.useEffect(() => {
    (async () => {
      setBroadcastLoading(true)
      try {
        const { data } = await api.BroadcastProgram.listBroadcastPrograms(undefined, true)
        let findOne = false
        let computedData: BroadcastProgramListItem[] = []
        data.broadcastPrograms?.reverse().forEach(x => {
          //Başlangıç tarihi şimdiden sonra olan ilk item seçildi
          if (moment(x.startsAt).isAfter(moment.now())) {
            if (findOne) {
              return false
            }
            findOne = true
            return computedData.push(x)
          } else {
            return computedData.push(x)
          }
        })
        computedData = computedData.reverse()
        computedData = computedData.sort(function(x, y) {
          return (x.isLive === y.isLive) ? 0 : x.isLive ? -1 : 1
        })
        setBroadcasts(computedData ?? [])
        isUndefined(initialBroadcasts) && setInitialBroadcasts(computedData ?? [])
      } catch {
      }
      setBroadcastLoading(false)
    })()
  }, [auth.isLoggedIn, api.BroadcastProgram, initialBroadcasts])

  React.useEffect(() => {
    if (isUndefined(initialPackagesCourses)) {
      getPackageCourses()
    }
  }, [initialPackagesCourses, getPackageCourses])

  React.useEffect(() => {
      if (debouncedQuery !== '') {
        initialTrainers && getPersonalTrainers()
        initialBroadcasts && setBroadcasts(initialBroadcasts.filter(z => searchInQuery(z.title) || searchInQuery(z.personalTrainer?.visibleName)))
        initialPackagesCourses && getPackageCourses()
      } else {
        setTrainers(initialTrainers ?? [])
        setBroadcasts(initialBroadcasts ?? [])
        setPackagesCourses(initialPackagesCourses ?? [])
      }
    },// eslint-disable-next-line react-hooks/exhaustive-deps
    [searchInQuery, debouncedQuery, initialTrainers, initialBroadcasts, initialPackagesCourses])

  return (
    <>
      <HomeTemplate>
        <Helmet>
          <title>Anasayfa | {Config.app.name}</title>
        </Helmet>

        <PullToRefresh onRefresh={handleRefresh} isPullable={isMobile && !swipeHandle} pullingContent='' resistance={1} pullDownThreshold={40}>
          <div>
            <div className={styles['input-wrapper']}>
              <Input
                onChangeText={setQuery}
                placeholder={isMobile ? 'Arama Yap...' : 'Canlı Yayın, Eğitmen veya Antrenman Paketi Ara...'}
                background={'gray'}
                leadingComponent={<Search2Line />} borderless
              />
            </div>

            {!isUndefined(carouselImages) && carouselImages.length > 0 && (
              <div className={styles.carouselWrapper}>
                <Swiper
                  spaceBetween={20}
                  slidesPerView={1}
                  autoplay={{ delay: 3000, pauseOnMouseEnter: false }}
                  onSliderFirstMove={() => setSwipeHandle(true)}
                  onTouchEnd={() => setSwipeHandle(false)}
                  pagination={{ clickable: true, el: '.swiper-pagination' }}
                  loop
                >
                  {carouselImages.map(image => <SwiperSlide key={image.id + '_carousel'}>{CarouselWrapper(image)}</SwiperSlide>)}
                </Swiper>
                <div className='swiper-pagination' />
              </div>
            )}

            {query.length > 1 && trainers.length === 0 && broadcasts.length === 0 && packageCourses.length === 0 && (
              <div className={styles['error-box']}>
                <ErrorBox text={'Aramanızla İlgili Hiç Sonuç Bulunamadı'} align={'center'} size={'sm'} />
              </div>
            )}

            {broadcasts.length > 0 && (
              <>
                <div className={styles['swiper-header']}>
                  <Text type={'default'} size={'2xl'} weight={'bold'}>Canlı Yayınlar</Text>
                  {broadcastLoading && <TailSpin color={'#334362'} height={20} width={20} />}
                </div>
                <Swiper
                  grabCursor
                  slidesPerView={'auto'}
                  slidesOffsetBefore={16}
                  onSliderFirstMove={() => setSwipeHandle(true)}
                  onTouchEnd={() => setSwipeHandle(false)}
                  navigation={{
                    prevEl: broadcastsPrevRef.current,
                    nextEl: broadcastsNextRef.current,
                  }}
                >
                  {broadcasts.map((broadcast) => (
                    <SwiperSlide key={broadcast.id + '_broadcast'} style={{ width: 236 }}>
                      {BroadcastWrapper(broadcast)}
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div className={styles['next-prev-buttons']}>
                  <div ref={broadcastsPrevRef}>
                    <ArrowLeftSLine />
                  </div>
                  <div ref={broadcastsNextRef}>
                    <ArrowRightSLine />
                  </div>
                </div>
              </>
            )}

            {trainers.length > 0 && (
              <>
                <div className={styles['swiper-header']}>
                  <Text type={'default'} size={'2xl'} weight={'bold'}>Eğitmenler</Text>
                  {trainerLoading && <TailSpin color={'#334362'} height={20} width={20} />}
                </div>
                <Swiper
                  grabCursor
                  slidesPerView={'auto'}
                  slidesOffsetBefore={16}
                  onSliderFirstMove={() => setSwipeHandle(true)}
                  onTouchEnd={() => setSwipeHandle(false)}
                  onSlideChange={(sw) => {
                    if (sw.isEnd) {
                      getTrainersNextPage()
                    }
                  }}
                  navigation={{
                    prevEl: trainersPrevRef.current,
                    nextEl: trainersNextRef.current,
                  }}
                >
                  {trainers.map((trainer) => (
                    <SwiperSlide key={trainer.id + '_trainer'} style={{ width: isMobile ? 180 : 220 }}>
                      <AuthLink to={`/antrenor/${trainer.metadataId}`}>
                        <PersonalTrainerCard personalTrainer={trainer} />
                      </AuthLink>
                    </SwiperSlide>
                  ))}

                </Swiper>
                <div className={styles['next-prev-buttons']}>
                  <div ref={trainersPrevRef}>
                    <ArrowLeftSLine />
                  </div>
                  <div ref={trainersNextRef}>
                    <ArrowRightSLine />
                  </div>
                </div>
              </>
            )}

            {packageCourses.length > 0 && (
              <>
                <div className={styles['swiper-header']}>
                  <Text type={'default'} size={'2xl'} weight={'bold'}>Antrenman Paketleri</Text>
                  {packageCourseLoading && <TailSpin color={'#334362'} height={20} width={20} />}
                </div>
                <Swiper
                  grabCursor
                  slidesPerView={'auto'}
                  slidesOffsetBefore={16}
                  onSliderFirstMove={() => setSwipeHandle(true)}
                  onTouchEnd={() => setSwipeHandle(false)}
                  onSlideChange={(sw) => {
                    if (sw.isEnd) {
                      getPackageCoursesNextPage()
                    }
                  }}
                  navigation={{
                    prevEl: packageCoursesPrevRef.current,
                    nextEl: packageCoursesNextRef.current,
                  }}
                >
                  {packageCourses.map((packageCourse) => (
                    <SwiperSlide key={packageCourse.id + '_packageCourse'} style={{ width: isMobile ? 180 : 220 }}>
                      <AuthLink to={`/ders-paketi/${packageCourse.id}`} className={styles.dropdownItem}>
                        <PackagesCoursesCard packageCourse={packageCourse} />
                      </AuthLink>
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div className={styles['next-prev-buttons']}>
                  <div ref={packageCoursesPrevRef}>
                    <ArrowLeftSLine />
                  </div>
                  <div ref={packageCoursesNextRef}>
                    <ArrowRightSLine />
                  </div>
                </div>
              </>
            )}
          </div>
        </PullToRefresh>
        <BroadcastReplayModal broadcast={selectedBroadcast} onClose={() => setSelectedBroadcast(undefined)} />
      </HomeTemplate>
      <div className={styles.footer}>
        <LogoBandColored />
        <div>
          {appInfo.legalDocuments?.map(x => (
            <div
              key={x.id + '_dokuman'}
              onClick={() => {
                params.set('dokuman', kebabCase(x.title))
                history.replace({ search: params.toString() })
              }}
            >
              {x.title}
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default HomeScreen
