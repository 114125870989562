import * as React from 'react'
import Config from '../config'
import { UserRole } from 'types'
import useApi from 'hooks/useApi'
import Moment from 'react-moment'
import classnames from 'classnames'
import usePage from 'hooks/usePage'
import moment from 'moment-timezone'
import useAuth from 'hooks/useAuth'
import useMedia from 'hooks/useMedia'
import { toast } from 'react-toastify'
import Text from 'components/atoms/text'
import { getUserRole } from 'utils/auth'
import NotFoundScreen from './not-found'
import { ThreeDots } from 'react-loader-spinner'
import { Helmet } from 'react-helmet-async'
import Card from 'components/molecules/card'
import { useParams } from 'react-router-dom'
import Divider from 'components/atoms/divider'
import { AuthContextProps } from 'providers/auth'
import ErrorBox from 'components/atoms/error-box'
import { roundHalf } from '../utils/number-helpers'
import { ApiContextInterface } from 'providers/api'
import ErrorCard from 'components/organisms/error-card'
import InfiniteScroll from 'react-infinite-scroll-component'
import ActivityCard from 'components/molecules/activity-card'
import ImageWithFallback from 'components/atoms/image-with-fallback'
import PTReportModalCard from 'components/organisms/pt-report-modal-card'
import MyProfileTemplate, { styles } from 'components/templates/my-profile'
import PTProfileNavigation from 'components/organisms/pt-profile-navigation'
import { CalendarTodoLine, InformationFill, StarFill, StarHalfLine } from 'components/icons'
import { ActivityListItem, GetPublicPersonalTrainerDetailsQueryResult, InfinityPagination } from '@tetahq/bironbir_api_client'

const PersonalTrainerScreen: React.FC = () => {
    const api = useApi() as ApiContextInterface
    const auth = useAuth() as AuthContextProps
    const pager = usePage()
    const userRole = getUserRole()
    const isMobile = useMedia('(max-width: 1024px)')
    const { id } = useParams<{ id?: string }>()

    const [loading, setLoading] = React.useState<boolean>(true)

    const [activities, setActivities] = React.useState<ActivityListItem[]>([])
    const [activeTab, setActiveTab] = React.useState<number>(0)

    const [pagination, setPagination] = React.useState<InfinityPagination>({ isNextPageAvailable: false })
    const [personalTrainer, setPersonalTrainer] = React.useState<GetPublicPersonalTrainerDetailsQueryResult | undefined>(undefined)

    const [showReportModal, setShowReportModal] = React.useState<boolean>(false)

    React.useEffect(() => {
        if (userRole === UserRole.ROLE_CUSTOMER) {
            setActiveTab(1)
        }
    }, [userRole])

    React.useEffect(() => {
        if (typeof personalTrainer !== 'undefined' && typeof pager.leadIcon === 'undefined' && userRole === UserRole.ROLE_CUSTOMER) {
            pager.setLeadIcon?.(<InformationFill />)
            pager.setLeadClick?.(() => () => setShowReportModal(true))
        }
    }, [personalTrainer, pager, userRole])

    React.useEffect(() => {
        (async () => {
            setLoading(true)
            try {
                if (typeof id !== 'undefined' && auth.isLoggedIn) {
                    const { data } = await api.PersonalTrainer.getPublicPersonalTrainerDetails(parseInt(id))
                    setPersonalTrainer(data)
                }
            } catch {
            }
            setLoading(false)
        })()
    }, [id, api.PersonalTrainer, auth.isLoggedIn])

    React.useEffect(() => {
        if (typeof id !== 'undefined' && auth.isLoggedIn) {
            (async () => {
                try {
                    const { data } = await api.PersonalTrainer.listPersonalTrainerActivities(parseInt(id))
                    if (data.activities) {
                        setActivities(data.activities)
                        setPagination(data.pagination)
                    }
                } catch {
                }
            })()
        }
    }, [id, api.PersonalTrainer, auth.isLoggedIn])

    React.useEffect(() => {
            return (() => {
                pager.setLeadIcon?.(undefined)
                pager.setLeadClick?.(() => () => {
                })
            })
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [])

    const handleReport = async (title: string, content: string) => {
        try {
            await api.CustomerService.reportPersonalTrainer({ id: personalTrainer?.metadataId, title, content })
            setShowReportModal(false)
            toast.success('Bildiriminiz için teşekkürler')
        } catch {
            toast.error('Bildirim gönderilemedi, Tekrar deneyiniz')
        }
    }

    const renderActivities = React.useMemo(() => {
        const loadNextActivities = async () => {
            if (typeof id !== 'undefined' && pagination.isNextPageAvailable) {
                try {
                    const { data } = await api.PersonalTrainer.listPersonalTrainerActivities(parseInt(id), pagination.firstIdOfNextPage)
                    if (data.activities) {
                        setActivities(x => [...x, ...data.activities])
                        setPagination(data.pagination)
                    }
                } catch {
                    setPagination({ isNextPageAvailable: false })
                }
            }
        }

        return (
            <div>
                {!isMobile && (
                    <Card type={'light'}>
                        <Text size='xl' weight={'medium'}>Aktivite</Text>
                    </Card>
                )}
                <InfiniteScroll
                    next={loadNextActivities}
                    hasMore={pagination.isNextPageAvailable ?? false}
                    loader={<div className={styles.center}><ThreeDots color={'#334362'} height={50} width={50} /></div>}
                    dataLength={activities.length}
                    endMessage={<ErrorBox style={{ marginTop: '1rem' }} text={'Daha Fazla Aktivite Yok'} size={'sm'} align={'center'} />}
                >
                    <Card type={'light'} paddingles>
                        {activities.map((activity, i) => {
                            return (
                                <ActivityCard activity={activity} isLastItem={i === activities.length - 1} key={activity.id + 'activity'} />
                            )
                        })}
                    </Card>
                </InfiniteScroll>
            </div>
        )
    }, [activities, isMobile, pagination, api.PersonalTrainer, id])

    const renderDescription = React.useMemo(() => {
        if (!personalTrainer) {
            return <></>
        }

        return (
            <div className={styles.descriptionCard}>
                <Card type={'light'}>
                    {!isMobile && (
                        <>
                            <Text size='xl' weight={'medium'}>Kısa Açıklama</Text>
                            <Divider />
                        </>
                    )}
                    <Text type={'soft-blue'} weight={'medium'}>
                        {personalTrainer.description && personalTrainer.description.length > 0 ? (
                            personalTrainer.description
                        ) : (
                            'Henüz Açıklama Yok'
                        )}
                    </Text>
                </Card>
            </div>

        )
    }, [personalTrainer, isMobile])


    const renderAbout = React.useMemo(() => {
        if (!personalTrainer) {
            return <></>
        }

        return (
            <Card type={'light'}>
                {!isMobile && (
                    <>
                        <Text size='xl' weight={'medium'}>Hakkında</Text>
                        <Divider />
                    </>
                )}

                <div className={styles['about-card-wrapper']}>
                    <div className={styles['about-card']}>
                        <CalendarTodoLine />
                        <Text type={'default'} weight={'medium'}>Yaş</Text>
                        <Text type={'soft-blue'} weight={'medium'}><Moment diff={personalTrainer.birthdate} unit='years'>{moment.now()}</Moment></Text>
                    </div>

                    <div className={styles['about-card']}>
                        <CalendarTodoLine />
                        <Text type={'default'} weight={'medium'}>Boy</Text>
                        <Text type={'soft-blue'} weight={'medium'}>{personalTrainer.height}</Text>
                    </div>

                    <div className={styles['about-card']}>
                        <CalendarTodoLine />
                        <Text type={'default'} weight={'medium'}>Kilo</Text>
                        <Text type={'soft-blue'} weight={'medium'}>{personalTrainer.weight}</Text>
                    </div>
                </div>

            </Card>
        )
    }, [personalTrainer, isMobile])

    if (!auth.isLoggedIn) {
        return (
            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <ErrorCard type={'401'} />
            </div>
        )
    }

    if (loading) {
        return (
            <Helmet>
                <title>Yükleniyor... | {Config.app.name}</title>
            </Helmet>
        )
    } else if (typeof personalTrainer === 'undefined') {
        return (<NotFoundScreen />)
    }

    const Stars = (() => {
        let views: JSX.Element[] = []

        const totalStars = roundHalf(personalTrainer.rate)

        if (totalStars === 0) {
            return <>Yeni</>
        }
        for (let i = 0; i < totalStars; i++) {
            if ((totalStars - i) === 0.5) {
                views.push(<StarHalfLine />)
            } else {
                views.push(<StarFill />)
            }
        }

        return <>{views}</>
    })

    return (
        <MyProfileTemplate>
            <Helmet>
                <title>{personalTrainer.name + ' ' + personalTrainer.surname} Profili | {Config.app.name}</title>
            </Helmet>

            {/*Mobile View*/}

            {isMobile && <div className={styles['profile-card']}>

                {/*My Profile Page*/}

                <ImageWithFallback src={personalTrainer.avatar.avatar4X ? personalTrainer.avatar.avatar4X.replace('small_user_avatar', 'user_avatar') : ' '}
                                   fallbackSrc={'/images/fallbacks/250-250.png'} />
                <div className={styles['mobile-area']}>
                    <div className={styles['mobile-user-card']}>
                        <div>
                            <Text weight={'medium'}>{personalTrainer.name + ' ' + personalTrainer.surname}</Text>
                        </div>

                        <div className={styles.badge}>{<Stars />}</div>
                    </div>

                    {userRole !== UserRole.ROLE_CUSTOMER ? <div className={styles.tabs}>
                        <div onClick={() => setActiveTab(0)} className={classnames(styles.tab, { [styles.activeTab]: activeTab === 0 })}>Aktivite</div>
                        <div onClick={() => setActiveTab(1)} className={classnames(styles.tab, { [styles.activeTab]: activeTab === 1 })}>Hakkında</div>
                    </div> : <div style={{ marginBottom: '1rem' }} />}

                    {activeTab === 0 && userRole !== UserRole.ROLE_CUSTOMER && renderActivities}

                    {activeTab === 1 && (<>{renderDescription} {renderAbout}</>)}

                </div>


            </div>}

            {/*Web View*/}

            {!isMobile && <div className={styles['web-container']}>
                <div className={styles['web-wrapper']}>

                    <PTProfileNavigation Stars={<Stars />} personalTrainer={personalTrainer} reportClick={() => setShowReportModal(true)} />

                    {/*My Profile Page*/}

                    <div className={styles['web-activities']}>

                        {userRole !== UserRole.ROLE_CUSTOMER ? renderActivities : renderDescription}

                        <div>
                            {userRole !== UserRole.ROLE_CUSTOMER && renderDescription}

                            {renderAbout}
                        </div>
                    </div>

                </div>
            </div>}

            {/*Modals*/}

            <PTReportModalCard isVisible={showReportModal} onModalClose={() => setShowReportModal(false)} onConfirm={handleReport} />

        </MyProfileTemplate>
    )
}

export default PersonalTrainerScreen
