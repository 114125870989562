import * as React from 'react'
import Config from '../config'
import { UserRole } from 'types'
import useAuth from 'hooks/useAuth'
import { getUserRole } from 'utils/auth'
import { TailSpin, ThreeDots } from 'react-loader-spinner'
import { Helmet } from 'react-helmet-async'
import { useHistory } from 'react-router-dom'
import { AuthContextProps } from 'providers/auth'
import ErrorBox from 'components/atoms/error-box'
import useNotification from 'hooks/useNotification'
import ErrorCard from 'components/organisms/error-card'
import { isBoolean, isFunction, isUndefined } from 'lodash'
import InfiniteScroll from 'react-infinite-scroll-component'
import NotificationCard from 'components/molecules/notification-card'
import NotificationsTemplate, { styles } from 'components/templates/notifications'

const NotificationScreen: React.FC = () => {
    const auth = useAuth() as AuthContextProps
    const history = useHistory()
    const userRole = getUserRole()
    const notifications = useNotification()

    React.useEffect(() => {
            if (notifications.hasNewNotification) {
                notifications.setAsAllRead?.()
            }
        },
        [notifications])

    React.useEffect(() => {
        if (auth.isLoggedIn && userRole !== UserRole.ROLE_CUSTOMER) {
            history.push({ pathname: '/' })
        }
    }, [history, auth, userRole])

    if (isUndefined(notifications.notifications) || isUndefined(notifications.pagination) || !isFunction(notifications.loadNextPage) || !isBoolean(notifications.pagination?.isNextPageAvailable)) {
        return <></>
    }

    if (!auth.isLoggedIn) {
        return (
            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <ErrorCard type={'401'} />
            </div>
        )
    }

    return (
        <NotificationsTemplate>
            <Helmet>
                <title>Bildirimler | {Config.app.name}</title>
            </Helmet>

            <InfiniteScroll
                next={notifications.loadNextPage}
                hasMore={notifications.pagination.isNextPageAvailable}
                loader={<div className={styles.center}><ThreeDots color={'#334362'} height={50} width={50} /></div>}
                dataLength={notifications.notifications.length}
                refreshFunction={notifications.refresh}
                endMessage={<ErrorBox style={{ marginTop: '1rem' }} text={'Daha Fazla Bildirim Yok'} size={'sm'} align={'center'} />}
                pullDownToRefresh
                pullDownToRefreshThreshold={50}
                pullDownToRefreshContent={
                    <div className={styles.center}>
                        <TailSpin color={'#334362'} height={40} width={50} />
                    </div>
                }
                releaseToRefreshContent={
                    <div className={styles.center}>
                        <TailSpin color={'#334362'} height={40} width={50} />
                    </div>
                }
            >
                <div className={styles.notifications}>
                    {notifications.notifications.map((notification) => (
                        <NotificationCard key={notification.id.toString() + '_notification'} notification={notification} />
                    ))}
                </div>
            </InfiniteScroll>

        </NotificationsTemplate>
    )
}

export default NotificationScreen
