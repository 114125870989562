import * as React from 'react'
import useApi from 'hooks/useApi'
import jwt_decode from 'jwt-decode'
import { DecodedJwtData, UserRole } from 'types'
import { ApiContextInterface } from './api'
import { GetMyAccountDetailsQueryResult } from '@tetahq/bironbir_api_client'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'

export interface AuthContextProps {
    initialPage: string;
    modalShown: boolean;
    hideModal: () => void;
    showModal: (initialPage?: string) => void;
    toggleModal: () => void;
    setModalShown: React.Dispatch<React.SetStateAction<boolean>>;
    isLoggedIn: boolean;
    logout: () => void;
    multiLoginDetect: () => void;
    user: GetMyAccountDetailsQueryResult | undefined;
    setUser: React.Dispatch<React.SetStateAction<GetMyAccountDetailsQueryResult | undefined>>;
    decodedJwt: DecodedJwtData | null
    userRole: UserRole | null
}

export const AuthContext = React.createContext<AuthContextProps | {}>({})

export const AuthProvider: React.FC = ({ children }) => {
    const history = useHistory()
    const api = useApi() as ApiContextInterface
    const localToken = useLocalStorage('token')

    const [modalShown, setModalShown] = React.useState<boolean>(false)
    const [initialPage, setInitialPage] = React.useState<string | undefined>()

    const [decodedJwt, setDecodedJwt] = React.useState<DecodedJwtData | null>(null)
    const [isLoggedIn, setLoggedIn] = React.useState<boolean | undefined>(localToken !== null)

    const [user, setUser] = React.useState<GetMyAccountDetailsQueryResult | undefined>(undefined)

    const [userRole, setUserRole] = React.useState<UserRole | string | null>(null)

    React.useEffect(() => {
        (async () => {
            if (localToken !== null) {
                setLoggedIn(true)
                try {
                    const { data } = await api.Account.getMyAccountDetails()
                    setUser(data)

                    const decode = jwt_decode<DecodedJwtData>(localToken)
                    setUserRole(decode.auth.split(',')[0])
                } catch {
                    logout()
                    setUserRole(null)
                }
            } else {
                logout()
            }
        })()
    }, [localToken, api.Account])

    React.useEffect(() => {
        if (localToken !== null && localToken.length > 0) {
            setDecodedJwt(jwt_decode<DecodedJwtData>(localToken))
        } else {
            setDecodedJwt(null)
        }
    }, [localToken])

    const showModal = (page?: string) => {
        if (page) setInitialPage(page)
        setModalShown(true)
    }

    const hideModal = () => {
        setInitialPage(undefined)
        setModalShown(false)
    }

    const toggleModal = () => {
        setModalShown(r => (isLoggedIn ? false : !r))
    }

    const logout = () => {
        localStorage.clear()
        setLoggedIn(false)
        setUser(undefined)
    }

    const multiLoginDetect = async () => {
        logout()
        history.push({ pathname: '/supheli-giris' })
        toast.warning('Başka bir cihazda oturum açtığınız için oturumunuz sonlandırıldı.', { toastId: 'anotherDeviceLogin', autoClose: 5000 })
    }

    return (
        <AuthContext.Provider
            value={{
                initialPage,
                modalShown,
                showModal,
                hideModal,
                toggleModal,
                setModalShown,
                isLoggedIn,
                logout,
                multiLoginDetect,
                user,
                setUser,
                decodedJwt,
                userRole,
            }}>{children}</AuthContext.Provider>
    )
}
