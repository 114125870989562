import * as React from 'react'
import Moment from 'react-moment'
import Text from '../../atoms/text'
import { ActivityListItem } from '@tetahq/bironbir_api_client'

import styles from './style.module.css'
import classnames from 'classnames'

type ActivityCardProps = {
  activity: ActivityListItem
  isLastItem: boolean
}

const ActivityCard = ({ activity, isLastItem }: ActivityCardProps) => {
  const [title, setTitle] = React.useState('')
  const [description, setDescription] = React.useState('')

  React.useEffect(() => {
    switch (activity.activityType) {
      case 'StartedToBroadcast':
        setTitle('Canlı Yayın Başladı')
        setDescription('')
        break
      case 'JoinedToBroadcast':
        setTitle(activity.broadcastData?.broadcasterName + ' ile ' + activity.broadcastData?.broadcastTitle + ' dersine katıldı')
        setDescription('Canlı Yayına Katıldı')
        break
      case 'TrainingWatched':
        setTitle('Eğitim Tamamlandı')
        setDescription('')
        break
      case 'BoughtPackageCourse':
        setTitle('Antrenman Paketi Satın Alındı')
        setDescription('')
        break
    }
  }, [activity.activityType, activity.broadcastData])

  return (
    <div className={classnames(styles.card, { [styles.lastItem]: isLastItem })}>
      <div>
        <div><Text weight={'medium'}>{title}</Text></div>
        <div><Text size={'xs'} type={'soft-blue'}>{description}</Text></div>
      </div>
      <div><Text type={'soft-blue'}><Moment locale='tr' interval={1000} ago fromNow>{activity.happenedAt}</Moment></Text></div>
    </div>
  )
}

export default ActivityCard
