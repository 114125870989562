import * as React from 'react'
import Text from 'components/atoms/text'
import Modal, { ModalSize, ModalType } from 'components/atoms/modal'
import { BroadcastProgramListItem } from '@tetahq/bironbir_api_client'
import { CloseCircleFill } from '../../icons'
import ReactHlsPlayer from 'react-hls-player'
import styles from './style.module.css'
import { BroadcastProgramFixtureListItem } from '@tetahq/bironbir_api_client/models/broadcast-program-fixture-list-item'

export type ConfirmModalCardProps = {
  broadcast: BroadcastProgramListItem | BroadcastProgramFixtureListItem | undefined
  onClose: () => void
}

const ConfirmModalCard = ({ onClose, broadcast }: ConfirmModalCardProps) => {
  const playerRef = React.useRef<any>()

  return (
    <Modal isVisible={typeof broadcast !== 'undefined'} onModalClose={onClose} type={ModalType.Centered} size={ModalSize.Large}>
      <div className={styles.video}>
        <CloseCircleFill className={styles.videoCloseButton} onClick={onClose} />
        <Text weight='medium' className={styles.videoTitle}>{broadcast?.personalTrainer?.visibleName + ' - ' + broadcast?.title}</Text>
        <ReactHlsPlayer
          playerRef={playerRef}
          src={broadcast?.replayVideoHlsUrl!}
          autoPlay={true}
          controls={true}
          width='100%'
          height='auto'
        />
      </div>
    </Modal>
  )
}

export default ConfirmModalCard
