import * as React from 'react'
import classnames from 'classnames'
import ImageWithFallback from '../image-with-fallback'
import { GetMyAccountDetailsQueryResult, PersonalTrainerListItem } from '@tetahq/bironbir_api_client'

import styles from './style.module.css'
import { generateAvatar } from 'ui-avatars'

export type AvatarProps = {
    url?: string
    rounded?: boolean
    size?: number
    status?: 'online' | 'offline' | 'busy'
    user?: Omit<GetMyAccountDetailsQueryResult & PersonalTrainerListItem, 'id' | 'accountCreatedAt'>
    fallbackSrc?: string
}

export default function Avatar({ url, rounded, size, status, user, fallbackSrc }: AvatarProps) {
    const [uiAvatar, setUiAvatar] = React.useState<string | undefined>(undefined)

    React.useEffect(() => {
        if (typeof user !== 'undefined') {
            const avatarURL = generateAvatar({
                name: encodeURI(user.fullName ?? user.name + ' ' + user.surname),
                size: 512,
            })

            setUiAvatar(avatarURL)
        }
    }, [user])


    return (
        <div
            style={{
                // @ts-ignore
                '--size': size ?? 4,
            }}
            className={classnames(styles.avatar, { [styles.rounded]: rounded })}
        >
            {typeof url === 'undefined' && typeof user !== 'undefined' && (
                <ImageWithFallback
                    src={user.avatar?.avatar1X ?? uiAvatar}
                    srcSet={user.avatar ? `${user.avatar?.avatar1X}, ${user.avatar?.avatar2X} 1.5x, ${user.avatar?.avatar3X} 2x, ${user.avatar?.avatar4X} 3x,` : uiAvatar}
                    fallbackSrc={fallbackSrc ?? uiAvatar ?? ''}
                    alt='avatar'
                />
            )}

            {typeof url !== 'undefined' && typeof user === 'undefined' && (
                <ImageWithFallback
                    src={url}
                    fallbackSrc={fallbackSrc ?? uiAvatar ?? ''}
                    alt='avatar'
                />
            )}

            {status && <div className={classnames(styles.status, styles[`status-${status}`])} />}
        </div>
    )
}
