import * as React from 'react'
import Text from '../../atoms/text'
import Button from '../../atoms/button'
import Card from 'components/molecules/card'
import {  CloseLine, StarFill } from '../../icons'
import Modal, { ModalSize, ModalType } from '../../atoms/modal'

import styles from './style.module.css'

type RateVideoCallModalProps = {
    rate?: number
    onSubmit?: (rate: number) => void
    isVisible: boolean
    onClose: () => void
}

const states = ['Çok Kötü', 'Kötü', 'Orta Seviye', 'İyi', 'Çok İyi']

const RateVideoCallModal = ({ onSubmit, rate, isVisible, onClose }: RateVideoCallModalProps) => {
    const [localStarRate, setLocalStarRate] = React.useState(rate ?? 0)
    const totalStar = 5

    const onLocalStarRateChanged = (starRate: number) => {
        setLocalStarRate(starRate)
    }

    const submitHandler = () => {
        if (typeof onSubmit === 'function') {
            onSubmit(localStarRate)
        }
    }

    const renderStarRate = () => {
        const starList: JSX.Element[] = []

        for (let x = 1; x <= totalStar; x++) {
            let func: any = undefined

            func = onLocalStarRateChanged.bind(null, x)

            starList.push(<StarFill className={localStarRate >= x ? styles.filledStar : styles.nonFilledStar}
                                    key={`starRate_${x}`} onClick={func} />)
        }

        return starList
    }

    return (
        <Modal isVisible={isVisible} onModalClose={onClose} type={ModalType.Fullscreen} size={ModalSize.Large}>
            <Card
                type='light'
                heading={{
                    trailing: <div className={styles.back} onClick={onClose}><CloseLine /></div>,
                    title: 'Yayın Tamamlandı',
                    alignment: 'center',
                    size: 'lg',
                    divider: true,
                }}
            >
                <div className={styles['moduleInspector']}>
                    <div className={styles['module']}>
                        <Text size={'xl'} className={styles['subText']}>Görüşmeyi değerlendirmek ister misiniz?</Text>
                        <div className={styles['stars']}>
                            {renderStarRate()}
                        </div>
                        <Text size={'sm'} type={'warning'}>{states[localStarRate - 1]}</Text>
                        <div>
                            <div className={styles['button']}>
                                <Button variation={'block'} type={'danger-2'} onClick={submitHandler}>
                                    Değerlendirmeyi Gönder
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        </Modal>
    )
}

export default RateVideoCallModal
