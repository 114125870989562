import * as React from 'react'
import classnames from 'classnames'

import styles from './style.module.css'

export type BroadcastCalendarTemplateProps = {
    children: React.ReactNode
}

const BroadcastCalendarTemplate: React.FC<BroadcastCalendarTemplateProps> = ({ children }) => {
    return <div className={classnames(styles.home)}>{children}</div>
}

export default BroadcastCalendarTemplate
