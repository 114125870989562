import * as React from 'react'
import classnames from 'classnames'
import CardBody, { CardBodyProps } from 'components/atoms/card-body'
import CardHeading, { CardHeadingProps } from 'components/atoms/card-heading'

import styles from './style.module.css'
import PropTypes from 'prop-types'
import { CSSProperties } from 'react'

type CardProps = {
    heading?: CardHeadingProps
    children: React.ReactNode
    onClick?: Function
    borderType?: 'none' | 'default' | 'danger'
    type?: 'default' | 'darken' | 'light'
    style?: CSSProperties
}

const propTypes = {
    type: PropTypes.oneOf(['default', 'darken', 'light']).isRequired,
    borderType: PropTypes.oneOf(['none', 'default', 'danger']).isRequired,
}

const defaultProps = {
    type: 'default',
    borderType: 'none',
}

const Card = ({ heading, children, onClick, borderType, type, style, ...props }: CardProps & CardBodyProps) => {
    const onClickHandler = () => {
        onClick && onClick()
    }

    return (
        <div
            className={classnames(
                styles.card,
                {
                    [styles[`border-${borderType}`]]: borderType,
                },
                styles[`type-${type}`],
            )}
            style={style}
            onClick={onClickHandler}
        >
            {heading != null && <CardHeading {...heading} />}
            <CardBody {...props}>{children}</CardBody>
        </div>
    )
}

Card.propTypes = propTypes
Card.defaultProps = defaultProps

export default Card
