import * as React from "react";

function SvgPageMaintenance(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={420.431}
      height={300.317}
      viewBox="0 0 420.431 300.317"
      {...props}
    >
      <g opacity={0.2}>
        <path
          data-name="Path 6652"
          d="M376.636 291.826v-1a4.178 4.178 0 014.178-4.178h35.438a4.178 4.178 0 014.178 4.178v1z"
          fill="#e0e0e0"
        />
        <path
          data-name="Path 6653"
          d="M397.206 246.875l-12.848 39.742h27.381l-11.894-39.693a1.393 1.393 0 00-2.639-.049z"
          fill="#e0e0e0"
        />
        <path
          data-name="Path 6654"
          d="M393.004 259.813h10.7l4.631 15.459h-20.32z"
          fill="#fafafa"
        />
        <path
          data-name="Path 6655"
          d="M118.458 290.935v-.975a4.178 4.178 0 014.178-4.178h35.438a4.178 4.178 0 014.178 4.178v.975z"
          fill="#e0e0e0"
        />
        <path
          data-name="Path 6656"
          d="M139.008 246.012l-12.82 39.742h27.381l-11.9-39.693a1.393 1.393 0 00-2.66-.049z"
          fill="#e0e0e0"
        />
        <path
          data-name="Path 6657"
          d="M134.83 258.951h10.71l4.631 15.459h-20.327z"
          fill="#fafafa"
        />
        <path
          data-name="Path 6658"
          d="M120.784 26.315l-3.517-6.156-5.515.815-4.554-5.724 2.347-5.7-6.149-4.076-4.589 4.631-6.051-1.058-2.451-7.1-7.347.766-.418 6.219-5.473 2.785-5.759-4.109-5.877 4.283 3.057 6.581-3.482 5.271-6.351-.334-1.664 7.834 6.051 2.326.515 6.588-6.267 3.6 2.994 6.8 6.49-1 4.3 4.958-2.249 6.184 5.94 4.178 4.638-5.021 6.441 1.741 2 6.643 7.152-.48.926-5.968 6.128-3.524 5.021 4 6.344-4.728-3.21-5.571 3.127-5.571 6.434.474 1.922-7.319-5.522-2.987-.738-6.65zM95.868 47.774a39.092 39.092 0 100-.021z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6659"
          d="M95.075 31.017a17.21 17.21 0 00-1.191-1.448 7.611 7.611 0 00-4.471-2.187A7.479 7.479 0 0082.735 30a7.576 7.576 0 00-1.678 3.976 7.444 7.444 0 004.394 7.66 7.277 7.277 0 002.159.592 7.116 7.116 0 002.145-.056 7.583 7.583 0 003.579-1.664 7.458 7.458 0 002.054-2.785 7.66 7.66 0 00.369-4.965 17.549 17.549 0 00-.655-1.762 6.1 6.1 0 01.787 1.72 7.6 7.6 0 01-.265 5.1 7.723 7.723 0 01-5.808 4.673 7.354 7.354 0 01-2.249.07 7.207 7.207 0 01-2.263-.613 7.785 7.785 0 01-4.631-7.973 7.4 7.4 0 01.613-2.263 7.534 7.534 0 011.163-1.915 7.834 7.834 0 013.4-2.319 7.721 7.721 0 018.113 2.006 6.065 6.065 0 011.114 1.532z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6660"
          d="M101.948 14.917c.084.056-1.873 3.182-4.38 6.964s-4.6 6.838-4.68 6.79 1.873-3.182 4.38-6.964 4.596-6.846 4.68-6.79z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6661"
          d="M109.054 40.242a12.784 12.784 0 01-1.95-.536c-1.191-.362-2.841-.843-4.687-1.3s-3.531-.787-4.756-1.01a13.831 13.831 0 01-1.978-.418 9.749 9.749 0 012.019.16 47.732 47.732 0 014.8.919 45.065 45.065 0 014.687 1.393 10.168 10.168 0 011.866.787z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6662"
          d="M91.67 41.894a8.022 8.022 0 01.627 1.685c.334 1.065.745 2.542 1.128 4.178s.7 3.161.836 4.262a9.051 9.051 0 01.188 1.79 8.808 8.808 0 01-.439-1.748c-.237-1.079-.557-2.577-.94-4.227s-.752-3.127-1.017-4.178a9.749 9.749 0 01-.383-1.762z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6663"
          d="M83.847 41.012a48.444 48.444 0 01-2.869 5.1 48.846 48.846 0 01-3.175 4.923 45.956 45.956 0 012.869-5.111 45.96 45.96 0 013.175-4.912z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6664"
          d="M82.756 16.357a45.3 45.3 0 011.845 5.376 44.9 44.9 0 011.5 5.48 44.754 44.754 0 01-1.845-5.376 43.074 43.074 0 01-1.5-5.48z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6665"
          d="M81.119 33.376a28.857 28.857 0 01-4.519-.251 28.641 28.641 0 01-4.478-.606 26.9 26.9 0 014.512.251 28.99 28.99 0 014.485.606z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6666"
          d="M93.591 31.887a5.961 5.961 0 11-3.613-2.791 5.961 5.961 0 013.613 2.791z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6667"
          d="M91.321 34.867a8.1 8.1 0 00-2.953-1.128 7.987 7.987 0 00-3.134.474 1.881 1.881 0 01.829-.515 4.875 4.875 0 012.34-.32 4.965 4.965 0 012.221.815c.495.346.732.646.697.674z"
          fill="#e0e0e0"
        />
        <path
          data-name="Path 6668"
          d="M88.932 31.378a19.548 19.548 0 00-.9 2.785 19.673 19.673 0 00-.125 2.911 5.905 5.905 0 011.024-5.682z"
          fill="#e0e0e0"
        />
        <path
          data-name="Path 6669"
          d="M198.875 150.47l-4.554-7.939-7.131 1.052-5.856-7.4 3.036-7.374-7.959-5.278-5.94 5.989-7.82-1.393-3.2-9.192-9.505 1-.543 8.05-7.061 3.614-7.493-5.313-7.6 5.571 3.955 8.489-4.526 6.817-8.224-.439-2.145 10.146 7.818 3.008.7 8.523-8.106 4.659 3.872 8.8 8.4-1.309 5.571 6.421-2.9 8 7.66 5.432 6-6.49 8.356 2.249 2.59 8.593 9.255-.62 1.191-7.723 7.939-4.554 6.49 5.181 8.21-6.121-4.178-7.228 4.046-7.249 8.322.613 2.556-9.436-7.152-3.872-.989-8.6zm-32.235 27.771a18.7 18.7 0 116.908-25.536 18.7 18.7 0 01-6.908 25.536z"
          fill="#e0e0e0"
        />
        <path
          data-name="Path 6670"
          d="M165.616 156.578a18.8 18.8 0 00-1.525-1.887 9.826 9.826 0 00-5.8-2.855 9.679 9.679 0 00-4.464.473 9.756 9.756 0 00-6.434 8.113 9.665 9.665 0 005.717 9.958 9.22 9.22 0 002.785.773 9.346 9.346 0 002.785-.077 9.812 9.812 0 007.771-12.228 20.035 20.035 0 00-.864-2.27.789.789 0 01.084.139l.23.411a4.946 4.946 0 01.334.7 7.02 7.02 0 01.348.989 9.228 9.228 0 01.4 2.855 9.994 9.994 0 01-8.273 9.742 9.312 9.312 0 01-2.89.091 9.527 9.527 0 01-2.911-.787 10.024 10.024 0 118.969-17.9 9.282 9.282 0 012.263 1.783 7.127 7.127 0 01.7.8 5.171 5.171 0 01.432.634l.237.411a1.227 1.227 0 01.1.132z"
          fill="#e0e0e0"
        />
        <path
          data-name="Path 6671"
          d="M174.51 135.75c.091.056-2.472 4.088-5.71 9s-5.933 8.865-6.017 8.809 2.479-4.088 5.717-9 5.926-8.865 6.01-8.809z"
          fill="#e0e0e0"
        />
        <path
          data-name="Path 6672"
          d="M183.701 168.485a24.856 24.856 0 01-2.507-.738 99.34 99.34 0 00-12.228-2.985 26.124 26.124 0 01-2.57-.494 2.292 2.292 0 01.7 0c.453.035 1.1.111 1.908.223 1.6.223 3.8.613 6.2 1.2a62.944 62.944 0 016.058 1.8c.766.272 1.393.5 1.79.7a2.4 2.4 0 01.649.294z"
          fill="#e0e0e0"
        />
        <path
          data-name="Path 6673"
          d="M161.215 170.652a14.3 14.3 0 01.773 2.194c.418 1.393.947 3.294 1.441 5.425s.87 4.095 1.093 5.515a13.231 13.231 0 01.279 2.312 17.1 17.1 0 01-.529-2.27c-.292-1.393-.7-3.343-1.2-5.473s-.982-4.046-1.337-5.439a16.933 16.933 0 01-.522-2.263z"
          fill="#e0e0e0"
        />
        <path
          data-name="Path 6674"
          d="M151.111 169.502c.084.056-1.595 3-3.76 6.581a75.62 75.62 0 01-4.067 6.393c-.084-.049 1.595-2.994 3.76-6.581s3.984-6.441 4.067-6.393z"
          fill="#e0e0e0"
        />
        <path
          data-name="Path 6675"
          d="M149.672 137.615c.1 0 1.142 3.092 2.34 6.964a69.443 69.443 0 011.985 7.068 70.735 70.735 0 01-2.333-6.964q-1.172-3.482-1.992-7.068z"
          fill="#e0e0e0"
        />
        <path
          data-name="Path 6676"
          d="M147.56 159.635a48.159 48.159 0 01-5.842-.376 45.781 45.781 0 01-5.8-.738 48.048 48.048 0 015.836.376 47.585 47.585 0 015.808.738z"
          fill="#e0e0e0"
        />
        <circle
          data-name="Ellipse 173"
          cx={7.716}
          cy={7.716}
          r={7.716}
          transform="rotate(-80.56 173.194 -3.496)"
          fill="#e0e0e0"
        />
        <path
          data-name="Path 6677"
          d="M160.763 161.563a11.436 11.436 0 01-1.058-.627 7.152 7.152 0 00-5.647-.613 10.358 10.358 0 01-1.17.39c-.028-.049.355-.341 1.086-.634a6.413 6.413 0 015.863.634 2.737 2.737 0 01.926.85z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6678"
          d="M157.657 157.044a18.106 18.106 0 00-1.219 3.579 18.57 18.57 0 00-.1 3.781 8.008 8.008 0 011.319-7.36z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6679"
          d="M201.529 16.281l-2.291-3.99-3.593.529-2.967-3.726 1.532-3.712-4-2.66-2.987 3.015-3.941-.7-1.595-4.631-4.784.508-.272 4.046-3.551 1.852-3.779-2.666-3.83 2.785 1.992 4.29-2.277 3.426-4.134-.211-1.086 5.1 3.962 1.483.341 4.29-4.088 2.34 1.957 4.429 4.22-.655 2.785 3.231-1.462 4.025 3.872 2.737 3.019-3.27 4.178 1.135 1.3 4.324 4.659-.313.6-3.886 4-2.291 3.266 2.6 4.129-3.078-2.089-3.642 2.04-3.642 4.178.306 1.3-4.742-3.6-1.95-.487-4.317zm-16.226 13.984a6.9 6.9 0 11.014 0z"
          fill="#f5f5f5"
        />
        <path
          data-name="Path 6680"
          d="M184.815 19.352a8.254 8.254 0 00-.766-.954 4.986 4.986 0 00-2.918-1.435 4.826 4.826 0 00-2.249.244 4.927 4.927 0 00-2.59 7.061 4.944 4.944 0 002.214 2.047 4.638 4.638 0 001.393.383 4.526 4.526 0 001.393-.035 4.875 4.875 0 002.34-1.093 4.749 4.749 0 001.337-1.818 4.909 4.909 0 00.23-3.245 9.116 9.116 0 00-.383-1.156l.042.07.118.209a2.783 2.783 0 01.167.348 3.639 3.639 0 01.174.494 4.679 4.679 0 01.2 1.435 4.965 4.965 0 01-.376 1.88 5.02 5.02 0 01-1.393 1.887 5.083 5.083 0 01-2.416 1.149 4.617 4.617 0 01-1.455.042 4.568 4.568 0 01-1.462-.4 5.049 5.049 0 01-2.354-2.089 5.139 5.139 0 01-.634-3.092 5.035 5.035 0 017.5-3.816 4.874 4.874 0 011.191.884 3.2 3.2 0 01.341.4 1.987 1.987 0 01.216.32l.125.209a.251.251 0 01.015.071z"
          fill="#f5f5f5"
        />
        <path
          data-name="Path 6681"
          d="M189.279 8.907c.042 0-1.246 2.089-2.876 4.533s-2.98 4.464-3.029 4.436 1.247-2.089 2.876-4.533 2.987-4.499 3.029-4.436z"
          fill="#f5f5f5"
        />
        <path
          data-name="Path 6682"
          d="M193.903 25.355a13.134 13.134 0 01-1.26-.376c-.773-.237-1.852-.55-3.057-.85s-2.3-.508-3.1-.648a10.663 10.663 0 01-1.288-.258 1.65 1.65 0 01.348 0 8.678 8.678 0 01.961.111c.808.111 1.915.313 3.127.606a31.29 31.29 0 013.05.905c.383.132.7.251.9.341a1.079 1.079 0 01.32.167z"
          fill="#f5f5f5"
        />
        <path
          data-name="Path 6683"
          d="M182.586 26.441a7.251 7.251 0 01.39 1.107c.209.7.474 1.657.7 2.73s.439 2.089.55 2.785a5.972 5.972 0 01.139 1.17 8.357 8.357 0 01-.265-1.142c-.146-.7-.355-1.685-.6-2.785s-.494-2.04-.7-2.737a7.778 7.778 0 01-.216-1.128z"
          fill="#f5f5f5"
        />
        <path
          data-name="Path 6684"
          d="M177.504 25.863c.042 0-.808 1.511-1.894 3.315s-2.006 3.245-2.047 3.217.808-1.511 1.894-3.294 2.002-3.258 2.047-3.238z"
          fill="#f5f5f5"
        />
        <path
          data-name="Path 6685"
          d="M176.779 9.812c.049 0 .571 1.56 1.177 3.482a33.763 33.763 0 011 3.558 36.336 36.336 0 01-1.177-3.482 36.393 36.393 0 01-1-3.558z"
          fill="#f5f5f5"
        />
        <path
          data-name="Path 6686"
          d="M175.714 20.898a24.3 24.3 0 01-2.939-.188 21.852 21.852 0 01-2.925-.376 23.565 23.565 0 012.939.195 23.926 23.926 0 012.925.369z"
          fill="#f5f5f5"
        />
        <path
          data-name="Path 6687"
          d="M183.847 19.909a3.872 3.872 0 11-2.351-1.819 3.879 3.879 0 012.351 1.819z"
          fill="#f5f5f5"
        />
        <path
          data-name="Path 6688"
          d="M182.357 21.866s-.2-.132-.529-.313a3.851 3.851 0 00-1.393-.446 3.691 3.691 0 00-1.455.139c-.362.1-.571.216-.585.195a1.281 1.281 0 01.543-.32 3.259 3.259 0 012.953.32 1.393 1.393 0 01.466.425z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6689"
          d="M180.805 19.596a9.266 9.266 0 00-.613 1.8 9.324 9.324 0 00-.056 1.908 3.482 3.482 0 01-.125-1.936 3.377 3.377 0 01.794-1.769z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6690"
          d="M104.677 114.647l-5.772-10.06-9.053 1.33-7.465-9.38 3.844-9.338-10.049-6.692-7.528 7.59-9.909-1.741-4.025-11.65-12.04 1.267-.7 10.195-8.976 4.561-9.5-6.727-9.638 7.019 5.007 10.794-5.733 8.632-10.445-.55L0 122.752l9.916 3.837.85 10.8-10.271 5.912 4.87 11.146 10.64-1.65 7.047 8.134-3.687 10.138 9.749 6.88 7.6-8.231 10.55 2.855 3.287 10.891 11.727-.787 1.511-9.784 10.056-5.773 8.224 6.567 10.4-7.758-5.258-9.157 5.125-9.185 10.543.78 3.273-12.026-9.053-4.875-1.2-10.891zm-40.849 35.187a23.676 23.676 0 1111.09-14.364 23.676 23.676 0 01-11.09 14.364z"
          fill="#f5f5f5"
        />
        <path
          data-name="Path 6691"
          d="M62.526 122.414a22.538 22.538 0 00-1.929-2.4 12.535 12.535 0 00-7.347-3.621 12.326 12.326 0 00-11 4.31 11.532 11.532 0 00-1.859 3.008 11.4 11.4 0 00-.954 3.565 12.256 12.256 0 007.242 12.618 11.538 11.538 0 003.558.975 11.38 11.38 0 003.544-.091 12.423 12.423 0 009.868-15.522 21.2 21.2 0 00-1.093-2.876s.042.056.111.167.174.313.292.522a6.3 6.3 0 01.418.884 8.357 8.357 0 01.446 1.253 11.734 11.734 0 01.508 3.607 12.642 12.642 0 01-10.452 12.354 12.728 12.728 0 01-11.922-20.766 12.646 12.646 0 0115.933-2.785 11.783 11.783 0 012.862 2.263 8.414 8.414 0 01.808 1 5.978 5.978 0 01.55.808c.125.2.223.376.306.515a.843.843 0 01.111.209z"
          fill="#f5f5f5"
        />
        <path
          data-name="Path 6692"
          d="M73.801 95.987c.1.07-3.134 5.181-7.235 11.413s-7.521 11.232-7.625 11.142 3.134-5.174 7.242-11.413 7.514-11.177 7.618-11.142z"
          fill="#f5f5f5"
        />
        <path
          data-name="Path 6693"
          d="M85.444 137.469a29.461 29.461 0 01-3.175-.933c-1.957-.606-4.666-1.393-7.66-2.138s-5.8-1.281-7.806-1.643a28.633 28.633 0 01-3.252-.634 3.483 3.483 0 01.891 0 51.85 51.85 0 012.409.285c2.033.279 4.826.78 7.862 1.518a76.716 76.716 0 017.66 2.277c.968.341 1.741.634 2.27.857a3.287 3.287 0 01.8.411z"
          fill="#f5f5f5"
        />
        <path
          data-name="Path 6694"
          d="M56.949 140.214a16.483 16.483 0 01.982 2.785c.529 1.741 1.2 4.178 1.831 6.873s1.093 5.188 1.393 6.964a16.944 16.944 0 01.348 2.932 21.524 21.524 0 01-.7-2.869c-.369-1.783-.891-4.241-1.518-6.964s-1.24-5.132-1.692-6.894a21.077 21.077 0 01-.644-2.827z"
          fill="#f5f5f5"
        />
        <path
          data-name="Path 6695"
          d="M44.149 138.765c.1.063-2.026 3.8-4.763 8.356s-5.042 8.168-5.153 8.106 2.019-3.8 4.763-8.356 5.042-8.176 5.153-8.106z"
          fill="#f5f5f5"
        />
        <path
          data-name="Path 6696"
          d="M42.332 98.355c.118 0 1.448 3.914 2.96 8.83s2.646 8.927 2.521 8.962a92.4 92.4 0 01-2.96-8.83c-1.511-4.916-2.646-8.927-2.521-8.962z"
          fill="#f5f5f5"
        />
        <path
          data-name="Path 6697"
          d="M39.651 126.258a60.756 60.756 0 01-7.4-.48 58.692 58.692 0 01-7.354-.933 58.91 58.91 0 017.4.48 60.8 60.8 0 017.354.933z"
          fill="#f5f5f5"
        />
        <circle
          data-name="Ellipse 174"
          cx={9.777}
          cy={9.777}
          r={9.777}
          transform="rotate(-73.89 109.721 41.356)"
          fill="#f5f5f5"
        />
        <path
          data-name="Path 6698"
          d="M56.378 128.679a14.885 14.885 0 01-1.344-.794 9.29 9.29 0 00-3.482-1.128 9.414 9.414 0 00-3.663.348 13.459 13.459 0 01-1.483.494 3.42 3.42 0 011.393-.8 8.21 8.21 0 013.816-.5 8.12 8.12 0 013.614 1.309 3.44 3.44 0 011.149 1.071z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6699"
          d="M52.457 122.971a24.493 24.493 0 00-1.539 4.54 25.764 25.764 0 00-.132 4.791 8.3 8.3 0 01-.32-4.875 8.866 8.866 0 011.991-4.456z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6700"
          d="M371.636 108.547l-5.766-10.056-9.053 1.33-7.465-9.38 3.851-9.345-10.087-6.682-7.528 7.611-9.909-1.734-4.018-11.657-12.045 1.274-.7 10.195-8.976 4.561-9.5-6.727-9.638 7.019 5.014 10.791-5.731 8.642-10.445-.55-2.653 12.82 9.909 3.816.857 10.8-10.278 5.9 4.875 11.142 10.654-1.65 7.047 8.141-3.684 10.132 9.749 6.887 7.6-8.231 10.56 2.851 3.28 10.891 11.727-.794 1.511-9.749 10.055-5.773 8.231 6.56 10.4-7.751-5.258-9.164 5.125-9.178 10.55.773 3.238-11.957-9.053-4.875-1.2-10.9zm-40.856 35.19a23.677 23.677 0 1111.09-14.364 23.676 23.676 0 01-11.09 14.364z"
          fill="#f5f5f5"
        />
        <path
          data-name="Path 6701"
          d="M329.478 116.293a21.432 21.432 0 00-1.929-2.4 12.35 12.35 0 10-13.907 19.89 11.63 11.63 0 003.558.975 11.768 11.768 0 003.537-.091 12.354 12.354 0 009.269-7.333 12.493 12.493 0 00.578-8.168 21.239 21.239 0 00-1.1-2.869.753.753 0 01.111.167l.292.522a5.507 5.507 0 01.418.877 9.486 9.486 0 01.453 1.253 12 12 0 01.508 3.614 12.653 12.653 0 01-4.387 9.478 12.731 12.731 0 11-2.026-20.7 11.923 11.923 0 012.862 2.263 9.234 9.234 0 01.857 1.017 6.443 6.443 0 01.55.8l.3.515a1.2 1.2 0 01.056.19z"
          fill="#f5f5f5"
        />
        <path
          data-name="Path 6702"
          d="M340.759 89.894c.1.07-3.134 5.181-7.242 11.413s-7.514 11.232-7.618 11.142 3.134-5.181 7.235-11.413 7.514-11.212 7.625-11.142z"
          fill="#f5f5f5"
        />
        <path
          data-name="Path 6703"
          d="M352.399 131.376a30.789 30.789 0 01-3.182-.933c-1.95-.606-4.659-1.393-7.66-2.138s-5.794-1.281-7.806-1.643a31.212 31.212 0 01-3.252-.634 3.126 3.126 0 01.891 0c.571.049 1.393.139 2.409.286a79.448 79.448 0 017.869 1.518c3.036.745 5.745 1.588 7.66 2.27.961.341 1.741.641 2.27.863a3.482 3.482 0 01.8.411z"
          fill="#f5f5f5"
        />
        <path
          data-name="Path 6704"
          d="M323.907 134.12a16.619 16.619 0 01.975 2.785c.536 1.741 1.2 4.178 1.831 6.873s1.1 5.188 1.393 6.964a17.5 17.5 0 01.355 2.932 20.718 20.718 0 01-.7-2.876c-.369-1.776-.884-4.234-1.511-6.964s-1.246-5.132-1.7-6.894a20.775 20.775 0 01-.648-2.82z"
          fill="#f5f5f5"
        />
        <path
          data-name="Path 6705"
          d="M311.101 132.665c.111.07-2.02 3.8-4.756 8.356a94.163 94.163 0 01-5.16 8.1c-.111-.07 2.026-3.8 4.763-8.356s5.049-8.163 5.153-8.1z"
          fill="#f5f5f5"
        />
        <path
          data-name="Path 6706"
          d="M309.283 92.262a91.572 91.572 0 012.96 8.83c1.511 4.916 2.646 8.927 2.528 8.962s-1.448-3.914-2.967-8.83-2.639-8.928-2.521-8.962z"
          fill="#f5f5f5"
        />
        <path
          data-name="Path 6707"
          d="M306.603 120.158a58.235 58.235 0 01-7.4-.474 60.3 60.3 0 01-7.354-.933 61.375 61.375 0 017.4.474 58.7 58.7 0 017.354.933z"
          fill="#f5f5f5"
        />
        <circle
          data-name="Ellipse 175"
          cx={9.777}
          cy={9.777}
          r={9.777}
          transform="rotate(-74.66 238.126 -136.325)"
          fill="#f5f5f5"
        />
        <path
          data-name="Path 6708"
          d="M323.329 122.609a14.721 14.721 0 01-1.337-.8 9.583 9.583 0 00-3.482-1.128 9.352 9.352 0 00-3.656.355 12.89 12.89 0 01-1.483.487 3.482 3.482 0 011.393-.794 8.127 8.127 0 017.43.808c.787.55 1.198 1.031 1.135 1.072z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6709"
          d="M319.415 116.878a23.788 23.788 0 00-1.546 4.533 25.06 25.06 0 00-.132 4.791 8.271 8.271 0 01-.313-4.875 8.782 8.782 0 011.991-4.449z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6710"
          d="M265.036 170.714l-2.834-4.944-4.45.648-3.67-4.61 1.894-4.589-4.958-3.294-3.7 3.733-4.875-.857-1.978-5.731-5.926.627-.334 5.014-4.415 2.242-4.673-3.308-4.732 3.482 2.465 5.306-2.82 4.248-5.125-.272-1.337 6.316 4.875 1.88.418 5.306-5.056 2.9 2.416 5.48 5.23-.815 3.482 4-1.811 4.986 4.791 3.384 3.739-4.046 5.188 1.393 1.616 5.355 5.766-.39.745-4.8 4.944-2.841 4.046 3.231 5.111-3.816-2.583-4.505 2.521-4.512 5.188.376 1.588-5.877-4.45-2.409-.592-5.362zm-20.083 17.3a11.657 11.657 0 114.3-15.912 11.657 11.657 0 01-4.3 15.912z"
          fill="#f5f5f5"
        />
        <path
          data-name="Path 6711"
          d="M244.314 174.523a10.282 10.282 0 00-.947-1.184 6.146 6.146 0 00-9.025.313 5.627 5.627 0 00-.912 1.483 5.7 5.7 0 00-.473 1.748 6.038 6.038 0 003.565 6.2 6.081 6.081 0 008.322-7.179 10.748 10.748 0 00-.536-1.393l.056.077.139.258a2.9 2.9 0 01.209.439 5.056 5.056 0 01.223.613 5.808 5.808 0 01.244 1.776 6.066 6.066 0 01-.467 2.326 6.155 6.155 0 01-1.685 2.333 6.267 6.267 0 01-2.987 1.393 5.953 5.953 0 01-1.8.056 5.7 5.7 0 01-1.818-.494 6.261 6.261 0 01-2.249-9.77 6.309 6.309 0 012.723-1.866 6.184 6.184 0 012.869-.279 6.267 6.267 0 012.249.766 6 6 0 011.393 1.107 4.669 4.669 0 01.418.5 3.418 3.418 0 01.272.4l.153.258z"
          fill="#f5f5f5"
        />
        <path
          data-name="Path 6712"
          d="M249.856 161.535c.056.035-1.539 2.549-3.558 5.613s-3.7 5.529-3.746 5.494 1.539-2.549 3.558-5.613 3.698-5.521 3.746-5.494z"
          fill="#f5f5f5"
        />
        <path
          data-name="Path 6713"
          d="M255.58 181.94s-.6-.167-1.56-.46-2.291-.7-3.781-1.052-2.848-.634-3.837-.808a14.624 14.624 0 01-1.6-.313 1.916 1.916 0 01.439 0 10.4 10.4 0 011.184.139c1 .139 2.375.383 3.872.745a37.464 37.464 0 013.774 1.121c.474.167.857.313 1.114.418a1.551 1.551 0 01.4.209z"
          fill="#f5f5f5"
        />
        <path
          data-name="Path 6714"
          d="M241.569 183.283a8.12 8.12 0 01.481 1.393c.265.857.592 2.047.905 3.384s.536 2.549.7 3.433a8.2 8.2 0 01.174 1.441 11.392 11.392 0 01-.327-1.393c-.181-.877-.439-2.089-.745-3.412s-.613-2.528-.836-3.391a10.569 10.569 0 01-.352-1.455z"
          fill="#f5f5f5"
        />
        <path
          data-name="Path 6715"
          d="M235.272 182.573c.056.035-1 1.866-2.34 4.1s-2.479 4.011-2.535 3.983 1-1.866 2.34-4.1a46.7 46.7 0 012.535-3.983z"
          fill="#f5f5f5"
        />
        <path
          data-name="Path 6716"
          d="M234.383 162.706c.056 0 .7 1.922 1.455 4.338a44.885 44.885 0 011.24 4.408 45.128 45.128 0 01-1.455-4.338 45.5 45.5 0 01-1.24-4.408z"
          fill="#f5f5f5"
        />
        <path
          data-name="Path 6717"
          d="M233.06 176.424a59.583 59.583 0 01-7.256-.7 28.919 28.919 0 013.642.237 28.336 28.336 0 013.614.46z"
          fill="#f5f5f5"
        />
        <circle
          data-name="Ellipse 176"
          cx={4.805}
          cy={4.805}
          r={4.805}
          transform="rotate(-72.18 240.41 -69.418)"
          fill="#f5f5f5"
        />
        <path
          data-name="Path 6718"
          d="M241.291 177.627a7.939 7.939 0 00-.7-.4 4.756 4.756 0 00-1.72-.55 4.52 4.52 0 00-1.8.174 6.53 6.53 0 01-.731.237 1.706 1.706 0 01.7-.39 3.969 3.969 0 013.656.4 1.692 1.692 0 01.592.529z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6719"
          d="M239.362 174.809a7.834 7.834 0 00-.822 4.582 4.178 4.178 0 01-.16-2.389 4.255 4.255 0 01.982-2.194z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6720"
          d="M298.887 31.538l-4.554-7.939-7.152 1.052-5.877-7.388 3.036-7.375-7.952-5.292-5.919 5.989-7.82-1.393L259.474 0l-9.505 1-.536 8.043-7.1 3.621-7.5-5.313-7.6 5.571 3.948 8.517-4.519 6.817-8.224-.439-2.152 10.146 7.827 2.973.7 8.523-8.113 4.659 3.879 8.8 8.391-1.309 5.571 6.42-2.911 8 7.695 5.432 6-6.49 8.356 2.249 2.59 8.593 9.255-.62 1.2-7.723 7.932-4.554 6.49 5.181 8.141-6.114-4.178-7.228 4.046-7.249 8.356.613 2.556-9.436-7.145-3.872-.954-8.593zm-32.235 27.771a18.711 18.711 0 116.908-25.543 18.711 18.711 0 01-6.908 25.543z"
          fill="#e0e0e0"
        />
        <path
          data-name="Path 6721"
          d="M265.596 37.644a17.6 17.6 0 00-1.525-1.887 9.8 9.8 0 00-5.794-2.855 9.665 9.665 0 00-4.464.474 9.748 9.748 0 00-6.441 8.113 9.666 9.666 0 005.717 9.958 9.269 9.269 0 002.785.773 9.387 9.387 0 002.785-.077 9.749 9.749 0 007.312-5.78 9.875 9.875 0 00.453-6.448 17.134 17.134 0 00-.863-2.27.57.57 0 01.091.139l.23.411a4.308 4.308 0 01.327.7 6.96 6.96 0 01.355.989 9.63 9.63 0 01.4 2.855 9.972 9.972 0 01-8.252 9.749 9.31 9.31 0 01-2.89.091 9.422 9.422 0 01-2.911-.787 10 10 0 01-5.933-10.327 9.679 9.679 0 01.787-2.911 9.415 9.415 0 011.6-2.486 10.034 10.034 0 014.387-2.967 9.861 9.861 0 014.6-.453 10.091 10.091 0 013.614 1.226 9.547 9.547 0 012.235 1.79 6.454 6.454 0 01.7.8 4.477 4.477 0 01.432.634l.244.411a.643.643 0 01.021.139z"
          fill="#e0e0e0"
        />
        <path
          data-name="Path 6722"
          d="M274.521 16.817c.084.056-2.472 4.088-5.71 9s-5.933 8.851-6 8.809 2.472-4.088 5.71-9 5.916-8.864 6-8.809z"
          fill="#e0e0e0"
        />
        <path
          data-name="Path 6723"
          d="M283.7 49.553a25.5 25.5 0 01-2.507-.738c-1.539-.48-3.677-1.1-6.065-1.685s-4.575-1.017-6.163-1.3a25.647 25.647 0 01-2.563-.494 2.264 2.264 0 01.7 0 17.086 17.086 0 011.9.223c1.6.223 3.809.613 6.2 1.2a63.163 63.163 0 016.065 1.8c.759.272 1.393.5 1.79.7a2.271 2.271 0 01.643.294z"
          fill="#e0e0e0"
        />
        <path
          data-name="Path 6724"
          d="M261.221 51.709a12.908 12.908 0 01.773 2.2c.418 1.393.947 3.294 1.448 5.425s.863 4.095 1.093 5.515a12.739 12.739 0 01.272 2.312 17.1 17.1 0 01-.529-2.27c-.286-1.393-.7-3.343-1.191-5.473s-.982-4.046-1.344-5.439a16.609 16.609 0 01-.522-2.27z"
          fill="#e0e0e0"
        />
        <path
          data-name="Path 6725"
          d="M251.117 50.57c.091.056-1.595 3-3.753 6.581s-3.983 6.448-4.067 6.393 1.595-2.994 3.753-6.581 3.983-6.442 4.067-6.393z"
          fill="#e0e0e0"
        />
        <path
          data-name="Path 6726"
          d="M249.682 18.654c.1 0 1.142 3.092 2.34 6.964a72.815 72.815 0 011.992 7.075 73.26 73.26 0 01-2.34-6.964 68.738 68.738 0 01-1.992-7.075z"
          fill="#e0e0e0"
        />
        <path
          data-name="Path 6727"
          d="M247.565 40.702a94.283 94.283 0 01-11.646-1.114 48.089 48.089 0 015.842.376 47.353 47.353 0 015.8.738z"
          fill="#e0e0e0"
        />
        <circle
          data-name="Ellipse 177"
          cx={7.694}
          cy={7.694}
          r={7.694}
          transform="rotate(-15.709 257.173 -875.405)"
          fill="#e0e0e0"
        />
        <path
          data-name="Path 6728"
          d="M260.77 42.63c-.035.042-.4-.265-1.058-.627a7.145 7.145 0 00-5.647-.613 10.363 10.363 0 01-1.17.39 2.6 2.6 0 011.086-.634 6.414 6.414 0 015.863.634c.645.441.959.824.926.85z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6729"
          d="M257.676 38.112a18.956 18.956 0 00-1.212 3.579 18.9 18.9 0 00-.111 3.781 8.008 8.008 0 011.323-7.36z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6730"
          d="M158.353 46.733l-1.184-2.089-1.845.272-1.567-1.887.787-1.915-2.089-1.396-1.539 1.553-2.028-.353-.822-2.389-2.465.265-.139 2.089-1.831.933-1.943-1.392-1.97 1.434 1.024 2.207-1.177 1.762-2.131-.111-.557 2.632 2.026.78.181 2.207-2.089 1.2 1 2.284 2.173-.341 1.469 1.706-.749 2.095 1.992 1.393 1.56-1.685 2.159.585.7 2.228 2.4-.16.306-2.006 2.054-1.177 1.685 1.344 2.124-1.588-1.072-1.873 1.052-1.88 2.152.16.662-2.444-1.852-1-.244-2.228zm-8.356 7.193a4.875 4.875 0 111.79-6.615 4.874 4.874 0 01-1.79 6.615z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6731"
          d="M149.697 48.314a5.3 5.3 0 00-.432-.453 2.57 2.57 0 00-1.483-.7 2.409 2.409 0 101.929 3.356 2.611 2.611 0 00.16-1.623c-.091-.383-.2-.585-.174-.6a1.3 1.3 0 01.306.564 2.507 2.507 0 01-.056 1.755 2.674 2.674 0 01-2.005 1.643 2.715 2.715 0 11-.125-5.355 2.542 2.542 0 011.546.843 1.323 1.323 0 01.334.57z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6732"
          d="M152.002 42.917a10.647 10.647 0 01-1.393 2.4 10.446 10.446 0 01-1.671 2.214 10.605 10.605 0 011.393-2.409 10.508 10.508 0 011.671-2.205z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6733"
          d="M154.384 51.385a11.949 11.949 0 01-2.256-.494 11.734 11.734 0 01-2.228-.6 8.684 8.684 0 014.484 1.094z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6734"
          d="M148.558 51.963a5.111 5.111 0 01.7 1.943 4.966 4.966 0 01.223 2.054 7.13 7.13 0 01-.571-1.971 7.186 7.186 0 01-.352-2.026z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6735"
          d="M145.937 51.663a5.571 5.571 0 01-.857 1.776 5.7 5.7 0 01-1.17 1.588 5.334 5.334 0 01.857-1.776 5.571 5.571 0 011.17-1.588z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6736"
          d="M145.568 43.398a5.223 5.223 0 01.731 1.769 5.418 5.418 0 01.39 1.873 5.23 5.23 0 01-.738-1.769 5.126 5.126 0 01-.383-1.873z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6737"
          d="M145.021 49.107a3.433 3.433 0 01-1.525 0 3.329 3.329 0 01-1.5-.327 3.482 3.482 0 011.532 0 3.217 3.217 0 011.49.327z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6738"
          d="M149.203 48.599a2 2 0 11-2.73-.738 2 2 0 012.73.738z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6739"
          d="M148.437 49.602c-.07.077-.46-.2-1-.258s-.989.125-1.038.042.383-.474 1.072-.4 1.049.556.966.616z"
          fill="#e0e0e0"
        />
        <path
          data-name="Path 6740"
          d="M147.636 48.433c.084.063-.1.453-.181.954s-.063.926-.16.954-.306-.425-.195-1.017.459-.954.536-.891z"
          fill="#e0e0e0"
        />
        <path
          data-name="Path 6741"
          d="M351.132 38.154l-1.922-3.343-3 .439-2.486-3.113 1.253-3.134-3.35-2.228-2.495 2.528-3.294-.578-1.3-3.879-4 .425-.23 3.391-2.98 1.511-3.161-2.235-3.2 2.333 1.664 3.586-1.908 2.9-3.482-.188-.9 4.276 3.294 1.267.286 3.593-3.419 1.957 1.63 3.7 3.538-.55 2.361 2.653-1.226 3.363 3.238 2.291 2.528-2.737 3.482.947 1.079 3.631 3.9-.258.5-3.252 3.343-1.922 2.737 2.18 3.482-2.577-1.745-3.044 1.706-3.05 3.482.258 1.079-3.976-2.994-1.595-.4-3.621zm-13.579 11.7a21.287 21.287 0 100-.028z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6742"
          d="M337.124 40.693c-.035 0-.209-.306-.7-.766a4.227 4.227 0 00-2.423-1.156 4.011 4.011 0 103.2 5.571 4.178 4.178 0 00.223-2.674c-.167-.627-.362-.947-.327-.968a1.053 1.053 0 01.153.223 3.274 3.274 0 01.306.7 4.129 4.129 0 01-.118 2.785 4.3 4.3 0 01-3.21 2.6 4.234 4.234 0 01-2.5-.3 4.311 4.311 0 012.312-8.224 4.178 4.178 0 012.486 1.316 3.266 3.266 0 01.46.62 1.131 1.131 0 01.139.279z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6743"
          d="M340.871 31.922a27.923 27.923 0 01-2.319 3.858 28.326 28.326 0 01-2.618 3.649 28.106 28.106 0 012.319-3.851 28.747 28.747 0 012.618-3.656z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6744"
          d="M344.745 45.71c0 .1-1.63-.432-3.635-.912s-3.663-.759-3.656-.863a12.6 12.6 0 013.739.508 12.814 12.814 0 013.551 1.267z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6745"
          d="M335.272 46.622a12.256 12.256 0 011.038 3.189 12.157 12.157 0 01.474 3.322 21.636 21.636 0 01-.829-3.238 21.254 21.254 0 01-.682-3.273z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6746"
          d="M331.052 46.141a14.313 14.313 0 01-1.5 2.827 13.716 13.716 0 01-1.8 2.639 14.313 14.313 0 011.5-2.827 13.717 13.717 0 011.8-2.639z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6747"
          d="M330.411 32.708a13.655 13.655 0 011.086 2.9 13.925 13.925 0 01.738 3.015 13.228 13.228 0 01-1.086-2.9 13.354 13.354 0 01-.738-3.015z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6748"
          d="M329.52 41.984a8.265 8.265 0 01-2.472-.056 8.468 8.468 0 01-2.43-.411 17.109 17.109 0 014.874.467z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6749"
          d="M336.332 41.155a3.245 3.245 0 11-1.969-1.519 3.252 3.252 0 011.969 1.519z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6750"
          d="M335.077 42.798a8.228 8.228 0 00-1.616-.536 8.552 8.552 0 00-1.7.174 1.9 1.9 0 011.734-.536c1.08.125 1.672.851 1.582.898z"
          fill="#e0e0e0"
        />
        <path
          data-name="Path 6751"
          d="M333.779 40.897c.084.056-.265.7-.411 1.525s-.049 1.546-.146 1.574a2.089 2.089 0 01-.216-1.643c.173-.934.699-1.526.773-1.456z"
          fill="#e0e0e0"
        />
        <path
          data-name="Path 6752"
          d="M102.707 196.987l-1.922-3.343-3 .446-2.486-3.12 1.276-3.109-3.35-2.221-2.5 2.521-3.294-.578-1.337-3.893-4 .418-.23 3.391-2.98 1.518-3.161-2.235-3.2 2.333 1.664 3.586-1.9 2.869-3.482-.181-.905 4.269 3.3 1.267.279 3.593-3.412 1.957 1.63 3.7 3.538-.55 2.34 2.709-1.212 3.384 3.245 2.284 2.528-2.737 3.482.954 1.093 3.614 3.9-.258.5-3.252 3.343-1.922 2.737 2.187 3.482-2.584-1.748-3.043 1.706-3.05 3.482.258 1.079-3.976-3.015-1.629-.4-3.621zm-13.579 11.7a7.883 7.883 0 112.911-10.759 7.883 7.883 0 01-2.911 10.758z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6753"
          d="M88.717 199.564a9.876 9.876 0 00-.7-.773 4.227 4.227 0 00-2.423-1.156 4.011 4.011 0 103.2 5.571 4.234 4.234 0 00.223-2.681c-.167-.627-.362-.947-.327-.961a.87.87 0 01.153.216 3.656 3.656 0 01.306.7 4.129 4.129 0 01-.118 2.785 4.3 4.3 0 01-5.71 2.3 4.373 4.373 0 01-2.019-1.811 4.3 4.3 0 014.338-6.413 4.109 4.109 0 012.493 1.309 3.065 3.065 0 01.46.627.864.864 0 01.124.287z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6754"
          d="M92.443 190.79a28.107 28.107 0 01-2.319 3.851 28.336 28.336 0 01-2.618 3.649 55.9 55.9 0 014.937-7.5z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6755"
          d="M96.314 204.585c-.042.1-1.629-.439-3.635-.919s-3.67-.759-3.656-.863a12.619 12.619 0 013.739.508 12.438 12.438 0 013.552 1.274z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6756"
          d="M86.844 205.49a11.879 11.879 0 011.031 3.182 12.047 12.047 0 01.48 3.322q-.9-3.217-1.511-6.5z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6757"
          d="M82.589 205.002a14.311 14.311 0 01-1.5 2.827 13.447 13.447 0 01-1.8 2.639 14.527 14.527 0 011.5-2.827 14 14 0 011.8-2.639z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6758"
          d="M81.983 191.577a13.279 13.279 0 011.086 2.9 13.815 13.815 0 01.738 3.008 13.048 13.048 0 01-1.086-2.9 13.434 13.434 0 01-.738-3.008z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6759"
          d="M81.095 200.846a8.4 8.4 0 01-2.472-.049 8.607 8.607 0 01-2.437-.39 17.111 17.111 0 014.875.467z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6760"
          d="M87.902 200.017a3.245 3.245 0 11-1.969-1.519 3.252 3.252 0 011.969 1.519z"
          fill="#ebebeb"
        />
        <path
          data-name="Path 6761"
          d="M86.628 201.661a8.232 8.232 0 00-1.616-.536 7.73 7.73 0 00-1.7.181 1.859 1.859 0 011.734-.536 1.887 1.887 0 011.582.891z"
          fill="#e0e0e0"
        />
        <path
          data-name="Path 6762"
          d="M85.346 199.759c.091.056-.265.7-.4 1.525s-.049 1.546-.153 1.574a2.089 2.089 0 01-.209-1.636 2.132 2.132 0 01.762-1.463z"
          fill="#e0e0e0"
        />
      </g>
      <g transform="translate(156.842 51.447)">
        <path
          data-name="Path 6764"
          d="M85.862 84.449v-5.09L82.13 77.89l-.794-5.195 3.482-2.716-2.375-4.749-4.526 1.246-3.391-2.827 1.024-5.313-4.875-2.145-2.493 3.726-4.408-.223-2.145-4.638-5.2.571-.453 5.195-4.067 2.033-3.844-2.486-3.8 4.331 2.939 3.621-2.033 4.29h-5.2l-.564 5.313 4.408 1.7.877 4.575-3.614 3.057 2.2 4.749 4.693-1.476 3.391 3.391-1.1 4.9 4.638 2.263 2.716-3.391h5.083l1.7 4.3 5.648-.7v-4.638l3.955-2.375 3.844 2.6 3.844-3.844-2.381-3.833 1.922-4.408zm-23.231 4.464a10.4 10.4 0 1110.4-10.4 10.4 10.4 0 01-10.4 10.4z"
          fill="#263238"
        />
        <path
          data-name="Path 6765"
          d="M68.132 78.19a9.834 9.834 0 00-.244-1.33 5.452 5.452 0 00-2.006-2.939 5.369 5.369 0 00-2.263-.989 5.438 5.438 0 00-2.785.244 5.334 5.334 0 105.07 9.262 5.467 5.467 0 002.006-2.946c.223-.836.2-1.33.244-1.323a1.33 1.33 0 010 .348 4.177 4.177 0 01-.118 1.01 5.459 5.459 0 01-1.992 3.113 5.646 5.646 0 11-5.334-9.8 5.7 5.7 0 012.973-.223 5.571 5.571 0 012.361 1.072 5.46 5.46 0 011.992 3.113 4.177 4.177 0 01.118 1.01 1.391 1.391 0 01-.022.378z"
          fill="#263238"
        />
        <path
          data-name="Path 6766"
          d="M78.188 70.604a49.361 49.361 0 01-5.209 2.834 48.263 48.263 0 01-5.376 2.507 48.418 48.418 0 015.209-2.827 49.155 49.155 0 015.376-2.514z"
          fill="#263238"
        />
        <path
          data-name="Path 6767"
          d="M73.558 88.933c-.084.07-1.539-1.609-3.524-3.482s-3.719-3.259-3.649-3.343a5.173 5.173 0 011.219.794 32.288 32.288 0 015.1 4.875 5.3 5.3 0 01.857 1.156z"
          fill="#263238"
        />
        <path
          data-name="Path 6768"
          d="M62.095 83.752a30.568 30.568 0 01-2.549 8.44c-.1 0 .536-1.908 1.233-4.234a43.1 43.1 0 011.316-4.206z"
          fill="#263238"
        />
        <path
          data-name="Path 6769"
          d="M57.561 80.399a25.011 25.011 0 01-3.593 2.2 24.372 24.372 0 01-3.774 1.887 24.868 24.868 0 013.6-2.2 24.163 24.163 0 013.767-1.887z"
          fill="#263238"
        />
        <path
          data-name="Path 6770"
          d="M65.695 64.629a22.784 22.784 0 01-.7 4.025 22.866 22.866 0 01-1.072 3.941 22.786 22.786 0 01.7-4.025 23.345 23.345 0 011.072-3.941z"
          fill="#263238"
        />
        <path
          data-name="Path 6771"
          d="M58.613 74.657a14.7 14.7 0 01-2.785-1.727 15.321 15.321 0 01-2.549-2.026 14.214 14.214 0 012.785 1.727 14.624 14.624 0 012.549 2.026z"
          fill="#263238"
        />
        <path
          data-name="Path 6772"
          d="M66.92 78.187a4.29 4.29 0 11-4.29-4.29 4.29 4.29 0 014.29 4.29z"
          fill="#263238"
        />
        <path
          data-name="Path 6773"
          d="M64.414 79.246a7.486 7.486 0 00-1.469-1.727 7.2 7.2 0 00-2.089-.863c0-.049.272-.111.7-.063a3.482 3.482 0 012.688 1.957c.191.404.211.682.17.696z"
          fill="#455a64"
        />
        <path
          data-name="Path 6774"
          d="M64.184 76.21c.056.1-.78.557-1.511 1.441s-1.058 1.776-1.163 1.741a3.928 3.928 0 012.674-3.182z"
          fill="#455a64"
        />
        <path
          data-name="Path 6775"
          d="M62.36 23.815l-3.44-5.989-5.383.787-4.45-5.571 2.291-5.571-6-4-4.485 4.526-5.905-1.038-2.4-6.964-7.18.759-.4 6.072-5.342 2.721-5.661-4L8.26 9.725l2.987 6.427-3.419 5.153-6.2-.334-1.623 7.66 5.905 2.27.508 6.434-6.121 3.517 2.925 6.643 6.337-.989 4.178 4.875-2.194 6.044 5.808 4.1 4.526-4.875 6.267 1.7 1.957 6.49 6.964-.474.9-5.829 5.989-3.44 4.875 3.914 6.2-4.624-3.134-5.453 3.057-5.473 6.267.46 1.929-7.124-5.4-2.918-.7-6.5zM38.022 44.787a14.122 14.122 0 115.214-19.286 14.122 14.122 0 01-5.214 19.286z"
          fill="#ff435b"
        />
        <path
          data-name="Path 6776"
          d="M37.249 28.432a16.664 16.664 0 00-1.163-1.393 7.451 7.451 0 00-4.373-2.131 7.263 7.263 0 00-3.35.362 7.347 7.347 0 00-3.175 2.173 6.719 6.719 0 00-1.1 1.783 6.9 6.9 0 00-.564 2.089 7.263 7.263 0 004.29 7.472 7.356 7.356 0 0010.021-8.684 18.219 18.219 0 00-.634-1.713 5.78 5.78 0 01.773 1.678 7.472 7.472 0 01-.258 4.986 7.6 7.6 0 01-2.047 2.841 7.694 7.694 0 01-3.635 1.727 7.438 7.438 0 01-2.194.07 7.179 7.179 0 01-2.207-.6 7.6 7.6 0 01-4.457-7.82 7.347 7.347 0 01.6-2.207 7.07 7.07 0 011.17-1.859 7.528 7.528 0 016.8-2.6 7.437 7.437 0 014.436 2.291 5.94 5.94 0 011.067 1.535z"
          fill="#263238"
        />
        <path
          data-name="Path 6777"
          d="M43.989 12.701c.084.056-1.824 3.113-4.276 6.824s-4.492 6.685-4.575 6.629 1.831-3.113 4.276-6.824 4.459-6.687 4.575-6.629z"
          fill="#263238"
        />
        <path
          data-name="Path 6778"
          d="M50.904 37.416a12.709 12.709 0 01-1.908-.522c-1.163-.355-2.785-.822-4.582-1.26s-3.447-.773-4.645-1a13.326 13.326 0 01-1.929-.4 8.962 8.962 0 011.971.153 46.9 46.9 0 014.693.891 46.122 46.122 0 014.575 1.393 9.388 9.388 0 011.824.745z"
          fill="#263238"
        />
        <path
          data-name="Path 6779"
          d="M33.927 39.052a8.288 8.288 0 01.613 1.65c.32 1.038.7 2.479 1.1 4.095s.648 3.092.815 4.178a8.354 8.354 0 01.181 1.755 8.828 8.828 0 01-.432-1.706c-.23-1.058-.543-2.521-.919-4.129s-.731-3.057-1-4.109a9.458 9.458 0 01-.362-1.734z"
          fill="#263238"
        />
        <path
          data-name="Path 6780"
          d="M26.294 38.188a45.429 45.429 0 01-2.785 4.993 46.505 46.505 0 01-3.106 4.8 42.73 42.73 0 012.785-4.993 44.692 44.692 0 013.106-4.8z"
          fill="#263238"
        />
        <path
          data-name="Path 6781"
          d="M25.188 14.115a42.785 42.785 0 011.8 5.244 42.1 42.1 0 011.462 5.355 42.446 42.446 0 01-1.811-5.251 42.479 42.479 0 01-1.451-5.348z"
          fill="#263238"
        />
        <path
          data-name="Path 6782"
          d="M23.614 30.738a27.041 27.041 0 01-4.415-.244 26.134 26.134 0 01-4.373-.6 26.95 26.95 0 014.408.244 26.3 26.3 0 014.38.6z"
          fill="#263238"
        />
        <circle
          data-name="Ellipse 178"
          cx={5.822}
          cy={5.822}
          r={5.822}
          transform="rotate(-19.5 94.805 -53.569)"
          fill="#263238"
        />
        <path
          data-name="Path 6783"
          d="M33.586 32.193a8.218 8.218 0 00-2.89-1.1 8 8 0 00-3.064.46 1.914 1.914 0 01.815-.508 4.777 4.777 0 014.45.48c.494.341.716.64.689.668z"
          fill="#455a64"
        />
        <path
          data-name="Path 6784"
          d="M31.246 28.78a20.362 20.362 0 00-.87 2.709 19.967 19.967 0 00-.125 2.848 4.666 4.666 0 01-.237-2.911 4.728 4.728 0 011.233-2.646z"
          fill="#455a64"
        />
        <path
          data-name="Path 6785"
          d="M85.11 40.8l-1.184-2.089-1.845.272-1.532-1.922.787-1.908-2.089-1.393-1.539 1.553-2.026-.355-.794-2.291-2.465.258-.139 2.089-1.831.9-1.943-1.393-1.971 1.435 1.024 2.207-1.178 1.77-2.131-.111-.557 2.625 2.026.78.174 2.207-2.089 1.212 1 2.277 2.173-.334 1.441 1.664-.752 2.089 1.992 1.393 1.56-1.678 2.159.585.7 2.221 2.4-.16.313-2 2.054-1.184 1.685 1.344 2.124-1.588-1.107-1.894 1.045-1.873 2.159.153.7-2.444-1.852-1-.244-2.228zM76.754 48a13.122 13.122 0 100-.014z"
          fill="#263238"
        />
        <path
          data-name="Path 6786"
          d="M76.489 42.415s-.146-.174-.432-.453a2.611 2.611 0 00-1.483-.7 2.451 2.451 0 00-2.138.87 2.408 2.408 0 104.067 2.493 2.611 2.611 0 00.16-1.623c-.091-.39-.209-.585-.174-.606a1.3 1.3 0 01.306.564 2.528 2.528 0 01-.056 1.762 2.688 2.688 0 01-2.006 1.643 2.723 2.723 0 01-2.556-4.45 2.667 2.667 0 012.451-.9 2.535 2.535 0 011.546.836 1.323 1.323 0 01.313.557z"
          fill="#263238"
        />
        <path
          data-name="Path 6787"
          d="M78.808 36.984a10.6 10.6 0 01-1.393 2.409 10.507 10.507 0 01-1.671 2.207 10.771 10.771 0 011.393-2.409 10.509 10.509 0 011.671-2.207z"
          fill="#263238"
        />
        <path
          data-name="Path 6788"
          d="M81.176 45.473a12.326 12.326 0 01-2.256-.5 11.141 11.141 0 01-2.228-.6 5.725 5.725 0 012.312.244 5.571 5.571 0 012.172.856z"
          fill="#263238"
        />
        <path
          data-name="Path 6789"
          d="M75.326 46.03a5.237 5.237 0 01.7 1.95 4.958 4.958 0 01.223 2.054 7.483 7.483 0 01-.578-1.978 7.32 7.32 0 01-.345-2.026z"
          fill="#263238"
        />
        <path
          data-name="Path 6790"
          d="M72.731 45.73a5.5 5.5 0 01-.857 1.776 5.446 5.446 0 01-1.17 1.588 5.467 5.467 0 01.857-1.776 5.571 5.571 0 011.17-1.588z"
          fill="#263238"
        />
        <path
          data-name="Path 6791"
          d="M72.352 37.472a5.076 5.076 0 01.738 1.762 5.447 5.447 0 01.39 1.873 5.076 5.076 0 01-.738-1.762 5.444 5.444 0 01-.39-1.873z"
          fill="#263238"
        />
        <path
          data-name="Path 6792"
          d="M71.843 43.175a3.322 3.322 0 01-1.525 0 3.384 3.384 0 01-1.5-.32 3.21 3.21 0 011.525-.035 3.384 3.384 0 011.5.355z"
          fill="#263238"
        />
        <path
          data-name="Path 6793"
          d="M76.023 42.666a2 2 0 11-1.236-.962 2 2 0 011.236.962z"
          fill="#263238"
        />
        <path
          data-name="Path 6794"
          d="M75.226 43.676c-.07.077-.46-.209-1-.258s-.989.125-1.038.035.383-.467 1.072-.4 1.066.567.966.623z"
          fill="#455a64"
        />
        <path
          data-name="Path 6795"
          d="M74.428 42.507c.084.056-.1.446-.181.947s-.063.933-.16.961-.306-.432-.2-1.024.464-.975.541-.884z"
          fill="#455a64"
        />
      </g>
      <path
        data-name="Path 6796"
        d="M275.935 93.166s-2.368-.829-2.4-2.382 17.723-3.767 21.114 4.875c0 0-.7.467-3.405.292"
        fill="#ff435b"
      />
      <g data-name="Group 6520">
        <path
          data-name="Path 6797"
          d="M275.935 93.166s-2.368-.829-2.4-2.382 17.723-3.767 21.114 4.875c0 0-.7.467-3.405.292"
          opacity={0.5}
        />
      </g>
      <path
        data-name="Path 6798"
        d="M290.663 118.235l-10.828-.863c-.07-1.26 0-6.156 0-6.156s-4.505-.7-4.561-5.2.7-14.853.7-14.853a15.654 15.654 0 0115.787 2.006l.7.515z"
        fill="#ffbe9d"
      />
      <path
        data-name="Path 6799"
        d="M277.063 98.925a.655.655 0 10-.877-.968.648.648 0 00-.049.919.655.655 0 00.926.049z"
        fill="#263238"
      />
      <path
        data-name="Path 6800"
        d="M275.969 97.894c.07.077.515-.265 1.135-.265s1.072.32 1.142.237-.042-.174-.237-.327a1.56 1.56 0 00-.912-.286 1.511 1.511 0 00-.9.313c-.158.154-.263.293-.228.328z"
        fill="#263238"
      />
      <path
        data-name="Path 6801"
        d="M283.121 98.438a.655.655 0 10.9.216.655.655 0 00-.9-.216z"
        fill="#263238"
      />
      <path
        data-name="Path 6802"
        d="M282.71 98.444c.07.077.515-.265 1.135-.265s1.072.313 1.135.237 0-.181-.23-.327a1.546 1.546 0 00-1.818 0c-.187.174-.257.321-.222.355z"
        fill="#263238"
      />
      <path
        data-name="Path 6803"
        d="M280.217 103.562a3.148 3.148 0 00-1-.216c-.167 0-.293-.056-.306-.139a.905.905 0 01.118-.467l.494-1.26a17.344 17.344 0 001.058-3.315 17.072 17.072 0 00-1.393 3.189l-.467 1.274a1.065 1.065 0 00-.084.7.411.411 0 00.279.258 1.449 1.449 0 00.279 0 3.092 3.092 0 001.022-.024z"
        fill="#263238"
      />
      <path
        data-name="Path 6804"
        d="M282.234 104.246a11.365 11.365 0 01-3.043.884 1.72 1.72 0 002.026.7c1.347-.513 1.017-1.584 1.017-1.584z"
        fill="#fff"
      />
      <path
        data-name="Path 6805"
        d="M279.855 111.174a10.445 10.445 0 005.87-1.671s-1.393 3.245-5.884 2.834z"
        fill="#eb996e"
      />
      <path
        data-name="Path 6806"
        d="M275.935 92.316a7.479 7.479 0 006.323 0c2.423-1 5.188-1.393 5.752-.85s.515 2.932 2.785 3.8c0 0-.494 4.582 1.344 4.554s1.226-7.228 1.226-7.228l-4.318-2.514-4.811-1.288-4.652.313-3.635.7z"
        fill="#263238"
      />
      <path
        data-name="Path 6807"
        d="M291.875 98.34c.077-.035 3.092-.919 2.994 2.166a2.313 2.313 0 01-3.175 2.256c-.007-.091.181-4.422.181-4.422z"
        fill="#ffbe9d"
      />
      <path
        data-name="Path 6808"
        d="M292.675 101.627s.049 0 .139.084a.55.55 0 00.4 0 1.323 1.323 0 00.648-1.17 1.679 1.679 0 00-.125-.78.592.592 0 00-.39-.418.265.265 0 00-.32.132c-.042.084 0 .146-.042.153s-.063-.056-.035-.181a.348.348 0 01.125-.188.432.432 0 01.3-.07.745.745 0 01.543.494 1.763 1.763 0 01.16.863 1.393 1.393 0 01-.822 1.309.571.571 0 01-.487-.1c-.094-.055-.108-.128-.094-.128z"
        fill="#eb996e"
      />
      <path
        data-name="Path 6809"
        d="M293.964 88.793c0 .1-.48.063-1 .23s-.863.446-.94.376.209-.529.836-.7 1.138.003 1.104.094z"
        fill="#263238"
      />
      <path
        data-name="Path 6810"
        d="M292.216 86.846a2.089 2.089 0 01.1 1.469c-.167.836-.7 1.33-.759 1.26s.265-.592.4-1.337.148-1.381.259-1.392z"
        fill="#263238"
      />
      <path
        data-name="Path 6811"
        d="M278.985 96.884c-.1.181-.7 0-1.393 0s-1.3.056-1.393-.132.529-.634 1.393-.592 1.501.557 1.393.724z"
        fill="#263238"
      />
      <path
        data-name="Path 6812"
        d="M285.9 96.884c-.1.181-.7 0-1.393 0s-1.309.056-1.393-.132.522-.634 1.393-.592 1.497.557 1.393.724z"
        fill="#263238"
      />
      <path
        data-name="Path 6813"
        d="M283.288 90.145c-8.12-.884-9.673.55-9.749.62a1.713 1.713 0 01-.418-.8c-.285-2.4 2.625-3.078 2.625-3.078 1.818-8.308 9.29-7.458 9.29-7.458v-.063a1.344 1.344 0 011.574-1.065l1.17.223a1.344 1.344 0 011.065 1.574c8.753 2.918 5.849 11.016 5.849 11.016l1.281 1.546a2.03 2.03 0 01-1.33 3s-3.029-4.583-11.357-5.515z"
        fill="#ff435b"
      />
      <g data-name="Group 6521">
        <path
          data-name="Path 6814"
          d="M287.376 87.31a18.28 18.28 0 01.641-3.579 18.107 18.107 0 011-3.482 18.8 18.8 0 01-.641 3.572 18.5 18.5 0 01-1 3.489z"
          opacity={0.5}
        />
      </g>
      <g data-name="Group 6522">
        <path
          data-name="Path 6815"
          d="M283.001 86.202a17.333 17.333 0 01.787-3.44 16.91 16.91 0 011.135-3.336 17.215 17.215 0 01-.787 3.433 17.091 17.091 0 01-1.135 3.343z"
          opacity={0.5}
        />
      </g>
      <g data-name="Group 6523">
        <path
          data-name="Path 6816"
          d="M276.109 87.237a2.785 2.785 0 01.7-.251 14.152 14.152 0 012.013-.4 18.934 18.934 0 016.734.432 24.421 24.421 0 016.267 2.535c.752.425 1.337.808 1.734 1.086a2.97 2.97 0 01.592.46c0 .056-.933-.529-2.451-1.323a27.777 27.777 0 00-6.225-2.4 20.619 20.619 0 00-6.622-.529c-1.697.149-2.728.455-2.742.39z"
          opacity={0.5}
        />
      </g>
      <g data-name="Group 6524">
        <path
          data-name="Path 6817"
          d="M286.986 88.779a4.463 4.463 0 011.441.272 16.3 16.3 0 016.17 3.363 4.693 4.693 0 011.017 1.058 43.468 43.468 0 00-4.098-2.785 42.426 42.426 0 00-4.533-1.908z"
          fill="#fff"
          opacity={0.2}
        />
      </g>
      <g data-name="Group 6525">
        <path
          data-name="Path 6818"
          d="M288.302 80.631c-.091-.042 0-.418-.111-.85s-.292-.752-.216-.829.467.195.571.759-.159.92-.244.92z"
          fill="#fff"
          opacity={0.2}
        />
      </g>
      <g data-name="Group 6526">
        <path
          data-name="Path 6819"
          d="M293.943 89.907c-.5-.1-.32-2.458-1.574-4.791s-3.259-3.538-3.057-4c.084-.223.773-.223 1.734.244a6.922 6.922 0 013.677 6.964c-.16 1.075-.578 1.638-.78 1.583z"
          fill="#fff"
          opacity={0.2}
        />
      </g>
      <path
        data-name="Path 6820"
        d="M228.932 147.858s-5.355-2.785-6.267-4-1.337-5.654-1.337-5.654l9.136 3.412z"
        fill="#ffbe9d"
      />
      <path
        data-name="Path 6821"
        d="M268.804 136.341c-.313.1-6.219 13.273-10.961 13.342s-28.913-1.845-28.913-1.845l1.5-6.267 23.182-.745 7.6-10.285z"
        fill="#ffbe9d"
      />
      <path
        data-name="Path 6822"
        d="M311.393 286.679l1.079 4.505s7.375 9.582 9.645 9.115c1.49-.313-.341-2.785-3.148-10.083l-.585-3.008z"
        fill="#ff435b"
      />
      <path
        data-name="Path 6823"
        d="M322.417 298.879a.411.411 0 01-.056.139.766.766 0 01-.306.285 1.184 1.184 0 01-.7.146 1.762 1.762 0 01-.836-.306 35.425 35.425 0 01-3.712-3.482 47.917 47.917 0 01-3.3-3.865c-.39-.515-.7-.94-.905-1.24l-.237-.348c-.049-.084-.077-.125-.07-.125l.1.1.251.334.947 1.212a54.951 54.951 0 003.322 3.823 41.172 41.172 0 003.663 3.482 1.393 1.393 0 001.393.195.9.9 0 00.446-.355z"
        fill="#263238"
      />
      <path
        data-name="Path 6824"
        d="M321.372 295.808s-.23 0-.592.035a3.615 3.615 0 00-1.33.5 2.708 2.708 0 00-1 1c-.174.32-.223.55-.244.543a1.227 1.227 0 01.153-.592 2.549 2.549 0 011.01-1.072 3.141 3.141 0 011.393-.481 1.149 1.149 0 01.61.067z"
        fill="#263238"
      />
      <path
        data-name="Path 6825"
        d="M314.395 293.555a7.352 7.352 0 00.132-.843 3.482 3.482 0 00-.369-1.978 3.28 3.28 0 00-1.393-1.393 6.1 6.1 0 00-.8-.293.767.767 0 01.237 0 2.291 2.291 0 01.606.188 3.148 3.148 0 011.5 1.448 3.384 3.384 0 01.341 2.054 3.912 3.912 0 01-.146.62.7.7 0 01-.108.197z"
        fill="#263238"
      />
      <path
        data-name="Path 6826"
        d="M311.867 287.452a4.358 4.358 0 01.411 1.393c.132.8.279 1.435.244 1.448a4.713 4.713 0 01-.39-1.393c-.139-.815-.299-1.448-.265-1.448z"
        fill="#263238"
      />
      <path
        data-name="Path 6827"
        d="M319.105 290.849c0 .042-.418.035-.912.146s-.863.306-.884.272a2.152 2.152 0 011.8-.418z"
        fill="#263238"
      />
      <path
        data-name="Path 6828"
        d="M319.256 291.63c0 .042-.355 0-.766.1s-.7.286-.731.251a1.1 1.1 0 01.7-.39 1.149 1.149 0 01.797.039z"
        fill="#263238"
      />
      <path
        data-name="Path 6829"
        d="M319.847 292.591a4.41 4.41 0 00-.8 0 4.469 4.469 0 00-.7.32.94.94 0 01.7-.46 1 1 0 01.8.14z"
        fill="#263238"
      />
      <path
        data-name="Path 6830"
        d="M318.594 290.529a.905.905 0 01-.244 0 4.934 4.934 0 01-.655-.181 5.808 5.808 0 01-.919-.4 5.919 5.919 0 01-.508-.313.766.766 0 01-.369-.571.439.439 0 01.564-.348 1.156 1.156 0 01.515.341 8.611 8.611 0 01.7.738 6.186 6.186 0 01.4.543.758.758 0 01.125.216s-.23-.272-.606-.7a8.17 8.17 0 00-.7-.7 1.052 1.052 0 00-.474-.313c-.167-.049-.383.042-.39.216s.16.334.306.446a5.767 5.767 0 00.494.313 6.753 6.753 0 00.884.432c.543.184.884.254.877.281z"
        fill="#263238"
      />
      <path
        data-name="Path 6831"
        d="M318.211 290.432v-.223a3.114 3.114 0 01.049-.613 3.3 3.3 0 01.23-.891 2.032 2.032 0 01.292-.474.508.508 0 01.592-.188.564.564 0 01.2.592 2.04 2.04 0 01-.16.529 2.854 2.854 0 01-.529.759 3.659 3.659 0 01-.467.4.647.647 0 01-.195.111 5.886 5.886 0 00.592-.578 2.919 2.919 0 00.474-.745 1.935 1.935 0 00.146-.494c0-.167 0-.369-.132-.439a.343.343 0 00-.418.153 1.87 1.87 0 00-.272.432 3.28 3.28 0 00-.251.85 7.451 7.451 0 01-.153.822z"
        fill="#263238"
      />
      <g data-name="Group 6527">
        <path
          data-name="Path 6832"
          d="M312.474 291.185l-.522-2.166s1.811.334 2.277 1.685a5.688 5.688 0 01.348 2.319l3.935 4.227s.982-1.922 2.785-1.462a21.14 21.14 0 011.393 3.865c0 .773-.947.7-1.267.536a16.191 16.191 0 01-3.517-2.786 81.1 81.1 0 01-5.432-6.218z"
          opacity={0.4}
        />
      </g>
      <path
        data-name="Path 6833"
        d="M274.737 288.956l-.035-6.017 11.26-.132.146 8.8-.7.056c-3.106.209-15.808.85-17.9.244-2.318-.68 7.229-2.951 7.229-2.951z"
        fill="#ff435b"
      />
      <g data-name="Group 6528">
        <path
          data-name="Path 6834"
          d="M282.026 291.567a3.92 3.92 0 011.393-2.423 3.8 3.8 0 012.618-.85l.042 3.148z"
          opacity={0.4}
        />
      </g>
      <path
        data-name="Path 6835"
        d="M270.072 290.237s-3.343 1.01-2.918 1.56 13.287.383 18.955-.188v-.251l-15 .32s-.383-1.441-1.037-1.441z"
        opacity={0.4}
      />
      <path
        data-name="Path 6836"
        d="M286.261 291.345a1.306 1.306 0 01-.188 0l-.557.042-2.047.111c-1.727.084-4.116.174-6.762.209s-5.028 0-6.762 0l-2.047-.063h8.809c2.639 0 5.028-.1 6.755-.167l2.047-.07h.557z"
        fill="#263238"
      />
      <path
        data-name="Path 6837"
        d="M271.094 291.846a4.964 4.964 0 00-.4-.975 4.426 4.426 0 00-.7-.773 1.539 1.539 0 01.829.7 1.511 1.511 0 01.265 1.051z"
        fill="#263238"
      />
      <path
        data-name="Path 6838"
        d="M274.395 290.119a1.432 1.432 0 01-.362-.432c-.167-.258-.279-.481-.244-.5s.188.174.355.432a1.156 1.156 0 01.251.5z"
        fill="#263238"
      />
      <path
        data-name="Path 6839"
        d="M275.301 289.625a1.858 1.858 0 01-.4-.265c-.2-.174-.334-.334-.306-.362s.2.091.4.265.349.334.306.362z"
        fill="#263238"
      />
      <path
        data-name="Path 6840"
        d="M275.865 288.552c0 .042-.265.077-.585.084s-.592 0-.592-.056a1.338 1.338 0 01.585-.084 1.393 1.393 0 01.592.056z"
        fill="#263238"
      />
      <path
        data-name="Path 6841"
        d="M276.04 287.799a1.135 1.135 0 01-.655.2 1.121 1.121 0 01-.7-.084 3.292 3.292 0 01.662-.056 4.255 4.255 0 01.693-.06z"
        fill="#263238"
      />
      <path
        data-name="Path 6842"
        d="M273.999 289.137a1.887 1.887 0 01-.7 0 4.257 4.257 0 01-.773-.16 2.207 2.207 0 01-.439-.174.432.432 0 01-.188-.223.292.292 0 01.111-.292 1.323 1.323 0 011.643.369 1.3 1.3 0 01.244.48.466.466 0 010 .195 2.089 2.089 0 00-.341-.613 1.3 1.3 0 00-.62-.4 1.128 1.128 0 00-.863.07c-.111.091-.07.223.063.3a2.422 2.422 0 00.411.167 4.771 4.771 0 00.745.181c.435.086.707.079.707.1z"
        fill="#263238"
      />
      <path
        data-name="Path 6843"
        d="M273.811 289.179a.745.745 0 01-.091-.5 1.3 1.3 0 01.23-.529.731.731 0 01.6-.376.285.285 0 01.223.334.7.7 0 01-.118.327 2.088 2.088 0 01-.334.439 1.073 1.073 0 01-.411.3 4.178 4.178 0 00.341-.362 2.522 2.522 0 00.293-.439c.1-.153.167-.432 0-.46s-.369.167-.474.313a1.205 1.205 0 00-.2.474 3.8 3.8 0 00-.056.48z"
        fill="#263238"
      />
      <path
        data-name="Path 6844"
        d="M285.997 288.266s-.327 0-.836.035a3.538 3.538 0 00-2.918 2.389 8.27 8.27 0 00-.2.808v-.223a3.064 3.064 0 01.118-.613 3.4 3.4 0 013-2.451 2.526 2.526 0 01.62 0c.139.021.216.041.216.055z"
        fill="#263238"
      />
      <path
        data-name="Path 6845"
        d="M284.764 283.594a18.35 18.35 0 01.132 2.312 19.116 19.116 0 010 2.312 21.363 21.363 0 01-.125-2.312 20.121 20.121 0 01-.007-2.312z"
        fill="#263238"
      />
      <path
        data-name="Path 6846"
        d="M280.594 290.481a6.129 6.129 0 01-1.643.188 6.464 6.464 0 01-1.65-.111c0-.042.738 0 1.643 0s1.643-.112 1.65-.077z"
        fill="#263238"
      />
      <path
        data-name="Path 6847"
        d="M282.801 290.45s-.063.153-.132.327-.111.32-.153.313-.056-.174 0-.369.257-.296.285-.271z"
        fill="#263238"
      />
      <path
        data-name="Path 6848"
        d="M283.616 289.408a.74.74 0 01-.16.265c-.118.125-.23.195-.251.167s.042-.146.153-.265.23-.195.258-.167z"
        fill="#263238"
      />
      <path
        data-name="Path 6849"
        d="M284.799 288.921c0 .035-.153.035-.32.1s-.279.146-.313.125.063-.181.272-.258.375-.002.361.033z"
        fill="#263238"
      />
      <path
        data-name="Path 6850"
        d="M285.645 288.685c0 .035-.049.091-.146.132s-.181.035-.2 0 .056-.091.146-.125.19-.042.2-.007z"
        fill="#263238"
      />
      <path
        data-name="Path 6851"
        d="M301.108 171.158l-34.017 1.929c-.1-3.746 1.288-33.753 1.288-33.753l-1.873 3.482-9.749-8.684 8.51-11.5a16.852 16.852 0 0111.267-6.664c1.323-.181 2.326-.265 2.973-.348a19.178 19.178 0 0011.553 0 15.654 15.654 0 014.777.919 19.686 19.686 0 017.66 4.937l10.188 17.117-12.354 7.771-1.783-5.383z"
        fill="#ff435b"
      />
      <path
        data-name="Path 6852"
        d="M267.07 169.522l5.258 116.843 17.541.369-2.911-97.818 19.728 98.564h16.678l-22.276-117.958"
        fill="#263238"
      />
      <path
        data-name="Path 6853"
        d="M305.816 285.892l.4 1.727 17.145.23-.258-2.7z"
        fill="#455a64"
      />
      <path
        data-name="Path 6854"
        d="M286.839 285.711c.1 0 .195.23.209.515s-.056.522-.153.529-.2-.223-.209-.508.056-.529.153-.536z"
        fill="#455a64"
      />
      <path
        data-name="Path 6855"
        d="M286.673 281.484c.1 0 .195.467.216 1.052s-.042 1.058-.139 1.065-.2-.467-.223-1.052.041-1.065.146-1.065z"
        fill="#455a64"
      />
      <path
        data-name="Path 6856"
        d="M286.541 277.255c.1 0 .195.467.216 1.052s-.056 1.065-.153 1.065-.2-.467-.209-1.052.048-1.065.146-1.065z"
        fill="#455a64"
      />
      <path
        data-name="Path 6857"
        d="M286.435 273.016c.1 0 .195.467.209 1.052s-.056 1.065-.153 1.065-.195-.474-.209-1.058.056-1.059.153-1.059z"
        fill="#455a64"
      />
      <path
        data-name="Path 6858"
        d="M286.339 268.775c.1 0 .188.474.209 1.058s-.056 1.058-.16 1.065-.188-.474-.209-1.058.055-1.058.16-1.065z"
        fill="#455a64"
      />
      <path
        data-name="Path 6859"
        d="M286.22 264.542c.1 0 .2.467.209 1.052s-.049 1.058-.146 1.065-.195-.467-.216-1.058.048-1.059.153-1.059z"
        fill="#455a64"
      />
      <path
        data-name="Path 6860"
        d="M286.053 260.293c.1 0 .2.46.23 1.045s-.042 1.065-.139 1.065-.2-.46-.223-1.045.034-1.065.132-1.065z"
        fill="#455a64"
      />
      <path
        data-name="Path 6861"
        d="M285.879 256.074c.1 0 .2.467.223 1.052s-.035 1.058-.139 1.065-.195-.467-.223-1.052.048-1.065.139-1.065z"
        fill="#455a64"
      />
      <path
        data-name="Path 6862"
        d="M285.723 251.84c.1 0 .2.467.223 1.052s-.049 1.058-.146 1.065-.195-.467-.216-1.052.044-1.065.139-1.065z"
        fill="#455a64"
      />
      <path
        data-name="Path 6863"
        d="M285.593 247.599c.1 0 .2.474.216 1.058s-.049 1.058-.153 1.065-.195-.474-.209-1.058.048-1.058.146-1.065z"
        fill="#455a64"
      />
      <path
        data-name="Path 6864"
        d="M285.482 243.369c.1 0 .188.467.209 1.052s-.056 1.058-.16 1.065-.188-.474-.2-1.058.046-1.059.151-1.059z"
        fill="#455a64"
      />
      <path
        data-name="Path 6865"
        d="M285.384 239.131c.1 0 .2.474.209 1.058s-.063 1.058-.16 1.058-.2-.467-.2-1.052.054-1.057.151-1.064z"
        fill="#455a64"
      />
      <path
        data-name="Path 6866"
        d="M285.315 234.897c.1 0 .188.474.2 1.058s-.07 1.058-.167 1.058-.188-.467-.195-1.052.064-1.064.162-1.064z"
        fill="#455a64"
      />
      <path
        data-name="Path 6867"
        d="M285.266 230.663c.1 0 .188.474.2 1.058s-.07 1.058-.174 1.058-.181-.474-.188-1.058.064-1.058.162-1.058z"
        fill="#455a64"
      />
      <path
        data-name="Path 6868"
        d="M285.239 226.424c.1 0 .188.474.188 1.058s-.077 1.058-.174 1.065-.181-.474-.188-1.058.076-1.065.174-1.065z"
        fill="#455a64"
      />
      <path
        data-name="Path 6869"
        d="M285.238 222.188c.1 0 .181.474.181 1.058s-.084 1.058-.181 1.058-.181-.474-.181-1.058.084-1.058.181-1.058z"
        fill="#455a64"
      />
      <path
        data-name="Path 6870"
        d="M285.259 217.953c.1 0 .174.474.174 1.058s-.091 1.058-.188 1.058-.181-.474-.174-1.058.091-1.058.188-1.058z"
        fill="#455a64"
      />
      <path
        data-name="Path 6871"
        d="M285.301 213.714c.1 0 .174.48.167 1.065s-.091 1.058-.188 1.052-.181-.474-.174-1.058.097-1.059.195-1.059z"
        fill="#455a64"
      />
      <path
        data-name="Path 6872"
        d="M285.371 209.458c.1 0 .167.48.16 1.065s-.1 1.052-.2 1.052-.167-.474-.16-1.058.095-1.059.2-1.059z"
        fill="#455a64"
      />
      <path
        data-name="Path 6873"
        d="M285.454 205.281c.1 0 .174.474.16 1.058s-.1 1.058-.209 1.058-.167-.48-.153-1.065.105-1.093.202-1.051z"
        fill="#455a64"
      />
      <path
        data-name="Path 6874"
        d="M285.586 201.007c.1 0 .16.48.139 1.065s-.111 1.058-.216 1.052-.167-.48-.146-1.065.118-1.061.223-1.052z"
        fill="#455a64"
      />
      <path
        data-name="Path 6875"
        d="M285.788 196.771c.1 0 .153.487.118 1.065s-.146 1.058-.244 1.052-.153-.487-.118-1.072.165-1.052.244-1.045z"
        fill="#455a64"
      />
      <path
        data-name="Path 6876"
        d="M286.178 192.552c.1 0 .139.487.077 1.072s-.181 1.045-.279 1.038-.139-.487-.077-1.072.181-1.049.279-1.038z"
        fill="#455a64"
      />
      <path
        data-name="Path 6877"
        d="M286.582 189.417c.1 0 .146.258.1.543s-.153.5-.251.487-.146-.251-.1-.543.153-.5.251-.487z"
        fill="#455a64"
      />
      <path
        data-name="Path 6878"
        d="M271.868 286.93l18.551.306-.146-3.092-18.4.1z"
        fill="#455a64"
      />
      <path
        data-name="Path 6879"
        d="M292.64 173.074l-1.866-35.7h-20.459L267 174.093z"
        fill="#263238"
      />
      <path
        data-name="Path 6880"
        d="M297.076 117.037s-8.287 2.173-8.426 21.636h-3.343s-.877-16.344 8.14-22.764c.001 0 2.605-.626 3.629 1.128z"
        fill="#263238"
      />
      <path
        data-name="Path 6881"
        d="M279.521 115.648s-5.884 2.785-6.024 22.284h-2.841s-.188-18.259 5.383-21.852a3.955 3.955 0 013.482-.432z"
        fill="#263238"
      />
      <path
        data-name="Path 6882"
        d="M319.869 284.2c.1 0 .216.2.265.487s0 .522-.1.543-.216-.2-.265-.487.002-.53.1-.543z"
        fill="#455a64"
      />
      <path
        data-name="Path 6883"
        d="M319.218 280.14c.1 0 .251.425.341.982s.091 1.031 0 1.045-.251-.425-.341-.982-.115-1.031 0-1.045z"
        fill="#455a64"
      />
      <path
        data-name="Path 6884"
        d="M318.518 276.073c.1 0 .258.425.348.989s.091 1.031 0 1.045-.258-.425-.348-.989-.084-1.024 0-1.045z"
        fill="#455a64"
      />
      <path
        data-name="Path 6885"
        d="M317.856 272.02c.1 0 .258.418.348.982s.091 1.031 0 1.045-.258-.425-.348-.982-.098-1.031 0-1.045z"
        fill="#455a64"
      />
      <path
        data-name="Path 6886"
        d="M317.173 267.952c.1 0 .258.425.348.982s.1 1.031 0 1.045-.251-.425-.348-.982-.097-1.045 0-1.045z"
        fill="#455a64"
      />
      <path
        data-name="Path 6887"
        d="M316.484 263.901c.1 0 .258.425.355.982s.091 1.031 0 1.045-.258-.418-.355-.982-.098-1.029 0-1.045z"
        fill="#455a64"
      />
      <path
        data-name="Path 6888"
        d="M315.787 259.847c.1 0 .258.425.355.982s.1 1.024 0 1.045-.251-.425-.348-.982-.104-1.031-.007-1.045z"
        fill="#455a64"
      />
      <path
        data-name="Path 6889"
        d="M315.092 255.788c.1 0 .258.425.348.989s.1 1.024 0 1.045-.258-.425-.355-.989-.098-1.024.007-1.045z"
        fill="#455a64"
      />
      <path
        data-name="Path 6890"
        d="M314.381 251.735c.1 0 .258.425.355.982s.1 1.031 0 1.045-.258-.418-.355-.982-.098-1.024 0-1.045z"
        fill="#455a64"
      />
      <path
        data-name="Path 6891"
        d="M313.643 247.682c.1 0 .258.425.362.982s.1 1.031 0 1.045-.258-.418-.362-.982a1.749 1.749 0 010-1.045z"
        fill="#455a64"
      />
      <path
        data-name="Path 6892"
        d="M312.947 243.637c.1 0 .258.418.355.975s.1 1.031 0 1.045-.265-.418-.362-.975-.091-1.031.007-1.045z"
        fill="#455a64"
      />
      <path
        data-name="Path 6893"
        d="M312.25 239.584c.1 0 .265.418.362.982s.1 1.024 0 1.045-.265-.425-.362-.982-.132-1.031 0-1.045z"
        fill="#455a64"
      />
      <path
        data-name="Path 6894"
        d="M311.484 235.531c.1 0 .258.425.362.982s.1 1.031 0 1.045-.265-.418-.362-.982-.098-1.024 0-1.045z"
        fill="#455a64"
      />
      <path
        data-name="Path 6895"
        d="M310.748 231.485c.1 0 .258.418.362.982s.1 1.024 0 1.045-.265-.425-.362-.982-.1-1.025 0-1.045z"
        fill="#455a64"
      />
      <path
        data-name="Path 6896"
        d="M310.001 227.439c.1 0 .258.418.362.975s.1 1.031 0 1.045-.258-.418-.362-.975-.104-1.031 0-1.045z"
        fill="#455a64"
      />
      <path
        data-name="Path 6897"
        d="M309.242 223.386c.1 0 .265.418.369.975s.111 1.031 0 1.045-.265-.418-.369-.975-.097-1.045 0-1.045z"
        fill="#455a64"
      />
      <path
        data-name="Path 6898"
        d="M308.489 219.343c.1 0 .258.418.362.975s.111 1.031 0 1.045-.265-.418-.369-.975-.097-1.02.007-1.045z"
        fill="#455a64"
      />
      <path
        data-name="Path 6899"
        d="M307.724 215.301c.1 0 .265.425.369.982s.111 1.024 0 1.045-.265-.418-.369-.982-.098-1.024 0-1.045z"
        fill="#455a64"
      />
      <path
        data-name="Path 6900"
        d="M306.95 211.262c.1 0 .265.418.369.975s.118 1.024 0 1.045-.265-.418-.369-.975-.097-1.028 0-1.045z"
        fill="#455a64"
      />
      <path
        data-name="Path 6901"
        d="M306.167 207.224c.1 0 .265.418.376.975s.111 1.024 0 1.045-.265-.418-.369-.975-.101-1.031-.007-1.045z"
        fill="#455a64"
      />
      <path
        data-name="Path 6902"
        d="M305.391 203.192c.1 0 .265.411.369.975s.118 1.024 0 1.045-.265-.418-.376-.975-.098-1.045.007-1.045z"
        fill="#455a64"
      />
      <path
        data-name="Path 6903"
        d="M304.59 199.146c.1 0 .265.411.376.975s.118 1.024 0 1.045-.265-.418-.376-.975-.091-1.031 0-1.045z"
        fill="#455a64"
      />
      <path
        data-name="Path 6904"
        d="M303.802 195.107c.1 0 .265.418.376.975s.118 1.024 0 1.045-.265-.418-.376-.975-.1-1.024 0-1.045z"
        fill="#455a64"
      />
      <path
        data-name="Path 6905"
        d="M302.995 191.068c.1 0 .272.418.383.975s.118 1.024 0 1.045-.265-.418-.376-.975-.104-1.025-.007-1.045z"
        fill="#455a64"
      />
      <path
        data-name="Path 6906"
        d="M302.188 187.035c.1 0 .272.418.383.975s.125 1.024 0 1.038-.265-.411-.376-.968-.105-1.024-.007-1.045z"
        fill="#455a64"
      />
      <path
        data-name="Path 6907"
        d="M301.373 182.997c.1 0 .272.411.383.968s.125 1.024 0 1.045-.272-.411-.383-.968-.097-1.045 0-1.045z"
        fill="#455a64"
      />
      <path
        data-name="Path 6908"
        d="M300.753 179.961c.1 0 .223.195.285.474s0 .529-.077.55-.223-.2-.279-.474-.026-.529.071-.55z"
        fill="#455a64"
      />
      <path
        data-name="Path 6909"
        d="M273.616 136.912a2.25 2.25 0 01.174 1.031 2.507 2.507 0 01-.237 1.156 1.629 1.629 0 01-1.149.926 1.483 1.483 0 01-1.393-.557 1.831 1.831 0 01-.411-1.121c0-.7.209-1.045.244-1.024s-.056.4 0 1a1.685 1.685 0 00.4.926 1.212 1.212 0 001.065.418 1.393 1.393 0 00.912-.7 2.64 2.64 0 00.272-1.01 5.058 5.058 0 01.123-1.045z"
        fill="#455a64"
      />
      <path
        data-name="Path 6910"
        d="M272.133 137.42c0-.042.209-.132.453.084a.585.585 0 01-.32 1.01.6.6 0 01-.641-.272.592.592 0 010-.55c.146-.292.39-.292.383-.244a1.017 1.017 0 00-.139.327.306.306 0 10.543-.1c-.126-.171-.272-.199-.279-.255z"
        fill="#455a64"
      />
      <path
        data-name="Path 6911"
        d="M288.428 137.037a1.908 1.908 0 01.139.975 2.27 2.27 0 01-.292 1.079 1.441 1.441 0 01-1.184.731 1.783 1.783 0 01-1.3-.48 2.089 2.089 0 01-.564-.961c-.167-.613-.07-1 0-1s.063.383.265.912a2.089 2.089 0 00.55.794 1.532 1.532 0 001.058.369 1.149 1.149 0 00.926-.55 2.257 2.257 0 00.313-.919 4.4 4.4 0 01.089-.95z"
        fill="#455a64"
      />
      <path
        data-name="Path 6912"
        d="M286.826 137.413c0-.042.181-.1.362.1a.487.487 0 01.118.439.543.543 0 01-.968.16.508.508 0 01-.035-.453c.1-.244.306-.251.306-.2a1.225 1.225 0 00-.056.272c0 .146.077.279.258.251s.216-.181.167-.327-.152-.186-.152-.242z"
        fill="#455a64"
      />
      <path
        data-name="Path 6913"
        d="M287.647 146.04a3.553 3.553 0 01-.48.341 15.315 15.315 0 01-1.393.745 21.288 21.288 0 01-5 1.616 21.026 21.026 0 01-5.251.334 13.691 13.691 0 01-1.574-.2 2.007 2.007 0 01-.571-.132 14.649 14.649 0 012.166.056 26.2 26.2 0 005.167-.439 25.577 25.577 0 004.965-1.5c1.212-.479 1.944-.869 1.971-.821z"
        fill="#455a64"
      />
      <path
        data-name="Path 6914"
        d="M303.818 177.322l-37.29-3.231v4.248l37.29 3.175z"
        fill="#ff435b"
      />
      <path
        data-name="Path 6915"
        d="M278.296 174.933a12.459 12.459 0 01-1.553-.042c-.961-.056-2.3-.132-3.872-.251l.174-.188.759 4.255.056.341-.167-.146 4.951.32-.16.16c-.084-1.3-.132-2.4-.167-3.182a11.839 11.839 0 010-1.267 8.018 8.018 0 01.167 1.17c.084.766.2 1.894.327 3.259v.174h-.174l-4.958-.258h-.146v-.14l-.063-.341-.7-4.262-.035-.209h.209c1.636.153 3.008.306 3.955.418a11.835 11.835 0 011.397.189z"
        fill="#455a64"
      />
      <path
        data-name="Path 6916"
        d="M271.164 175.817a5.941 5.941 0 012.089-.042 6.129 6.129 0 012.054.32 5.92 5.92 0 01-2.089.042 6.128 6.128 0 01-2.054-.32z"
        fill="#263238"
      />
      <path
        data-name="Path 6917"
        d="M270.916 177.036a6.769 6.769 0 012.221.035 6.964 6.964 0 012.187.4 6.79 6.79 0 01-2.221-.035 6.964 6.964 0 01-2.187-.4z"
        fill="#263238"
      />
      <path
        data-name="Path 6918"
        d="M229.292 138.223l-13.8-4.673-.546 1.773s5.4 8.5 11.685 10.257z"
        fill="#455a64"
      />
      <path
        data-name="Path 6919"
        d="M270.322 162.948s-21.309-14.951-22.59-15.4-10.445-.474-12.709-.23a71.742 71.742 0 01-8.851-.425l6.762-18.76-14.366-3.684.759-2.563s11.56-6.964 18.238-4.958 9.812 10.752 11.2 12.918 4.4 9.93 18.67 19.053l8.433 5.634z"
        fill="#455a64"
      />
      <path
        data-name="Path 6920"
        d="M247.335 132.741a2.142 2.142 0 01-.084.216l-.251.585-.954 2.214-3.482 7.98-.139-.188a17.883 17.883 0 012.451 0 6.964 6.964 0 012.591.536c1.595.9 3.148 1.985 4.812 3.1a686.026 686.026 0 0110.606 7.388 3.189 3.189 0 001.553.745 2.577 2.577 0 002.876-3.134 2.618 2.618 0 00-.808-1.393c-.418-.334-.912-.662-1.358-.982l-2.667-1.9-9.213-6.6a7.794 7.794 0 01-1.643-1.636 26.42 26.42 0 01-1.2-1.678c-.7-1.065-1.288-2.012-1.741-2.785s-.8-1.393-1.024-1.811l-.251-.474a1.838 1.838 0 01-.077-.174.605.605 0 01.111.153l.279.46 1.079 1.776c.474.766 1.065 1.7 1.79 2.785.362.522.759 1.079 1.212 1.643a7.241 7.241 0 001.616 1.574l9.255 6.539 2.674 1.887c.46.334.912.627 1.393 1a2.939 2.939 0 01-2.347 5.125 3.482 3.482 0 01-1.713-.815 982.32 982.32 0 00-10.585-7.4c-1.65-1.114-3.224-2.221-4.763-3.1a6.727 6.727 0 00-2.472-.529 19.708 19.708 0 00-2.4 0h-.23l.091-.209c1.546-3.356 2.785-6.093 3.684-8.015.425-.912.752-1.63 1-2.145l.258-.55a1.3 1.3 0 01.071-.188z"
        fill="#263238"
      />
      <path
        data-name="Path 6921"
        d="M302.654 180.037a6.267 6.267 0 01-8.955 1.567l-28.433-22.2 5.049-8.593 30.71 20.578a6.267 6.267 0 011.629 8.648z"
        fill="#455a64"
      />
      <path
        data-name="Path 6922"
        d="M240.218 131.535l-2.563 7.994-4.687.021 2.911-7.987z"
        fill="#263238"
      />
      <path
        data-name="Path 6923"
        d="M232.669 137.775c0-.2 1.546-.369 3.447-.383s3.482.146 3.482.348-1.539.369-3.447.383-3.482-.146-3.482-.348z"
        fill="#263238"
      />
      <path
        data-name="Path 6924"
        d="M233.558 135.226c0-.2 1.546-.376 3.482-.383s3.482.146 3.482.348-1.546.369-3.482.376-3.482-.146-3.482-.341z"
        fill="#263238"
      />
      <path
        data-name="Path 6925"
        d="M234.114 132.789c0-.2 1.539-.369 3.447-.383s3.482.146 3.482.348-1.546.369-3.447.376-3.482-.139-3.482-.341z"
        fill="#263238"
      />
      <path
        data-name="Path 6926"
        d="M229.382 137.329a17.61 17.61 0 01-1.142 4.178 21.918 21.918 0 01-1.706 3.962 31.394 31.394 0 011.393-4.088 32.12 32.12 0 011.455-4.052z"
        fill="#263238"
      />
      <path
        data-name="Path 6927"
        d="M317.856 155.101c.063-5.251-5.571-15.6-5.571-15.6l-10.083 6.337 1.636 4.283-22.562 9.283-6.17.286-3.941 5.654 5.07 4.652 13.231-3.656c8.369-1.452 28.313-5.418 28.39-11.239z"
        fill="#ffbe9d"
      />
      <g transform="translate(56.879 11.437)">
        <path
          data-name="Path 6928"
          d="M60.263 174.75l2.785-5.313-9.916-5.334-4.178 7.73.6.369c2.653 1.623 13.614 8.071 15.745 8.5 2.373.482-5.036-5.952-5.036-5.952z"
          fill="#ff435b"
        />
        <g data-name="Group 6529">
          <path
            data-name="Path 6929"
            d="M52.597 173.684a3.907 3.907 0 00-.125-2.785 3.8 3.8 0 00-1.936-1.971l-1.49 2.785z"
            opacity={0.4}
          />
        </g>
        <path
          data-name="Path 6930"
          d="M63.801 178.044s2.5 2.437 1.866 2.73-11.95-5.815-16.713-8.941l.139-.216 13.147 7.228s.977-1.088 1.561-.801z"
          opacity={0.4}
        />
        <path
          data-name="Path 6931"
          d="M48.941 171.56l.16.111.474.292 1.762.989c1.49.87 3.572 2.054 5.891 3.308s4.457 2.347 6.01 3.113l1.838.9.508.237a.7.7 0 00.181.07l-.174-.1-.487-.258-1.824-.933c-1.539-.794-3.663-1.894-5.982-3.148s-4.408-2.423-5.912-3.273l-1.783-1.01-.487-.272z"
          fill="#263238"
        />
        <path
          data-name="Path 6932"
          d="M62.15 178.997a5.028 5.028 0 01.8-.7 4.631 4.631 0 01.989-.362 1.5 1.5 0 00-1.058.244 1.567 1.567 0 00-.731.815z"
          fill="#263238"
        />
        <path
          data-name="Path 6933"
          d="M60.027 175.941a1.226 1.226 0 00.515-.216c.272-.146.474-.3.453-.327a1.267 1.267 0 00-.515.209c-.263.146-.474.295-.453.334z"
          fill="#263238"
        />
        <path
          data-name="Path 6934"
          d="M59.457 175.077c0 .042.216 0 .474-.042s.453-.139.439-.174-.216 0-.474.042-.455.139-.439.174z"
          fill="#263238"
        />
        <path
          data-name="Path 6935"
          d="M59.449 173.874a1.551 1.551 0 00.48.341 1.393 1.393 0 00.55.23 1.53 1.53 0 00-.48-.348c-.285-.162-.529-.26-.55-.223z"
          fill="#263238"
        />
        <path
          data-name="Path 6936"
          d="M59.629 173.12a1.656 1.656 0 00.487.474 1.128 1.128 0 00.641.244 3.547 3.547 0 00-.564-.355 3.409 3.409 0 00-.564-.363z"
          fill="#263238"
        />
        <path
          data-name="Path 6937"
          d="M60.828 175.25a2.159 2.159 0 00.641.341 4.179 4.179 0 00.759.216 2.02 2.02 0 00.473.049.376.376 0 00.265-.111.292.292 0 00.042-.306 1.323 1.323 0 00-1.629-.439 1.518 1.518 0 00-.439.313c-.084.1-.111.16-.1.167a1.908 1.908 0 01.578-.39 1.344 1.344 0 01.738-.063 1.114 1.114 0 01.731.467c.063.125-.042.223-.188.23a1.935 1.935 0 01-.446-.042 4.33 4.33 0 01-.745-.181c-.423-.143-.666-.264-.68-.251z"
          fill="#263238"
        />
        <path
          data-name="Path 6938"
          d="M60.98 175.377a.8.8 0 00.306-.4 1.1 1.1 0 00.077-.557.7.7 0 00-.355-.613.292.292 0 00-.355.188.745.745 0 00-.042.341 2.383 2.383 0 00.091.55 1.072 1.072 0 00.23.453 3.432 3.432 0 01-.132-.48 2.284 2.284 0 01-.063-.522c0-.181.056-.46.23-.4a.7.7 0 01.272.5 1.093 1.093 0 01-.042.508 3.814 3.814 0 01-.216.439z"
          fill="#263238"
        />
        <path
          data-name="Path 6939"
          d="M50.639 168.921a4.172 4.172 0 011.943 1.943 3.663 3.663 0 01.237 1.95 7.937 7.937 0 01-.195.808 1.083 1.083 0 00.111-.2 3.42 3.42 0 00.181-.592 3.482 3.482 0 00-.209-2.019 3.538 3.538 0 00-1.316-1.546 2.916 2.916 0 00-.557-.279.787.787 0 00-.195-.065z"
          fill="#263238"
        />
        <path
          data-name="Path 6940"
          d="M53.86 165.349a18.387 18.387 0 00-1.184 1.992 19.867 19.867 0 00-1.065 2.054 18.382 18.382 0 001.184-1.992 19.085 19.085 0 001.065-2.054z"
          fill="#263238"
        />
        <path
          data-name="Path 6941"
          d="M54.365 173.391a9.129 9.129 0 002.883 1.588c0-.035-.655-.341-1.448-.78s-1.414-.835-1.435-.808z"
          fill="#263238"
        />
        <path
          data-name="Path 6942"
          d="M52.423 172.34c-.035 0 0 .167-.035.355s-.049.334 0 .348.139-.125.153-.341-.084-.369-.118-.362z"
          fill="#263238"
        />
        <path
          data-name="Path 6943"
          d="M52.179 171.038c-.035 0 0 .146 0 .306s.111.279.153.272 0-.153 0-.313-.116-.279-.153-.265z"
          fill="#263238"
        />
        <path
          data-name="Path 6944"
          d="M51.336 170.056c0 .035.125.1.237.237s.188.265.223.258 0-.195-.118-.348-.342-.182-.342-.147z"
          fill="#263238"
        />
        <path
          data-name="Path 6945"
          d="M50.717 169.471a.3.3 0 00.07.181c.07.077.139.118.167.1s0-.111-.063-.188-.147-.135-.174-.093z"
          fill="#263238"
        />
        <path
          data-name="Rectangle 2529"
          fill="#263238"
          d="M96.781 173.552h25.118v5.049H96.781z"
        />
        <path
          data-name="Rectangle 2530"
          fill="#263238"
          d="M107.588 206.636h25.118v5.049h-25.118z"
        />
        <path
          data-name="Rectangle 2531"
          fill="#263238"
          d="M113.981 235.967h30.194v5.049h-30.194z"
        />
        <path
          data-name="Rectangle 2532"
          fill="#263238"
          d="M88.369 139.339h21.364v5.049H88.369z"
        />
        <path
          data-name="Rectangle 2533"
          fill="#263238"
          d="M77.707 105.183h21.364v5.049H77.707z"
        />
        <path
          data-name="Path 6946"
          d="M68.843 71.214L0 280.688h6.553L71.134 85.634l48.85 195.052h7.869L75.068 71.214z"
          fill="#263238"
        />
        <path
          data-name="Path 6947"
          d="M84.901 71.214L31.469 280.688h10.16L89.163 85.963l64.254 194.725h8.976L92.322 71.214z"
          fill="#455a64"
        />
        <path
          data-name="Path 6948"
          d="M25.759 211.037a2.521 2.521 0 002.521 2.528h22.813a2.521 2.521 0 002.521-2.528 2.521 2.521 0 00-2.521-2.521H28.279a2.521 2.521 0 00-2.521 2.521z"
          fill="#455a64"
        />
        <path
          data-name="Path 6949"
          d="M15.822 243.536a2.528 2.528 0 002.528 2.521h22.806a2.521 2.521 0 002.528-2.521 2.528 2.528 0 00-2.528-2.528H18.315a2.535 2.535 0 00-2.493 2.528z"
          fill="#455a64"
        />
        <path
          data-name="Path 6950"
          d="M36.712 177.563a2.521 2.521 0 002.521 2.521h22.813a2.521 2.521 0 002.521-2.521 2.521 2.521 0 00-2.521-2.521H39.254a2.521 2.521 0 00-2.542 2.521z"
          fill="#455a64"
        />
        <rect
          data-name="Rectangle 2534"
          width={26.399}
          height={5.049}
          rx={1.815}
          transform="translate(47.764 140.363)"
          fill="#455a64"
        />
        <path
          data-name="Path 6951"
          d="M58.049 108.581a2.528 2.528 0 002.521 2.528h16.323a2.528 2.528 0 002.521-2.528 2.521 2.521 0 00-2.521-2.521H60.57a2.521 2.521 0 00-2.521 2.521z"
          fill="#455a64"
        />
        <path
          data-name="Path 6952"
          d="M62.694 56.904c.32.1 6.219 13.273 10.961 13.342s28.92-1.845 28.92-1.845l.292-7.054-24.979.07-7.6-10.285z"
          fill="#ffbe9d"
        />
        <path
          data-name="Path 6953"
          d="M99.803 62.233s4.122-1.323 4.575-1.971 2.312-3.691 2.312-3.691a2.465 2.465 0 01.46 1.824 108.6 108.6 0 00-.529 2.089.6.6 0 00.7.738c.975-.174 2.4-.348 2.57.118.265.7-.87 5.32-1.7 6.17s-6.5.863-6.5.863z"
          fill="#ffbe9d"
        />
        <path
          data-name="Path 6954"
          d="M108.834 62.698a2.084 2.084 0 01-.836.578 4.053 4.053 0 01-1.079.327 1.107 1.107 0 01-.731-.139.759.759 0 01-.306-.78 1.093 1.093 0 01.432-.7 1.9 1.9 0 01.634-.286c.4-.111.759-.174 1.065-.237a2.723 2.723 0 011-.125 2.688 2.688 0 01-.933.369l-1.031.313a1.017 1.017 0 00-.808.7c-.077.383.279.564.655.522a4.807 4.807 0 001.01-.23 6.217 6.217 0 01.933-.313z"
          fill="#eb996e"
        />
        <path
          data-name="Path 6955"
          d="M108.152 64.787a1.984 1.984 0 01-.7.369 1.754 1.754 0 01-.411.1.655.655 0 01-.522-.195.919.919 0 01-.209-1.149 1.727 1.727 0 01.926-.627l.78-.313c.453-.181.752-.23.766-.181s-.244.181-.662.411c-.209.118-.46.251-.752.39a1.622 1.622 0 00-.752.508.6.6 0 00.132.7.327.327 0 00.279.132 1.979 1.979 0 00.355-.042c.45-.096.756-.166.77-.103z"
          fill="#eb996e"
        />
        <path
          data-name="Path 6956"
          d="M105.652 66.316c-.049 0-.132-.237-.348-.585a3.078 3.078 0 00-1.163-1.1l-.23-.125.209-.174c.139-.118.292-.244.432-.383a9.239 9.239 0 001.588-2.437c.049 0 0 .355-.16.884a4.916 4.916 0 01-1.177 1.811 4.116 4.116 0 01-.467.4v-.291a2.2 2.2 0 011.316 2z"
          fill="#eb996e"
        />
        <path
          data-name="Path 6957"
          d="M108.257 66.409c0 .042-.181.23-.62.383a1.072 1.072 0 01-.85-.042.94.94 0 01-.39-.369.7.7 0 010-.627 1.755 1.755 0 01.773-.7 6.623 6.623 0 01.7-.327c.411-.16.7-.2.7-.153s-.223.174-.592.383c-.181.111-.4.237-.641.39a1.769 1.769 0 00-.613.529c-.1.2.049.432.244.55a.912.912 0 00.627.091c.377-.06.641-.164.662-.108z"
          fill="#eb996e"
        />
        <path
          data-name="Path 6958"
          d="M38.488 20.133a15.32 15.32 0 01-.7 3.635 5.306 5.306 0 01-2.249 2.862c-1.031.578-2.451.822-2.848 1.936s.467 2.549-.348 3.384a2.6 2.6 0 01-1.267.543 5.571 5.571 0 00-3.621 2.785 6 6 0 00.362 5.258 17.875 17.875 0 003.482 4.178L44.53 41.88s-3.914-20.981-3.851-20.835a16.782 16.782 0 01-2.194-.912z"
          fill="#263238"
        />
        <path
          data-name="Path 6959"
          d="M56.037 21.243a2.263 2.263 0 001.128 1.546 15.877 15.877 0 011.685 1.045 4.178 4.178 0 011.316 3.175 12.465 12.465 0 01-.627 3.482 7.458 7.458 0 015.571 2.9 9.749 9.749 0 011.964 6.051A5.3 5.3 0 0165.5 43.62a6.622 6.622 0 01-3.391 1.2c-2.4.355-4.819.564-7.235.773s-5.021.376-7.166-.787a8.976 8.976 0 01-3.774-4.645 19.157 19.157 0 01-1.121-8.907 42.792 42.792 0 011.957-8.83"
          fill="#263238"
        />
        <path
          data-name="Path 6960"
          d="M39.373 17.845a9.359 9.359 0 00-3.781-1.01 3.163 3.163 0 00-3.071 2.047 2.973 2.973 0 00.237 2.04 6.121 6.121 0 001.253 1.671 3.078 3.078 0 001.79 1.052 2.611 2.611 0 001.553-.439 4.074 4.074 0 001.88-2.416 2.841 2.841 0 00-.864-2.848"
          fill="#263238"
        />
        <path
          data-name="Path 6961"
          d="M54.428 14.878s2.375-.829 2.4-2.382-17.722-3.767-21.114 4.875c0 0 .662.467 3.405.292"
          fill="#ff435b"
        />
        <g data-name="Group 6530">
          <path
            data-name="Path 6962"
            d="M54.429 14.878s2.375-.829 2.4-2.382-17.722-3.767-21.114 4.875c0 0 .662.467 3.405.292"
            opacity={0.5}
          />
        </g>
        <path
          data-name="Path 6963"
          d="M40.835 38.798l10.828-.863c.07-1.26 0-6.156 0-6.156s4.505-.7 4.561-5.2-.7-14.853-.7-14.853a15.668 15.668 0 00-15.738 1.978l-.7.515z"
          fill="#ffbe9d"
        />
        <path
          data-name="Path 6964"
          d="M53.666 19.224a.655.655 0 11.657.172.655.655 0 01-.657-.172z"
          fill="#263238"
        />
        <path
          data-name="Path 6965"
          d="M55.512 18.437c-.07.077-.508-.258-1.128-.265s-1.072.32-1.142.237.035-.174.237-.32a1.49 1.49 0 01.905-.292 1.518 1.518 0 01.905.313c.223.153.274.292.223.327z"
          fill="#263238"
        />
        <path
          data-name="Path 6966"
          d="M47.806 18.771a.658.658 0 01-.7 1.114.7.7 0 01-.216-.905.648.648 0 01.912-.209z"
          fill="#263238"
        />
        <path
          data-name="Path 6967"
          d="M48.794 18.987c-.077.077-.515-.265-1.135-.265s-1.072.313-1.142.237.042-.174.237-.327a1.532 1.532 0 011.811.021c.194.153.264.299.229.334z"
          fill="#263238"
        />
        <path
          data-name="Path 6968"
          d="M51.28 24.105a3.016 3.016 0 011-.216c.16 0 .292-.049.3-.139a.877.877 0 00-.111-.467c-.16-.411-.327-.822-.494-1.26a17.347 17.347 0 01-1.058-3.315 17.633 17.633 0 011.393 3.189c.16.439.313.857.46 1.274a1.017 1.017 0 01.091.7.425.425 0 01-.279.258.863.863 0 01-.279 0 3.092 3.092 0 01-1.023-.024z"
          fill="#263238"
        />
        <path
          data-name="Path 6969"
          d="M51.649 31.716a10.5 10.5 0 01-5.877-1.671s1.393 3.245 5.884 2.834z"
          fill="#eb996e"
        />
        <path
          data-name="Path 6970"
          d="M55.57 12.859a7.514 7.514 0 01-6.323 0c-2.423-1-5.188-1.393-5.752-.85s.418 5.188-1.845 6.065c0 0-.432 2.319-2.277 2.291s-1.219-7.221-1.219-7.221l4.311-2.521 4.819-1.281 4.652.306 3.635.7z"
          fill="#263238"
        />
        <path
          data-name="Path 6971"
          d="M39.63 18.882c-.077 0-3.1-.912-2.994 2.166a2.314 2.314 0 003.168 2.256c.001-.091-.174-4.422-.174-4.422z"
          fill="#ffbe9d"
        />
        <path
          data-name="Path 6972"
          d="M38.802 22.169s-.056 0-.146.084a.529.529 0 01-.4 0 1.344 1.344 0 01-.655-1.17 1.824 1.824 0 01.125-.773.634.634 0 01.39-.425.258.258 0 01.32.132c.049.084 0 .146.049.153s.063-.056 0-.181a.327.327 0 00-.132-.188.411.411 0 00-.251-.049.766.766 0 00-.543.494 1.929 1.929 0 00-.153.9 1.393 1.393 0 00.829 1.309.578.578 0 00.487-.1c.08-.116.115-.179.08-.186z"
          fill="#eb996e"
        />
        <path
          data-name="Path 6973"
          d="M37.541 9.307c-.042-.091.474-.313 1.1-.118s.919.7.836.7-.432-.223-.947-.376-.968-.074-.989-.206z"
          fill="#263238"
        />
        <path
          data-name="Path 6974"
          d="M39.289 7.392c.111 0 .111.655.258 1.393s.48 1.274.4 1.337-.592-.425-.752-1.26a2.089 2.089 0 01.1-1.469z"
          fill="#263238"
        />
        <path
          data-name="Path 6975"
          d="M52.51 17.426c-.1-.167.467-.7 1.393-.7s1.511.418 1.393.592-.7.1-1.393.132-1.286.157-1.393-.024z"
          fill="#263238"
        />
        <path
          data-name="Path 6976"
          d="M43.404 11.912a21.832 21.832 0 012.674 2.012 4.68 4.68 0 003.085 1.128l-.272-.522a18.62 18.62 0 005.1 1.233 1.978 1.978 0 001.8-.474c.4-.522-.16-1.455-.223-2.43-.049-.7-.292-.884-.954-1.128a2.4 2.4 0 00-.947-.084 38.258 38.258 0 01-7.3-.4 7.082 7.082 0 00-1.713-.132 2.089 2.089 0 00-1.5.7"
          fill="#263238"
        />
        <path
          data-name="Path 6977"
          d="M51.336 24.683a.6.6 0 00-.7 0 .7.7 0 00-.32.592.919.919 0 00.251.641.857.857 0 00.474.292.773.773 0 00.808-.341.843.843 0 000-.884.9.9 0 00-.794-.4"
          fill="#eb996e"
        />
        <path
          data-name="Path 6978"
          d="M52.892 25.553c0 .042-.181.167-.529.181a3.05 3.05 0 01-1.3-.272c-.968-.376-1.574-1.038-1.469-1.121s.7.425 1.6.787a5.041 5.041 0 001.177.348c.309.05.504.032.521.077z"
          fill="#263238"
        />
        <path
          data-name="Path 6979"
          d="M48.655 17.364c-.077.07-.223.042-.411.07s-.494.1-.891.132c-.766.07-1.393 0-1.393-.237s.585-.418 1.351-.487a2.312 2.312 0 011.058.091c.293.139.363.371.286.431z"
          fill="#263238"
        />
        <path
          data-name="Path 6980"
          d="M41.308 13.882c.056 2.472.536 4.916.564 7.381a10.376 10.376 0 01-2.089 6.964 3.363 3.363 0 01-2.194 1.3 1.226 1.226 0 01-1.267-.62 1.525 1.525 0 01.139-1.149 26.873 26.873 0 011.915-3.252 7.472 7.472 0 001.288-3.482 11 11 0 00-.39-2.73 5.975 5.975 0 01-.1-2.737 2.4 2.4 0 011.838-1.845"
          fill="#263238"
        />
        <path
          data-name="Path 6981"
          d="M47.074 11.863c8.12-.884 9.679.55 9.749.62a1.6 1.6 0 00.418-.8c.292-2.375-2.618-3.071-2.618-3.071-1.824-8.308-9.29-7.458-9.29-7.458v-.063A1.344 1.344 0 0043.759.026l-1.163.223a1.344 1.344 0 00-1.065 1.574c-8.76 2.918-5.856 11.017-5.856 11.017l-1.281 1.546a2.03 2.03 0 001.33 3s3.022-4.597 11.35-5.523z"
          fill="#ff435b"
        />
        <g data-name="Group 6531">
          <path
            data-name="Path 6982"
            d="M42.979 9.029a18.5 18.5 0 01-1-3.482 18.453 18.453 0 01-.634-3.572 18.439 18.439 0 01.989 3.482 18.287 18.287 0 01.641 3.572z"
            opacity={0.5}
          />
        </g>
        <g data-name="Group 6532">
          <path
            data-name="Path 6983"
            d="M47.353 7.948a17.09 17.09 0 01-1.135-3.343 17.213 17.213 0 01-.787-3.433 16.908 16.908 0 011.135 3.336 17.333 17.333 0 01.787 3.44z"
            opacity={0.5}
          />
        </g>
        <g data-name="Group 6533">
          <path
            data-name="Path 6984"
            d="M54.25 8.952c0 .07-1.045-.237-2.751-.39a20.619 20.619 0 00-6.622.529 27.91 27.91 0 00-6.225 2.4c-1.518.794-2.416 1.393-2.444 1.323a3.036 3.036 0 01.585-.46c.4-.279.989-.662 1.741-1.086a24.373 24.373 0 016.267-2.535 18.941 18.941 0 016.734-.432 14.151 14.151 0 012.012.4 2.785 2.785 0 01.703.251z"
            opacity={0.5}
          />
        </g>
        <g data-name="Group 6534">
          <path
            data-name="Path 6985"
            d="M43.377 10.498a41.421 41.421 0 00-4.535 1.937 42.766 42.766 0 00-4.088 2.785 4.36 4.36 0 011.01-1.058 16.26 16.26 0 016.177-3.363 4.436 4.436 0 011.435-.3z"
            fill="#fff"
            opacity={0.2}
          />
        </g>
        <g data-name="Group 6535">
          <path
            data-name="Path 6986"
            d="M36.427 11.626c-.237.056-.627-.508-.787-1.567a6.964 6.964 0 013.677-6.964c.968-.467 1.65-.467 1.741-.244.195.467-1.852 1.65-3.064 4s-1.065 4.67-1.567 4.775z"
            fill="#fff"
            opacity={0.2}
          />
        </g>
        <path
          data-name="Path 6987"
          d="M30.445 91.673l32.868 2.967c.056-2.249-.627-15.968-1.031-24.979-.077-1.706 1.609-4.666 1.546-6.093-.167-3.551-.7-3.691-.7-3.691l1.873 3.482 9.749-8.684-8.51-11.5a16.852 16.852 0 00-11.267-6.664c-1.323-.174-2.326-.265-2.98-.348a19.157 19.157 0 01-11.546 0 15.7 15.7 0 00-4.784.919 19.707 19.707 0 00-7.66 4.937L14.08 58.421l12.883 8.329 4.958-5.216.641 13.551z"
          fill="#ff435b"
        />
        <path
          data-name="Path 6988"
          d="M60.939 54.264c0 .1-4.875.181-10.822.181s-10.822-.084-10.822-.181 4.84-.181 10.822-.181 10.822.076 10.822.181z"
          fill="#fff"
        />
        <path
          data-name="Path 6989"
          d="M60.215 50.741a27.569 27.569 0 01-6.309 0 27.571 27.571 0 016.309 0z"
          fill="#fff"
        />
        <path
          data-name="Path 6990"
          d="M36.712 55.688a12.242 12.242 0 01-1.33 1.706c-.857 1.024-2.089 2.416-3.405 3.948s-2.577 2.89-3.482 3.872a11.673 11.673 0 01-1.525 1.532 13.864 13.864 0 011.33-1.7c.863-1.024 2.089-2.423 3.412-3.955s2.577-2.9 3.482-3.872a12.537 12.537 0 011.518-1.531z"
          fill="#263238"
        />
        <path
          data-name="Path 6991"
          d="M34.379 202.618L33.3 207.13s-7.374 9.575-9.645 9.108c-1.49-.306.341-2.785 3.141-10.083l.592-3z"
          fill="#ff435b"
        />
        <path
          data-name="Path 6992"
          d="M23.356 214.826a.94.94 0 00.39.376 1.393 1.393 0 001.393-.2 39.994 39.994 0 003.67-3.482 45.543 45.543 0 003.322-3.83l.94-1.212c.091-.111.181-.223.258-.327l.1-.111a.217.217 0 01-.07.125l-.237.348c-.209.3-.515.7-.905 1.246a48.2 48.2 0 01-3.3 3.858 35.592 35.592 0 01-3.712 3.482 1.838 1.838 0 01-.836.306 1.309 1.309 0 01-.662-.146.829.829 0 01-.306-.292.418.418 0 01-.042-.139z"
          fill="#263238"
        />
        <path
          data-name="Path 6993"
          d="M24.401 211.747a1.184 1.184 0 01.606-.077 3.252 3.252 0 011.393.487 2.542 2.542 0 011.01 1.065 1.24 1.24 0 01.153.592 3.379 3.379 0 00-.244-.543 2.785 2.785 0 00-1-1 3.613 3.613 0 00-1.33-.5c-.358-.044-.588-.003-.588-.024z"
          fill="#263238"
        />
        <path
          data-name="Path 6994"
          d="M31.378 209.498a.939.939 0 01-.091-.216 3.732 3.732 0 01-.146-.62 3.336 3.336 0 01.341-2.054 3.182 3.182 0 011.5-1.448 2.59 2.59 0 01.606-.188h.237a5.068 5.068 0 00-.8.285 3.224 3.224 0 00-1.393 1.428 3.433 3.433 0 00-.369 1.971c.038.507.143.835.115.842z"
          fill="#263238"
        />
        <path
          data-name="Path 6995"
          d="M33.925 203.384c.035 0-.125.641-.265 1.441a4.548 4.548 0 01-.39 1.393c-.035 0 .111-.641.244-1.441a4.519 4.519 0 01.411-1.393z"
          fill="#263238"
        />
        <path
          data-name="Path 6996"
          d="M26.67 206.796a1.58 1.58 0 01.947 0 1.657 1.657 0 01.85.411c0 .042-.39-.153-.884-.265s-.913-.104-.913-.146z"
          fill="#263238"
        />
        <path
          data-name="Path 6997"
          d="M26.518 207.569a1.462 1.462 0 011.5.355 3.681 3.681 0 00-.7-.251c-.375-.09-.793-.062-.8-.104z"
          fill="#263238"
        />
        <path
          data-name="Path 6998"
          d="M25.926 208.542a1.2 1.2 0 011.525.341 4.131 4.131 0 00-.7-.313 3.773 3.773 0 00-.829-.028z"
          fill="#263238"
        />
        <path
          data-name="Path 6999"
          d="M27.179 206.469s.334-.1.863-.3a7.649 7.649 0 00.884-.425l.494-.313c.146-.111.313-.279.306-.446s-.223-.265-.39-.223a1.121 1.121 0 00-.453.3c-.272.258-.5.5-.7.7-.376.425-.592.7-.606.7a.828.828 0 01.118-.216c.091-.132.23-.32.411-.536a8.606 8.606 0 01.7-.745 1.156 1.156 0 01.515-.341.432.432 0 01.557.355.7.7 0 01-.362.564 5.926 5.926 0 01-.508.313 5.31 5.31 0 01-.919.411 4.707 4.707 0 01-.655.174.647.647 0 01-.258.035z"
          fill="#263238"
        />
        <path
          data-name="Path 7000"
          d="M27.562 206.379s-.056-.32-.139-.822a3.4 3.4 0 00-.251-.857 2.034 2.034 0 00-.272-.432c-.118-.125-.286-.23-.418-.153s-.153.279-.132.446a2.368 2.368 0 00.139.494 3.224 3.224 0 00.48.738c.341.376.606.564.592.585s-.077 0-.195-.111a3.289 3.289 0 01-.467-.4 2.744 2.744 0 01-.529-.759 2.04 2.04 0 01-.16-.529.557.557 0 01.2-.585.487.487 0 01.592.188 1.86 1.86 0 01.292.467 3.37 3.37 0 01.23.891c0 .258.042.474.042.613s.01.226-.004.226z"
          fill="#263238"
        />
        <g data-name="Group 6536">
          <path
            data-name="Path 7001"
            d="M33.301 207.131l.522-2.166s-1.811.334-2.277 1.685a5.745 5.745 0 00-.355 2.312l-3.928 4.234s-.982-1.929-2.785-1.462a21.038 21.038 0 00-1.393 3.858c0 .773.947.7 1.267.536a16.015 16.015 0 003.517-2.785 70.473 70.473 0 005.432-6.212z"
            opacity={0.4}
          />
        </g>
        <path
          data-name="Path 7002"
          d="M10.745 80.211a10.94 10.94 0 01.056-14.422l5.487-5.912 9.9 6.365c-.313.216-6.838 7.27-6.838 7.27l11.427 14.241a8.189 8.189 0 011.393 7.8l-1.957 7.966-9.519-4.241.634-.975h6.267l.369-1.5z"
          fill="#ffbe9d"
        />
        <path
          data-name="Path 7003"
          d="M16.894 70.965a6.365 6.365 0 011.532 1.309 4.387 4.387 0 011.226 1.6 7.765 7.765 0 01-1.483-1.351 5.1 5.1 0 01-1.275-1.558z"
          fill="#eb996e"
        />
        <path
          data-name="Path 7004"
          d="M38.864 93.612l1.866-35.7h20.452l1.511 37.84z"
          fill="#263238"
        />
        <path
          data-name="Path 7005"
          d="M58.299 67.161a3.791 3.791 0 01-.474.376 12.383 12.383 0 01-1.393.85 19.282 19.282 0 01-10.522 2.2 13.969 13.969 0 01-1.63-.23 1.852 1.852 0 01-.585-.16c0-.063.857.077 2.235.132a22.716 22.716 0 005.327-.432 22.353 22.353 0 005.063-1.706c1.241-.626 1.979-1.085 1.979-1.03z"
          fill="#455a64"
        />
        <path
          data-name="Path 7006"
          d="M43.989 72.383c-.1 0-.2-.23-.216-.508s.049-.515.146-.536.195.216.216.508-.048.536-.146.536z"
          fill="#455a64"
        />
        <path
          data-name="Path 7007"
          d="M43.432 76.305c-.1 0-.1-.46 0-1s.251-.968.348-.947.1.467 0 1-.25.96-.348.947z"
          fill="#455a64"
        />
        <path
          data-name="Path 7008"
          d="M43.92 80.177c-.084.056-.39-.313-.543-.87s-.1-1.017 0-1.024.209.418.348.926.285.919.195.968z"
          fill="#455a64"
        />
        <path
          data-name="Path 7009"
          d="M47.249 81.945c0 .1-.46.174-1.024.07s-.954-.39-.905-.48.46.035.975.125.954.18.954.285z"
          fill="#455a64"
        />
        <path
          data-name="Path 7010"
          d="M51.21 81.91c0 .1-.446.188-.989.195s-1-.07-1-.167.446-.188.989-.195 1 .062 1 .167z"
          fill="#455a64"
        />
        <path
          data-name="Path 7011"
          d="M55.173 81.848c0 .1-.439.195-.989.2s-.989-.07-1-.167.446-.188 1-.195.989.043.989.162z"
          fill="#455a64"
        />
        <path
          data-name="Path 7012"
          d="M58.402 80.065c.084 0 .035.515-.348.975s-.905.557-.933.467.334-.32.655-.7.526-.777.626-.742z"
          fill="#455a64"
        />
        <path
          data-name="Path 7013"
          d="M59.122 76.158c.1 0 .111.467.035 1.01s-.23.968-.327.947-.118-.467-.035-1 .201-.957.327-.957z"
          fill="#455a64"
        />
        <path
          data-name="Path 7014"
          d="M59.519 72.23c.1 0 .139.453.1 1s-.167.982-.265.968-.139-.46-.1-1 .16-.975.265-.968z"
          fill="#455a64"
        />
        <path
          data-name="Path 7015"
          d="M59.643 69.194c.1 0 .174.244.167.529s-.1.522-.195.515-.174-.237-.167-.522.091-.522.195-.522z"
          fill="#455a64"
        />
        <path
          data-name="Path 7016"
          d="M34.428 37.579s8.287 2.173 8.426 21.636h3.336s.877-16.344-8.141-22.764c.001.014-2.604-.626-3.621 1.128z"
          fill="#263238"
        />
        <path
          data-name="Path 7017"
          d="M51.949 36.194s5.884 2.785 6.031 22.284h2.834s.244-18.224-5.32-21.817a3.99 3.99 0 00-3.544-.467z"
          fill="#263238"
        />
        <path
          data-name="Path 7018"
          d="M46.166 57.725a2.284 2.284 0 01.2 1.107 2.089 2.089 0 01-.362 1.212 1.8 1.8 0 01-1.393.655 1.769 1.769 0 01-1.462-.557 2.012 2.012 0 01-.439-1.184 2.333 2.333 0 01.139-1.114 7.535 7.535 0 01.118 1.086 1.9 1.9 0 00.432 1 1.769 1.769 0 002.368-.091 1.873 1.873 0 00.369-1.017 6.868 6.868 0 01.028-1.093z"
          fill="#455a64"
        />
        <path
          data-name="Path 7019"
          d="M44.511 58.303c0-.042.181-.153.439 0a.536.536 0 01.188.508.585.585 0 01-1.086.174.515.515 0 010-.543c.181-.258.411-.216.4-.174a1.116 1.116 0 00-.174.292.292.292 0 00.042.251.306.306 0 00.265.118.293.293 0 00.209-.195.265.265 0 00-.042-.251c-.087-.11-.241-.131-.241-.18z"
          fill="#455a64"
        />
        <path
          data-name="Path 7020"
          d="M60.877 57.711c.042 0 .2.334.167.947a2.04 2.04 0 01-.348 1.017 1.609 1.609 0 01-1.135.7 1.344 1.344 0 01-1.253-.5 2.284 2.284 0 01-.327-1.957c.063 0 .077.362.188.905a1.866 1.866 0 00.39.836 1.058 1.058 0 00.954.362 1.393 1.393 0 00.912-.557 2.013 2.013 0 00.362-.857c.096-.554.032-.889.09-.896z"
          fill="#455a64"
        />
        <path
          data-name="Path 7021"
          d="M59.295 58.184c-.035 0 .091-.209.4-.167a.522.522 0 01.4.348.578.578 0 01-.181.634.564.564 0 01-.662 0 .488.488 0 01-.223-.487c.063-.313.292-.355.3-.313a.874.874 0 00-.042.32.237.237 0 00.132.195.272.272 0 00.272 0 .286.286 0 00.084-.265.272.272 0 00-.153-.195c-.159-.07-.299-.028-.327-.07z"
          fill="#455a64"
        />
        <path
          data-name="Path 7022"
          d="M63.279 94.639l20.891 34.512a4.951 4.951 0 010 5.146l-19.443 40.1-15.947-8.447 15.849-30.933a4.923 4.923 0 00-.4-5.216l-20.557-24.9.836-10.257z"
          fill="#263238"
        />
        <path
          data-name="Path 7023"
          d="M30.41 90.064L20.327 204.881h16.675l14.192-97.442-.22-13.828z"
          fill="#263238"
        />
        <path
          data-name="Path 7024"
          d="M39.142 95.12a2.834 2.834 0 01-.376 1.267 5.773 5.773 0 01-5.23 3.252 2.786 2.786 0 01-1.3-.23 7.058 7.058 0 011.3 0 6.448 6.448 0 002.9-.926 6.316 6.316 0 002.089-2.187c.411-.709.555-1.197.617-1.176z"
          fill="#455a64"
        />
        <path
          data-name="Path 7025"
          d="M43.502 157.912a3.527 3.527 0 01.07-.571l.286-1.63c.251-1.455.592-3.482 1.024-6 .843-5.07 1.915-12.089 3.022-19.853s2.033-14.812 2.653-19.916c.292-2.528.536-4.582.7-6.044.077-.7.139-1.24.188-1.643a4.809 4.809 0 01.091-.571 3.286 3.286 0 010 .578q-.056.62-.125 1.65c-.118 1.435-.32 3.482-.6 6.058-.55 5.118-1.441 12.165-2.542 19.937s-2.228 14.784-3.134 19.846A240.297 240.297 0 0144 155.742c-.146.7-.265 1.212-.348 1.616a3.892 3.892 0 01-.146.557z"
          fill="#455a64"
        />
        <path
          data-name="Path 7026"
          d="M37.346 202.089c0 .1-3.788.1-8.447 0s-8.44-.265-8.44-.369 3.781-.1 8.447 0 8.44.272 8.44.369z"
          fill="#455a64"
        />
        <path
          data-name="Path 7027"
          d="M66.538 171.999a2.883 2.883 0 01-.7-.244 39.832 39.832 0 01-1.866-.8c-1.56-.7-3.691-1.755-5.982-3.036s-4.29-2.549-5.71-3.482c-.7-.48-1.281-.884-1.664-1.17s-.592-.46-.571-.48a3.67 3.67 0 01.655.362l1.72 1.072c1.448.905 3.482 2.138 5.752 3.412s4.38 2.354 5.912 3.12l1.818.905a3.246 3.246 0 01.634.341z"
          fill="#455a64"
        />
        <path
          data-name="Path 7028"
          d="M35.717 201.364c.1 0 .153.251.125.536s-.139.515-.237.5-.153-.251-.125-.536.139-.507.237-.5z"
          fill="#455a64"
        />
        <path
          data-name="Path 7029"
          d="M36.169 197.211c.1 0 .125.487.063 1.058s-.188 1.031-.292 1.017-.125-.487-.063-1.058.195-1.028.292-1.017z"
          fill="#455a64"
        />
        <path
          data-name="Path 7030"
          d="M36.629 193.064c.1 0 .132.487.07 1.058s-.2 1.031-.3 1.017-.125-.487-.063-1.058.195-1.032.293-1.017z"
          fill="#455a64"
        />
        <path
          data-name="Path 7031"
          d="M37.106 188.913c.1 0 .125.487.063 1.058s-.2 1.031-.306 1.017-.125-.487-.056-1.058.205-1.024.299-1.017z"
          fill="#455a64"
        />
        <path
          data-name="Path 7032"
          d="M37.597 184.791c.1 0 .125.487.056 1.059s-.2 1.024-.3 1.017-.125-.487-.056-1.058.202-1.018.3-1.018z"
          fill="#455a64"
        />
        <path
          data-name="Path 7033"
          d="M38.105 180.613c.1 0 .125.487.056 1.058s-.209 1.031-.306 1.017-.125-.487-.056-1.058.201-1.017.306-1.017z"
          fill="#455a64"
        />
        <path
          data-name="Path 7034"
          d="M38.614 176.476c.1 0 .125.487.056 1.058s-.216 1.031-.313 1.017-.118-.487-.049-1.058.208-1.024.306-1.017z"
          fill="#455a64"
        />
        <path
          data-name="Path 7035"
          d="M39.143 172.34c.1 0 .118.48.049 1.058s-.216 1.024-.313 1.01-.118-.487-.049-1.058.215-1.024.313-1.01z"
          fill="#455a64"
        />
        <path
          data-name="Path 7036"
          d="M39.686 168.197c.1 0 .118.487.042 1.058s-.216 1.024-.313 1.01-.118-.487-.049-1.058.215-1.02.32-1.01z"
          fill="#455a64"
        />
        <path
          data-name="Path 7037"
          d="M40.236 164.06c.1 0 .118.487.042 1.058s-.223 1.024-.32 1.01-.118-.487-.042-1.058.223-1.023.32-1.01z"
          fill="#455a64"
        />
        <path
          data-name="Path 7038"
          d="M40.8 159.924c.1 0 .118.487.042 1.058s-.223 1.024-.32 1.01-.118-.487-.042-1.058.223-1.024.32-1.01z"
          fill="#455a64"
        />
        <path
          data-name="Path 7039"
          d="M41.386 155.788c.1 0 .111.487 0 1.058s-.223 1.024-.32 1.01-.118-.487 0-1.058.215-1.024.32-1.01z"
          fill="#455a64"
        />
        <path
          data-name="Path 7040"
          d="M41.977 151.654c.1 0 .111.487 0 1.058s-.23 1.024-.327 1.01-.111-.487 0-1.058.222-1.027.327-1.01z"
          fill="#455a64"
        />
        <path
          data-name="Path 7041"
          d="M42.576 147.521c.1 0 .111.487 0 1.058s-.23 1.024-.327 1-.111-.487 0-1.058.229-1.014.327-1z"
          fill="#455a64"
        />
        <path
          data-name="Path 7042"
          d="M43.196 143.392c.1 0 .111.487 0 1.058s-.23 1.017-.334 1-.1-.487 0-1.058.237-1.014.334-1z"
          fill="#455a64"
        />
        <path
          data-name="Path 7043"
          d="M43.822 139.263c.1 0 .111.487 0 1.058s-.237 1.017-.334 1-.111-.487 0-1.058.237-1.014.334-1z"
          fill="#455a64"
        />
        <path
          data-name="Path 7044"
          d="M44.47 135.13c.1 0 .1.494 0 1.065s-.244 1.017-.341 1-.1-.494 0-1.058.243-1.017.341-1.007z"
          fill="#455a64"
        />
        <path
          data-name="Path 7045"
          d="M45.126 131.011c.1 0 .1.494 0 1.058s-.244 1.024-.348 1-.1-.487 0-1.058.248-1.014.348-1z"
          fill="#455a64"
        />
        <path
          data-name="Path 7046"
          d="M45.765 126.888c.1 0 .1.494 0 1.058s-.244 1.024-.348 1-.1-.487 0-1.058.272-1.014.348-1z"
          fill="#455a64"
        />
        <path
          data-name="Path 7047"
          d="M46.462 122.769c.1 0 .1.487 0 1.058s-.251 1.017-.348 1-.1-.487 0-1.058.257-1.017.348-1z"
          fill="#455a64"
        />
        <path
          data-name="Path 7048"
          d="M47.158 118.636c.1 0 .1.494 0 1.065s-.258 1.01-.355 1-.1-.487 0-1.058.265-1.007.355-1.007z"
          fill="#455a64"
        />
        <path
          data-name="Path 7049"
          d="M47.854 114.535c.1 0 .1.494 0 1.058s-.258 1.017-.362 1-.1-.494 0-1.058.279-1.014.362-1z"
          fill="#455a64"
        />
        <path
          data-name="Path 7050"
          d="M48.697 110.44c.1 0 .077.5-.042 1.058s-.3 1-.4.982-.077-.487.049-1.058.299-1.006.393-.982z"
          fill="#455a64"
        />
        <path
          data-name="Path 7051"
          d="M49.943 106.45c.091.035 0 .5-.167 1.045s-.411.961-.5.933 0-.5.16-1.052.375-.968.507-.926z"
          fill="#455a64"
        />
        <path
          data-name="Path 7052"
          d="M51.224 103.608c.091.056.056.3-.084.55s-.313.418-.4.376-.056-.286.077-.543.324-.431.407-.383z"
          fill="#455a64"
        />
        <path
          data-name="Path 7053"
          d="M52.729 163.608c.084.056 0 .292-.139.536s-.355.39-.439.334 0-.292.132-.536.362-.39.446-.334z"
          fill="#455a64"
        />
        <path
          data-name="Path 7054"
          d="M54.996 160.105c.084.056-.1.487-.411.975s-.634.836-.7.78.1-.494.411-.975.619-.836.7-.78z"
          fill="#455a64"
        />
        <path
          data-name="Path 7055"
          d="M57.227 156.567c.084.056-.1.494-.4.982s-.62.843-.7.787.091-.487.4-.982.609-.843.7-.787z"
          fill="#455a64"
        />
        <path
          data-name="Path 7056"
          d="M59.393 153.002c.09.049-.084.487-.383.982s-.606.857-.7.8.091-.494.39-.989.609-.849.693-.793z"
          fill="#455a64"
        />
        <path
          data-name="Path 7057"
          d="M61.517 149.402c.084.049-.084.494-.369.989s-.6.863-.7.815.077-.5.369-1 .612-.86.7-.804z"
          fill="#455a64"
        />
        <path
          data-name="Path 7058"
          d="M63.585 145.794c.084.049-.077.494-.362 1s-.578.87-.7.815.07-.494.355-1 .616-.891.707-.815z"
          fill="#455a64"
        />
        <path
          data-name="Path 7059"
          d="M65.597 142.111c.084.042-.07.494-.341 1s-.571.877-.662.829.063-.5.341-1 .572-.878.662-.829z"
          fill="#455a64"
        />
        <path
          data-name="Path 7060"
          d="M67.555 138.42c.091.042-.056.494-.327 1.01s-.557.884-.648.836.063-.494.327-1.01.557-.885.648-.836z"
          fill="#455a64"
        />
        <path
          data-name="Path 7061"
          d="M69.115 134.555c.1 0 .077.5-.118 1.058s-.453.961-.543.919 0-.494.2-1.038.356-.959.461-.939z"
          fill="#455a64"
        />
        <path
          data-name="Path 7062"
          d="M68.78 130.475c.091-.042.369.362.515.94a1.525 1.525 0 010 1.079c-.077.007-.2-.446-.341-.989s-.264-1.03-.174-1.03z"
          fill="#455a64"
        />
        <path
          data-name="Path 7063"
          d="M66.405 127.041c.07-.063.432.244.8.7s.6.87.515.926-.439-.251-.8-.7-.591-.856-.515-.926z"
          fill="#455a64"
        />
        <path
          data-name="Path 7064"
          d="M63.634 123.915c.077-.07.446.23.829.662s.634.836.557.905-.446-.23-.829-.7-.633-.796-.557-.867z"
          fill="#455a64"
        />
        <path
          data-name="Path 7065"
          d="M60.863 120.788c.077-.07.446.23.829.662s.634.836.557.905-.446-.23-.829-.7-.634-.797-.557-.867z"
          fill="#455a64"
        />
        <path
          data-name="Path 7066"
          d="M58.091 117.661c.077-.07.446.23.829.7s.634.836.557.905-.446-.23-.829-.662-.627-.869-.557-.943z"
          fill="#455a64"
        />
        <path
          data-name="Path 7067"
          d="M55.327 114.535c.07-.07.446.23.829.662s.627.836.557.9-.446-.23-.829-.662-.634-.837-.557-.9z"
          fill="#455a64"
        />
        <path
          data-name="Path 7068"
          d="M52.555 111.408c.077-.07.446.23.829.662s.634.836.557.9-.446-.23-.829-.7-.633-.8-.557-.862z"
          fill="#455a64"
        />
        <path
          data-name="Path 7069"
          d="M50.479 109.062c.077-.063.292.056.48.272s.286.446.209.515-.292-.056-.48-.272-.285-.446-.209-.515z"
          fill="#455a64"
        />
        <path
          data-name="Path 7070"
          d="M61.642 92.724a4.114 4.114 0 01.355.46l.954 1.344c.822 1.184 1.985 2.9 3.377 5.063 2.785 4.3 6.434 10.4 10.606 17.026 2.089 3.315 4.178 6.407 6.093 9.2a11.587 11.587 0 012.089 4.387 7.229 7.229 0 01.042 2.221 9.46 9.46 0 01-.55 1.964 21.588 21.588 0 01-1.657 3.05l-1.393 2.214-.863 1.393a3.479 3.479 0 01-.313.487 3.974 3.974 0 01.251-.522c.174-.341.446-.822.808-1.448l1.337-2.242a21.931 21.931 0 001.567-3.064 9.652 9.652 0 00.508-1.915 7.277 7.277 0 00-.056-2.124 11.393 11.393 0 00-2.04-4.255 213.91 213.91 0 01-6.128-9.192c-4.178-6.636-7.764-12.75-10.515-17.089-1.393-2.173-2.493-3.914-3.28-5.118l-.9-1.393a2.82 2.82 0 01-.293-.446z"
          fill="#455a64"
        />
      </g>
    </svg>
  );
}

export default SvgPageMaintenance;
