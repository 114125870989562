import * as React from 'react'
import Config from '../config'
import { UserRole } from 'types'
import useApi from 'hooks/useApi'
import useAuth from 'hooks/useAuth'
import moment from 'moment-timezone'
import { toast } from 'react-toastify'
import { getUserRole } from 'utils/auth'
import { MutatingDots } from 'react-loader-spinner'
import { Helmet } from 'react-helmet-async'
import { useHistory } from 'react-router-dom'
import ErrorBox from 'components/atoms/error-box'
import { ApiContextInterface } from 'providers/api'
import ErrorCard from 'components/organisms/error-card'
import BroadcastCalendarTemplate from 'components/templates/broadcast-calendar'
import BroadcastCalendarCard from 'components/molecules/broadcast-calendar-card'
import { BroadcastDaySelection } from 'components/molecules/broadcast-day-selection'
import { BroadcastProgramFixtureListItem } from '@tetahq/bironbir_api_client/models/broadcast-program-fixture-list-item'
import { AuthContextProps } from '../providers/auth'
import BroadcastReplayModal from '../components/organisms/broadcast-replay-modal'

import styles from 'components/templates/broadcast-calendar/style.module.css'

const BroadcastCalendarScreen: React.FC = () => {
    const api = useApi() as ApiContextInterface
    const userRole = getUserRole()
    const auth = useAuth() as AuthContextProps

    const [broadcasts, setBroadcasts] = React.useState<BroadcastProgramFixtureListItem[]>([])

    const history = useHistory()

    const [loading, setLoading] = React.useState(true)
    const [selectedDay, setSelectedDay] = React.useState<string>(moment().format('YYYY-MM-DD'))
    const [selectedBroadcast, setSelectedBroadcast] = React.useState<BroadcastProgramFixtureListItem | undefined>(undefined)

    const onNewDaySelected = (newDaySelection: string) => {
        setSelectedDay(moment(newDaySelection).format('YYYY-MM-DD'))
    }

    React.useEffect(() => {
        (async () => {
            setLoading(true)
            if (userRole === UserRole.ROLE_PERSONAL_TRAINER && auth.isLoggedIn) {
                setBroadcasts([])
                try {
                    const { data } = await api.PersonalTrainer.listMyBroadcastFixture(selectedDay)
                    if (data.broadcastPrograms) {
                        setBroadcasts(data.broadcastPrograms)
                    }
                } catch {
                }
            } else {
                setBroadcasts([])
            }
            setLoading(false)
        })()
    }, [selectedDay, api, userRole, auth.isLoggedIn])

    const broadcastClick = (item: BroadcastProgramFixtureListItem) => {
        const startMoment = moment(item.startsAt)
        const endMoment = moment(item.endsAt)
        const isLive = moment().isBetween(startMoment, endMoment, 'seconds', '[]')

        if (isLive && !item.isEnded) {
            const liveBroadcast = broadcasts.find(x => x.isLive)

            if (liveBroadcast && liveBroadcast.id !== item.id) {
                toast.error('Lütfen mevcut canlı yayınınızı sonlandırınız.', { toastId: 'broadcastMustEnd' })
                return
            }

            history.push({ pathname: `/canli-yayin/${item.agoraRoomId}` })

        } else {
            if (item.replayVideoHlsUrl) {
                setSelectedBroadcast(item)
            } else {
                toast.warning('Canlı Yayın Tekrarı Henüz Yayınlanmadı!', { toastId: 'broadcastReplayNotYet' })
            }
        }
    }

    const renderLoader = React.useMemo(() => {
        if (!loading) {
            return null
        }

        return (
            <div className={styles.listLoader}>
                <MutatingDots color={'#334362'} secondaryColor={'#e3352a'} height={100} width={100} />
            </div>
        )
    }, [loading])

    const renderEmptyList = React.useMemo(() => {
        if (broadcasts.length > 0 || loading) {
            return null
        }

        return (
            <ErrorBox text={'Güne ait yayın programı henüz bulunmamaktadır.'} style={{ width: '100%' }} size={'sm'} align={'center'} />
        )
    }, [broadcasts, loading])

    if (!auth.isLoggedIn || userRole !== UserRole.ROLE_PERSONAL_TRAINER) {
        return (
            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <ErrorCard type={'401'} />
            </div>
        )
    }

    return (
        <BroadcastCalendarTemplate>
            <Helmet>
                <title>Yayın Takvimi | {Config.app.name}</title>
            </Helmet>

            <BroadcastDaySelection selectedDay={selectedDay} onClickedOnDay={onNewDaySelected} />

            <div className={styles['header']}>
                <div className={styles['card-wrapper']}>
                    {broadcasts.map((broadcast) => (
                        <BroadcastCalendarCard
                            key={broadcast.id + 'broadcast'}
                            broadcast={broadcast}
                            onClick={broadcastClick}
                        />
                    ))}
                </div>
                {renderEmptyList}
                {renderLoader}
            </div>

            <BroadcastReplayModal broadcast={selectedBroadcast} onClose={() => setSelectedBroadcast(undefined)} />
        </BroadcastCalendarTemplate>
    )
}

export default BroadcastCalendarScreen
