import * as React from 'react'
import Text from '../text'

import styles from './style.module.css'

type ItemProps = {
    trailing?: React.ReactNode
}

type ListProps = {
    Item: React.FC<ItemProps>
}

type ListCardProps = {
    title?: string
}

const ListCard: React.FC<ListCardProps> & ListProps = ({ title, children }) => {
    return <div className={styles.wrapper}>
        {title && <div className={styles.title}><Text size="lg" weight="medium">{title}</Text></div>}
        {children}
    </div>
}

const Item: React.FC<ItemProps> = ({ trailing, children }) => {
    return (
        <div className={styles.item}>
            {children}
            {trailing && <div className={styles.trailing}>{trailing}</div>}
        </div>
    )
}

export default ListCard

ListCard.Item = Item
