import * as React from 'react'
import Input, { IInput, InputProps, ITextarea } from 'components/atoms/input'
import InputSaveButton from 'components/atoms/input-save-button'

import styles from './style.module.css'
import InputReverseButton from 'components/atoms/input-reverse-button'

type InputWithActionsProps = {
    onSubmit: () => void
    onReverse: () => void
    loading?: boolean
    value?: string
    initialValue?: string
    onChangeText?: (text: string) => void
    disableActions?: boolean
}

type InputWithActionsTypes = InputWithActionsProps & (InputProps & (IInput | ITextarea))

const InputWithActions = ({ onSubmit, onReverse, loading, value, initialValue, onChangeText, disableActions, ...props }: InputWithActionsTypes) => {
    const onChangeHandler = (text: string) => {
        if (typeof onChangeText === 'function') {
            onChangeText(text)
        }
    }

    return (
        <Input
            value={value}
            onChangeText={onChangeHandler}
            {...props}
            trailingComponent={
                <div className={styles.actions}>
                    {initialValue !== value && !disableActions && <InputReverseButton onReverse={onReverse} />}
                    {initialValue !== value && !disableActions && <InputSaveButton loading={loading} onSubmit={onSubmit} />}
                </div>
            }
        />
    )
}

export default InputWithActions
