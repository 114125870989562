import * as React from 'react'
import useApi from 'hooks/useApi'
import useAuth from 'hooks/useAuth'
import Input from '../../atoms/input'
import { toast } from 'react-toastify'
import Text from 'components/atoms/text'
import Button from 'components/atoms/button'
import Card from 'components/molecules/card'
import { AuthContextProps } from 'providers/auth'
import { ApiContextInterface } from 'providers/api'
import Modal, { ModalSize, ModalType } from 'components/atoms/modal'

import styles from './style.module.css'

export type ContactModalProps = {
    isVisible: boolean
    onModalClose: (confirm?: boolean) => void
}

const ContactModal = ({ isVisible, onModalClose }: ContactModalProps) => {
    const api = useApi() as ApiContextInterface
    const auth = useAuth() as AuthContextProps

    const [title, setTitle] = React.useState<string | undefined>(undefined)
    const [content, setContent] = React.useState<string | undefined>(undefined)
    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        if (!auth.isLoggedIn) {
            onModalClose()
        }
    }, [auth.isLoggedIn, onModalClose])

    const onModalCloseHandler = () => {
        if (typeof onModalClose === 'function') {
            onModalClose()
        }
    }

    const onConfirmButtonClickHandler = async () => {
        setLoading(true)

        try {
            await api.CustomerService.sendContactMessage({ content, title })
            toast.success('Bizimle iletişime geçtiğiniz için teşekkür ederiz')
            onModalClose(true)
        } catch {
        }

        setLoading(false)
    }

    return (
        <Modal isVisible={isVisible} onModalClose={onModalClose} type={ModalType.ActionSheet} size={ModalSize.Large}>
            <div className={styles.container}>
                <Card
                    type={'light'}
                    paddingles
                    heading={{
                        title: 'Bizimle İletişime Geçin',
                        size: 'md',
                    }}
                >
                    <div className={styles.inputWrapper}>
                        <Text size={'base'} type={'dimmed'} weight={'medium'}>
                            Konu Başlığı
                        </Text>
                        <Text size={'base'} type={'soft-blue'} weight={'medium'}>
                            (Zorunlu) (Minimum 3 Karakter)
                        </Text>
                        <Input onChangeText={setTitle} label={'Başlık'} />
                    </div>

                    <div className={styles.inputWrapper}>
                        <Text size={'base'} type={'dimmed'} weight={'medium'}>
                            Lütfen bir açıklama giriniz
                        </Text>
                        <Text size={'base'} type={'soft-blue'} weight={'medium'}>
                            (Zorunlu) (Minimum 10 Karakter)
                        </Text>
                        <Input onChangeText={setContent} label={'Açıklama'} rows={5} multiline />
                    </div>

                    <div className={styles.buttonsWrapper}>
                        <Button size={'large'} type={'gray'} onClick={onModalCloseHandler}>
                            Vazgeç
                        </Button>
                        <Button
                            style={{ whiteSpace: 'nowrap' }}
                            size={'large'}
                            type={'danger-2'}
                            onClick={onConfirmButtonClickHandler}
                            loading={loading ?? false}
                            disabled={typeof title === 'undefined' || typeof content === 'undefined' || title.length < 3 || content.length < 10}
                        >
                            Gönder
                        </Button>
                    </div>
                </Card>
            </div>
        </Modal>
    )
}

export default ContactModal
