import { FC } from 'react'
import Config from '../config'
import { Helmet } from 'react-helmet-async'
import ErrorCard from 'components/organisms/error-card'
import NotFoundTemplate from 'components/templates/not-found'

const MultiLoginDetectScreen: FC = () => {
    return (<NotFoundTemplate>
        <Helmet>
            <title>Şüpheli Giriş | {Config.app.name}</title>
        </Helmet>
        <div>
            <ErrorCard type={'multiLogin'} />
        </div>
    </NotFoundTemplate>)
}

export default MultiLoginDetectScreen
