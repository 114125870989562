import { FC, ReactNode } from 'react'

import styles from './style.module.css'
import { use100vh } from 'react-div-100vh'

export { styles }

const NotFoundTemplate: FC<{ fullPage?: boolean, children: ReactNode }> = ({ fullPage, children }) => {
    const height = use100vh()

    return (
        <div className={styles.wrapper} style={{ height: fullPage ? height ?? '100vh' : '100%' }}>{children}</div>
    )
}

export default NotFoundTemplate
