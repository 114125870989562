import * as React from 'react'

export default function useMedia(query: string) {
    const [matches, setMatches] = React.useState<boolean>(false)

    React.useEffect(() => {
        const media = window.matchMedia(query)
        if (media.matches !== matches) setMatches(media.matches)

        const listener = () => {
            setMatches(media.matches)
        }

        if(typeof media.addEventListener === 'function') {
            media.addEventListener('change', listener);
        } else if(typeof media.addListener === 'function') {
            media.addListener(listener);
        }

        return () => {
            if(typeof media.removeEventListener === 'function') {
                media.removeEventListener('change', listener);
            } else if(typeof media.removeListener === 'function') {
                media.removeListener(listener);
            }
        }
    }, [matches, query])


    return matches
}
