import * as React from 'react'
import { UserRole } from 'types'
import useApi from 'hooks/useApi'
import useAuth from 'hooks/useAuth'
import { getUserRole } from 'utils/auth'
import { AuthContextProps } from './auth'
import { ApiContextInterface } from './api'
import { GetCurrentAdvertiseQueryResult } from '@tetahq/bironbir_api_client'
import { toast } from 'react-toastify'

type AdvertiseContextProps = {
    initialPage: string
    modalShown: boolean
    hideModal: () => void
    showModal: (initialPage?: string) => void
    setModalShown: React.Dispatch<React.SetStateAction<boolean>>
    isSeen: boolean
    advertise: GetCurrentAdvertiseQueryResult | undefined
}

export const AdvertiseContext = React.createContext<Partial<AdvertiseContextProps>>({})

export const AdvertiseProvider: React.FC = ({ children }) => {
    const api = useApi() as ApiContextInterface
    const auth = useAuth() as AuthContextProps
    const userRole = getUserRole()

    const lastAdvertise = localStorage.getItem('advertise')
    const [modalShown, setModalShown] = React.useState<boolean>(false)
    const [advertise, setAdvertise] = React.useState<GetCurrentAdvertiseQueryResult | undefined>(undefined)

    React.useEffect(() => {
        if (userRole !== UserRole.ROLE_CUSTOMER || !auth.user) {
            setModalShown(false)
            return
        }

        if (typeof advertise === 'undefined') {
            api.Advertise.getCurrentAdvertise().then(response => {
                if (response.data) {
                    setAdvertise(response.data)
                }
            }).catch(() => {
                toast.error('Lütfen Reklam Engelleyicinizi Kapatınız')
            })
        }

        if (typeof advertise === 'undefined') return

        if (auth.modalShown) {
            setModalShown(false)
            return
        }

        if (lastAdvertise === null) {
            setModalShown(true)
        } else if (parseInt(lastAdvertise) !== advertise.id) {
            setModalShown(true)
        }
    }, [advertise, lastAdvertise, auth.user, userRole, api.Advertise, auth.modalShown])

    const showModal = () => {
        setModalShown(true)
    }

    const hideModal = () => {
        setModalShown(false)
    }

    return (
        <AdvertiseContext.Provider
            value={{ modalShown, showModal, hideModal, setModalShown, advertise }}>{children}</AdvertiseContext.Provider>
    )
}
