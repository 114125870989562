import classNames from 'classnames'
import * as React from 'react'
import ReactDOM from 'react-dom'

import styles from './style.module.css'
import { use100vh } from 'react-div-100vh'

export enum ModalSize {
    Small,
    Medium,
    Large,
    Fullscreen
}

export enum ModalType {
    Fullscreen, // Centered in web
    Centered,
    ActionSheet,
}

type ModalProps = {
    isVisible: boolean
    onModalClose: () => void
    size?: ModalSize
    type?: ModalType
    backdropUnClickable?: boolean
    escUnClickable?: boolean
    transparent?: boolean
    children: any
}

const Modal: React.NamedExoticComponent<ModalProps> = React.memo(({ isVisible, onModalClose, children, size, type, backdropUnClickable, escUnClickable,transparent }) => {
    const [isBrowser, setIsBrowser] = React.useState(false)
    const height = use100vh()

    const backdropHandler = () => {
        if (!backdropUnClickable) {
            onModalClose()
        }
    }

    React.useEffect(() => {
        setIsBrowser(true)
    }, [])

    React.useEffect(() => {
        document.body.style.overflow = isVisible ? 'hidden' : 'auto'
    }, [isVisible])

    React.useEffect(() => {
        const handleEsc = (event: any) => {
            if (!escUnClickable && event.keyCode === 27) {
                onModalClose()
            }
        }
        window.addEventListener('keydown', handleEsc)

        return () => {
            window.removeEventListener('keydown', handleEsc)
        }
    }, [escUnClickable, onModalClose])

    const modalBody = isVisible ? (
        <div
            className={classNames(styles.overlay, {
                [styles.large]: size === ModalSize.Large,
                [styles.medium]: size === ModalSize.Medium || !size,
                [styles.small]: size === ModalSize.Small,
                [styles.sizeFullscreen]: size === ModalSize.Fullscreen,
                [styles.fullscreen]: type === ModalType.Fullscreen || !type,
                [styles.centered]: type === ModalType.Centered,
                [styles.actionSheet]: type === ModalType.ActionSheet,
                [styles.transparent]: transparent
            })}
            style={{ height: height ?? '100vh' }}
        >
            <div className={styles.backdrop} onClick={backdropHandler} />
            <div className={styles.wrapper}>
                <div className={styles.modal}>{children}</div>
            </div>
        </div>
    ) : null

    if (!isBrowser) return null

    return ReactDOM.createPortal(modalBody, document.getElementById('modal-root') as HTMLElement)
})

export default Modal
