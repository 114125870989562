import * as React from 'react'
import { PageProvider } from './page'
import { PaymentProvider } from './payment'
import { AdvertiseProvider } from './advertise'
import { ToastContainer } from 'react-toastify'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { NotificationContext, NotificationContextProps, NotificationProvider } from './notification'
import { AuthContext, AuthContextProps, AuthProvider } from './auth'

import 'react-toastify/dist/ReactToastify.css'
import { ChatProvider } from './chat'
import useApi from '../hooks/useApi'
import { ApiContextInterface } from './api'
import useMedia from '../hooks/useMedia'

const Providers: React.FC = ({ children }) => {
    const api = useApi() as ApiContextInterface
    const isMobile = useMedia('(max-width: 1024px)')

    return (
        <BrowserRouter>
            <AuthProvider>
                <AuthContext.Consumer>
                    {(authProvider: AuthContextProps | {}) => (
                        <AdvertiseProvider>
                            <PageProvider>
                                <PaymentProvider>
                                    <NotificationProvider>
                                        <NotificationContext.Consumer>
                                            {(notificationProvider: NotificationContextProps | {}) => (
                                                <HelmetProvider>
                                                    <ChatProvider api={api} auth={authProvider as AuthContextProps} notification={notificationProvider as NotificationContextProps}>

                                                        {children}

                                                        <ToastContainer
                                                            position={isMobile ? 'top-center' : 'top-right'}
                                                            autoClose={2000}
                                                            hideProgressBar
                                                            newestOnTop
                                                            closeOnClick
                                                            rtl={false}
                                                            pauseOnFocusLoss={false}
                                                            draggable
                                                            pauseOnHover
                                                            limit={isMobile ? 1 : 3}
                                                            closeButton={<></>}
                                                            theme={'colored'}
                                                        />

                                                    </ChatProvider>
                                                </HelmetProvider>
                                            )}
                                        </NotificationContext.Consumer>
                                    </NotificationProvider>
                                </PaymentProvider>
                            </PageProvider>
                        </AdvertiseProvider>
                    )}
                </AuthContext.Consumer>
            </AuthProvider>
        </BrowserRouter>
    )
}

export default Providers
