import React, { FC } from 'react'
import classNames from 'classnames'
import { PlayFill } from 'components/icons'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'

import styles from './style.module.css'
import 'react-circular-progressbar/dist/styles.css'

type PlanIndicatorProps = {
    onPress?: () => void
    percentage: number
}

const PlanIndicator: FC<PlanIndicatorProps> = ({ onPress, percentage }) => {
    const onPressHandler = (e: React.MouseEvent) => {
        e.preventDefault()
        onPress && onPress()
    }

    return (
        <div
            onClick={onPressHandler}
            tabIndex={-1}
            role="button"
            className={classNames(styles.wrapper, {
                [styles.full]: percentage === 100,
                [styles.inProgress]: percentage > 0 && percentage < 100,
            })}
        >
            <PlayFill />
            {percentage > 0 && percentage < 100 && (
                <div className={styles.progressbar}>
                    <CircularProgressbar
                        value={percentage}
                        strokeWidth={8}
                        styles={buildStyles({
                            strokeLinecap: 'butt',
                            pathColor: '#E3352A',
                            trailColor: 'transparent',
                            backgroundColor: 'transparent',
                        })}
                    />
                </div>
            )}
        </div>
    )
}

export default PlanIndicator
