import * as React from 'react'
import Text from 'components/atoms/text'
import Button from 'components/atoms/button'
import { CheckboxCircleFill } from 'components/icons'
import Badge, { BadgeType } from 'components/atoms/badge'
import { GetPackageCourseDetailsQueryResult } from '@tetahq/bironbir_api_client'
import ImageWithFallback from '../../atoms/image-with-fallback'

import styles from './style.module.css'

type TrainingInfoCardProps = {
    training: GetPackageCourseDetailsQueryResult
    onClick: () => void
    loading?: boolean
    platform: 'ios' | 'android' | 'web'
}
const TrainingInfoCard: React.FC<TrainingInfoCardProps> = ({ training, onClick, loading, platform }) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.heading}>
                <ImageWithFallback
                    srcSet={training.coverPhoto ? `${training.coverPhoto.coverImage1X}, ${training.coverPhoto.coverImage2X} 1.5x, ${training.coverPhoto.coverImage3X} 2x, ${training.coverPhoto.coverImage4X} 3x,` : undefined}
                    src={training.coverPhoto?.coverImage4X}
                    fallbackSrc={'/images/fallbacks/250-250.png'}
                    alt=''
                />
                {training.isPurchased && <Badge type={BadgeType.GreenTransparent}>
                    <CheckboxCircleFill />
                </Badge>}
                {!training.isPurchased &&
                    <Badge type={BadgeType.RedTransparent}>{training.price === 0 ? 'Ücretsiz' : `₺${platform === 'ios' ? training.iosPrice : training.price}`}</Badge>}
            </div>
            <Text className={styles.title} size='xl' weight='medium'>
                {training.name}
            </Text>

            <div className={styles.body}>
                {training.isPurchased && <Button type='success' variation='block'>
                    Bu Pakete Sahipsiniz
                </Button>}
                {!training.isPurchased && <Button type='danger-2' variation='block' onClick={onClick} loading={loading}>
                    {training.price === 0 ? 'Ücretsiz Al' : `Paketi Satın Al`}
                </Button>}
            </div>
        </div>
    )
}

export default TrainingInfoCard
