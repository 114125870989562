import * as React from 'react'
import Text from '../../atoms/text'
import Button from '../../atoms/button'
import { useHistory } from 'react-router-dom'
import useAdvertise from 'hooks/useAdvertise'
import { LogoFull, StarFill } from '../../icons'
import Modal, { ModalSize } from 'components/atoms/modal'
import ImageWithFallback from '../../atoms/image-with-fallback'

import styles from './style.module.css'

type LineContent = string

type ContentJsonData = {
    Title: string
    LineContents: LineContent[]
}

const AdvertiseModal: React.FC = () => {
    const history = useHistory()

    const advertise = useAdvertise()
    const [loading, setLoading] = React.useState(false)
    const [content, setContent] = React.useState<ContentJsonData | undefined>()

    React.useEffect(() => {
        if (typeof advertise.advertise === 'undefined') {
            setLoading(true)
        } else {
            setLoading(false)
        }
    }, [advertise])

    const hideModal = () => {
        advertise.hideModal?.()
        if (advertise.advertise?.id) {
            localStorage.setItem('advertise', advertise.advertise.id.toString())
        }
    }

    const handlePayment = () => {
        history.push({ pathname: '/abonelik' })
        hideModal()
    }

    React.useEffect(() => {
        if (typeof content === 'undefined' && advertise.advertise?.contentJsonData) {
            setContent(JSON.parse(advertise.advertise.contentJsonData))
        }
    }, [content, advertise])

    if (loading) {
        return null
    }

    return (
        <Modal size={ModalSize.Small} isVisible={advertise.modalShown ?? false} onModalClose={hideModal} backdropUnClickable transparent>
            <div className={styles.container}>
                <LogoFull className={styles.logo} width={141} height={56} />

                <div className={styles.wrapper}>
                    <ImageWithFallback
                        src={advertise.advertise?.coverPhoto?.coverImage1X}
                        srcSet={`${advertise.advertise?.coverPhoto?.coverImage1X}, ${advertise.advertise?.coverPhoto?.coverImage2X} 1.5x, ${advertise.advertise?.coverPhoto?.coverImage3X} 2x, ${advertise.advertise?.coverPhoto?.coverImage4X} 3x,`}
                        fallbackSrc='/images/fallbacks/250-250.png' alt='avatar'
                    />
                </div>
                <div className={styles.card}>
                    <div className={styles.contentWrapper}>
                        <div className={styles.content}>
                            <Text weight={'bold'} size={'xl'}>{content?.Title}</Text>
                            <div className={styles.lineContentWrapper}>
                                {content?.LineContents.map((line, i) => (
                                    <div className={styles.lineContent} key={i.toString() + 'lineContent'}>
                                        <StarFill /><Text>{line}</Text>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className={styles.buttons}>
                        <Button type='blured' variation='block' onClick={hideModal}>
                            Reklamı Geç
                        </Button>
                        <Button type='danger-2' variation='block' onClick={handlePayment}>
                            Abonelikleri Gör
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default AdvertiseModal
