import * as React from 'react'
import classnames from 'classnames'
import { Listbox } from '@headlessui/react'

import styles from './style.module.css'
import { ArrowDownSLine, CheckLine } from 'components/icons'

type ListBoxProps = {
    value: number
    onChange: (value: number) => void
    options: any[]
    text?: string
    disabled?: boolean
    valueIndexBased?: boolean
}

export default function ListBox({ value, onChange, options, text, disabled, valueIndexBased }: ListBoxProps) {
    return (
        <Listbox as='div' value={value} onChange={onChange} disabled={disabled ?? false}>
            {({ open }) =>
                <div className={classnames(styles.wrapper)}>
                    <Listbox.Button className={classnames(styles.button, { [styles['button-open']]: open }, { [styles['button-disabled']]: disabled })}>
                        <span className={classnames(styles['button-label'])}>{text ?? value}</span>
                        <span className={classnames(styles['button-indicator'])}>
                        <ArrowDownSLine />
                    </span>
                    </Listbox.Button>
                    <div className={classnames(styles['options-wrapper'])}>
                        <Listbox.Options className={classnames(styles.options)}>
                            {options.map((r, index) => (
                                <Listbox.Option key={`option-${index}`} value={valueIndexBased ? index : r}>
                                    {({ selected, active }) => (
                                        <div
                                            className={classnames(styles.option, {
                                                [styles.selected]: selected,
                                                [styles.active]: active,
                                            })}
                                        >
                                            <span
                                                className={classnames(styles['option-label'])}>{typeof r === 'object' ? r.label !== '' || null || undefined ? r.label : r.deviceId : r}</span>
                                            <span>
                                            <CheckLine />
                                        </span>
                                        </div>
                                    )}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </div>
                </div>
            }
        </Listbox>
    )
}
