export const validateEmail = (text: string) => {
    const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i)
    return pattern.test(text)
}

export const replaceNumber = (text: string) => {
    return text.replace(/[^0-9]/g, '')
}

export const validatePhoneNumber = (text: string) => {
    const replaced = replaceNumber(text)
    return replaced.length > 9 && replaced.length < 12 && replaced[0] !== '0'
}
