import * as React from 'react'
import Text from 'components/atoms/text'
import Button from 'components/atoms/button'
import Card from 'components/molecules/card'
import Modal, { ModalSize, ModalType } from 'components/atoms/modal'

import styles from './style.module.css'

export type ConfirmModalCardProps = {
    title: string
    description: string
    confirmButtonText: string
    cancelButtonText?: string
    isVisible: boolean
    onModalClose: () => void
    onConfirmButtonClick: () => void
    onCancelButtonClick: () => void
    loading?: boolean
}

const ConfirmModalCard = ({
                              isVisible,
                              onModalClose,
                              onCancelButtonClick,
                              onConfirmButtonClick,
                              title,
                              description,
                              confirmButtonText,
                              cancelButtonText,
                              loading,
                          }: ConfirmModalCardProps) => {
    const onModalCloseHandler = () => {
        if (typeof onModalClose === 'function') {
            onModalClose()
        }
    }

    const onCancelButtonClickHandler = () => {
        if (typeof onCancelButtonClick === 'function') {
            onCancelButtonClick()
        }
    }

    const onConfirmButtonClickHandler = () => {
        if (typeof onConfirmButtonClick === 'function') {
            onConfirmButtonClick()
        }
    }

    return (
        <Modal isVisible={isVisible} onModalClose={onModalCloseHandler} type={ModalType.ActionSheet} size={ModalSize.Large}>
            <div className={styles.container}>
                <Card
                    type={'light'}
                    heading={{
                        title,
                        size: 'lg',
                    }}
                    paddingles={true}
                >
                    <div className={styles.descriptionWrapper}>
                        <Text size={'base'} type={'dimmed'} weight={'medium'}>
                            {description}
                        </Text>
                    </div>

                    <div className={styles.buttonsWrapper}>
                        <Button size={'large'} type={'pink-link'} onClick={onCancelButtonClickHandler}>
                            {cancelButtonText ?? 'Vazgeç'}
                        </Button>
                        <Button style={{ whiteSpace: 'nowrap' }} size={'large'} type={'gradient'} onClick={onConfirmButtonClickHandler} loading={loading ?? false}>
                            {confirmButtonText}
                        </Button>
                    </div>
                </Card>
            </div>
        </Modal>
    )
}

export default ConfirmModalCard
