import * as React from 'react'
import useInterval from 'hooks/useInterval'
import Text, { TextProps } from 'components/atoms/text'
import { remainingSeconds, secondsToTime } from 'utils/time-helpers'

const RemainingDuration = ({ date, onEnd, textProps }: { date: string, onEnd?: () => void, textProps?: TextProps }) => {
    const [seconds, setSeconds] = React.useState<number>(remainingSeconds(date))

    React.useEffect(() => {
        setSeconds(remainingSeconds(date))
    }, [date])

    useInterval(
        () => {
            setSeconds(r => --r)
        },
        seconds > 0 ? 1000 : null,
    )

    React.useMemo(() => {
        if (seconds <= 0 && typeof onEnd === 'function') {
            onEnd()
        }
    }, [seconds, onEnd])

    return (
        <Text size={textProps?.size ?? 'sm'} type={textProps?.type ?? 'dimmed'} weight={textProps?.weight ?? 'medium'}>
            Kalan Süre: {secondsToTime(seconds)}
        </Text>
    )
}

export default RemainingDuration
