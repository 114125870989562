const env = process.env.NODE_ENV || 'development'

const Config = {
    nodeEnv: env,
    app: {
        name: 'Online Training',
        eulaLink: 'https://app.onlinetraining.com.tr/?dokuman=son-kullanici-lisans-anlasmasi'
    },
    api: {
        baseUrl: 'https://api.onlinetraining.com.tr'
        // baseUrl: 'http://localhost:8080'
    },
    agora: {
        appId: 'ac5246b959244a779a38b9814d8fcc37',
    },
    chat: {
        appId: 'bironbir-id',
    },
    linkLy: {
        url: 'https://l.linklyhq.com/l/VoLh',
    },
    oneSignal: {
        appId: '182d26aa-21ae-43d0-a193-549cc6de3cab',
    },
    sentry: {
        url: 'https://18ca8263e6674fc7b86a047ac74b8c1d@o396942.ingest.sentry.io/5858159',
    },
    iap: {
        publicKey: 'appl_kSKxafGPKTzVldapNSZAuskmoog',
    },
}

export default Config
