import * as React from 'react'
import { UserRole } from 'types'
import useApi from 'hooks/useApi'
import useAuth from 'hooks/useAuth'
import { getUserRole } from 'utils/auth'
import { AuthContextProps } from './auth'
import { ApiContextInterface } from './api'
import { InfinityPagination, NotificationListItem } from '@tetahq/bironbir_api_client'

export interface NotificationContextProps {
    notifications: NotificationListItem[]
    setNotifications: React.Dispatch<React.SetStateAction<NotificationListItem[]>>
    addNotification: (item: NotificationListItem) => void
    setAsRead: (item: NotificationListItem) => void
    loadNextPage: () => void
    refresh: () => void
    setAsAllRead: () => void
    hasNewNotification: boolean
    pagination: InfinityPagination
}

export const NotificationContext = React.createContext<Partial<NotificationContextProps>>({})

export const NotificationProvider: React.FC = ({ children }) => {
    const api = useApi() as ApiContextInterface
    const auth = useAuth() as AuthContextProps
    const userRole = getUserRole()
    const [hasNewNotification, setHasNewNotification] = React.useState<boolean>(false)
    const [notifications, setNotifications] = React.useState<NotificationListItem[]>([])

    const [pagination, setPagination] = React.useState<InfinityPagination>({ isNextPageAvailable: false })

    const loadNextPage = async () => {
        if (pagination.isNextPageAvailable) {
            try {
                const { data } = await api.Notification.listMyNotifications(pagination.firstIdOfNextPage)
                setPagination(data.pagination)
                addNotifications(data.notifications)
            } catch {
            }
        }
    }

    const refresh = async () => {
        try {
            const { data } = await api.Notification.listMyNotifications()
            setNotifications(data.notifications)
            setPagination(data.pagination)
        } catch {
        }
    }

    const addNotification = (item: NotificationListItem) => {
        setNotifications(x => [item, ...x])
    }

    const addNotifications = (items: NotificationListItem[]) => {
        setNotifications(x => [...x, ...items])
    }

    const setAsRead = (item: NotificationListItem) => {
        setNotifications(x => {
            return x.map(y => {
                if (y === item) {
                    y.didRead = true
                }
                return y
            })
        })
    }

    const setAsAllRead = async () => {
        try {
            // await api.Notification.readAllNotifications()
        } catch {
        }
    }

    React.useEffect(() => {
        const noty = notifications.filter(x => !x.didRead)
        if (noty.length > 0) {
            setHasNewNotification(true)
        } else {
            setHasNewNotification(false)
        }
    }, [notifications])

    React.useEffect(() => {
        (async () => {
            if (auth.isLoggedIn && userRole !== UserRole.ROLE_PERSONAL_TRAINER) {
                try {
                    const { data } = await api.Notification.listMyNotifications()
                    setNotifications(data.notifications)
                    setPagination(data.pagination)
                } catch {
                }
            }
        })()
    }, [auth.isLoggedIn, userRole, api.Notification])

    return (
        <NotificationContext.Provider
            value={{
                notifications,
                setNotifications,
                hasNewNotification,
                refresh,
                addNotification,
                loadNextPage,
                setAsRead,
                pagination,
                setAsAllRead,
            }}
        >{children}</NotificationContext.Provider>
    )
}
