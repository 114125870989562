// @ts-nocheck

export function readFile(file: File): Promise<string> {
    return new Promise(resolve => {
        const reader = new FileReader()
        reader.addEventListener('load', () => resolve(reader.result as string), false)
        reader.readAsDataURL(file)
    })
}

const createImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => resolve(image))
        image.addEventListener('error', error => reject(error))
        image.src = url
    })

export type CropType = {
    x?: number
    y?: number
    width?: number
    height?: number
}

export async function getCroppedImg(imageSrc: string | ArrayBuffer | null, pixelCrop: CropType, type?: 'BASE64' | 'BLOB' = 'BLOB'): Promise<string> {
    const image: HTMLImageElement = await createImage(imageSrc);

    const canvas = document.createElement('canvas');

    canvas.width = 500;
    canvas.height = 500;

    const ctx = canvas.getContext('2d');

    ctx.drawImage(
        image, pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height, 0, 0, 500, 500
    );

    switch (type) {
        case 'BLOB':
            // As a blob
            return new Promise(resolve => {
                canvas.toBlob(file => {
                    resolve(URL.createObjectURL(file))
                }, 'image/jpeg')
            })
        case 'BASE64':
            // As Base64 string
            const a = canvas.toDataURL('image/jpeg', 1.0)
            return a
    }
}
