export const numberFormat = (count: number) => {
    const formatter = new Intl.NumberFormat('tr', {
        notation: 'compact',
    })
    return formatter.format(count)
}

export const getRandomNumber = (min: number, max: number) => Math.random() * (max - min) + min

export const roundHalf = (num?: number) => {
    if (!num) return 0
    return Math.round(num * 2) / 2
}
