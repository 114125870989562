import * as React from 'react'
import Input from '../../atoms/input'
import Text from 'components/atoms/text'
import Button from 'components/atoms/button'
import Card from 'components/molecules/card'
import Modal, { ModalSize, ModalType } from 'components/atoms/modal'

import styles from './style.module.css'

export type ConfirmModalCardProps = {
    isVisible: boolean
    onModalClose: () => void
    onConfirm: (title: string, content: string) => void
    loading?: boolean
}

const PTReportModalCard = ({ isVisible, onModalClose, onConfirm, loading }: ConfirmModalCardProps) => {
    const [title, setTitle] = React.useState<string | undefined>(undefined)
    const [content, setContent] = React.useState<string | undefined>(undefined)

    const onModalCloseHandler = () => {
        if (typeof onModalClose === 'function') {
            onModalClose()
        }
    }

    const onConfirmButtonClickHandler = () => {
        if (typeof onConfirm === 'function' && typeof title !== 'undefined' && typeof content !== 'undefined') {
            onConfirm(title, content)
        }
    }

    return (
        <Modal isVisible={isVisible} onModalClose={onModalCloseHandler} type={ModalType.ActionSheet} size={ModalSize.Large}>
            <div className={styles.container}>
                <Card
                    type={'light'}
                    paddingles
                    heading={{
                        title: 'Sorun Bildir',
                        size: 'md',
                    }}
                >
                    <div className={styles.inputWrapper}>
                        <Text size={'base'} type={'dimmed'} weight={'medium'}>
                            Konu Başlığı
                        </Text>
                        <Text size={'base'} type={'soft-blue'} weight={'medium'}>
                            (Zorunlu) (Minimum 3 Karakter)
                        </Text>
                        <Input onChangeText={setTitle} label={'Başlık'}/>
                    </div>

                    <div className={styles.inputWrapper}>
                        <Text size={'base'} type={'dimmed'} weight={'medium'}>
                            Lütfen bir açıklama giriniz
                        </Text>
                        <Text size={'base'} type={'soft-blue'} weight={'medium'}>
                            (Zorunlu) (Minimum 10 Karakter)
                        </Text>
                        <Input onChangeText={setContent} label={'Açıklama'} rows={5} multiline/>
                    </div>

                    <div className={styles.buttonsWrapper}>
                        <Button size={'large'} type={'gray'} onClick={onModalCloseHandler}>
                            Vazgeç
                        </Button>
                        <Button
                            style={{ whiteSpace: 'nowrap' }}
                            size={'large'}
                            type={'danger-2'}
                            onClick={onConfirmButtonClickHandler}
                            loading={loading ?? false}
                            disabled={typeof title === 'undefined' || typeof content === 'undefined' || title.length < 3 || content.length < 10}
                        >
                            Sorun Bildir
                        </Button>
                    </div>
                </Card>
            </div>
        </Modal>
    )
}

export default PTReportModalCard
