import * as React from 'react'
import { HeartFill } from '../../icons'
import styles from './style.module.css'
import { getRandomNumber } from '../../../utils/number-helpers'
import { v4 as uuidv4 } from 'uuid'

type FloatingHeartsProps = {
    count: number
}

const FloatingHearts: React.FC<FloatingHeartsProps> = ({ count }) => {
    const [items, setItems] = React.useState<JSX.Element[]>([])

    React.useEffect(() => {
            if (items.length < 20 && count > 0) {
                const randomColor = '#' + (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6)
                const itemId = uuidv4()
                setItems(x => [...x, (
                    <FloatingHeart
                        id={itemId}
                        randomColor={randomColor}
                        key={itemId}
                        onEnd={(id) => setItems(x => x.filter(z => z.props.id !== id))}
                    >
                        <HeartFill />
                    </FloatingHeart>
                )])
            }
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [count])

    return (
        <>{items}</>
    )
}

export default FloatingHearts

export const FloatingHeart: React.FC<{ randomColor: string, onEnd: (id: string) => void, id: string }> = ({ randomColor, onEnd, id }) => {
    setTimeout(() => {
        onEnd(id)
    }, 1500)

    return <div style={{ right: getRandomNumber(-10, 30), fill: randomColor }} className={styles.heart}><HeartFill /></div>
}
